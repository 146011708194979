import { useEffect, useLayoutEffect, useState } from 'react';
import track from 'utils/amplitudeWrapper';
import { useNavigate, useSearchParams, useLocation } from 'react-router-dom';
import axios from 'axios';
import delay from 'utils/delay';
import routes from 'base/constants/routes';
import { UserLocation } from 'base/types/UserLocation';
import { WelcomeModal } from 'components/WelcomeModal';
import { useFlowActions } from 'context/actions/flowActions';
import { useAppState, useAppDispatch } from 'context/AppState';
import { useMapActions } from 'context/actions/mapActions';
import { useRecommendationFormActions } from 'context/actions/recommendationFormActions';
import { FlowSteps, OptionType } from 'context/store/flowReducer';
import Header from 'containers/Header/Header';
import { findRegionalLocation } from 'base/services/location';
import { Root } from './Home.styles';
import { notification } from 'antd';
import { LandscapeWarningMessageContainer } from 'pages/BoundaryWizard/BoundaryWizard.styles';
import { useTranslation } from 'react-i18next';
import { useBreakpoint } from 'hooks';
import { SUPPORTED_LANGS } from 'base/constants/supportedLangs';
import { setLanguageAsperCountryCode } from 'utils/setLanguageAsperCountryCode';
import { trackDrawBoundaryIncomplete } from 'utils/helpers/amplitude';
import { ORIENTATION_BASED_DISPLAY, ORIENTATION_TYPE } from 'utils/constants/BrowserConstants';
import { showCookiebotIcon } from 'utils/helpers/cookiebot';
import { useLDClient } from 'launchdarkly-react-client-sdk';
import MobileOnboardModal from 'components/MobileOnboardModal/MobileOnboardModal';
import MobileHome from 'pages/MobileHome/MobileHome';

export default function Home() {
  const { isMobile, orientation } = useBreakpoint();
  const appDispatcher = useAppDispatch();
  const mapActions = useMapActions();
  const flowActions = useFlowActions();
  const recommendationFormActions = useRecommendationFormActions();
  const { setIsLocationEnabled, setFlowStep, setShowButtonHub, setShowPin, setDisabledButtonHub } =
    flowActions;
  const navigate = useNavigate();
  const { i18n, t } = useTranslation();
  const [searchParams] = useSearchParams();
  const location = useLocation();
  const ldClient = useLDClient();
  const [showOnboardingModal, setShowOnboardingModal] = useState(false);
  const {
    flow: { showFieldInformationSheet },
  } = useAppState();
  const langFromRecommendationPage = sessionStorage.getItem('langFromRecommendationPage');

  useLayoutEffect(() => {
    showCookiebotIcon();
    if (
      isMobile &&
      location?.pathname !== '' &&
      location.pathname.includes('onboarding') &&
      sessionStorage.getItem('hasUserOnboardedPage') !== 'true'
    ) {
      setShowOnboardingModal(true);
    }
  }, []);

  useEffect(() => {
    const queryParams = new URLSearchParams(location.search);
    if (queryParams) {
      const utm: Record<string, string> = {
        utm_source: queryParams.get('utm_source') ?? '',
        utm_content: queryParams.get('utm_content') ?? '',
        utm_term: queryParams.get('utm_term') ?? '',
        utm_campaign: queryParams.get('utm_campaign') ?? '',
        utm_medium: queryParams.get('utm_medium') ?? '',
      };
      const utmFiltered: Record<string, string> = {};
      let save = false;
      for (const [key, value] of Object.entries(utm)) {
        if (value) {
          save = true;
          utmFiltered[key] = value;
        }
      }
      save && sessionStorage.setItem('utmParams', JSON.stringify(utmFiltered));
      let currentCountry = queryParams.get('lang') ?? '';
      currentCountry = currentCountry?.split('?')[0]?.trim();
      window.sessionStorage.setItem('currentCountry', currentCountry);
    }
  }, [location.search]);

  useEffect(() => {
    const sfdcId = searchParams.get('sfdcId');
    if (sfdcId) {
      recommendationFormActions.setSfdcId({ sfdcId });
      window.sessionStorage.setItem('sfdcId', sfdcId);
    }
  }, [recommendationFormActions, searchParams]);

  useEffect(() => {
    // remove notification elements in cache
    notification.destroy();

    sessionStorage.removeItem('isCreatingAnotherRecommendation');
    if (!showFieldInformationSheet) {
      appDispatcher({ type: 'reset-app' });
    }
    trackDrawBoundaryIncomplete();
  }, [appDispatcher]);

  const getIpAPI = async (cancelToken: any) => {
    try {
      mapActions.setIPApiLoading({ ipApiLoading: true });
      const data: UserLocation = await findRegionalLocation({ cancelToken: cancelToken?.token });
      // @ts-ignore
      if (data.code === 'ERR_NETWORK' || data.code === 'ERR_CANCELED') {
        mapActions.setIPApiLoading({ ipApiLoading: false });
      } else {
        const { longitude, latitude, country = 'en' } = data || {};
        if (longitude && latitude) {
          mapActions.setIPApiLoading({ ipApiLoading: false });
          mapActions.setMapCenter({ center: [longitude, latitude] });
        }
        flowActions.setLocalCountry({ country: String(country).toLocaleLowerCase() });
        if (ldClient && country) {
          const user = {
            key: 'user-location',
            country: country,
          };
          ldClient.identify(user);
        }
        const isSupportedLanguage = SUPPORTED_LANGS.some(
          (lang) => lang.value === String(country).toLocaleLowerCase()
        );
        if (langFromRecommendationPage) {
          i18n.changeLanguage(langFromRecommendationPage);
        } else {
          setLanguageAsperCountryCode(
            isSupportedLanguage,
            String(country).toLocaleLowerCase(),
            i18n
          );
        }
      }
    } catch (e: any) {
      console.error(e);
    }
  };

  useEffect(() => {
    const cancelToken = axios.CancelToken.source();
    getIpAPI(cancelToken);
    return () => {
      cancelToken?.cancel();
    };
  }, [mapActions, flowActions, i18n, ldClient]);

  const onSelectDropOption = () => {
    setIsLocationEnabled({ isEnabled: true });
    if (!isMobile) {
      setDisabledButtonHub({ disabled: true });
    }
    setFlowStep({
      step: FlowSteps.STEP1,
      type: OptionType.Drop,
    });

    navigate(routes.wizardDropAPin);
    delay(300).then(() => {
      setShowPin({ show: true });
      track('pin creation', { 'select pin': true });
    });
  };

  const onSelectDrawOption = () => {
    setIsLocationEnabled({ isEnabled: true });
    setFlowStep({
      step: FlowSteps.STEP1,
      type: OptionType.Draw,
    });

    navigate(routes.wizard);
    delay(300).then(() => {
      setShowButtonHub({ show: true });
      track('boundary creation', { 'select boundary creation': true });
      flowActions.setAreBoundariesConfirmed({ areBoundariesConfirmed: false });
      flowActions.setIsDrawingBoundaries({ isDrawing: true });
    });
  };

  const closeOnboardModal = () => {
    setShowOnboardingModal(false);
    navigate(routes.home);
    sessionStorage.setItem('hasUserOnboardedPage', 'true');
    track('user on onboarding page', { 'onboarding page users': true });
  };

  return (
    <>
      <Root
        className={
          isMobile && orientation === ORIENTATION_TYPE.LANDSCAPE
            ? ORIENTATION_BASED_DISPLAY.HIDE_ON_LANDSCAPE
            : ''
        }
      >
        <Header />
        {showOnboardingModal && <MobileOnboardModal closeOnboardModal={closeOnboardModal} />}
        {isMobile ? (
          <MobileHome />
        ) : (
          <WelcomeModal onClickDropAPin={onSelectDropOption} onClickDraw={onSelectDrawOption} />
        )}
      </Root>
      <LandscapeWarningMessageContainer
        className={
          isMobile && orientation === ORIENTATION_TYPE.LANDSCAPE
            ? ORIENTATION_BASED_DISPLAY.SHOW_ON_LANDSCAPE
            : 'hide'
        }
      >
        {t('Landscape for cellphone message')}
      </LandscapeWarningMessageContainer>
    </>
  );
}
