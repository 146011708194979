/* eslint-disable indent */
import { CloseCircleFilled } from '@ant-design/icons';
import {
  Button,
  Input,
  Typography,
  colors,
  SYT_PREFIX,
} from 'syngenta-digital-cropwise-react-ui-kit';
import breakpoints from 'base/constants/breakpoints';
import styled from 'styled-components';
import { InputProps } from 'antd';

const { Text } = Typography;
const ControlButton = styled(Button)`
  width: 40px;
  height: 40px;
  border-radius: 8px;
  padding: 11px;
  gap: 8px;
  line-height: normal;
  color: ${colors.neutral60} !important;
  border: none !important;

  @media screen and ${breakpoints.maxWidth.sm} {
    background-color: ${colors.neutral10};

    &:hover {
      background-color: ${colors.neutral10};
    }
  }
`;

interface ControlInputProps extends InputProps {
  $hasErrors: boolean;
}

const ControlInput = styled(Input)<ControlInputProps>`
  margin: 0 !important;
  line-height: normal;
  width: 100%;
  margin-top: 0px !important;
  background-color: #fff;
  border: 1px solid ${colors.neutral20};
  .ant-input {
    background-color: #fff;
  }

  &.${SYT_PREFIX}-input {
    height: 40px !important;
  }

  &.${SYT_PREFIX}-input-affix-wrapper {
    background: ${colors.neutral00} !important;
    background-color: ${colors.neutral00};
    height: 40px !important;

    border: ${(props: ControlInputProps) =>
      (props.$hasErrors ? `1px solid ${colors.red50} !important` : `1px solid ${colors.neutral20}`)};
    box-shadow: ${(props: ControlInputProps) =>
      (props.$hasErrors ? `0px 0px 0px 0.1px ${colors.red50} !important` : '')};
  }

  &.${SYT_PREFIX}-input-affix-wrapper-focused {
    border: ${(props) =>
      (props.$hasErrors ? `1px solid ${colors.red50} !important` : `1px solid ${colors.blue50}`)};
    box-shadow: 0px 0px 0px 0.1px ${colors.red50} !important;
  }
`;

const CloseCircle = styled(CloseCircleFilled)`
  color: red;
`;

const StyledText = styled(Text)({
  display: 'block',
});

const StyledMainContainer = styled.div`
  padding-bottom: 20px;
`;

const StyledButtonContainer = styled.div`
  display: flex;
  flex-direction: row;
`;

const StyledLabel = styled(Text)`
  font-style: normal;
  font-weight: 400;
  font-size: 14px;
  line-height: 16px;
  /* identical to box height, or 114% */

  display: flex;
  align-items: center;
  letter-spacing: -0.0075em;

  /* Neutral / neutral-100 */

  color: ${colors.neutral100};
  margin-bottom: 5px;
`;

export {
  ControlButton,
  ControlInput,
  CloseCircle,
  StyledText,
  StyledButtonContainer,
  StyledMainContainer,
  StyledLabel,
};
