import { useCallback, useState, useEffect, useMemo } from 'react';
import { FlowSteps, ModeTypes, OverlappingGeometryTypes } from 'context/store/flowReducer';
import { isMobile } from 'react-device-detect';

interface IProps {
  currentModeType: ModeTypes;
  currentStep: FlowSteps;
  isAtLeastOneField: boolean;
  optionType: string;
  points?: any;
  isDrawingBoundaries?: boolean;
  overlappingGeometryType?: OverlappingGeometryTypes
}

const useValidation = ({
  currentModeType,
  currentStep,
  isAtLeastOneField,
  optionType,
  points = [],
  isDrawingBoundaries,
  overlappingGeometryType,
}: IProps) => {
  const [nextButtonDisabled, setNextButtonDisabled] = useState(false);
  const [prevButtonDisabled, setPrevButtonDisabled] = useState(false);
  const validateCreatingMode = useCallback(() => {
    if (optionType === 'DRAW' && isMobile) {
      if (isDrawingBoundaries) {
        setPrevButtonDisabled(points.length < 3);
        setNextButtonDisabled(
          points.length < 3 || overlappingGeometryType !== OverlappingGeometryTypes.NONE
        );
      } else {
        setPrevButtonDisabled(false);
        setNextButtonDisabled(false);
      }
    } else {
      setPrevButtonDisabled(true);
      setNextButtonDisabled(true);
    }
  }, [optionType, isDrawingBoundaries, points.length, overlappingGeometryType]);

  const validateEditingMode = useCallback(() => {
    setPrevButtonDisabled(true);
    setNextButtonDisabled(true);
  }, []);

  const validateSavingMode = useCallback(() => {
    if (currentStep === FlowSteps.STEP1) {
      setPrevButtonDisabled(true);
      setNextButtonDisabled(false);
    } else {
      setPrevButtonDisabled(false);
      setNextButtonDisabled(true);
    }
  }, [currentStep]);

  const validateNoActionMode = useCallback(() => {
    if (currentStep === FlowSteps.STEP1 && isAtLeastOneField) {
      setPrevButtonDisabled(true);
      setNextButtonDisabled(false);
    } else if (currentStep !== FlowSteps.STEP1) {
      setPrevButtonDisabled(false);
      setNextButtonDisabled(true);
    } else {
      setPrevButtonDisabled(true);
      setNextButtonDisabled(true);
    }
  }, [currentStep, isAtLeastOneField]);

  const validateConfirmingMode = useCallback(() => {
    setPrevButtonDisabled(true);
    setNextButtonDisabled(false);
  }, []);

  const validateDeletingMode = useCallback(() => {
    if (currentStep === FlowSteps.STEP1 && isAtLeastOneField) {
      setPrevButtonDisabled(true);
      setNextButtonDisabled(false);
    } else {
      setPrevButtonDisabled(true);
      setNextButtonDisabled(true);
    }
  }, [currentStep, isAtLeastOneField]);

  const validationModeTypeMap = useMemo(
    (): { [key in ModeTypes]: () => void | undefined } => ({
      [ModeTypes.CREATING]: validateCreatingMode,
      [ModeTypes.EDITING]: validateEditingMode,
      [ModeTypes.SAVING]: validateSavingMode,
      [ModeTypes.CANCELLING]: () => undefined,
      [ModeTypes.NO_ACTION]: validateNoActionMode,
      [ModeTypes.CONFIRMING_AFTER_EDIT]: validateConfirmingMode,
      [ModeTypes.DELETING]: validateDeletingMode,
    }),
    [
      validateCreatingMode,
      validateEditingMode,
      validateSavingMode,
      validateNoActionMode,
      validateConfirmingMode,
      validateDeletingMode,
    ]
  );

  useEffect(() => {
    validationModeTypeMap[currentModeType]();
  }, [currentModeType, validationModeTypeMap]);

  useEffect(() => {
    if (currentStep !== FlowSteps.STEP1) {
      setNextButtonDisabled(true);
      setPrevButtonDisabled(true);
    }
  }, [currentStep]);

  return {
    nextButtonDisabled,
    prevButtonDisabled,
  };
};

export default useValidation;
