import { SVGProps } from 'react';

import { colors } from 'theme/colors';

const IconWarning = ({
  height = '24px',
  width = '24px',
  color = colors.neutral60,
  ...props
}: SVGProps<SVGSVGElement>) => (
  <svg
    width={width}
    height={height}
    viewBox="0 0 24 24"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
    {...props}
  >
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M1 21.5L12 2.5L23 21.5H1ZM19.5297 19.4983L11.9997 6.48828L4.46973 19.4983H19.5297ZM11 16.5V18.5H13V16.5H11ZM11 10.5H13V14.5H11V10.5Z"
      fill={color}
    />
  </svg>
);

export default IconWarning;
