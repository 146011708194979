import React, {
  ComponentType,
  ForwardRefExoticComponent,
  MouseEventHandler,
  SVGProps,
  CSSProperties,
} from 'react';
import { ButtonProps } from 'antd';
import type { CustomIconComponentProps } from '@ant-design/icons/lib/components/Icon';

import {
  StyledButtonIcon,
  StyledButtonRectangleDefault,
  StyledIconContainer,
  StyledTextContainer,
  StyledTextContainerInverted,
  StyledButtonRectangleSuccess,
  StyledButtonRectangleDanger,
  StyledButtonRectangleOutlined,
} from './ButtonBase.styles';

enum EButtonType {
  primary = 'primary',
  danger = 'danger',
  default = 'default',
  outlined = 'outlined',
}

interface IProps {
  buttonTestId?: string;
  buttonProps?: ButtonProps;
  text?: string;
  icon?: React.ReactNode;
  iconAlign?: 'LEFT' | 'RIGHT' | null;
  iconCustom?:
    | ComponentType<CustomIconComponentProps | SVGProps<SVGSVGElement>>
    | ForwardRefExoticComponent<CustomIconComponentProps>;
  iconCustomProps?: Partial<CustomIconComponentProps>;
  onClick?: MouseEventHandler<HTMLLIElement>;
  fullWidth?: boolean;
  textSize?: 'SMALL' | 'MEDIUM' | 'LARGE';
  style?: CSSProperties;
  type?: EButtonType;
  isDisabled?: boolean;
  className?: string;
  isAddAnotherButtonWeb?: boolean;
  isCancelButton?: boolean;
}

const ButtonContainerMapByType = {
  [EButtonType.primary]: StyledButtonRectangleSuccess,
  [EButtonType.danger]: StyledButtonRectangleDanger,
  [EButtonType.default]: StyledButtonRectangleDefault,
  [EButtonType.outlined]: StyledButtonRectangleOutlined,
};

const ButtonBase = ({
  buttonTestId = '',
  buttonProps,
  text,
  icon,
  iconCustom,
  iconCustomProps,
  iconAlign,
  onClick,
  style,
  fullWidth = false,
  textSize = 'SMALL',
  type = EButtonType.default,
  isDisabled = false,
  className,
  isAddAnotherButtonWeb,
  isCancelButton,
}: IProps): JSX.Element => {
  const StyledButtonRectangleContainer = ButtonContainerMapByType[type];

  const isButtonDisabled = () => {
    if (isCancelButton || (isAddAnotherButtonWeb && !isDisabled)) {
      return false;
    }
    return isDisabled;
  };

  return (
    <StyledButtonRectangleContainer
      data-testid={buttonTestId}
      shape="default"
      onClick={onClick}
      style={style}
      disabled={isButtonDisabled()}
      fullWidth={!!fullWidth}
      heightSize={textSize}
      className={className}
      {...buttonProps}
    >
      {iconAlign === 'RIGHT' && (
        <StyledIconContainer textSize={textSize}>
          <StyledTextContainer>{text}</StyledTextContainer>
          {iconCustom ? <StyledButtonIcon component={iconCustom} {...iconCustomProps} /> : icon}
        </StyledIconContainer>
      )}
      {iconAlign === 'LEFT' && (
        <StyledIconContainer textSize={textSize}>
          {iconCustom ? <StyledButtonIcon component={iconCustom} {...iconCustomProps} /> : icon}
          {text && <StyledTextContainerInverted>{text}</StyledTextContainerInverted>}
        </StyledIconContainer>
      )}
      {!iconAlign && <StyledIconContainer textSize={textSize}>{text}</StyledIconContainer>}
    </StyledButtonRectangleContainer>
  );
};

export default ButtonBase;

export { EButtonType };

export type { IProps };
