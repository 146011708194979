import { useEffect, useState, useCallback } from 'react';
import {
  StyledSider,
  StyledContainer,
  StyledTitle,
  StyledParagraph,
  StyledButton,
  Footer,
} from './PinInformationSider.styles';
import InputNumberHandler from '../input-number-handler';
import { useTranslation } from 'react-i18next';
import { ButtonType } from 'syngenta-digital-cropwise-react-ui-kit';
import { useBreakpoint } from 'hooks';
import { useFlowActions } from 'context/actions/flowActions';
import { FlowSteps } from 'context/store/flowReducer';
import { useMap } from 'react-map-gl';
import track from 'utils/amplitudeWrapper';
import { useRecommendationFormActions } from 'context/actions/recommendationFormActions';
import { ActionTypes, useApiDataActions } from 'context/actions/ApiDataActions';
import { Field } from 'context/store/recommendationFormReducer';
import { FieldSoilRequest } from 'base/types/SoilType';
import { getUniqueSoils } from 'utils/helpers/soils';
import { pointInCountry, pointIsInSpecificCountry } from 'utils/helpers/geospatial';
import createCircleFeild from 'utils/createCircleField';
import { INPUT_MAX_VALUE, INPUT_MIN_VALUE } from 'base/constants/inputNumber';
import { useAppDispatch, useAppState } from 'context/AppState';
import ProxyLayer from 'base/api/ProxyLayer';
import { useFlags } from 'launchdarkly-react-client-sdk';
import { getCountryCropRegion } from 'utils/constants/CountryData';
import { CropConstants } from 'utils/constants/Crop';

interface PinInformationSiderProps {
  title: string;
  description?: string;
  setIsValid?: (isValid: boolean) => void;
  setPinSize?: (size: string) => void;
  isDisabled?: boolean;
  defaultSize?: string;
  id?: string;
  className?: string;
}

const PinInformationDrawer = ({
  title,
  description,
  isDisabled = false,
  setIsValid,
  setPinSize,
  defaultSize,
  id,
  className,
}: PinInformationSiderProps): JSX.Element => {
  const [t] = useTranslation();
  const [size, setSize] = useState('12');
  const [hasInputErrors, setHasInputErrors] = useState({ fieldName: false, size: false });
  const flowActions = useFlowActions();
  const ApiDataActions = useApiDataActions();
  const recommendationFormActions = useRecommendationFormActions();
  const appDispatcher = useAppDispatch();
  const { isMobile } = useBreakpoint();
  const { flowMap, flowMapMobile } = useMap();
  const flags = useFlags();
  const map = flowMap ?? flowMapMobile;
  const {
    flow: { optionType },
    recommendationForm: { countryCode },
  } = useAppState();

  useEffect(() => {
    defaultSize && setSize(defaultSize);
  }, [defaultSize]);

  useEffect(() => {
    recommendationFormActions.setAGProducts({ agProducts: [] });
    recommendationFormActions.setProductMadurityRange({ productMadurityRange: [] });
    appDispatcher({
      type: ActionTypes.setProductCatalog,
      payload: { data: [] },
    });
    appDispatcher({
      type: ActionTypes.setProductMaturityRangeFAO,
      payload: { data: {} },
    });
  }, [appDispatcher, countryCode, recommendationFormActions]);

  useEffect(() => {
    if (setIsValid) {
      setIsValid(!hasInputErrors.size);
    }
    // eslint-disable-next-line
  }, [hasInputErrors.size]);

  const getCircleSoilsForDesktop = useCallback(
    async ({ circleBoundary, countryCode }: { circleBoundary: Field; countryCode: string }) => {
      const fieldsDrawed = [
        {
          id: circleBoundary.id,
          geometry: {
            type: circleBoundary.boundary.geometry.type,
            coordinates: [...circleBoundary.boundary.geometry.coordinates],
          },
        },
      ] as FieldSoilRequest[];
      const soilsFetched = await ApiDataActions.getFieldSoils(
        fieldsDrawed,
        getCountryCropRegion(countryCode),
        'SYNGENTA',
        false
      );

      const fieldId = circleBoundary.id;
      const circleSoils = soilsFetched.soilGrid[fieldId];

      return {
        ...circleBoundary,
        soils: circleSoils,
        selectedSoils: getUniqueSoils({ soils: circleSoils }),
        fieldsResolution: soilsFetched.fieldsResolution,
      };
    },
    [ApiDataActions]
  );

  // This is getting called from List tab(mobile) for Step 1.
  const onClickCancel = useCallback(() => {
    flowActions.setMobileActiveTab({ activeTab: 'Map' });
  }, [flowActions]);

  const onClickNext = useCallback(async () => {
    // This is getting called from desktop & on List tab(mobile) for Step 1.
    if (!map) {
      return;
    }
    const lngLat = map.getMap().getCenter();
    const countryCode = pointInCountry(lngLat, flags);
    console.log('countryCode PinInformationSider', countryCode);
    if (!countryCode) {
      flowActions.setMobileActiveTab({ activeTab: 'Map' });
      flowActions.setShowPinWarning({ show: true });
      return;
    }
    const checkIsLandArable = async () => {
      const cancelToken = ProxyLayer.cancelToken();
      try {
        const { lng, lat } = lngLat;
        return await ApiDataActions.isLandArable(lng, lat, cancelToken.token);
      } catch (e) {
        console.log('Error!!', e);
        return { arable_land: true };
      } finally {
        cancelToken?.cancel();
      }
    };
    const res = isMobile ? { arable_land: true } : await checkIsLandArable();
    if (!res?.arable_land) {
      flowActions.setShowLandIsNotArableModal({ show: true });
      return;
    }
    const { lng, lat } = lngLat;
    if (pointIsInSpecificCountry({ lng, lat }, CropConstants.SOUTH_AFRICA_CODE)) {
      const tppData = await ApiDataActions.getTPPForFields(
        CropConstants.SOUTH_AFRICA,
        CropConstants.WHEAT_SMALLCASE,
        [lat, lng]
      );
      if (!tppData?.spatialClass) {
        flowActions.setShowPinWarning({ show: true });
        return;
      }
    }
    map.zoomTo(Math.max(map.getZoom(), 12));
    const center = [lng, lat];
    const circleBoundary = createCircleFeild(center);
    circleBoundary.properties = { center: center, id: 1 };

    recommendationFormActions.setPinPosition({ lngLat });
    recommendationFormActions.setCountryCode({ countryCode });

    recommendationFormActions.setPinHectaresSize({ hectaresSize: Number.parseFloat(size) });
    flowActions.setArePinConfirmed({ arePinConfirmed: true });
    flowActions.setCurrentStep({ step: FlowSteps.STEP2 });
    flowActions.setShowCropTypeDrawer({ show: true });
    const circleBoundaryWithSoils = await getCircleSoilsForDesktop({
      countryCode,
      circleBoundary: {
        fieldName: 'Field 1',
        hectares: size,
        boundary: circleBoundary,
        id: 1,
      },
    });
    recommendationFormActions.saveFields({
      fields: [circleBoundaryWithSoils],
    });
    track('confirm pin location', { 'pin created': true });
  }, [flowActions, getCircleSoilsForDesktop, map, recommendationFormActions, size]);

  const handleEstimatedSizeChange = useCallback(
    (val: string) => {
      setPinSize?.(val);
      setSize(val);
    },
    [setPinSize]
  );

  return (
    <div id={id} className={className}>
      <StyledSider
        collapsedWidth={0}
        collapsed={false}
        zeroWidthTriggerStyle={{ display: 'none' }}
        width={!isMobile ? 375 : 'auto'}
      >
        <StyledContainer>
          <StyledTitle level={3} data-testid="drop-a-pin-page-title">
            {title ?? 'Title goes here'}
          </StyledTitle>
          <StyledParagraph data-testid="drop-a-pin-page-description">{description}</StyledParagraph>
          <InputNumberHandler
            inputName="size"
            inputValue={size}
            setInputValue={handleEstimatedSizeChange}
            step={1}
            label={t('Estimated Size (ha)')}
            min={INPUT_MIN_VALUE}
            max={INPUT_MAX_VALUE}
            setHasErrorsForParent={setHasInputErrors}
            inputProps={{
              disabled: isDisabled,
              optionType,
            }}
          />
          <Footer>
            <StyledButton
              type={ButtonType.default}
              size={'large'}
              disabled={!isMobile}
              onClick={onClickCancel}
            >
              {isMobile ? t('Cancel') : t('Back')}
            </StyledButton>
            <StyledButton
              type={ButtonType.primary}
              onClick={onClickNext}
              disabled={hasInputErrors.size}
            >
              {isMobile ? t('Save') : t('Next')}
            </StyledButton>
          </Footer>
        </StyledContainer>
      </StyledSider>
    </div>
  );
};

export default PinInformationDrawer;
