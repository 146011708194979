import ModalView from 'components/ModalView';
import { colors, SYT_PREFIX, Typography, ButtonType } from 'syngenta-digital-cropwise-react-ui-kit';
import styled from 'styled-components';
import { showSlidingAnimation } from 'utils/modalBoxAnimations';
import { IStyledModalProps } from 'base/types/ModalBox';
import { Button } from 'antd';

export const StyledModalView = styled(ModalView)<IStyledModalProps>`
  .${SYT_PREFIX}-modal-content {
    border-radius: 8px;
  }
  ${(props) => showSlidingAnimation(props.slideDirection)}
  @media screen and (max-height: 769px) {
    .${SYT_PREFIX}-modal-content {
      top: 56px !important;
      overflow: auto !important;
    }
  }
  .${SYT_PREFIX}-modal-header {
    border-radius: 8px;
  }

  .iMmxge {
    border: 0px;
  }

  .clIVIc {
    padding: 0px;
  }

  .kZmOny {
    color: ${colors.neutral60};
  }
`;

export const StyledButton = styled(Button)`
  height: 44px;
  display: flex;
  align-items: center;
  justify-content: center;
  gap: 10px;
  flex: 1;
  z-index: 20;
  background-color: ${(props) => (props.type === ButtonType.default ? '#FFFFFF' : '#14803C')};
  border: ${(props) =>
    props.type === ButtonType.default && `1px solid ${colors.neutral30} !important`};
  border-radius: 4px;

  font-size: 16px;
  font-weight: 400px;
  line-height: 24px;
`;

export const StyledContainer = styled.div`
  display: flex;
  flex: 1;
  flex-direction: column;
  overflow-y: auto;
`;

export const Title = styled.div`
  display: flex;
  justify-content: center;
  font-weight: 600;
  font-size: 24px;
  line-height: 40px;
`;

const SheetTextBase = styled(Typography.Text)`
  font-family: Noto Sans;
  font-size: 14px;
  line-height: 22px;
  letter-spacing: -0.075em;
  color: ${colors.neutral100};
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
`;

export const SheetValueText = styled(SheetTextBase)`
  font-weight: 700;
`;

export const SheetText = styled(SheetTextBase)`
  font-weight: 400;
`;
