import 'moment/locale/hu';
import 'moment/locale/pl';
import 'moment/locale/ro';
import 'moment/locale/de';
import 'moment/locale/uk';
import 'moment/locale/bg';
import 'moment/locale/it';
import localeEnglish from 'antd/es/date-picker/locale/en_US';
import localeHungarian from 'antd/es/date-picker/locale/hu_HU';
import localePoland from 'antd/es/date-picker/locale/pl_PL';
import localeRomania from 'antd/es/date-picker/locale/ro_RO';
import localeGerman from 'antd/es/date-picker/locale/de_DE';
import localUkrainian from 'antd/es/date-picker/locale/uk_UA';
import localBulgarian from 'antd/es/date-picker/locale/bg_BG';
import localItalian from 'antd/es/date-picker/locale/it_IT';
import { PickerLocale } from 'antd/es/date-picker/generatePicker';

export const datePickerMapLocales = (currentLang: string): PickerLocale => {
  switch (currentLang) {
    case 'hu':
      return localeHungarian;
    case 'pl':
      return localePoland;
    case 'ro':
      return localeRomania;
    case 'de':
      return localeGerman;
    case 'uk':
      return localUkrainian;
    case 'bg':
      return localBulgarian;
    case 'it':
      return localItalian;
    default:
      return localeEnglish;
  }
};
