/* eslint-disable @typescript-eslint/explicit-module-boundary-types */
/* eslint-disable array-bracket-newline */
import { WeatherResponse } from 'base/types/Weather';

export const CHART_CONFIG = [
  {
    name: 'Temperature',
    id: 'temperature',
    type: 'area-spline',
    color: 'url(#temperature-gradient-weather-combined-chart)',
    default: true,
    group: 1,
    unit: '°C'
  },
  {
    name: 'Precipitation',
    id: 'weeklyAvgPrecipitation',
    type: 'bar',
    color: '#0171cc',
    default: true,
    group: 1,
    unit: 'mm',
    axis: 'Y'
  },
  {
    name: 'Growing Degree Days',
    id: 'growingDegree',
    type: 'spline',
    color: '#eaa537',
    group: 2,
    unit: '°C'
  },
  {
    name: 'Heat Stress',
    id: 'heatStress',
    type: 'bar',
    color: 'none',
    group: 2,
    axis: 'Y'
  },
  {
    name: 'Cold Stress',
    id: 'coldStress',
    type: 'bar',
    color: 'none',
    group: 2,
    axis: 'Y'
  },
];

export const CHART_NAME_CONST = {
  TEMPERATURE: 'Temperature',
  PRECIPITATION: 'Precipitation',
  GDD: 'Growing Degree Days',
  HT: 'Heat Stress',
  CT: 'Cold Stress',
  WEEK: 'Week',
  MONTH: 'Month'
};

export const getChartTypes = CHART_CONFIG.map(t => t.name);

export const getChartItemsOfGroup = (group: number) => CHART_CONFIG.filter(c => c.group === group).map(t => t.name);
export const getChartItemsOfAxis = (axis: string) => CHART_CONFIG.filter(c => c.axis === axis).map(t => t.name);
export const getChartItemUnit = (chartItem: string) => CHART_CONFIG.find(item => item.name === chartItem)?.unit ?? '';
export const getChartItemID = (chartItem: string) => CHART_CONFIG.find(item => item.name === chartItem)?.id ?? '';

export const getChartProps = (prop: string) => {
  return CHART_CONFIG.reduce((acc: any, cur: any) => ({ ...acc, [cur.name]: cur[prop] }), {});
};

export const findNewMonthPositions = (months: string[]): number[] => {
  return months.reduce((positions: number[], month: string, index: number) => {
    if (index === 0 || month !== months[index - 1]) {
      positions.push((index - 0.5));
    }
    return positions;
  }, []);
};

export const getMonthDistribution = (MONTHS: string[]) => {
  const monthFrequency: any = {};
  const distribution = new Array(MONTHS.length).fill('');

  MONTHS.forEach((month, index) => {
    // eslint-disable-next-line no-prototype-builtins
    if (monthFrequency.hasOwnProperty(month)) {
      monthFrequency[month].push(index);
    } else {
      monthFrequency[month] = [index];
    }
  });

  let currentIndex = 0;
  Object.values(monthFrequency).forEach((indexes: any) => {
    const middlePos = Math.floor((indexes.length - 1) / 2);
    distribution[indexes[middlePos]] = MONTHS[indexes[0]];
    for (let i = 0; i < indexes.length; i++) {
      if (i !== middlePos) {
        currentIndex++;
        distribution[indexes[i]] = '';
      }
    }
  });
  return distribution;
};

export const calculateAccumulatedGDD = (data: any[], plantingWeek?: number, baseTemperature = 10) => {
  const gddData = [];
  let accumulatedGDD = 0;

  for (const week of data) {
    const avgTemperature = (week.temp_max + week.temp_min) / 2;
    const gdd = Math.max(avgTemperature - baseTemperature, 0);

    // as this is weekly data.. we need to multiply the acc data by 7 to get the weekly DGG accumulated
    accumulatedGDD += (gdd * 7);

    if (week.week === (plantingWeek || data[0].week)) {
      accumulatedGDD = 0; // Reset accumulated GDD at planting week
    }

    gddData.push({ week: week.week, month: week.month, accumulatedGDD });
  }

  return gddData;
};

export const sortWeatherDataByWeek = (data: WeatherResponse[]): WeatherResponse[] => {
  return data.slice().sort((a, b) => a.week - b.week);
};
