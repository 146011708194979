import { Country } from 'base/types/Countries';
import { CropConstants } from 'utils/constants/Crop';

export const getDataForSouthAfrica = (
  country: string | undefined,
  spatialClass: string | undefined,
  productName: string,
  countryList: Country[],
  isSeedRate: boolean
): number => {
  const selectedCropFromCountryList = countryList
    ?.find((con) => con?.name === country || con?.shortCode === country)
    ?.crops.find((crop) => crop?.CropName.toLowerCase() === CropConstants.WHEAT_SMALLCASE);
  if (selectedCropFromCountryList?.TPPSeedRate && spatialClass) {
    const selectedTPPSeedRate = selectedCropFromCountryList.TPPSeedRate[spatialClass];
    const { defaultValue, packSize } = selectedTPPSeedRate[productName];
    return isSeedRate ? defaultValue : packSize;
  }
  return 0;
};

export const getBagCountForSouthAfrica = (
  country: Country,
  spatialClass: string | undefined,
  productName: string,
  hectares: number
): number => {
  const selectedCropFromCountryList = country?.crops.find(
    (crop) => crop?.CropName.toLowerCase() === CropConstants.WHEAT_SMALLCASE
  );
  if (selectedCropFromCountryList?.TPPSeedRate && spatialClass && productName) {
    const selectedTPPSeedRate = selectedCropFromCountryList.TPPSeedRate[spatialClass];
    const productSeedPackage = selectedTPPSeedRate[productName];
    const { packSize, defaultValue } = productSeedPackage;
    const bagCount = Math.ceil((hectares * defaultValue) / packSize);
    return isNaN(bagCount) ? 0 : bagCount;
  }
  return 0;
};
