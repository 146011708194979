import styled from 'styled-components/macro';
import PhoneInput, { PhoneInputProps } from 'react-phone-input-2';
import { colors } from 'syngenta-digital-cropwise-react-ui-kit';
import 'react-phone-input-2/lib/style.css';
import { MISSING_PHONE_MASKS } from '../../base/constants/inputNumber';

interface IProps extends PhoneInputProps {
  className?: string;
}

const Wrapper = ({ className, ...props }: IProps) => (
  <div className={className}>
    <PhoneInput
      {...props}
      countryCodeEditable={false}
      masks={MISSING_PHONE_MASKS}
    />
  </div>
);

const PhoneStyled = styled(Wrapper)`
  #phone-input {
    height: 40px !important;
    width: 100% !important;
    background-color: ${colors.neutral10} !important;
    border: 1px solid ${colors.neutral30};
    border-left: 0;
  }
  .flag-dropdown {
    border: 1px solid ${colors.neutral30};
    border-right: 0;
  }
`;

export { PhoneStyled };
