import {
  FarmSelectDiv,
  TextSection,
  FarmSelectBase,
  ImageSection,
  Text,
  BottomArrow,
} from './FarmSelect.styles';
import farmSearch from '../../../assets/icons/place_24px.png';
import arrow from '../../../assets/icons/keyboard_arrow_down_white.png';
import { Dispatch, SetStateAction } from 'react';
import { useTranslation } from 'react-i18next';

interface FarmSelectProp {
  setWebFarmSelectClick: Dispatch<SetStateAction<boolean>>;
  webFarmSelectClick: boolean;
  selectFarmName: string;
  setShowFarmSearchPopUp?: Dispatch<SetStateAction<boolean>>;
  isMobile: boolean;
}
const FarmControlMain = ({
  webFarmSelectClick,
  setWebFarmSelectClick,
  selectFarmName,
  setShowFarmSearchPopUp,
  isMobile,
}: FarmSelectProp): JSX.Element => {
  const { t } = useTranslation();
  const handleFarmSelectClick = () => {
    setWebFarmSelectClick(!webFarmSelectClick);
    if (isMobile) {
      setShowFarmSearchPopUp && setShowFarmSearchPopUp(true);
    }
  };

  return (
    <FarmSelectBase
      onClick={handleFarmSelectClick}
      isSelected={webFarmSelectClick}
      isMobile={isMobile}
      data-testid="farm-select"
    >
      <FarmSelectDiv isMobile={isMobile}>
        <ImageSection isMobile={isMobile}>
          <img src={farmSearch} alt="farm-icon" />
        </ImageSection>
        <TextSection isMobile={isMobile}>
          <Text isSelected={selectFarmName.length > 0} isMobile={isMobile}>
            {selectFarmName.length > 0 ? selectFarmName : t('Select Farm')}
          </Text>
        </TextSection>
        <BottomArrow>
          <img src={arrow} alt="bottom-arrow" />
        </BottomArrow>
      </FarmSelectDiv>
    </FarmSelectBase>
  );
};

export default FarmControlMain;
