import breakpoints from 'base/constants/breakpoints';
import styled from 'styled-components';
import { colors } from 'syngenta-digital-cropwise-react-ui-kit';

export const ItemsContainer = styled.div`
  display: flex;
  flex-wrap: wrap;
  justify-content: space-between;
  @media screen and ${breakpoints.maxWidth.md} {
    padding-bottom: 24px;
    margin-bottom: 24px;
    border-bottom: 2px solid ${colors.neutral20};
  }
`;

export const AgronomicItem = styled.div`
  width: 42%;
  padding: 16px 0;
  color: ${colors.neutral90};
  h1 {
    font-weight: 600;
    font-size: 16px;
    line-height: 24px;
    letter-spacing: -0.0075em;
  }
  p {
    letter-spacing: -0.0075em;
    color: #696f88 !important;
    font-weight: 400;
    font-size: 14px !important;
    line-height: 20px !important;
  }

  @media screen and ${breakpoints.maxWidth.md} {
    width: 100% !important;
    padding: 8px 0 !important;
  }
`;

export const ImportanceItems = styled.div`
  display: flex;
  justify-content: space-between;
`;
