/* eslint-disable react-hooks/exhaustive-deps */
import FieldInformationDrawer from 'components/FieldInformationDrawer/FieldInformationDrawer';

const FirstStep = () => {
  return (
    <FieldInformationDrawer />
  );
};

export default FirstStep;
