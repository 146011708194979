import {
  StyledPopup,
  FieldInfoSection,
  FieldDetailSection,
  Text,
  SubTextSection,
  HectareText,
  FarmIconSection,
} from './FieldPopup.styles';
import { useTranslation } from 'react-i18next';
import farm from '../../../assets/images/farm.png';
import { PropertyData } from './SoilCharacteristics';
import { getTranslatedFieldName } from 'utils/constants/Fields';

interface MapboxPopupProps {
  data: PropertyData;
}

const FarmPopup = ({ data }: MapboxPopupProps): JSX.Element => {
  const { t } = useTranslation();
  return (
    <StyledPopup>
      <FieldInfoSection>
        <FarmIconSection>
          <img src={farm} alt="icon" />
        </FarmIconSection>
        <FieldDetailSection>
          <Text>
            {getTranslatedFieldName({
              defaultName: data.farmName,
              t,
            })}
          </Text>
          <SubTextSection>
            <HectareText>
              {`${data.farmSize} ${t('ha')} • ${data.fieldCount} ${t('fields')}`}
            </HectareText>
          </SubTextSection>
        </FieldDetailSection>
      </FieldInfoSection>
    </StyledPopup>
  );
};

export default FarmPopup;
