import { colors } from 'syngenta-digital-cropwise-react-ui-kit';

import GeometryToSVGConverter from './GeometryToSVGConverter';

interface PolygonRendererProps {
  size?: number;
  strokeColor?: string;
  fillColor?: string;
  coords: [number, number][];
  id?: string;
  viewboxSize?: number;
}

const FieldPreview = ({
  coords,
  size = 50,
  strokeColor,
  fillColor,
  id,
  viewboxSize = 50,
}: PolygonRendererProps): JSX.Element => {
  const path = GeometryToSVGConverter.ConvertToSVGPath({ coords });
  return (
    <div id={id}>
      <svg width={size} height={size} viewBox={`0 0 ${viewboxSize} ${viewboxSize}`}>
        <path d={path} fill={fillColor ?? colors.neutral30} stroke={strokeColor ?? 'none'} />
      </svg>
    </div>
  );
};

export default FieldPreview;
