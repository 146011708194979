import { useEffect, useState } from 'react';
import axios from 'axios';
import moment from 'moment';
import { WeatherChart } from 'components/WeatherChart/WeatherChart';

import { useTranslation } from 'react-i18next';

import { StyledPanelContainer, StyledChartContainer } from '../EnvironmentDrawer.styles';
import { ErrorsTypeMap } from 'utils/constants/ErrorsType';
import { isErrorWithCode } from 'utils/errors';
import RetryFunc from 'utils/RetryFunc';
import { useAppState } from 'context/AppState';
import { useRecommendationFormActions } from 'context/actions/recommendationFormActions';
import { WeatherResponse } from 'base/types/Weather';
import ProxyLayer from 'base/api/ProxyLayer';
import { useBreakpoint } from 'hooks';

const year = new Date().getFullYear();
const EnvironmentWeatherPanel = ({
  id,
  isTempField,
}: {
  id: string;
  isTempField: boolean;
}): JSX.Element => {
  const [t] = useTranslation();
  const {
    recommendationForm: { fields, tempBoundaryField },
  } = useAppState();
  const { setTempBoundaryField, setWeatherInField } = useRecommendationFormActions();
  const [isLoadingWeatherData, setIsLoadingWeatherData] = useState(false);
  const [weatherData, setWeatherData] = useState<WeatherResponse[]>();
  const { isMobile } = useBreakpoint();

  useEffect(() => {
    const cancelToken = axios.CancelToken.source();

    const previousYear = new Date().getFullYear() - 1;
    const startDate = moment(new Date(previousYear, 3, 1)).format('YYYY-MM-DD');
    const endDate = moment(new Date(previousYear, 9, 31)).format('YYYY-MM-DD');
    let currentField;

    (async () => {
      try {
        if (isTempField && tempBoundaryField) {
          if (tempBoundaryField.weather) {
            setWeatherData(tempBoundaryField.weather);
            setIsLoadingWeatherData(false);
            return;
          }

          const center = tempBoundaryField?.properties?.center;
          const longitude = center?.[0];
          const latitude = center?.[1];
          setIsLoadingWeatherData(true);
          const weather = (await RetryFunc(async () => {
            return ProxyLayer.getWeather({
              lat: latitude,
              lng: longitude,
              startDate,
              endDate,
              cancelToken: cancelToken.token,
            });
          })) as WeatherResponse[];

          setTempBoundaryField({ boundary: { ...tempBoundaryField, weather } });
          setWeatherData(weather);
          setIsLoadingWeatherData(false);
        } else {
          currentField = fields.find(({ id: fieldId }) => fieldId.toString() === id);
          if (currentField?.weather) {
            setIsLoadingWeatherData(false);
            setWeatherData(currentField?.weather);
            return;
          }

          const center = currentField?.boundary.properties?.center;
          const longitude = center?.[0];
          const latitude = center?.[1];

          setIsLoadingWeatherData(true);
          const weather = (await RetryFunc(async () => {
            return ProxyLayer.getWeather({
              lat: latitude,
              lng: longitude,
              startDate,
              endDate,
              cancelToken: cancelToken.token,
            });
          })) as WeatherResponse[];

          setWeatherInField({ weather, fieldId: id });
          setWeatherData(weather);
          setIsLoadingWeatherData(false);
        }
      } catch (error) {
        if (isErrorWithCode(error) && error.code !== 'ERR_CANCELED') {
          console.error(error);
        }
      }
    })();

    return () => {
      cancelToken?.cancel();
    };
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [fields, id, isTempField, tempBoundaryField]);

  return (
    <StyledPanelContainer id="custom">
      <p>{t('Environment Chart description')}</p>
      <StyledChartContainer isMobile>
        <WeatherChart
          id={`environment-chart-${id}`}
          showOnlyTemperatureChartFilters={true}
          isLoading={isLoadingWeatherData}
          errorMessage={t(ErrorsTypeMap.MAX_ATTEMPTS.statusMessage ?? '')}
          showWeatherChartFilters={!isMobile}
          months={[
            `${year}-04-01`,
            `${year}-05-01`,
            `${year}-06-01`,
            `${year}-07-01`,
            `${year}-08-01`,
            `${year}-09-01`,
            `${year}-10-01`,
          ]}
          weatherResponse={weatherData}
          weatherChartConfig={{ size: { height: 311, width: isMobile ? 800 : undefined } }}
        />
      </StyledChartContainer>
    </StyledPanelContainer>
  );
};

export default EnvironmentWeatherPanel;
