import { ButtonProps, StyledButton, StyledTooltip } from './CenterMapControl.styles';
import centerMapIconActive from '../../../../assets/images/centerMapActive.svg';
import centerMapIcon from '../../../../assets/images/centerMap.svg';
import centerMapDisableIcon from '../../../../assets/images/centerMapDisableIcon.svg';
import { colors } from 'syngenta-digital-cropwise-react-ui-kit';
import { useAppState } from 'context/AppState';
import { useCallback } from 'react';
import { useTranslation } from 'react-i18next';

interface CenterMapProps {
  isDisabled: boolean;
  id?: string;
  onCenterMapButtonClick?: () => void;
  isActive?: boolean;
  customStyle?: ButtonProps;
  customIcon?: string;
  showTooltip?: boolean;
}

export const CenterMapControl = ({
  id,
  isDisabled,
  onCenterMapButtonClick,
  isActive,
  customStyle,
  customIcon,
  showTooltip,
}: CenterMapProps): JSX.Element => {
  const { flow } = useAppState();
  const [t] = useTranslation();

  const getMapIcon = useCallback(() => {
    if (customIcon) {
      return customIcon;
    }
    if (isDisabled) {
      return centerMapDisableIcon;
    } else if (isActive) {
      return centerMapIconActive;
    } else {
      return centerMapIcon;
    }
  }, [isActive, isDisabled, customIcon]);

  return (
    <div>
      {showTooltip ? (
        <StyledTooltip
          placement="left"
          color={colors.neutral90}
          getPopupContainer={(trigger) => trigger.parentElement as HTMLElement}
          title={t('Center on farm')}
        >
          <StyledButton
            style={{
              backgroundColor: `${isActive ? colors.green60 : ''}`,
              display: flow.isDropAPinFlow ? 'none' : 'block',
            }}
            disabled={isDisabled}
            id={id}
            data-testid={id}
            onClick={onCenterMapButtonClick}
            {...customStyle}
          >
            <img src={getMapIcon()} alt="Center Button" />
          </StyledButton>
        </StyledTooltip>
      ) : (
        <StyledButton
          style={{
            backgroundColor: `${isActive ? colors.green60 : ''}`,
            display: flow.isDropAPinFlow ? 'none' : 'block',
          }}
          disabled={isDisabled}
          id={id}
          data-testid={id}
          onClick={onCenterMapButtonClick}
          {...customStyle}
        >
          <img src={getMapIcon()} alt="Center Button" />
        </StyledButton>
      )}
    </div>
  );
};
