import React, { Dispatch, SetStateAction } from 'react';
import {
  StyledResendModal,
  StyledModalView,
  StyledParagraph,
  StyledContent,
} from './ResendEmailModal.styles';
import { ModalAnimationDirections } from 'base/types/ModalBox';

interface ResendEmailModalProps {
  showConfirmation: boolean;
  setShowConfirmation: Dispatch<SetStateAction<boolean>>;
  title: string;
  image: React.ReactNode;
  description: string;
  isMobile: boolean;
  slideDirection?: ModalAnimationDirections;
  onCancel?: () => void;
  onAnimationEnd?: () => void;
}
const ResendEmailModal: React.FC<ResendEmailModalProps> = ({
  showConfirmation,
  setShowConfirmation,
  title,
  image,
  description,
  isMobile,
  slideDirection,
  onCancel,
  onAnimationEnd,
}: ResendEmailModalProps) => {
  const handleCancelOperation = () => {
    if (!isMobile || slideDirection === ModalAnimationDirections.Down) {
      setShowConfirmation(false);
    }
    onCancel?.();
  };
  return (
    <>
      {showConfirmation && isMobile ? (
        <StyledModalView
          data-testid="resend-email-modal-responsive"
          isClosable={true}
          isViewFooter
          title={title}
          content={
            <StyledContent>
              {image}
              <StyledParagraph>{description}</StyledParagraph>
            </StyledContent>
          }
          onClickOverlay={handleCancelOperation}
          onCancel={handleCancelOperation}
          footer={<></>}
          slideDirection={slideDirection}
          onAnimationEnd={onAnimationEnd}
        />
      ) : (
        <StyledResendModal
          data-testid="resend-email-modal"
          open
          centered
          title={title}
          width={495}
          footer={null}
          maskClosable={false}
          onCancel={handleCancelOperation}
        >
          {image}
          <StyledParagraph>{description}</StyledParagraph>
        </StyledResendModal>
      )}
    </>
  );
};
export default ResendEmailModal;
