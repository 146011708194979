/* eslint-disable array-bracket-newline */
import { useState, RefObject, useEffect } from 'react';
import { useTranslation } from 'react-i18next';
import type { InputRef } from 'antd';

import { InputTextHandler } from 'components/input-text-handler';
import { InputTextHandlerType } from 'components/input-text-handler/InputTextHandler';
import InputNumberHandler from 'components/input-number-handler';
import { Field } from 'context/store/recommendationFormReducer';
import { INPUT_MAX_VALUE, INPUT_MIN_VALUE } from 'base/constants/inputNumber';
import { useAppState } from 'context/AppState';
import track from 'utils/amplitudeWrapper';
import { getFieldDefaultName, getTranslatedFieldName } from 'utils/constants/Fields';

export const useFieldForm = (config: {
  fieldDefaultValue?: string;
  hectaresDefaultValue?: string;
  fields?: Field[];
  isFormDisabled?: boolean;
}) => {
  const [t] = useTranslation();
  const { fieldDefaultValue = '', hectaresDefaultValue = '', fields = [], isFormDisabled } = config;
  const [fieldName, setFieldName] = useState('');
  const [size, setSize] = useState('');
  const [hasErrors, setHasErrors] = useState({ fieldName: false, size: false });
  const inputError = Boolean(fieldName.trim()) === false;

  useEffect(() => {
    setSize(hectaresDefaultValue);
  }, [hectaresDefaultValue]);

  useEffect(() => {
    fieldDefaultValue && setFieldName(fieldDefaultValue);
  }, [fieldDefaultValue]);

  useEffect(() => {
    if (isFormDisabled) {
      setFieldName(getFieldDefaultName({ fieldName: fieldDefaultValue, fields: fields }));
    }
  }, [t]);

  const resetForm = () => {
    setFieldName(getFieldDefaultName({ fieldName: fieldDefaultValue, fields: fields }));
    setSize(hectaresDefaultValue);
  };

  return {
    fieldName,
    setFieldName,
    size,
    setSize,
    hasErrors,
    setHasErrors,
    inputError,
    resetForm,
  };
};

interface FieldFormProps extends ReturnType<typeof useFieldForm> {
  disableForm?: boolean;
  [key: string]: any;
  fieldNameInputRef?: RefObject<InputRef>;
}

export default function FieldForm(props: FieldFormProps) {
  const { fieldName, setFieldName, size, setSize, setHasErrors, disableForm } = props;
  const [t] = useTranslation();
  const [nameHasFocus, setNameHasFocus] = useState(false);
  const [nameChanged, setNameChanged] = useState(false);
  const {
    flow: { optionType },
  } = useAppState();
  const handleNameChange = (name: string) => {
    setFieldName(name);
    setNameChanged(true);
  };
  const defaultFieldName = getTranslatedFieldName({ defaultName: fieldName, t });

  return (
    <div>
      <InputTextHandler
        label={t('Field Name')}
        data-testid="fieldName"
        isRequired
        inputName="fieldName"
        inputValue={defaultFieldName}
        setInputValue={handleNameChange}
        type={InputTextHandlerType.FIELD_NAME}
        setHasErrorsForParent={setHasErrors}
        inputProps={{
          disabled: disableForm,
          onFocus: (e: any) => {
            e.target.select();
            setNameHasFocus(true);
            setNameChanged(false);
          },
          onBlur: () => {
            setNameHasFocus(false);
            if (nameChanged && fieldName !== '') {
              track('user updates field name', { 'updates field name': true });
            }
          },
        }}
        allowClear={nameHasFocus}
      />
      <InputNumberHandler
        inputName="size"
        inputValue={size}
        setInputValue={(num) => setSize(num)}
        onChange={(num) => setSize(num)}
        step={1}
        label={t('Estimated Size (ha)')}
        min={INPUT_MIN_VALUE}
        max={INPUT_MAX_VALUE}
        setHasErrorsForParent={setHasErrors}
        inputProps={{
          disabled: disableForm,
          optionType,
        }}
      />
    </div>
  );
}
