import { SVGProps } from 'react';
import { colors } from 'syngenta-digital-cropwise-react-ui-kit';

const IconEdit = ({
  height = '14px',
  width = '14px',
  color = colors.neutral60,
  ...props
}: SVGProps<SVGSVGElement>) => (
  <svg
    data-testid="editIcon"
    width={width}
    height={height}
    viewBox="0 0 14 14"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
    {...props}
  >
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M11.245 0.25C11.0575 0.25 10.8625 0.325 10.72 0.4675L9.3475 1.84L12.16 4.6525L13.5325 3.28C13.825 2.9875 13.825 2.515 13.5325 2.2225L11.7775 0.4675C11.6275 0.3175 11.44 0.25 11.245 0.25ZM8.545 4.765L9.235 5.455L2.44 12.25H1.75V11.56L8.545 4.765ZM0.25 10.9386L8.545 2.64355L11.3575 5.45605L3.0625 13.7511H0.25V10.9386Z"
      fill={color}
    />
  </svg>
);

export default IconEdit;
