/* eslint-disable array-bracket-newline */
import { useCallback, useState, useEffect, useMemo } from 'react';

import { ModeTypes } from 'context/store/flowReducer';
import { useAppState } from 'context/AppState';

interface IProps {
  currentModeType: ModeTypes;
  fields: any[];
  fieldsHasErrors: boolean;
  isBoundaryClosed: boolean;
}

const useValidation = ({ currentModeType, fields, fieldsHasErrors, isBoundaryClosed }: IProps) => {
  const {
    recommendationForm: { tempBoundaryField },
  } = useAppState();
  const [showFieldForm, setShowFieldForm] = useState(false);
  const [disableForm, setDisableForm] = useState(true);
  const [disableSaveButton, setDisableSaveButton] = useState(true);
  const [disableNextButton, setDisableNextButton] = useState(true);

  const validateCreatingMode = useCallback(() => {
    setShowFieldForm(true);
    setDisableForm(true);
    setDisableSaveButton(true);

    if (isBoundaryClosed) {
      setDisableNextButton(fieldsHasErrors);
    } else {
      setDisableNextButton(true);
    }
  }, [fieldsHasErrors, isBoundaryClosed]);

  const validateEditingMode = useCallback(() => {
    setShowFieldForm(true);
    setDisableForm(true);
    setDisableSaveButton(true);
    if (isBoundaryClosed) {
      setDisableNextButton(fieldsHasErrors);
    } else {
      setDisableNextButton(true);
    }
  }, [fieldsHasErrors, isBoundaryClosed]);

  const validateSavingMode = useCallback(() => {
    setShowFieldForm(false);
    setDisableForm(true);
    setDisableSaveButton(true);
    setDisableNextButton(false);
  }, []);

  const validateNoActionMode = useCallback(() => {
    setShowFieldForm(false);
    setDisableSaveButton(true);
    setDisableNextButton(false);
  }, []);

  const validateConfirmingMode = useCallback(() => {
    setDisableForm(false);
    setDisableSaveButton(fieldsHasErrors);
  }, [fieldsHasErrors]);

  const validationModeTypeMap = useMemo(
    (): { [key in ModeTypes]: () => void | undefined } => ({
      [ModeTypes.CREATING]: validateCreatingMode,
      [ModeTypes.EDITING]: validateEditingMode,
      [ModeTypes.SAVING]: validateSavingMode,
      [ModeTypes.CANCELLING]: () => undefined,
      [ModeTypes.NO_ACTION]: validateNoActionMode,
      [ModeTypes.CONFIRMING_AFTER_EDIT]: validateConfirmingMode,
      [ModeTypes.DELETING]: () => undefined,
    }),
    [
      validateCreatingMode,
      validateEditingMode,
      validateSavingMode,
      validateNoActionMode,
      validateConfirmingMode,
    ]
  );

  useEffect(() => {
    validationModeTypeMap[currentModeType]();
    if (!fields.length) {
      setShowFieldForm(true);
    }
    if (tempBoundaryField) {
      setDisableForm(false);
      setDisableSaveButton(fieldsHasErrors);
    }
  }, [currentModeType, fields, validationModeTypeMap, tempBoundaryField, fieldsHasErrors]);

  return {
    showFieldForm,
    disableForm,
    disableSaveButton,
    disableNextButton,
  };
};

export default useValidation;
