/* eslint-disable array-bracket-newline */
import { Dispatch, SetStateAction, useCallback, useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { CountryData } from 'react-phone-input-2';
import { areArraysEqual } from 'utils/helpers/arrayOperations';

import PhoneNumberInput from 'components/PhoneNumberInput';

import {
  ContainerStyled,
  TextStyled,
  RequiredTextStyled,
  TextErrorStyled,
} from './PhoneNumberWrapper.styles';
import { useRecommendationFormActions } from 'context/actions/recommendationFormActions';

interface IProps {
  label?: string;
  inputName: string;
  inputValue?: string | null;
  setInputValue?: (val: string | null) => void;
  isRequired?: boolean;
  setHasErrorsForParent?: Dispatch<
    SetStateAction<{ name: boolean; email: boolean; phoneNumber: boolean; lastName: boolean }>
  >;
  regexFailMessage?: string;
  requiredFailMessage?: string;
  country?: string;
}

const ErrorList = ({ errors }: { errors: string[] }) => {
  return (
    <div>
      {errors.map((error, idx) => (
        <TextErrorStyled key={idx} type="danger">
          {error}
        </TextErrorStyled>
      ))}
    </div>
  );
};

const validateRegexForm = (value: string): boolean => {
  const regex = /^(?:[0-9] ?){6,14}[0-9]$/;
  return regex.test(value);
};

const validateRequired = ({
  isRequired,
  value,
}: {
  isRequired: boolean;
  value: string | null | undefined;
}): boolean => {
  return !!(isRequired && value) || !isRequired;
};

const PhoneNumberWrapper = ({
  label,
  inputValue,
  inputName,
  setInputValue,
  isRequired = false,
  setHasErrorsForParent,
  regexFailMessage,
  requiredFailMessage,
  country,
}: IProps): JSX.Element => {
  const [t] = useTranslation();
  const { setCountryPhoneDialCode } = useRecommendationFormActions();
  const [countryDialCode, setCountryDialCode] = useState('');
  const [inputErrors, setInputErrors] = useState<string[]>([]);
  const [inputHasChanged, setInputHasChanged] = useState<boolean>(false);

  const setErrors = useCallback(
    (value: string) => {
      const errors: string[] = [];
      const numberWithoutDialCode = value.split('').slice(countryDialCode.length).join('');

      if ((isRequired || numberWithoutDialCode.length > 0) && !validateRegexForm(value)) {
        errors.push(regexFailMessage ? regexFailMessage : t('Phone number invalid'));
      }

      if (!validateRequired({ isRequired, value: value })) {
        errors.push(requiredFailMessage ? requiredFailMessage : t('This field is required'));
      }

      if (areArraysEqual(inputErrors, errors)) {
        return;
      }
      setInputErrors(errors);
    },
    [countryDialCode.length, isRequired, inputErrors, regexFailMessage, t, requiredFailMessage]
  );

  const handleChange = (value: string, { dialCode }: CountryData) => {
    setCountryDialCode(dialCode);
    setCountryPhoneDialCode({ countryPhoneDialCode: dialCode });
    setErrors(value);
    setInputValue && setInputValue(value);
    setInputHasChanged(true);
  };

  useEffect(() => {
    const hasInputErrors = inputErrors.length > 0;
    if (setHasErrorsForParent) {
      setHasErrorsForParent((prev: any) => {
        const prevErrorState = prev[inputName];
        if (prevErrorState !== hasInputErrors) {
          return { ...prev, [inputName]: hasInputErrors };
        }
        return prev;
      });
    }
  }, [inputErrors, setHasErrorsForParent, inputName]);

  useEffect(() => {
    if (inputHasChanged && inputValue !== null && inputValue !== undefined) {
      setErrors(inputValue);
    }
  }, [t, inputValue, inputHasChanged, setErrors]);

  const hasInputErrors = inputErrors.length > 0;
  return (
    <ContainerStyled>
      <TextStyled>
        {label || 'Label goes here'} {isRequired && <RequiredTextStyled>{' *'}</RequiredTextStyled>}
      </TextStyled>
      <PhoneNumberInput
        country={country}
        inputClass={hasInputErrors ? 'error-border' : ''}
        buttonClass={hasInputErrors ? 'error-border' : ''}
        value={inputValue}
        onChange={handleChange}
      />
      {inputErrors.length > 0 && <ErrorList errors={inputErrors} />}
    </ContainerStyled>
  );
};

export default PhoneNumberWrapper;
