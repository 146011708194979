import { SVGProps } from 'react';
import { colors } from 'syngenta-digital-cropwise-react-ui-kit';

const IconNextArrow = ({
  height = '12px',
  width = '12px',
  color = colors.green40,
  ...props
}: SVGProps<SVGSVGElement>) => (
  <svg
    width="60"
    height="60"
    viewBox="0 0 48 48"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
    {...props}
  >
    <rect width="48" height="48" rx="24" fill="#14803C" />
    <path
      d="M21.7049 18L20.2949 19.41L24.8749 24L20.2949 28.59L21.7049 30L27.7049 24L21.7049 18Z"
      fill={'#fff'}
    />
  </svg>
);

export default IconNextArrow;
