import styled from 'styled-components';
import { colors } from 'syngenta-digital-cropwise-react-ui-kit';

export const StyledSpinner = styled.div`
  border: 8px solid ${colors.neutral20};
  border-radius: 50%;
  border-top: 8px solid ${colors.green60};
  border-right: 8px solid ${colors.green60};
  width: 90px;
  height: 90px;
  -webkit-animation: spin 2s linear infinite; /* Safari */
  animation: spin 2s linear infinite;

  /* Safari */
  @-webkit-keyframes spin {
    0% {
      -webkit-transform: rotate(0deg);
    }
    100% {
      -webkit-transform: rotate(360deg);
    }
  }

  @keyframes spin {
    0% {
      transform: rotate(0deg);
    }
    100% {
      transform: rotate(360deg);
    }
  }
`;

export const OverlayContainer = styled.div`
  z-index: 1090;
  position: fixed;
  top: 55px;
  left: 0;
  right: 0;
  bottom: 0;
  width: 100%;

  display: flex;
  justify-content: center;
  align-items: center;

  background: rgba(0, 0, 0, 0.5);
`;

export const StyledNotification = styled.div`
  min-height: max-content;
  min-width: max-content;
  color: rgb(255, 255, 255);
  background-color: rgba(20, 21, 28, 0.9);
  border-radius: 24px;
  padding: 12px 16px;
  font-size: 14px;
`;
