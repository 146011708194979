import { useTranslation } from 'react-i18next';

const NotificationTextTranslator = ({
  text,
  translationOptions,
}: {
  text: string;
  translationOptions?: Object;
}): JSX.Element => {
  const [t] = useTranslation();
  return <>{t(text, translationOptions)}</>;
};

export default NotificationTextTranslator;
