/* eslint-disable array-bracket-newline */
import MapsDropAPin from 'components/Maps/MapsDropAPin';
import { useFlowActions } from 'context/actions/flowActions';
import { useAppState } from 'context/AppState';
import { useCallback, useEffect, useLayoutEffect, useMemo, useRef, useState } from 'react';
import { MapContainer } from './DropAPinMapView.styles';
import { useTranslation } from 'react-i18next';
import { useRecommendationFormActions } from 'context/actions/recommendationFormActions';
import { useMap } from 'react-map-gl';
import debounce from 'utils/debounce';
import { pointInCountry } from 'utils/helpers/geospatial';
import { FlowSteps } from 'context/store/flowReducer';
import { ButtonBaseProps } from 'components/Buttons/ButtonBase';
import ProxyLayer from 'base/api/ProxyLayer';
import { useApiDataActions } from 'context/actions/ApiDataActions';
import { useFlags } from 'launchdarkly-react-client-sdk';

export default function DropAPinMapView({ id }: Readonly<{ id: string }>): JSX.Element {
  const mapContainerRef = useRef(null);
  const {
    flow: { optionType, isLocationEnabled, currentStep },
    recommendationForm,
    map: { center: mapCenter, mapStyle },
  } = useAppState();
  const flowActions = useFlowActions();
  const flags = useFlags();
  const recommendationFormActions = useRecommendationFormActions();
  const [movingBack, setMovingBack] = useState(false);

  const { flowMap, flowMapMobile } = useMap();
  const map = flowMap ?? flowMapMobile;
  const [disabledPrevBtn, setDisabledPrevBtn] = useState(true);
  const [disabledNextBtn, setDisabledNextBtn] = useState(false);
  const { pin, countryCode } = recommendationForm;
  const { t } = useTranslation();
  const [longitude, latitude] = mapCenter;
  const ApiDataActions = useApiDataActions();

  // This nextClick handler is getting used for mobile screen only.
  const onNextClick = useCallback( async() => {
    if (map) {
      const lngLat = map.getMap().getCenter();
      const checkIsLandArable = async (): Promise<{ arable_land: boolean }> => {
        const cancelToken = ProxyLayer.cancelToken();
        let response = {
          arable_land: true,
        };
        try {
          const { lng, lat } = lngLat;
          response = await ApiDataActions.isLandArable(lng, lat, cancelToken.token);
        } catch (e) {
          console.log('Error!!', e);
        } finally {
          cancelToken?.cancel();
        }
        return response;
      };
      return checkIsLandArable();
    }
  }, [ApiDataActions, flowActions, flags.isBrazilEnable, map]);

  useEffect(() => {
    // enable/disable next btn

    switch (currentStep) {
      case FlowSteps.STEP1:
        setDisabledPrevBtn(true);
        setDisabledNextBtn(false);
        break;
      case FlowSteps.STEP2:
        setDisabledPrevBtn(true);
        setDisabledNextBtn(true);
        break;
      default:
        break;
    }
  }, [currentStep]);

  const stepsControlProps = useMemo(
    () => ({
      buttonPreviousProps: {
        'aria-label': t('Previous Step'),
        onClick: () => {
          flowActions.setShowCancelBoundaryModal({ show: true });
        },
        disabled: disabledPrevBtn,
      } as ButtonBaseProps,
      buttonNextProps: {
        'aria-label': t('Next step'),
        onClick: () => {
          if (map) {
            const lngLat = map.getMap().getCenter();
            const code = pointInCountry(lngLat, flags);
            if (!code) {
              flowActions.setShowPinWarning({ show: true });
              return;
            }
            onNextClick().then((res) => {
              if (res?.arable_land) {
                recommendationFormActions.setPinPosition({ lngLat });
                recommendationFormActions.setCountryCode({ countryCode: countryCode ?? '' });
                flowActions.setShowPinInformationModal({ show: true });
                flowActions.setMobileActiveTab({ activeTab: 'List' });
              } else {
                flowActions.setShowLandIsNotArableModal({ show: true });
              }
            });
          }
        },
        disabled: disabledNextBtn,
      } as ButtonBaseProps,
    }),
    [t, disabledPrevBtn, disabledNextBtn, flowActions, map, onNextClick, recommendationFormActions, countryCode]
  );

  useLayoutEffect(() => {
    const mapRef = map?.getMap();
    const resizer = new ResizeObserver(
      debounce(() => {
        // Recenter the map on any container size change
        try {
          mapRef?.resize();
          if (pin.position && countryCode) {
            const { lat, lng } = pin.position;
            mapRef?.flyTo({
              center: [lng, lat],
              essential: true,
              zoom: 15,
              duration: 0,
            });
          }
        } catch (e) {
          // TODO: Error Map is not mounted
        }
      })
    );

    if (mapContainerRef.current) {
      resizer.observe(mapContainerRef.current);
    }

    return () => {
      resizer.disconnect();
    };
  }, [pin.position, map, countryCode, id]);

  useLayoutEffect(() => {
    // Track Pan event
    const mapRef = map?.getMap();

    function moveEndEvent(event: any) {
      const { target: map } = event;
      if (movingBack === false) {
        recommendationFormActions.addItemToHistory(map.getCenter());
      } else {
        setMovingBack(false);
      }
    }

    mapRef?.on('load', () => {
      mapRef.setLayoutProperty('poi-label', 'visibility', 'none');
    });

    mapRef?.on?.('moveend', moveEndEvent);

    return () => {
      mapRef?.off?.('moveend', moveEndEvent);
    };
    // eslint-disable-next-line array-bracket-newline
  }, [flowActions, pin.positionHistory, movingBack, recommendationFormActions, map]);

  useLayoutEffect(() => {
    const mapRef = map;

    // set first item in the history
    if (mapRef) {
      recommendationFormActions.addItemToHistory(mapRef.getMap().getCenter());
    }
  }, [recommendationFormActions, map]);

  return (
    <MapContainer
      ref={mapContainerRef}
      id="map-container"
      className={`${optionType.toLocaleLowerCase()}-flow`}
    >
      <MapsDropAPin
        id={id}
        longitude={longitude}
        latitude={latitude}
        mapStyle={mapStyle}
        stepsControlProps={stepsControlProps}
        isLocationEnabled={isLocationEnabled}
        showPin={true}
      />
    </MapContainer>
  );
}
