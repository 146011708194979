import { CSSProperties, MouseEventHandler } from 'react';
import {
  StyledCollapsed,
  StyledPanel,
  StyledButton,
  StyledDivText,
  StyledDivIcon,
} from './MapTools.styles';
import { Tooltip } from 'syngenta-digital-cropwise-react-ui-kit';
import { TooltipPlacement } from 'antd/lib/tooltip';
import { CollapsibleType } from 'antd/lib/collapse/CollapsePanel';
import CustomExpandIcon from 'components/CustomExpandIcon/CustomExpandIcon';

export interface ItemToolProps {
  label: string;
  tooltipLabel?: string;
  icon: React.ReactNode;
  isOnlyPressable?: boolean;
  onClick?: MouseEventHandler<HTMLLIElement>;
  style?: CSSProperties;
  disabled?: boolean;
  tooltipProps?: unknown;
}

export interface MapToolsProps {
  id?: string;
  title: string;
  items: Array<ItemToolProps>;
  tooltipPlacement?: TooltipPlacement;
  selectedTool?: number;
  collapsible?: CollapsibleType;
  onChange?: () => void;
  activeKey?: string[];
  handleSelectedToolChange?: (toolOption: number) => void;
}

const MapTools = ({
  id,
  title,
  items,
  tooltipPlacement = 'right',
  selectedTool = -1,
  collapsible,
  onChange,
  activeKey = ['1'],
  handleSelectedToolChange,
}: MapToolsProps): JSX.Element => {
  return (
    <StyledCollapsed
      defaultActiveKey={['1']}
      expandIconPosition="end"
      collapsible={collapsible}
      activeKey={activeKey}
      onChange={onChange}
      expandIcon={CustomExpandIcon}
    >
      <StyledPanel header={title ?? 'Header goes here'} key="1" id={id} >
        {items.map((item, index) => (
          <Tooltip key={`tooltip${index}`} placement={tooltipPlacement} title={item.tooltipLabel}>
            <StyledButton
              disabled={item.disabled}
              key={index}
              onClick={(e: React.MouseEvent<HTMLElement>) => {
                if (!item.disabled) {
                  e.currentTarget.blur();
                  handleSelectedToolChange && handleSelectedToolChange(index);
                  item.onClick?.(e as React.MouseEvent<HTMLLIElement>);
                }
              }}
              className={
                selectedTool === index && !item.disabled && !item.isOnlyPressable ? 'selected' : ''
              }
            >
              <StyledDivIcon>{item.icon}</StyledDivIcon>
              <StyledDivText>{item.label}</StyledDivText>
            </StyledButton>
          </Tooltip>
        ))}
      </StyledPanel>
    </StyledCollapsed>
  );
};

export default MapTools;
