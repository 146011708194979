import { IStyledModalProps } from 'base/types/ModalBox';
import ModalView from 'components/ModalView';
import styled from 'styled-components';
import { colors, Modal, SYT_PREFIX, Typography } from 'syngenta-digital-cropwise-react-ui-kit';
import { showSlidingAnimation } from 'utils/modalBoxAnimations';

export const StyledResendModal = styled(Modal)`
  .${SYT_PREFIX}-modal-title {
    font-weight: 600;
    font-size: 16px;
    line-height: 24px;
    text-align: center;
  }
  .${SYT_PREFIX}-typography {
    font-size: 16px;
    line-height: 24px;
    color: ${colors.neutral70};
    margin: 0;
    text-align: 'center';
  }
  img {
    display: block;
    margin: auto;
    margin-bottom: 16px;
    width: 100%;
  }
  .${SYT_PREFIX}-modal-view-footer, .modal-view-footer {
    display: none;
  }
`;
export const StyledContent = styled.div`
  display: flex;
  justify-content: center;
  flex-direction: column;
  align-items: center;
`;
export const StyledParagraph = styled(Typography.Paragraph)`
  text-align: center;
  padding-bottom: 15px;
  padding-left: 15px;
  @media screen and (max-width: 769px) {
    margin-top: 12px !important;
    padding-left: 0px !important;
  }
`;
export const StyledModalView = styled(ModalView)<IStyledModalProps>`
  top: auto !important;
  height: 315px !important;
  .${SYT_PREFIX}-modal-content {
    border-radius: 8px;
    justify-content: center;
  }
  ${(props) => showSlidingAnimation(props.slideDirection)}
  @media screen and (max-height: 769px) {
    .${SYT_PREFIX}-modal-content {
      overflow: auto !important;
      justify-content: center;
    }
  }
  .${SYT_PREFIX}-modal-header {
    border-radius: 8px;
  }
`;
