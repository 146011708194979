import styled from 'styled-components';
import { colors, Typography } from 'syngenta-digital-cropwise-react-ui-kit';

const TableStyled = styled.table`
  border: 1px solid var(--Neutral-neutral-20, #DFE2E7);
  width: -webkit-fill-available;
  margin-bottom: 16px;
`;
const TableContainer = styled.div`
  padding: 32px 16px 0px 16px;
  border-radius: 4px;
`;
export const SkuContainer = styled.div`
  border-top: 1px solid #DFE2E7;
`;

const TrStyled = styled.tr`
  height: 52px;
  background-color: ${colors.neutral10};
  border: 1px solid #DFE2E7;
`;

const TrExpandableRow = styled.tr`
  border: 1px solid #dfe2e7;
`;

const ExpandedTdStyled = styled.td`
  padding: 8px 12px;
  display: flex;
  align-items: center;
`;
export const ExpandedDiviverTdStyled = styled.td`
  align-items: center;
  border: 1px solid #DFE2E7;
`;

const RankText = styled(Typography.Text)`
  font-family: 'Noto Sans';
  font-style: normal;
  font-weight: 600;
  font-size: 14px;
  line-height: 20px;
  color: ${colors.neutral100};
  margin: 0;
`;

const VarietyText = styled(Typography.Text)`
  font-family: 'Noto Sans';
  font-style: normal;
  font-weight: 600;
  font-size: 14px;
  line-height: 20px;
  color: ${colors.neutral100};
  margin-left: 16px;
`;
export const DescriptionText = styled(Typography.Text)`
  font-family: 'Noto Sans';
  font-style: normal;
  font-weight: 600;
  font-size: 14px;
  line-height: 20px;
  color: ${colors.neutral100};
  margin: -16px 0px 0px 20px;
`;

const NumberText = styled(Typography.Text)`
  font-family: 'Noto Sans';
  font-style: normal;
  font-weight: 600;
  font-size: 14px;
  line-height: 20px;
  color: ${colors.neutral100};
  margin: 0px -25px 0px auto;
`;
const CommonText = styled(Typography.Text)`
  font-family: 'Noto Sans';
  font-style: normal; 
  font-weight: 400;
  font-size: 14px;
  line-height: 20px;
  color ${colors.neutral100};
  margin-left: 22px;
`;

export {
  TableStyled,
  TrStyled,
  RankText,
  VarietyText,
  TrExpandableRow,
  ExpandedTdStyled,
  NumberText,
  TableContainer,
  CommonText,
};
