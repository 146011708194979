import { SVGProps } from 'react';
import { colors } from 'syngenta-digital-cropwise-react-ui-kit';

const IconKeyboardArrowRight = ({
  height = '10px',
  width = '7px',
  color = colors.neutral10,
  ...props
}: SVGProps<SVGSVGElement>) => (
  <svg
    width={width}
    height={height}
    viewBox="0 0 7 10"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
    {...props}
  >
    <path
      d="M0.412109 8.825L4.22878 5L0.412109 1.175L1.58711 0L6.58711 5L1.58711 10L0.412109 8.825Z"
      fill={color}
    />
  </svg>
);

export default IconKeyboardArrowRight;
