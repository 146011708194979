import { useTranslation } from 'react-i18next';
import { useAppState } from 'context/AppState';
import {
  StyledRow,
  StyledCol,
  StyledH1,
  AgronomicInputsContainer,
  StyledParagraph,
  SpinnerContainer,
} from './AgronomicPreferences.styles';
import { AgronomicWeightingsList } from './AgronomicWeightingsList';
import moment from 'moment';
import { useEffect, useState } from 'react';
import { getTraitLabel, clearfieldTraitValues } from 'utils/trait';
import breakpoints from 'base/constants/breakpoints';
import {
  AgroPreferenceTitle,
  AgronomicPreferenceContent,
  SectionContainer,
  Text,
} from 'pages/RecommendationV2/V2Common.styles';
import { AgronomicInputs } from 'base/types/RecommendationCropwise';
import { StyledSpinner } from 'components/Spinner/Spinner.styles';
import { CropConstants } from 'utils/constants/Crop';
import { SS_AG_InputOptions } from 'utils/constants/AGInputOptionsConstants';

export default function AgronomicPreferences({ sectionRef }: { readonly sectionRef?: any }) {
  const {
    BETWEEN_1_TO_3_YEARS,
    PLOUGHING,
    Less_than_3_Years,
    Over_3_Years,
    Conventional_Tillage_Ploughing,
    Minimal_or_No_Tillage
  } = SS_AG_InputOptions;
  const [t] = useTranslation();
  const {
    apiData: { recommendationCropwise },
  } = useAppState();
  const [agronomicInputs, setAgronomicInputs] = useState<AgronomicInputs>();
  const isDDG = recommendationCropwise?.is_ddg;

  const getYieldRange = (yieldRange: string, cropName: string, country: string): string => {
    // TODO: Check with SS and Partner if yieldRange along with Label can be saved to avoid following manipulation
    if (isDDG) {
      return yieldRange;
    } else if (country === CropConstants.Bulgaria) {
      const [start, end] = yieldRange.split('-').map(Number);
      const multiplier = cropName === 'corn' ? 100 : 10;
      const newStart = start * multiplier;
      const newEnd = end * multiplier;
      return `${newStart}-${newEnd}`;
    }
    return yieldRange;
  };

  useEffect(() => {
    if (recommendationCropwise?.recommendations?.length) {
      const agroInput =
        recommendationCropwise.recommendations[0]?.multi_field?.config?.agronomicInputs;
      setAgronomicInputs(agroInput);
    }
  }, [recommendationCropwise]);

  useEffect(() => {
    let minH1Height = 30;
    let minP1Height = 20;
    const h1Tags = document.querySelectorAll('.ag-input h1');
    const p1Tags = document.querySelectorAll('.ag-input div');
    if (window.innerWidth <= parseInt(breakpoints.size.sm)) {
      p1Tags?.forEach((p: any) => {
        p.style.height = `25px`;
      });
      h1Tags?.forEach((h: any) => {
        h.style.minHeight = '40px';
      });
    }

    h1Tags.forEach((h1) => {
      const height = h1.clientHeight;
      if (height > minH1Height) {
        minH1Height = height;
      }
    });
    h1Tags?.forEach((h: any) => {
      h.style.minHeight = `${minH1Height}px`;
    });

    p1Tags.forEach((p1) => {
      const height = p1.clientHeight;
      if (height > minP1Height) {
        minP1Height = height;
      }
    });
    p1Tags?.forEach((p: any) => {
      p.style.height = `${minP1Height}px`;
    });
  }, []);

  const commonPreference = () => {
    return (
      <>
        <StyledCol span={6} xs={12} md={6} className="ag-input">
          <StyledParagraph>
            {`${t('Historical yield range')}`}
            <span>
              {agronomicInputs?.extraInfo?.yieldRangeUnit
                ? `(${agronomicInputs?.extraInfo?.yieldRangeUnit})`
                : '(-)'}
            </span>
          </StyledParagraph>
          <StyledH1>{getYieldRange(agronomicInputs?.yieldRange ?? '',
            agronomicInputs?.cropName ?? '',
            recommendationCropwise?.country ?? '') ?? '-'}</StyledH1>
        </StyledCol>
        <StyledCol span={6} xs={12} md={6} className="ag-input">
          <StyledParagraph>{t('Targeted planting date')}</StyledParagraph>
          <StyledH1>
            {agronomicInputs?.plantingDate
              ? moment(agronomicInputs?.plantingDate).format('DD/MM/YY')
              : '-'}
          </StyledH1>
        </StyledCol>
        <StyledCol span={6} xs={12} md={6} className="ag-input">
          <StyledParagraph>{t('Targeted harvest date')}</StyledParagraph>
          <StyledH1>
            {agronomicInputs?.harvestDate
              ? moment(agronomicInputs?.harvestDate).format('DD/MM/YY')
              : '-'}
          </StyledH1>
        </StyledCol>
      </>
    );
  };

  const renderCorn = () => {
    return (
      <StyledRow>
        <StyledCol span={6} xs={12} md={6} className="ag-input">
          <StyledParagraph className="ag-input-values">
            {t('Product maturity range (FAO)')}
          </StyledParagraph>
          <StyledH1>
            {agronomicInputs?.fao?.[0]
              ? `${agronomicInputs?.fao[0]}-${agronomicInputs?.fao[1]}`
              : '-'}
          </StyledH1>
        </StyledCol>
        {commonPreference()}
      </StyledRow>
    );
  };

  const renderWheat = () => {
    return <StyledRow>{commonPreference()}</StyledRow>;
  };

  const updatedTraitText = (value: string): string => {
    if (value !== clearfieldTraitValues[1]) {
      return getTraitLabel(value);
    }
    return '';
  };

  const getRotationText = (value: string): string => {
    return value === BETWEEN_1_TO_3_YEARS ? t(Less_than_3_Years) : t(Over_3_Years);
  };

  const getTillagePracticeText = (value: string): string => {
    return value === PLOUGHING ? t(Conventional_Tillage_Ploughing) : t(Minimal_or_No_Tillage);
  };

  const renderSunflower = () => {
    const tillageTextAdjusted = agronomicInputs?.tillage
      ? t(agronomicInputs.tillage).split('/').join(' / ')
      : '-';
    return (
      <StyledRow>
        <StyledCol span={6} xs={12} md={6} className="ag-input">
          <StyledParagraph isLongString>
            {`${t('Historical yield range')}`}
            <span>
              {agronomicInputs?.extraInfo?.yieldRangeUnit
                ? `(${agronomicInputs?.extraInfo?.yieldRangeUnit})`
                : '(-)'}
            </span>
          </StyledParagraph>
          <StyledH1>{getYieldRange(agronomicInputs?.yieldRange ?? '',
            agronomicInputs?.cropName ?? '',
            recommendationCropwise?.country ?? '') ?? '-'}</StyledH1>
        </StyledCol>
        <StyledCol span={6} xs={12} md={6} className="ag-input">
          <StyledParagraph isLongString>{t('Targeted planting date')}</StyledParagraph>
          <StyledH1>
            {agronomicInputs?.plantingDate
              ? moment(agronomicInputs?.plantingDate).format('DD/MM/YY')
              : '-'}
          </StyledH1>
        </StyledCol>
        <StyledCol span={6} xs={12} md={6} className="ag-input">
          <StyledParagraph isLongString>{t('Targeted harvest date')}</StyledParagraph>
          <StyledH1>
            {agronomicInputs?.harvestDate
              ? moment(agronomicInputs?.harvestDate).format('DD/MM/YY')
              : '-'}
          </StyledH1>
        </StyledCol>
        <StyledCol span={6} xs={12} md={6} className="ag-input">
          <StyledParagraph>{t('Oil Trait')}</StyledParagraph>
          <StyledH1 isLongString>
            {agronomicInputs?.cropSegments?.length
              ? agronomicInputs?.cropSegments?.map((segmentText) => t(segmentText)).join(', ')
              : '-'}
          </StyledH1>
        </StyledCol>
        <StyledCol span={6} xs={12} md={6} className="ag-input">
          <StyledParagraph>{t('Broomrape Technology')}</StyledParagraph>
          {
            recommendationCropwise?.is_ddg
              ? <StyledH1>
                {
                  agronomicInputs?.broomRape?.length
                    ? agronomicInputs?.broomRape?.map((broomRape) => t(broomRape)).join(', ')
                    : '-'
                }
              </StyledH1>
              // as discussed with Evgeniya and Balu we will show first broomrape technology in nonDDG recommendation
              : <StyledH1> {agronomicInputs?.broomRape?.length ? agronomicInputs?.broomRape[0] : '-'}</StyledH1>
          }
        </StyledCol>
        <StyledCol span={6} xs={12} md={6} className="ag-input">
          <StyledParagraph>{t('Herbicide Technology')}</StyledParagraph>
          <StyledH1 isLongString lang="en">
            {agronomicInputs?.traits?.length
              ? agronomicInputs?.traits
                ?.map((traitText) => t(`${updatedTraitText(traitText)}`))
                .filter((v) => v !== '')
                .join(', ')
              : '-'}
          </StyledH1>
        </StyledCol>

        <StyledCol span={6} xs={12} md={6} className="ag-input">
          <StyledParagraph>{t('Sunflower Rotation')}</StyledParagraph>
          {
            recommendationCropwise?.is_ddg
              ? <StyledH1>{agronomicInputs?.rotation ? t(agronomicInputs.rotation) : '-'}</StyledH1>
              : <StyledH1> {agronomicInputs?.inputManagementPractices?.crop_rotation ? getRotationText(agronomicInputs.inputManagementPractices.crop_rotation) : '-'}</StyledH1>
          }
        </StyledCol>

        <StyledCol span={6} xs={12} md={6} className="ag-input">
          <StyledParagraph>{t('Tillage Practice')}</StyledParagraph>
          {
            recommendationCropwise?.is_ddg
              ? <StyledH1>{tillageTextAdjusted}</StyledH1>
              : <StyledH1> {agronomicInputs?.inputManagementPractices?.tillage_system ? getTillagePracticeText(agronomicInputs.inputManagementPractices.tillage_system) : '-'}</StyledH1>
          }
        </StyledCol>
      </StyledRow>
    );
  };

  const renderCrop = () => {
    const cropName = recommendationCropwise?.recommendations[0]?.crop_name.toLowerCase();
    switch (true) {
      case cropName?.includes('corn'):
        return renderCorn();
      case cropName?.includes('sunflower'):
        return renderSunflower();
      case cropName?.includes('wheat'):
        return renderWheat();
      default:
        return renderCorn();
    }
  };

  return (
    <SectionContainer
      ref={sectionRef}
      data-testid="agronomic-preference-section"
      className="secondary-container"
    >
      <AgronomicPreferenceContent>
        <AgroPreferenceTitle>{t('Your Agronomic Preferences')}</AgroPreferenceTitle>
        <Text center>{t('Your Agronomic Preferences description')}</Text>
        {!agronomicInputs ? (
          <SpinnerContainer>
            <StyledSpinner />
          </SpinnerContainer>
        ) : (
          <AgronomicInputsContainer id="ag-preference-input">
            {renderCrop()}
          </AgronomicInputsContainer>
        )}
        <AgronomicWeightingsList />
      </AgronomicPreferenceContent>
    </SectionContainer>
  );
}
