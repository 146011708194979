import { SVGProps } from 'react';
import { colors } from 'syngenta-digital-cropwise-react-ui-kit';

const IconHub = ({
  height = '39px',
  width = '39px',
  color = colors.neutral60,
  ...props
}: SVGProps<SVGSVGElement>) => (
  <svg
    width={width}
    height={height}
    viewBox="0 0 40 40"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
    {...props}
  >
    <path
      fill={color}
      fillRule="evenodd"
      clipRule="evenodd"
      d="M17.75 15.5C17.75 16.4135 17.2056 17.1999 16.4235 17.5523L16.8211 22.3231C16.6387 22.2753 16.4473 22.2499 16.2499 22.2499C15.9208 22.2499 15.6081 22.3206 15.3263 22.4477L14.9287 17.6768C13.9627 17.424 13.25 16.5452 13.25 15.5C13.25 14.2574 14.2574 13.25 15.5 13.25C16.7426 13.25 17.75 14.2574 17.75 15.5ZM16.25 26.75C17.3012 26.75 18.184 26.0291 18.4311 25.0548L22.4547 24.689C22.3232 24.4032 22.2499 24.0851 22.2499 23.7499C22.2499 23.5585 22.2738 23.3726 22.3188 23.1952L18.2953 23.5609C17.9394 22.7872 17.1574 22.25 16.25 22.25C15.0074 22.25 14 23.2574 14 24.5C14 25.7426 15.0074 26.75 16.25 26.75ZM26.75 23.75C26.75 24.9926 25.7426 26 24.5 26C23.2574 26 22.25 24.9926 22.25 23.75C22.25 22.5074 23.2574 21.5 24.5 21.5C25.7426 21.5 26.75 22.5074 26.75 23.75ZM26.75 16.25C26.75 17.2297 26.1239 18.0632 25.2499 18.372V21.628C25.0153 21.545 24.7629 21.4999 24.4999 21.4999C24.2369 21.4999 23.9845 21.5451 23.7499 21.628V18.3719C22.8761 18.063 22.25 17.2296 22.25 16.25C22.25 15.0074 23.2574 14 24.5 14C25.7426 14 26.75 15.0074 26.75 16.25ZM18.0503 16.4345L19.3003 16.5595L19.4495 15.0669L18.1995 14.9419L18.0503 16.4345ZM20.5503 16.6845L21.8003 16.8095L21.9495 15.3169L20.6995 15.1919L20.5503 16.6845ZM17 24.5C17 24.9142 16.6642 25.25 16.25 25.25C15.8358 25.25 15.5 24.9142 15.5 24.5C15.5 24.0858 15.8358 23.75 16.25 23.75C16.6642 23.75 17 24.0858 17 24.5ZM15.5 16.25C15.9142 16.25 16.25 15.9142 16.25 15.5C16.25 15.0858 15.9142 14.75 15.5 14.75C15.0858 14.75 14.75 15.0858 14.75 15.5C14.75 15.9142 15.0858 16.25 15.5 16.25ZM25.25 16.25C25.25 16.6642 24.9142 17 24.5 17C24.0858 17 23.75 16.6642 23.75 16.25C23.75 15.8358 24.0858 15.5 24.5 15.5C24.9142 15.5 25.25 15.8358 25.25 16.25ZM24.5 24.5C24.9142 24.5 25.25 24.1642 25.25 23.75C25.25 23.3358 24.9142 23 24.5 23C24.0858 23 23.75 23.3358 23.75 23.75C23.75 24.1642 24.0858 24.5 24.5 24.5Z"
    />
  </svg>
);

export default IconHub;
