import { Spinner } from 'components/Spinner';
import styled from 'styled-components';
import { Typography, SYT_PREFIX, colors, Row } from 'syngenta-digital-cropwise-react-ui-kit';
import ButtonBase from 'components/Buttons/ButtonBase';
import breakpoints from 'base/constants/breakpoints';
import ModalView from 'components/ModalView';
import { InputTextHandler, InputTextHandlerProps } from 'components/input-text-handler';

export const Root = styled.div`
  display: flex;
  flex-direction: column;
  flex: 1;
  overflow: auto;
`;

export const Content = styled.div`
  flex: 1;
  display: flex;
  max-width: 750px;
  flex-direction: column;
  align-items: center;
  padding: 30px 15px;
  margin: auto;
`;

export const Title = styled(Typography.Text)`
  font-size: 24px;
  font-weight: 600;
  text-align: center;
  margin-top: 45px;
  margin-bottom: 45px;

  @media screen and ${breakpoints.maxWidth.sm} {
    margin-bottom: 16px;
  }
`;

export const Message = styled(Typography.Text)`
  font-size: 16px;
  display: block;
  max-width: 620px;
  margin: 0 auto;
  text-align: center;
  color: ${colors.neutral70};

  @media screen and ${breakpoints.maxWidth.sm} {
    margin-top: 0px;
  }
`;

export const Image = styled.img`
  width: 100%;
  margin-bottom: 36px;

  @media screen and ${breakpoints.maxWidth.sm} {
    height: auto !important;
    margin-bottom: 0px !important;
  }
`;

export const ButtonStyled = styled(ButtonBase)`
  min-width: 177px;
  margin-top: 23px;

  @media screen and ${breakpoints.maxWidth.sm} {
    margin-top: 0;
  }
`;

interface InputTextHandlerCustomProps extends InputTextHandlerProps {
  showingCountdown?: boolean;
}

export const StyledPrimaryButtonRow = styled(Row)`
  gap: 24px;
  width: 100%;
  @media screen and ${breakpoints.maxWidth.sm} {
    gap: 0px;
    flex-direction: row;
    width: 100%;
    margin-top: 48px;
    justify-content: flex-start;
  }
`;

export const StyledSecondaryButtonRow = styled(Row)`
  gap: 24px;
  width: 100%;
  @media screen and ${breakpoints.maxWidth.sm} {
    gap: 15px;
    flex-direction: row;
    width: 100%;
    margin-top: 2px;
    margin-bottom: 16px;
    justify-content: flex-start;
  }
`;

export const StyledInputTextHandler = styled(InputTextHandler)<InputTextHandlerCustomProps>`
  /* margin-bottom: 13px; */
  width: 49.1%;
  @media screen and ${breakpoints.maxWidth.sm} {
    gap: 15px;
    flex-direction: row;
    width: 100%;
    justify-content: flex-start;
  }
`;

export const StyledPhoneNumberWrapper = styled.div`
  /* margin-bottom: 13px; */
  width: 47.5%;

  span {
    margin-bottom: 3.5px;
  }

  @media screen and ${breakpoints.maxWidth.sm} {
    gap: 16px;
    flex-direction: row;
    width: 100%;
    justify-content: flex-start;
  }
`;

export const StyledSpanText = styled.span`
  color: ${colors.neutral60};
  font-style: normal;
  font-weight: 400;
  font-size: 14px;
  line-height: 20px;
  /* identical to box height, or 143% */
  letter-spacing: -0.0075em;
`;

export const StyledCountdownText = styled(Typography.Paragraph)`
  font-size: 14px;
  color: ${colors.neutral70};
  margin-bottom: 0;

  @media screen and ${breakpoints.maxWidth.sm} {
    margin: 2px 0 0 0 !important;
  }
`;

export const Footer = styled.div<{
  showingCountdown?: boolean;
}>`
  display: flex;
  justify-content: flex-end;
  width: 100%;
  gap: 16px;

  & > div {
    margin: 0;
    flex: 1;
  }

  .${SYT_PREFIX}-input-affix-wrapper {
    background-color: white !important;

    & input {
      background-color: white !important;
    }
  }

  @media screen and ${breakpoints.maxWidth.sm} {
    flex-direction: column;
    margin-top: auto;
    gap: ${(props) => (props.showingCountdown ? '7px' : '16px')};
  }
`;

export const StyledSpinner = styled(Spinner)`
  top: 0;
`;

export const ConfirmationModal = styled(ModalView)`
  position: fixed;
  top: initial !important;

  .${SYT_PREFIX}-modal-title {
    font-weight: 600;
    font-size: 16px;
    line-height: 24px;
    text-align: center;
  }

  .${SYT_PREFIX}-typography {
    font-size: 16px;
    line-height: 24px;
    color: ${colors.neutral70};
    margin: 0;
  }

  img {
    display: block;
    margin: auto;
    margin-bottom: 20px;
    width: 100%;
  }

  .modal-view-footer {
    display: none;
  }
`;
