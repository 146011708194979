export function areArraysEqual<T>(array1: T[], array2: T[]): boolean {
  if (array1 === array2) {
    return true;
  }
  if (array1.length !== array2.length) {
    return false;
  }
  return array1.every((element, index) => element === array2[index]);
}

export function removeDuplicates<T>(array: T[]): T[] {
  if (array.length < 2) {
    return array;
  }
  return Array.from(new Set(array));
}

export function findArrayDifference<T>(originalArray: T[], updatedArray: T[]): T[] {
  const temporarySet = new Set(originalArray);
  const differentElements = updatedArray.filter((item) => !temporarySet.has(item));
  return differentElements;
}

export function isArrayEmpty<T>(array: T[]): boolean {
  return array.length === 0;
}

export function isObjectEmpty(object: Record<string, unknown> | undefined): boolean {
  if (!object) {
    return true;
  }
  return isArrayEmpty(Object.keys(object));
}
