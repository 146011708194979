import React, { useState, useEffect } from 'react';
import { useTranslation } from 'react-i18next';
import { StyledMenu, StyledMenuItem } from './LangDropdown.styles';
import { Typography, Dropdown } from 'syngenta-digital-cropwise-react-ui-kit';
import { SUPPORTED_LANGS, NONDDG_SUPPORTED_LANGS } from 'base/constants/supportedLangs';
import { useFlowActions } from '../../context/actions/flowActions';
import { removeQueryParameter } from 'utils/urlSearchParams';
import { useFlags } from 'launchdarkly-react-client-sdk';

const { Text } = Typography;

interface LangDropdownProps {
  children?: JSX.Element;
  isDDG?: boolean;
  isRecommendationPage?: boolean;
}

const LangDropdown: React.FC<LangDropdownProps> = ({
  children,
  isDDG,
  isRecommendationPage = false,
}) => {
  const { i18n } = useTranslation();
  const { supportedLanguages } = useFlags();
  const [showLangMenu, setShowLangMenu] = useState(false);
  const { setAppLanguage } = useFlowActions();
  const [languageMenu, setLanguageMenu] = useState(NONDDG_SUPPORTED_LANGS);

  const onClickOption = (lang: string) => {
    removeQueryParameter('lang');
    i18n.changeLanguage(lang);
    setShowLangMenu(false);
    setAppLanguage({ language: lang });
  };

  const onOpenChange = (flag: boolean) => {
    setShowLangMenu(flag);
  };

  useEffect(() => {
    if (supportedLanguages) {
      const updatedLang: any[] = [];
      supportedLanguages
        .split(',')
        .map((val: string) => {
          const filteredLangs =
            isRecommendationPage && !isDDG
              ? NONDDG_SUPPORTED_LANGS.filter((lang) => lang.value === val)
              : SUPPORTED_LANGS.filter((lang) => lang.value === val);
          return filteredLangs;
        })
        .forEach((lang: any) => {
          if (lang.length) {
            updatedLang.push({
              name: lang[0].name,
              shortLabel: lang[0].shortLabel,
              value: lang[0].value,
            });
          }
        });
      setLanguageMenu(updatedLang);
    }
  }, [supportedLanguages, isRecommendationPage, isDDG]);
  return (
    <Dropdown
      open={showLangMenu}
      overlay={
        <StyledMenu data-testid="dropdown-menu">
          {languageMenu.map(({ name, value, shortLabel }) => (
            <StyledMenuItem
              onClick={() => {
                onClickOption(value);
              }}
              key={`language-${value}`}
            >
              <Text strong={i18n.language === value}>{`${name} (${shortLabel})`}</Text>
            </StyledMenuItem>
          ))}
        </StyledMenu>
      }
      trigger={['click']}
      onOpenChange={onOpenChange}
    >
      {children}
    </Dropdown>
  );
};

export default LangDropdown;
