import { useAppState } from 'context/AppState';
import { useEffect, useState } from 'react';
import CookieBot from 'react-cookiebot';

const POLAND_DOMAIN_GROUP_ID = '89660666-f6c5-45da-80a5-55dac979bf9a';

function CookieBotRef() {
  const { flow } = useAppState();
  const [localLanguage, setLocalLanguage] = useState(localStorage.getItem('i18nextLng') ?? '');
  const domainGroupId =
    localLanguage === 'pl' ? POLAND_DOMAIN_GROUP_ID : process.env.REACT_APP_COOKIE_CBID ?? '';
  const w = window as any;
  const hasCookieBot = w.Cookiebot || w.CookieBot;

  const recoverI18NLangKey = () => {
    const origLangKey = localStorage.getItem('i18nextLng') ?? '';
    const backupLangKey = sessionStorage.getItem('i18nextLngBackup') ?? '';
    if (!origLangKey) {
      localStorage.setItem('i18nextLng', backupLangKey);
    }
  };

  useEffect(() => {
    sessionStorage.setItem('i18nextLngBackup', localLanguage);
    if (!hasCookieBot) {
      return;
    }
    const cookiebotOnLoadEvent = () => {
      const cookieConsentOnLoad = () => {
        recoverI18NLangKey();
      };

      if (w.CookieConsent) {
        w.CookieConsent.onload = cookieConsentOnLoad;
        w.CookieConsent.onaccept = cookieConsentOnLoad;
        w.CookieConsent.ondecline = cookieConsentOnLoad;
      }
    };
    const cookiebotScriptElement = document.getElementById('CookieBot');
    if (cookiebotScriptElement) {
      cookiebotScriptElement.id = 'Cookiebot';
    }
    const cookieBotScripts = document.querySelectorAll('script#CookieBot');
    if (!cookieBotScripts) {
      return;
    }
    cookieBotScripts[1]?.remove();

    window.addEventListener('CookiebotOnLoad', cookiebotOnLoadEvent, { once: true });
  }, [hasCookieBot, localLanguage, w.CookieConsent]);

  useEffect(() => {
    const localStoredLanguage = localStorage.getItem('i18nextLng');
    if (localStoredLanguage === flow.appLanguage && window.location.pathname === '/') {
      setLocalLanguage(localStoredLanguage);
    }
  }, [flow.appLanguage]);

  return hasCookieBot ? null : (
    <CookieBot
      domainGroupId={
        domainGroupId.includes('REACT_APP') ? domainGroupId.split('REACT_APP')[0] : domainGroupId
      }
      language={`${localLanguage}`}
    />
  );
}

export default CookieBotRef;
