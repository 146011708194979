import { SVGProps } from 'react';

import { colors } from '../../theme/colors';

const IconUndo = ({
  height = '18px',
  width = '18px',
  color = colors.neutral60,
  ...props
}: SVGProps<SVGSVGElement>) => (
  <svg
    width={width}
    height={height}
    viewBox={`0 0 ${width} ${height}`}
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
    {...props}
  >
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M9.19873 6.375C7.21123 6.375 5.41123 7.1175 4.02373 8.325L1.32373 5.625V12.375H8.07373L5.35873 9.66C6.40123 8.79 7.72873 8.25 9.19873 8.25C11.8537 8.25 14.1112 9.9825 14.8987 12.375L16.6762 11.79C15.6337 8.6475 12.6862 6.375 9.19873 6.375Z"
      fill={color}
    />
  </svg>
);

export default IconUndo;
