import ButtonBase from 'components/Buttons/ButtonBase';
import styled from 'styled-components';

export const OnboardingContainer = styled.div`
  top: 56px;
  height: 100vh;
  width: 100vw;
  position: absolute;
  z-index: 100;
  background: #23263080;
`;

export const ModalContainer = styled.div`
  max-height: 90dvh;
  height: max-content;
  width: 85dvw;
  position: relative;
  overflow: auto;
  top: 45%;
  left: 50%;
  transform: translate(-50%, -50%);
  background: rgba(255, 255, 255, 1);
  box-shadow: 0px 2px 8px 0px rgba(0, 0, 0, 0.15);
  border-radius: 8px;
  padding: 16px;
  .icon-container {
    display: flex;
    justify-content: space-between;
    align-items: center;
  }
`;

export const TextContainer = styled.div`
  text-align: center;
  padding: 8px;
  line-height: 24px;
  font-family: 'Noto Sans';
  margin-top: 36px;
  h2 {
    font-size: 16px;
    font-weight: 600;
    margin-top: 30px;
  }
  p {
    font-size: 14px;
    font-weight: 400;
  }
`;

export const Button = styled(ButtonBase)`
  border-radius: 4px;
  margin: 0 auto;
  height: 44px;
  width: 96%;
  margin-top: 10px;
`;
