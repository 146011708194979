import breakpoints from 'base/constants/breakpoints';
import { ORIENTATION_TYPE } from 'utils/constants/BrowserConstants';
import useMediaQuery from './useMediaQuery';
import isMobileTool from 'ismobilejs';
import { useEffect, useState } from 'react';

function toNumber(str = '') {
  return Number(str.replace('px', ''));
}

export const MobileBreakpoint = toNumber(breakpoints.size.md) - 20;

function useBreakpoint() {
  const xs = useMediaQuery(`screen and ${breakpoints.maxWidth.xs}`);
  const sm = useMediaQuery(
    `screen and (min-width: ${toNumber(breakpoints.size.xs) + 1}px) and (max-width: ${
      breakpoints.size.sm
    })`
  );
  const md = useMediaQuery(
    `screen and (min-width: ${toNumber(breakpoints.size.sm) + 1}px) and (max-width: ${
      breakpoints.size.md
    })`
  );

  /**
   * https://developer.mozilla.org/en-US/docs/Web/API/Screen/orientation
   * The orientation media feature in CSS is indeed based on the orientation of the viewport,
   * not the device itself. This can lead to unexpected behavior when the soft keyboard is opened,
   * causing the browser to use landscape styles instead of portrait styles
   *
   * window.orientation is considered deprecated and screen.orientation is better alternative
   */
  const getOrientationUsingDimensions = () => {
    // support: https://developer.mozilla.org/en-US/docs/Web/API/Screen/availHeight
    const diff = screen?.availHeight - screen?.availWidth;
    return ((screen?.availHeight > screen?.availWidth) || diff > -180) ? 'portrait' : 'landscape';
  };

  const orientationType = screen?.orientation?.type ?? getOrientationUsingDimensions(); // use screen orientation

  const lg = useMediaQuery('screen and (min-width: 769px)');
  const landscape = useMediaQuery('screen and (orientation: landscape)');
  const portrait = useMediaQuery('screen and  (orientation: portrait)');
  const orientation = orientationType.startsWith('landscape') ? ORIENTATION_TYPE.LANDSCAPE : ORIENTATION_TYPE.PORTRAIT;
  const [isMobile, setIsMobile] = useState(isMobileTool(window.navigator).phone);
  const isTablet = isMobileTool(window.navigator).tablet;
  const isDesktop =
    !(isMobileTool(window.navigator).phone || isMobileTool(window.navigator).android.phone) &&
    !isTablet;
  const isMobilePortraitOrDesktop = (isMobile && !landscape) || !isMobile;

  useEffect(() => {
    const handleResize = () => {
      setIsMobile(
        isMobileTool(window.navigator).phone || isMobileTool(window.navigator).android.phone
      );
    };
    window.addEventListener('resize', handleResize);

    return () => {
      window.removeEventListener('resize', handleResize);
    };
  }, []);

  return {
    isMobile,
    isMobilePortraitOrDesktop,
    isTablet,
    isDesktop,
    xs,
    sm,
    md,
    lg,
    landscape,
    portrait,
    orientation,
  };
}

export default useBreakpoint;
