import { SVGProps } from 'react';

import { colors } from '../../theme/colors';

const IconFirstPage = ({
  height = '10px',
  width = '10px',
  color = colors.neutral70,
  ...props
}: SVGProps<SVGSVGElement>) => (
  <svg
    width={width}
    height={height}
    viewBox="0 0 10 10"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
    {...props}
  >
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M1.84668 0.5H0.34668V9.5H1.84668V0.5ZM6.21168 5L9.65418 8.4425L8.59668 9.5L4.09668 5L8.59668 0.5L9.65418 1.5575L6.21168 5Z"
      fill={color}
    />
  </svg>
);

export default IconFirstPage;
