import * as d3 from 'd3';
import { FeatureCollection } from '@turf/turf';

interface IProps {
  width?: number;
  height?: number;
  coords: [number, number][];
}

class GeometryToSVGConverter {
  static genericShapePath =
    'M0,7.058173415061901L2.587159650371177,17.94182658495265L25,15.65105998191575Z';

  static ConvertToSVGPath({ width = 50, height = 50, coords }: IProps): string {
    try {
      const geometry: any = {
        type: 'LineString',
        coordinates: coords[0],
      };

      const geoJSON: FeatureCollection<GeoJSON.LineString> = {
        type: 'FeatureCollection',
        features: [
          {
            type: 'Feature',
            properties: {
              name: 'field',
            },
            geometry,
          },
        ],
      };

      const projection = d3.geoTransverseMercator().fitSize([width, height], geoJSON);
      const geoGenerator = d3.geoPath().projection(projection);
      const path = geoGenerator(geoJSON);
      if (path) {
        return path;
      } else {
        return GeometryToSVGConverter.genericShapePath;
      }
    } catch (error) {
      return GeometryToSVGConverter.genericShapePath;
    }
  }
}

export default GeometryToSVGConverter;
