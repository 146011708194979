enum ErrorTypes {
  MAX_ATTEMPTS = 'MAX_ATTEMPTS',
  DEFAULT = 'DEFAULT',
}
interface RequestError {
  status: number;
  statusMessage: string;
  statusTitle?: string;
  errorType?: ErrorTypes;
}

export { ErrorTypes };

export type { RequestError };
