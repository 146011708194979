import breakpoints from 'base/constants/breakpoints';
import ButtonBase, { EButtonType } from 'components/Buttons/ButtonBase';
import ModalView from 'components/ModalView';

import styled from 'styled-components';
import { colors, SYT_PREFIX } from 'syngenta-digital-cropwise-react-ui-kit';
import type ModalViewProps from 'components/ModalView';
import { showSlidingAnimation } from 'utils/modalBoxAnimations';

type CustomModalViewProps = typeof ModalViewProps & { notificationColor: string };

export const StyledModalView = styled(ModalView)<CustomModalViewProps>`
  background-color: ${(props) => props.notificationColor};
  ${(props) => showSlidingAnimation(props.slideDirection)}
  border-radius: 4px 4px 0px 0px;
  padding-top: 4px;

  .${SYT_PREFIX}-modal-header {
    padding-top: 16px;
    border-bottom: none;
  }

  .${SYT_PREFIX}-modal-content {
    border-radius: 4px 4px 0px 0px;
    background-color: ${colors.neutral00};
  }

  .header-bar {
    display: none;
  }

  .modal-content {
    border-radius: 4px 4px 0px 0px;
    background-color: ${colors.neutral00};
    padding-top: 12px;
  }

  .modal-view-footer {
    display: flex;
    gap: 16;
  }
`;

export const StyledTitle = styled.div`
  text-align: center;
  font-style: normal;
  font-weight: 600;
  font-size: 16px;
  line-height: 24px;
`;

export const StyledParagraph = styled.div`
  font-style: normal;
  font-weight: 400;
  font-size: 16px;
  line-height: 24px;
  text-align: center;

  color: ${colors.neutral100};
`;

export const StyledIconsContainer = styled.div`
  display: flex;
  flex-direction: row;
  justify-content: space-between;
`;

interface StyledButtonProps {
  isEnglish: boolean;
  isPolish: boolean;
}

export const StyledButton = styled(ButtonBase)<StyledButtonProps>`
  height: 40px;
  width: 100%;
  display: flex;
  align-items: center;
  justify-content: center;
  border: none;
  gap: 10px;

  white-space: ${({ isEnglish }) => !isEnglish && 'normal'};
  word-wrap: ${({ isEnglish }) => (isEnglish ? 'normal' : 'break-word')};

  font-style: normal;
  font-weight: 400;
  font-size: 16px;
  line-height: ${({ isPolish }) => (isPolish ? '13px !important' : '24px')};
  /* identical to box height, or 150% */
  letter-spacing: -0.0075em;

  @media screen and ${breakpoints.maxWidth.sm} {
    background-color: ${(props) => props.type === EButtonType.default && '#fff'};
    border: ${(props) => props.type === EButtonType.default && `1px solid ${colors.neutral30}`};
  }
`;
