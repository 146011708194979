import { MouseEventHandler, MouseEvent, useMemo } from 'react';

import {
  CardStyled,
  PreviewTitleContainerStyled,
  TitleStyled,
  SubtitleStyled,
  PreviewContainerStyled,
  ActionsStyled,
  IconEditStyled,
  IconDeleteStyled,
} from './FieldActionsCard.styles';

import FieldPreview from 'components/FieldPreview';
import { CustomTooltip } from 'components/CustomTooltip';
import { useBreakpoint } from 'hooks';
import IconInfo from 'components/Icons/IconInfo';
import { colors } from 'syngenta-digital-cropwise-react-ui-kit';
import { useTranslation } from 'react-i18next';
import { getTranslatedFieldName } from 'utils/constants/Fields';

interface IProps {
  disabled?: boolean;
  polygonCoords: Array<[number, number]>;
  title?: string;
  subtitle?: string;
  editIconTooltipLabel?: string;
  deleteIconTooltipLabel?: string;
  nextIconTooltipLabel?: string;
  handleOnEditClick?: MouseEventHandler<SVGSVGElement>;
  handleOnDeleteClick?: MouseEventHandler<SVGSVGElement>;
  handleOnNextClick?: MouseEventHandler<SVGSVGElement>;
  handleOnClick?: () => void;
  maxTitleLen?: number;
}

const FieldActionsCard = ({
  disabled,
  polygonCoords,
  title,
  subtitle,
  deleteIconTooltipLabel = 'Tooltip Text',
  editIconTooltipLabel = 'Tooltip Text',
  nextIconTooltipLabel = 'Tooltip Text',
  handleOnEditClick,
  handleOnDeleteClick,
  handleOnNextClick,
  handleOnClick,
  maxTitleLen = 12,
}: IProps): JSX.Element => {
  const [t] = useTranslation();

  const { isMobile } = useBreakpoint();
  const truncateTitle = useMemo(() => {
    const fieldName = getTranslatedFieldName({ defaultName: title ?? '', t });
    if (fieldName.length > maxTitleLen) {
      return `${fieldName.substring(0, maxTitleLen)}...`;
    } else if (!title) {
      return `--`;
    }
    return fieldName;
  }, [title, maxTitleLen, t]);

  const stopPropagation = (e: MouseEvent<SVGSVGElement, globalThis.MouseEvent>) => {
    return e.stopPropagation();
  };

  const onEditClickHandler = (e: MouseEvent<SVGSVGElement, globalThis.MouseEvent>) => {
    stopPropagation(e);
    return !disabled && handleOnEditClick?.(e);
  };

  const onDeleteClickHandler = (e: MouseEvent<SVGSVGElement, globalThis.MouseEvent>) => {
    stopPropagation(e);
    return !disabled && handleOnDeleteClick?.(e);
  };

  const onNextClickHandler = (e: MouseEvent<SVGSVGElement, globalThis.MouseEvent>) => {
    stopPropagation(e);
    return !disabled && handleOnNextClick?.(e);
  };

  const onClickHandler = () => {
    return !disabled && handleOnClick?.();
  };

  const modifiedSubtitle = useMemo(() => {
    if (subtitle && subtitle === '0 ha') {
      return `-- --`;
    }
    return subtitle;
  }, [subtitle]);
  return isMobile ? (
    <CardStyled
      disabled={disabled}
      isMobileScreen={true}
      onClick={onClickHandler}
      data-testid="fieldCard"
    >
      <PreviewTitleContainerStyled>
        <PreviewContainerStyled>
          <FieldPreview size={32} coords={polygonCoords} viewboxSize={48} />
        </PreviewContainerStyled>
        <div>
          <CustomTooltip placement="top" title={title} trigger={'hover'}>
            <TitleStyled>{truncateTitle}</TitleStyled>
          </CustomTooltip>
          <SubtitleStyled>{modifiedSubtitle}</SubtitleStyled>
        </div>
      </PreviewTitleContainerStyled>
      <ActionsStyled>
        <CustomTooltip
          placement="right"
          title={deleteIconTooltipLabel}
          trigger={disabled ? 'none' : ['click', 'hover']}
          zIndex={1000}
        >
          <IconDeleteStyled onClick={(e) => onDeleteClickHandler(e)} disabled={disabled} />
        </CustomTooltip>
      </ActionsStyled>
    </CardStyled>
  ) : (
    <CardStyled disabled={disabled} onClick={onClickHandler} data-testid="fieldCard">
      <PreviewTitleContainerStyled>
        <PreviewContainerStyled>
          <FieldPreview size={40} coords={polygonCoords} viewboxSize={50} />
        </PreviewContainerStyled>
        <div>
          <CustomTooltip placement="top" title={title} trigger={'hover'}>
            <TitleStyled>{truncateTitle}</TitleStyled>
          </CustomTooltip>
          <SubtitleStyled>{subtitle}</SubtitleStyled>
        </div>
      </PreviewTitleContainerStyled>
      <ActionsStyled>
        <CustomTooltip
          placement="bottom"
          title={editIconTooltipLabel}
          trigger={disabled ? 'none' : 'hover'}
        >
          <IconEditStyled onClick={(e) => onEditClickHandler(e)} />
        </CustomTooltip>
        <CustomTooltip
          placement="right"
          title={deleteIconTooltipLabel}
          trigger={disabled ? 'none' : ['click', 'hover']}
          zIndex={1000}
        >
          <IconDeleteStyled onClick={(e) => onDeleteClickHandler(e)} disabled={disabled} />
        </CustomTooltip>
        <CustomTooltip
          placement="right"
          title={nextIconTooltipLabel}
          trigger={disabled || isMobile ? 'none' : 'hover'}
        >
          <IconInfo
            className="pointer-cursor"
            data-testid="infoIcon"
            width={22}
            height={22}
            color={colors.blue60}
            onClick={(e) => onNextClickHandler(e)}
          />
        </CustomTooltip>
      </ActionsStyled>
    </CardStyled>
  );
};

export default FieldActionsCard;
