import { useCallback, useMemo } from 'react';
import { Marker, MarkerDragEvent } from 'react-map-gl';

import VertexImage from '../../../assets/images/vertex.png';
import MiddleVertexImage from '../../../assets/images/middle-vertex.png';

interface IProps {
  coords: [number, number][];
  areCurrentPolygon: boolean;
  handleOnDragMarkerEvent: (e: MarkerDragEvent, index: number) => void;
  handleMarkerOnClick: (e: mapboxgl.MapboxEvent<MouseEvent>, index: number) => void;
  handleOnDragEndMarker: (index: number) => void;
};

const MarkersLayer = ({
  coords,
  areCurrentPolygon,
  handleOnDragMarkerEvent,
  handleMarkerOnClick,
  handleOnDragEndMarker,
}: IProps): JSX.Element => {

  const MarkersToPrint = useMemo((): [number, number][] => {
    const markersData = [...coords];
    if (areCurrentPolygon) {
      markersData.pop();
    }
    return markersData;
  }, [coords, areCurrentPolygon]);

  const renderMarkers = useCallback(() => MarkersToPrint.map((currentCoords: [number, number], index: number) => (
    <Marker
      draggable={true}
      onDrag={(e: MarkerDragEvent) => handleOnDragMarkerEvent(e, index)}
      key={`marker-${index}`}
      latitude={currentCoords[1]}
      longitude={currentCoords[0]}
      onClick={(e) => handleMarkerOnClick(e, index)}
      onDragEnd={() => handleOnDragEndMarker(index)}
    >
      <img src={index % 2 === 0 ? VertexImage : MiddleVertexImage} alt='vertex-image'/>
    </Marker>
  )), [
    MarkersToPrint,
    handleOnDragMarkerEvent,
    handleMarkerOnClick,
    handleOnDragEndMarker,
  ]);

  return (
    <>
      {renderMarkers()}
    </>
  );
};

export default MarkersLayer;
