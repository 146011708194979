import { useAppState } from 'context/AppState';
import { useEffect, useState } from 'react';
import { Container, Content, ContentItem, TabItem, TabsContainer } from './TabsView.styles';
import { useTranslation } from 'react-i18next';
import { useBreakpoint } from 'hooks';
import { Spinner } from 'components/Spinner';
import { FlowSteps } from 'context/store/flowReducer';

type TabItemType = {
  key: string;
  label: string;
  children?: React.ReactNode;
  disabled?: boolean;
};

interface TabsViewProps {
  items: TabItemType[];
  activeKey?: string;
  className?: string;
  onChange?: (selectedKey: string) => void;
}

export default function TabsView(props: TabsViewProps) {
  const [t] = useTranslation();
  const { items = [], activeKey = '', className = '', onChange } = props;
  const { flow, apiData } = useAppState();
  const [active, setActive] = useState(activeKey);
  const screens = useBreakpoint();
  const { isMobile } = screens;

  useEffect(() => {
    if (!activeKey) {
      const [firstTab] = items;
      setActive(firstTab.key);
    }
  }, [activeKey, items]);

  useEffect(() => {
    if (activeKey) {
      setActive(activeKey);
    }
  }, [activeKey]);

  const onClickTab = (tabKey: string) => {
    setActive(tabKey);

    if (active !== tabKey) {
      onChange?.(tabKey);
    }
  };

  return (
    <Container className={className}>
      {isMobile && flow.mobileActiveTab === 'List' && apiData.fieldSoilsLoading && flow.currentStep === FlowSteps.STEP1 && <Spinner />}
      <TabsContainer className="tabs-container">
        {items.map((tab) => {
          const isActive = active === tab.key;
          return (
            <TabItem
              key={tab.key}
              active={isActive}
              disabled={
                (flow.isListTabDisabled && tab.key === 'List' && flow.addAnother) || tab.disabled
              }
              onClick={() => onClickTab(tab.key)}
            >
              {t(tab.label)}
            </TabItem>
          );
        })}
      </TabsContainer>
      <Content className="tabs-content">
        {items.map((tab) => {
          const isActive = active === tab.key;
          return (
            <ContentItem key={tab.key} active={isActive} className="tabs-content-item">
              {tab.children}
            </ContentItem>
          );
        })}
      </Content>
    </Container>
  );
}
