import styled from 'styled-components';
import { Skeleton } from 'syngenta-digital-cropwise-react-ui-kit';
import isMobileTool from 'ismobilejs';

import {
  FullScreenChartContainer,
  SectionContainer,
  Title,
  Text,
} from '../../RecommendationV2/V2Common.styles';
import mapBG from 'assets/images/map-skeleton.svg';
import breakpoints from 'base/constants/breakpoints';

const isMobileScreen = isMobileTool(window.navigator).phone;

export const SectionContainerStyled = styled(SectionContainer)`
  flex: 1;
`;

export const MapSkeleton = styled.div`
  background-image: url(${mapBG});
  background-size: cover;
  margin-top: -30px;
  flex: 1;
  width: 100%;
`;

interface IsMobileProps {
  isMobile: boolean;
}

export const MapContainer = styled(FullScreenChartContainer)<IsMobileProps>`
  display: flex;
  flex: 1;
  width: 100%;
  min-height: 640px;
  padding: ${({ fullScreen }) => (fullScreen ? '0 !important' : '')};

  @media screen and (orientation: landscape) {
    min-height: ${({ isMobile }) => (isMobile ? '100%' : '640px')} !important;
  }
`;

export const StyledTitle = styled(Title)`
  @media only screen and ${breakpoints.maxWidth.sm} {
    font-weight: 600;
    font-size: 20px;
    line-height: 28px;
  }
`;

export const StyledText = styled(Text)`
  @media only screen and ${breakpoints.maxWidth.sm} {
    font-family: 'Noto Sans';
    font-style: normal;
    font-weight: 400;
    font-size: 14px;
    line-height: 22px;
  }
`;

interface ISkeletonTitleProps {
  width?: string;
}

const SkeletonTitleStyled = styled(Skeleton.Button)<ISkeletonTitleProps>`
  width: ${({ width }) => width || '100%'};
  max-width: none !important;
  height: 31px;
  span {
    width: 100% !important;
    height: 31px !important;
  }
`;

interface ISkeletonRowProps {
  width?: string;
}

const SkeletonRowStyled = styled(Skeleton.Button)<ISkeletonRowProps>`
  width: ${({ width }) => width || '100%'};
  max-width: none !important;
  height: 25px;
  span {
    width: 100% !important;
    height: 25px !important;
  }
`;

const SkeletonMobileContainer = styled.div`
  display: flex;
  flex-direction: column;
  gap: 22px;
`;

const SkeletonMobileTopContainer = styled.div`
  text-align: center;
`;

const SkeletonMobileBottomContainer = styled.div`
  display: flex;
  flex-direction: column;
  gap: 8px;
`;

const Content = styled.div`
  display: flex;
  width: 100%;
  max-width: 1126px;
  margin: 0 9%;
  padding: 60px 0;
  flex-direction: column;

  @media screen and ${breakpoints.maxWidth.xl} {
    padding: ${() => `60px ${isMobileScreen ? '20px' : '50px'}`};
  }
  @media screen and ${breakpoints.maxWidth.sm} {
    padding: 48px 16px 48px 16px;
  }
`;

export const StyledUserMarker = styled.img.attrs((props) => ({
  src: props.src,
}))`
  background-size: cover;
  height: 50px;
  width: 42px;
  cursor: pointer;
`;

export {
  SkeletonTitleStyled,
  SkeletonRowStyled,
  SkeletonMobileContainer,
  SkeletonMobileBottomContainer,
  SkeletonMobileTopContainer,
  Content,
};
