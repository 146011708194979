import { Feature, Polygon, LineString } from '@turf/turf';
import { Layer, Source } from 'react-map-gl';

import { colors } from 'syngenta-digital-cropwise-react-ui-kit';

interface IProps {
  polygon: Feature<Polygon> | undefined;
  boundaryLineCoords: Feature<LineString> | undefined;
  geoJsonMoveCoords: Feature<LineString>;
  isDrawing: boolean;
  geoJsonLastTwoCoords: Feature<LineString>;
  isMobile: boolean;
  tempPolygon: Feature<Polygon> | undefined;
}

const BoundaryLayer = ({ boundaryLineCoords, polygon, geoJsonMoveCoords, isDrawing, isMobile, geoJsonLastTwoCoords, tempPolygon }: IProps) => (
  <>
    {isDrawing && tempPolygon && (
      <Source key={'current-polygon-key'} id={'current-polygon'} type="geojson" data={tempPolygon}>
        <Layer
          type="fill"
          source={'current-polygon'}
          paint={{
            'fill-color': colors.blue40,
            'fill-opacity': 0.3,
          }}
        />
      </Source>
    )}

    { geoJsonLastTwoCoords.geometry.coordinates.length > 0 && isDrawing && (
      <Source id="saveLastCoords" type="geojson" data={geoJsonLastTwoCoords}>
        <Layer

          type="line"
          source="saveLastCoords"
          layout={{
            'line-join': 'round',
            'line-cap': 'round',
          }}
          paint={{
            'line-color': colors.blue40,
            'line-width': 2,
            'line-dasharray': [2, 2]
          }}
        />
      </Source>
    )}

    {boundaryLineCoords && (
      <Source id="saveCoords" type="geojson" data={boundaryLineCoords}>
        <Layer
          type="line"
          source="saveCoords"
          layout={{
            'line-join': 'round',
            'line-cap': 'round',
          }}
          paint={{
            'line-color': colors.blue40,
            'line-width': 2,
          }}
        />
      </Source>
    )}

    {polygon && (
      <Source key={'current-polygon-key'} id={'current-polygon'} type="geojson" data={polygon}>
        <Layer
          type="fill"
          source={'current-polygon'}
          paint={{
            'fill-color': colors.blue40,
            'fill-opacity': 0.3,
          }}
        />
      </Source>
    )}

    {geoJsonMoveCoords.geometry.coordinates.length > 0 && isDrawing && (
      <Source id="onmoveCoords" type="geojson" data={geoJsonMoveCoords}>
        <Layer
          type="line"
          source="onmoveCoords"
          layout={{
            'line-join': 'round',
            'line-cap': 'round',
          }}
          paint={{
            'line-color': colors.blue40,
            'line-width': 2,
          }}
        />
      </Source>
    )}
  </>
);

export default BoundaryLayer;
