import IconMyLocation from 'components/Icons/IconMyLocation';
import { colors } from 'theme/colors';

export const StyledIconMyLocation = () => {
  return <IconMyLocation color={colors.neutral70} width={18} height={18} />;
};

export const StyledIconUserLocated = () => {
  return <IconMyLocation color={colors.neutral10} width={18} height={18} />;
};
