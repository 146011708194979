import { ReactNode } from 'react';
import { ModalStyled, View, Header, ModalContent, Footer } from './ModalView.styles';
import { ViewFooter } from './ViewFooter';
import { NotificationFooter } from './NotificationFooter';
import useBreakpoint from 'hooks/useBreakpoint';
import { ModalAnimationDirections } from 'base/types/ModalBox';

export interface ModalViewProps {
  id?: string;
  title?: string | ReactNode;
  content?: ReactNode;
  footer?: ReactNode;
  width?: number;
  className?: string;
  isClosable?: boolean;
  isViewFooter?: boolean;
  isDropAPinFlow?: boolean;
  isNotificationFooter?: boolean;
  notificationColor?: string;
  onCancel?: () => void;
  onClickOverlay?: () => void;
  forceViewFooterTitleInMobile?: boolean;
  wrapperId?: string;
  displayBottomBorder?: boolean;
  onAnimationEnd?: () => void;
  slideDirection?: ModalAnimationDirections;
  showHeaderRectangle?: boolean;
  showPopUpforMobile?: boolean;
}

const ByPassComponent = (props: { [key: string]: any }) => {
  const {
    title,
    onCancel,
    isClosable = true,
    isViewFooter = false,
    isNotificationFooter = false,
    notificationColor,
    children,
    onClickOverlay,
    isDropAPinFlow,
    wrapperId = '',
    displayBottomBorder,
    showPopUpforMobile,
    ...others
  } = props;
  const screens = useBreakpoint();
  const isMobile = screens.isMobile;

  const showNotificationFooter = () => {
    return isNotificationFooter ? (
      <NotificationFooter
        {...others}
        wrapperId={wrapperId}
        notificationColor={notificationColor}
        onClickOverlay={onClickOverlay}
      >
        {children}
      </NotificationFooter>
    ) : (
      <ViewFooter title={title} onCancel={onCancel} isDropAPinFlow={isDropAPinFlow} {...others}>
        {children}
      </ViewFooter>
    );
  };

  return isMobile && !showPopUpforMobile ? (
    <>{isViewFooter ? showNotificationFooter() : <View {...others}> {children} </View>}</>
  ) : (
    <ModalStyled
      open
      centered
      closable={!isMobile && isClosable}
      maskClosable={false}
      footer={null}
      transitionName=""
      maskTransitionName=""
      title={title}
      onCancel={onCancel}
      displayBottomBorder={displayBottomBorder}
      {...others}
    >
      {children}
    </ModalStyled>
  );
};

export default function ModalView(props: Readonly<ModalViewProps>) {
  const {
    id,
    title,
    content,
    footer,
    width = 390,
    isViewFooter,
    isNotificationFooter,
    notificationColor,
    isDropAPinFlow,
    forceViewFooterTitleInMobile,
    displayBottomBorder = true,
    showPopUpforMobile,
    ...others
  } = props;
  const screens = useBreakpoint();
  const { isMobile } = screens;

  return (
    <ByPassComponent
      title={title}
      width={width}
      isViewFooter={isViewFooter}
      isNotificationFooter={isNotificationFooter}
      notificationColor={notificationColor}
      isDropAPinFlow={isDropAPinFlow}
      displayBottomBorder={displayBottomBorder}
      showPopUpforMobile={showPopUpforMobile}
      {...others}
    >
      {isMobile && (!isViewFooter || forceViewFooterTitleInMobile) && (
        <Header className="modal-view-title">{title}</Header>
      )}
      <ModalContent id={id} className="modal-view-content">
        {content}
      </ModalContent>
      <Footer className="modal-view-footer">{footer}</Footer>
    </ByPassComponent>
  );
}
