import { useEffect } from 'react';
import { Identify, identify } from '@amplitude/analytics-browser';
import track from 'utils/amplitudeWrapper';
import { initAmplitude, initSentry, initPendo } from './utils';
import { RouterProvider } from 'react-router-dom';
import Cookies from 'js-cookie';
import { withLDProvider } from 'launchdarkly-react-client-sdk';

import Router from 'pages/Router';
import getZoomLevel from 'utils/getZoomLevel';
import { CookieBot } from 'components/CookieBot';
import './App.less';
import { isPendoEnvironmentSupported } from 'utils/Pendo';
import { HeadSectionGA } from 'components/HeadSection';

const setupHeightViewPortForMobile = () => {
  const vh = window.innerHeight * 0.01;
  document.documentElement.style.setProperty('--vh', `${vh}px`);
};

const clientId = process.env.REACT_APP_LAUNCHDARKLY_CLIENT_ID ?? '655ca1a8c3a8bb12577cd092';

function App() {

  useEffect(() => {
    initAmplitude();
    initSentry();

    if (process.env.REACT_APP_ENV && isPendoEnvironmentSupported(process.env.REACT_APP_ENV)) {
      initPendo('dev');
    }

    const identifyObj = new Identify();
    identifyObj.set('location', 'LAX');
    identify(identifyObj);

    setupHeightViewPortForMobile();
  }, []);

  return (
    <div className="App" data-testid="app-main-component">
      <HeadSectionGA />
      <RouterProvider router={Router} />
      <CookieBot />
    </div>
  );
}

// eslint-disable-next-line import/no-default-export
export default withLDProvider({
  clientSideID: clientId,
})(App);
