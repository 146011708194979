import { MainDiv, BottomCardBase } from './FarmSelect.styles';
import { Dispatch, SetStateAction } from 'react';
import { PropertyData } from './SoilCharacteristics';
import FarmSearch from './FarmSearch';
import FarmControlMain from './FarmControlMain';

interface FarmSelectProp {
  setWebFarmSelectClick: Dispatch<SetStateAction<boolean>>;
  webFarmSelectClick: boolean;
  farmData: PropertyData[];
  handleFarmClick: (_id: string) => void;
  selectFarmName: string;
  isMobile: boolean;
}
const FarmSelect = ({
  webFarmSelectClick,
  setWebFarmSelectClick,
  farmData,
  handleFarmClick,
  selectFarmName,
  isMobile,
}: FarmSelectProp): JSX.Element => {
  return (
    <MainDiv id="farmSelect">
      <FarmControlMain
        webFarmSelectClick={webFarmSelectClick}
        setWebFarmSelectClick={setWebFarmSelectClick}
        selectFarmName={selectFarmName}
        isMobile={isMobile}
      />
      {webFarmSelectClick && !isMobile && (
        <BottomCardBase>
          <FarmSearch
            farmData={farmData}
            handleFarmClick={handleFarmClick}
            selectFarmName={selectFarmName}
          />
        </BottomCardBase>
      )}
    </MainDiv>
  );
};

export default FarmSelect;
