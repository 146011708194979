/* eslint-disable @typescript-eslint/explicit-module-boundary-types */
import { useTranslation } from 'react-i18next';
import { Checkbox } from 'antd';
import moment from 'moment';
import { StyledSpinner } from '../../pages/RecommendationV2/V2Common.styles';
import { useState, useLayoutEffect, useEffect } from 'react';
import debounce from 'utils/debounce';
import * as c3 from 'c3';
import * as d3 from 'd3';
import { WeatherResponse } from 'base/types/Weather';
import track from 'utils/amplitudeWrapper';
import {
  CHART_CONFIG,
  CHART_NAME_CONST,
  getChartTypes,
  getChartItemUnit,
  getChartProps,
  getChartItemsOfGroup,
  getChartItemsOfAxis,
  getChartItemID,
  getMonthDistribution,
  calculateAccumulatedGDD,
  findNewMonthPositions,
  sortWeatherDataByWeek,
} from './weatherChartConfig';

import {
  LoadingChartSkeleton,
  StyledFilters,
  RootWeatherChart,
  ChartContainer,
} from './WeatherChart.styles';
import './WeatherChart.less';

type WeatherChartProps = {
  id: string;
  showOnlyTemperatureChartFilters?: boolean;
  isLoading?: boolean;
  weatherResponse?: WeatherResponse[];
  GDDDayRange?: number[];
  months: string[];
  errorMessage?: string;
  weatherChartConfig?: Partial<c3.ChartConfiguration>;
  showWeatherChartFilters?: boolean;
};

let weatherChart: c3.ChartAPI;

export function WeatherChart({
  weatherResponse,
  id,
  showOnlyTemperatureChartFilters,
  isLoading,
  errorMessage,
  showWeatherChartFilters = true,
}: WeatherChartProps) {
  const [t, i18next] = useTranslation();
  const [activeChartItems, setActiveChartItems] = useState(getChartItemsOfGroup(1));
  const [xAxisLabel, setXAxisLabel] = useState(CHART_NAME_CONST.TEMPERATURE);
  const [charSize, setCharSize] = useState<{ width?: number; height?: number }>({});
  let previousItem = '';

  useLayoutEffect(() => {
    // Figma size height: 478, width: 1126  ratioSize = height/width
    const ratioSize = 0.4245115453;

    const chartSizeMath = debounce(() => {
      const chartContainer = document.getElementById('average-weather-chart');

      if (chartContainer?.offsetWidth && chartContainer?.offsetHeight) {
        const width = chartContainer.offsetWidth - 4;
        setCharSize({
          width,
          height: width * ratioSize,
        });
      }
    });

    chartSizeMath();
    window.addEventListener('resize', chartSizeMath);

    return () => {
      window.removeEventListener('resize', chartSizeMath);
    };
  }, []);

  const prepareChartData = (weatherData: any) => {
    const Temperature: number[] = [];
    const PPT: number[] = [];
    const HT: number[] = [];
    const CT: number[] = [];
    const WEEK: number[] = [];
    const MONTH: string[] = [];
    if (weatherData?.length) {
      weatherData.forEach((weatherDataItem: any) => {
        const avgTemperature = Math.ceil(
          (weatherDataItem?.temp_max + weatherDataItem.temp_min) / 2
        );
        Temperature.push(avgTemperature);
        PPT.push(Math.ceil(weatherDataItem.ppt));
        HT.push(weatherDataItem.heat_stress);
        CT.push(weatherDataItem.cold_stress);
        MONTH.push(weatherDataItem.month);
        WEEK.push(weatherDataItem.week);
      });
    }

    return {
      Temperature,
      PPT,
      HT,
      CT,
      MONTH: getMonthDistribution(MONTH),
      NEW_MONTH_POS: findNewMonthPositions(MONTH),
      WEEK,
    };
  };

  const trackInteractionInAmplitude = (key: string) => {
    let eventProperty = 'opt_';
    switch (key) {
      case CHART_NAME_CONST.TEMPERATURE:
        eventProperty = eventProperty.concat('temperature');
        break;
      case CHART_NAME_CONST.HT:
        eventProperty = eventProperty.concat('heatstress');
        break;
      case CHART_NAME_CONST.GDD:
        eventProperty = eventProperty.concat('gdd');
        break;
      case CHART_NAME_CONST.CT:
        eventProperty = eventProperty.concat('coldstress');
        break;
      case CHART_NAME_CONST.PRECIPITATION:
        eventProperty = eventProperty.concat('precipitation');
        break;
      default:
        break;
    }
    return eventProperty;
  };

  const calculateRegions = (stressType: string, formattedChartData: any) => {
    const regions: c3.RegionOptions[] = [];
    const stressData = stressType === 'HT' ? formattedChartData.HT : formattedChartData.CT;
    stressData.forEach((stress: number, index: number) => {
      if (stress) {
        const start = index === 0 ? 0 : index - 1;
        regions.push({ axis: 'x', start, end: index, class: `region${stressType}` });
      }
    });
    return regions;
  };

  const getY2Label = () => {
    const labels: string[] = [];
    const YAxisItems = getChartItemsOfAxis('Y');
    YAxisItems.forEach((item) => {
      if (activeChartItems.includes(item)) {
        const unit = getChartItemUnit(item);
        const label = t(item);
        const formattedLabel = unit ? `${label} (${unit})` : label;
        labels.push(formattedLabel);
      }
    });
    return labels;
  };

  const toggleCharts = (checkedValues: any) => {
    let hideChartItems: string[] = [];
    const newItem = checkedValues.filter((c: any) => !activeChartItems.includes(c))[0];

    if (newItem && newItem !== previousItem) {
      const eventProperty = trackInteractionInAmplitude(newItem);
      previousItem = newItem;
      track('recommendation interaction', { 'weather chart interaction': eventProperty });
    }

    if (newItem && getChartItemsOfGroup(2).includes(newItem)) {
      hideChartItems = [...hideChartItems, ...getChartItemsOfGroup(1)];
    } else if (newItem && getChartItemsOfGroup(1).includes(newItem)) {
      hideChartItems = [...hideChartItems, ...getChartItemsOfGroup(2)];
    }

    if (
      !getChartItemsOfGroup(1).includes(newItem) &&
      (getChartItemsOfGroup(2).includes(newItem) ||
        !!getChartItemsOfGroup(2).find((x) => checkedValues.includes(x)))
    ) {
      setXAxisLabel(CHART_NAME_CONST.GDD);
    } else {
      setXAxisLabel(getChartItemsOfGroup(1)[0]);
    }

    let newActiveItems = checkedValues.filter((v: any) => !hideChartItems.find((i) => i === v));
    if (!newActiveItems.length) {
      newActiveItems = getChartItemsOfGroup(1);
    }

    weatherChart?.hide(getChartTypes, { withLegend: true });
    weatherChart?.show(newActiveItems, { withLegend: true });

    const checkboxID: string = getChartItemID(newItem) ?? '';
    document.getElementById(checkboxID)?.click();

    setActiveChartItems(newActiveItems);
  };

  useEffect(() => {
    const sortedWeatherResponse = sortWeatherDataByWeek(weatherResponse ?? []);
    const formattedChartData = prepareChartData(sortedWeatherResponse);
    const regionHT = calculateRegions('HT', formattedChartData);
    const regionCT = calculateRegions('CT', formattedChartData);
    const gddData = calculateAccumulatedGDD(sortedWeatherResponse ?? []).map((record) =>
      Math.ceil(record.accumulatedGDD)
    );
    const hideItems = getChartTypes.filter((i) => !activeChartItems.find((c) => c === i));
    weatherChart = c3.generate({
      bindto: `#weather-combined-chart-${id}`,
      size: charSize,
      padding: {
        right: 50,
      },
      data: {
        columns: [
          [CHART_NAME_CONST.TEMPERATURE, ...formattedChartData.Temperature],
          [
            CHART_NAME_CONST.PRECIPITATION,
            ...(hideItems.includes(CHART_NAME_CONST.PRECIPITATION) ? [] : formattedChartData.PPT),
          ],
          [CHART_NAME_CONST.GDD, ...gddData],
          [CHART_NAME_CONST.HT, ...formattedChartData.HT],
          [CHART_NAME_CONST.CT, ...formattedChartData.CT],
          [CHART_NAME_CONST.WEEK, ...formattedChartData.WEEK],
        ],
        axes: {
          [CHART_NAME_CONST.TEMPERATURE]: 'y',
          [CHART_NAME_CONST.MONTH]: 'x',
          [CHART_NAME_CONST.PRECIPITATION]: 'y2',
        },
        type: 'area-spline',
        types: {
          ...getChartProps('type'),
        },
        colors: {
          ...getChartProps('color'),
          [CHART_NAME_CONST.TEMPERATURE]: `url(#temperature-gradient-weather-combined-chart-${id})`,
          Week: 'none',
        },
        groups: [[CHART_NAME_CONST.TEMPERATURE, CHART_NAME_CONST.PRECIPITATION]],
        hide: hideItems,
      },
      axis: {
        x: {
          type: 'category',
          tick: {
            rotate: 0,
            multiline: false,
            culling: false,
            format: function (x) {
              moment.locale('en');
              const month = formattedChartData.MONTH[x as number];
              const monthIndex = moment(month, 'MMMM').month();
              moment.locale(i18next.language);
              return month !== '' ? moment.monthsShort()[monthIndex] : '';
            },
          },
        },
        y: {
          show: !!activeChartItems.find((x) =>
            [CHART_NAME_CONST.TEMPERATURE, CHART_NAME_CONST.GDD].includes(x)
          ),
          label: {
            text: `${t(xAxisLabel)} (${getChartItemUnit(xAxisLabel)})`,
            position: 'outer-middle',
          },
        },
        y2: {
          show: !!activeChartItems.find((x) => getChartItemsOfAxis('Y').includes(x)),
          tick: {
            format: function (x) {
              return activeChartItems.includes(CHART_NAME_CONST.PRECIPITATION) ? x : '';
            },
            ...{
              ...(activeChartItems.find((x) =>
                [CHART_NAME_CONST.HT, CHART_NAME_CONST.CT].includes(x)
              )
                ? { values: [0] }
                : {}),
            },
          },
          label: {
            text: getY2Label()[0],
            position: 'outer-middle',
          },
        },
      },
      regions: [
        ...(activeChartItems.includes(CHART_NAME_CONST.HT) ? regionHT : []),
        ...(activeChartItems.includes(CHART_NAME_CONST.CT) ? regionCT : []),
      ],
      tooltip: {
        contents(this, data) {
          let content = '';
          data
            .filter((item) => ![CHART_NAME_CONST.HT, CHART_NAME_CONST.CT].includes(item.id))
            .forEach((item) => (content += `<div>
              <strong>${t(item.id)}</strong>: ${item.value} ${getChartItemUnit(item.id)}
              </div>`)
            );
          return `<div>
              ${content}
            </div>`;
        },
      },
      point: {
        show: false,
      },
      legend: {
        show: false,
        position: 'bottom',
      },
      grid: {
        x: {
          show: false,
          lines: formattedChartData.NEW_MONTH_POS.map((pos) => {
            return { value: pos, position: 'start' };
          }),
        },
        y: {
          show: true,
        },
      },
      bar: {
        width: {
          ratio: 0.4,
          max: 6,
        },
        space: 2,
      },
      oninit: function () {
        d3.select(`#weather-combined-chart-${id} svg defs`)
          .append('linearGradient')
          .attr('id', `temperature-gradient-weather-combined-chart-${id}`)
          .attr('x1', '0%')
          .attr('y1', '0%')
          .attr('x2', '0%')
          .attr('y2', '100%')
          .selectAll('stop')
          .data([
            { offset: '65%', color: '#CF3537', opacity: 1 },
            { offset: '90%', color: 'white', opacity: 0 },
          ])
          .enter()
          .append('stop')
          .attr('offset', (d) => d.offset)
          .attr('stop-color', (d) => d.color)
          .attr('stop-opacity', (d) => d.opacity);
        // workaround approach to display both labels: heat stress and cold stress
        setTimeout(() => {
          const y2Label2 = getY2Label()[1];
          const nodes = document.getElementsByClassName('c3-axis-y2-label');
          if (nodes[0] && nodes.length === 1 && y2Label2) {
            const parentNode = nodes[0].parentNode;
            if (parentNode) {
              parentNode.appendChild(nodes[0].cloneNode(true));
              const dy = nodes[0].getAttribute('dy');
              if (dy) {
                const updatedNodes = document.getElementsByClassName('c3-axis-y2-label');
                updatedNodes[1].setAttribute('dy', `${parseInt(dy) + 20}`);
                updatedNodes[1].innerHTML = y2Label2;
              }
            }
          }

          const xAxis = document.getElementsByClassName('c3-axis-x')[0];
          if (xAxis) {
            const ticks = xAxis.getElementsByClassName('tick');
            for (let i = 0; i < ticks.length; i++) {
              if (!formattedChartData.NEW_MONTH_POS.find((pos) => pos === i + 0.5)) {
                ticks[i].querySelectorAll('line')[0].style.display = 'none';
              }
            }
          }
        }, 100);
      },
    });
  }, [weatherResponse, activeChartItems, t, i18next.language]);

  if (errorMessage && !isLoading && !weatherResponse?.length) {
    return (
      <h3
        style={{ textAlign: 'center', margin: '20px 0', padding: '100px', background: '#efefef' }}
      >
        {errorMessage}
      </h3>
    );
  }

  return (
    <>
      <StyledFilters>
        {showWeatherChartFilters ? (
          <Checkbox.Group style={{ width: '80%' }} value={activeChartItems} onChange={toggleCharts}>
            {(showOnlyTemperatureChartFilters
              ? CHART_CONFIG.filter((chartItem) => chartItem.group === 1)
              : CHART_CONFIG
            ).map((chartItem: any) => (
              <Checkbox key={chartItem.id} id={chartItem.id} value={chartItem.name}>
                {t(chartItem.name)}
              </Checkbox>
            ))}
          </Checkbox.Group>
        ) : null}
      </StyledFilters>
      <div style={{ overflow: 'auto', width: '100%', minHeight: 300 }}>
        <RootWeatherChart id="average-weather-chart">
          {isLoading ? (
            <LoadingChartSkeleton>
              <StyledSpinner />
            </LoadingChartSkeleton>
          ) : (
            <ChartContainer
              className="weather-combined-chart"
              id={`weather-combined-chart-${id}`}
            />
          )}
        </RootWeatherChart>
      </div>
    </>
  );
}
