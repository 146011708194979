import { CSSProperties } from 'react';
import { Button } from 'syngenta-digital-cropwise-react-ui-kit';
import styled from 'styled-components';
import Icon from '@ant-design/icons';

import { colors } from '../../../theme/colors';

const styles: CSSProperties = {
  height: '38px',
  display: 'flex',
  alignItems: 'center',
  justifyContent: 'center',
  lineHeight: 0,
  border: 'none',
};

export const StyledIconContainer = styled('span')({
  fontSize: '16px',
});

export const StyledTextContainer = styled('span')({
  marginRight: '10px',
});

export const StyledTextContainerInversed = styled('span')({
  marginLeft: '10px',
});

const StyledButtonReactangleDefault = styled(Button)({
  ...styles,
  borderTopLeftRadius: '4px',
  borderTopRightRadius: '4px',
  borderBottomLeftRadius: '4px',
  borderBottomRightRadius: '4px',
});

const StyledButtonReactangleBottom = styled(Button)({
  ...styles,
  borderBottomLeftRadius: '8px',
  borderBottomRightRadius: '8px',
  borderTopLeftRadius: '0px',
  borderTopRightRadius: '0px',
});

const StyledButtonReactangleTop = styled(Button)({
  ...styles,
  borderTopLeftRadius: '8px',
  borderTopRightRadius: '8px',
  borderBottomLeftRadius: '0px',
  borderBottomRightRadius: '0px',
});

const StyledButtonReactangleLeft = styled(Button)({
  ...styles,
  borderTopLeftRadius: '8px',
  borderTopRightRadius: '0px',
  borderBottomLeftRadius: '8px',
  borderBottomRightRadius: '0px',
});

const StyledButtonReactangleRight = styled(Button)({
  ...styles,
  borderTopLeftRadius: '0px',
  borderTopRightRadius: '8px',
  borderBottomLeftRadius: '0px',
  borderBottomRightRadius: '8px',
});

const StyledButtonIcon = styled(Icon)({
  color: colors.neutral70,
  border: 0,
  margin: 0,
  padding: 0,
  lineHeight: 0,
});

export {
  StyledButtonReactangleDefault,
  StyledButtonReactangleBottom,
  StyledButtonReactangleTop,
  StyledButtonReactangleLeft,
  StyledButtonReactangleRight,
  StyledButtonIcon,
};
