import styled from 'styled-components';

const StyledContainer = styled.ul.attrs((props) => ({ className: props.className }))`
  display: flex;
  list-style: none;
  margin: 0;
  padding: 0;
  flex-direction: column-reverse;
  right: 2em;
  bottom: 2em;
  align-items: center;
  justify-content: center;
  height: auto;

  .open-root {
    max-height: max-content;
  }

  .fab-action {
    transform: translateY(50px) scale(0);
    transition: transform 300ms, opacity 300ms;
    opacity: 0;
  }

  .fab-action.open {
    transform: translateY(0) scale(1) !important;
    opacity: 1;
  }
`;

export { StyledContainer };
