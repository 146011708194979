export const numberToEuropeanFormat = (number: number | undefined, column?: string) => {
  return Number(number).toLocaleString('es-ES', { maximumFractionDigits: 2 });
};

export const numberFormat = (number: number | undefined, column?: string) => {
  const formattedNumber = Number(number).toLocaleString('es-ES', {
    maximumFractionDigits: 2,
    useGrouping: true,
  });
  return formattedNumber.replace(/\./g, ' ');
};
