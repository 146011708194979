export enum TillagePracticeForTraits {
  'PLOUGHING' = 'PLOUGHING',
  'MINIMAL_TILLAGE' = 'MINIMAL_TILLAGE'
}

export enum TillagePractice {
  'PLOUGHING' = 'Conventional/Ploughing',
  'MINIMAL_TILLAGE' = 'Minimal Tillage',
  'NO_TILLAGE' = 'No Tillage'
}
