import styled from 'styled-components';
import { PlusOutlined, MinusOutlined } from '@ant-design/icons';
import { colors } from 'syngenta-digital-cropwise-react-ui-kit';

export const StyledIconPlus = styled(PlusOutlined)`
  font-size: 18px;
  color: #ffffff;
  &:hover {
    color: ${colors.neutral60};
  }
`;
export const StyledIconMinus = styled(MinusOutlined)`
  font-size: 18px;
  color: #ffffff;
  &:hover {
    color: ${colors.neutral60};
  }
`;

export const StyledContainer = styled('div')`
  box-shadow: 0px 2px 8px rgba(0, 0, 0, 0.15);
  & > button {
    width: 40px;
  }
`;
