import { StyledCard, StyledText, StyledIconContainer, Tag } from './CropCard.styles';

export interface IProps {
  id: number;
  icon: React.FunctionComponent<React.SVGProps<SVGSVGElement>>;
  name: string;
  tag?: string;
  isSelected: boolean;
  disabled?: boolean;
  type?: string;
}

const CropCard = ({ name, icon: Icon, isSelected: is_selected, disabled = false, tag }: IProps): JSX.Element => {
  return (
    <StyledCard hoverable={disabled} is_selected={is_selected.toString()} disabled={disabled}>
      <StyledIconContainer>
        <Icon />
      </StyledIconContainer>
      <StyledText strong={is_selected}>{name}</StyledText>
      {tag && <Tag>{tag}</Tag>}
    </StyledCard>
  );
};

export default CropCard;
