import { useEffect, useState } from 'react';
import { StyledModalView } from './MobileModal.style';
import ProductAndSoilInfo from './ProductAndSoilInfo';
import { useAppState } from 'context/AppState';
import { ModalAnimationDirections } from 'base/types/ModalBox';
import { useFlowActions } from 'context/actions/flowActions';
import { SoilPopData, PopUpdata, PropertyData } from '../SoilCharacteristics';
import FieldPopup from '../FieldPopup';
import FarmSearchMobile from './FarmSearchMobile';

interface MobileModalSiderProps {
  title: string;
  onClickOutside: Function;
  soilPopUpData?: SoilPopData[];
  isFieldInfo: boolean;
  fieldPopUpData?: PopUpdata;
  showFarmSearchPopUp?: boolean;
  farmData: PropertyData[];
  selectFarmName: string;
  handleFarmClick: (_id: string) => void;
}

const MobileModal = ({
  title,
  onClickOutside,
  soilPopUpData,
  isFieldInfo,
  fieldPopUpData,
  showFarmSearchPopUp,
  farmData,
  selectFarmName,
  handleFarmClick,
}: MobileModalSiderProps): JSX.Element => {
  const [slideDirection, setSlideDirection] = useState(ModalAnimationDirections.Up);
  const [isAnimating, setIsAnimating] = useState(false);
  const {
    flow: { isModalToBeClosed },
  } = useAppState();
  const { setIsModalToBeClosed } = useFlowActions();

  const handleAnimationEnd = () => {
    if (isAnimating && onClickOutside) {
      onClickOutside();
    }
  };

  const handleCloseDrawer = () => {
    setSlideDirection(ModalAnimationDirections.Down);
    setIsAnimating(true);
  };

  const getContent = () => {
    if (showFarmSearchPopUp) {
      return (
        <FarmSearchMobile
          title={title}
          farmData={farmData}
          selectFarmName={selectFarmName}
          handleFarmClick={handleFarmClick}
        />
      );
    }
    if (isFieldInfo && fieldPopUpData) {
      return <FieldPopup data={fieldPopUpData} />;
    } else {
      return <ProductAndSoilInfo title={title} soilPopUpData={soilPopUpData} />;
    }
  };

  useEffect(() => {
    if (isModalToBeClosed) {
      handleCloseDrawer();
      setIsModalToBeClosed({ isModalToBeClosed: false });
    }
  }, [isModalToBeClosed, setIsModalToBeClosed]);

  return (
    <div data-testid="map-modal-information">
      <StyledModalView
        data-testid="map-information-sheet"
        width={494}
        isViewFooter
        showHeaderRectangle
        isFieldInfo={isFieldInfo}
        onCancel={handleCloseDrawer}
        content={getContent()}
        slideDirection={slideDirection}
        onAnimationEnd={handleAnimationEnd}
      />
    </div>
  );
};

export default MobileModal;
