import CryptoJS from 'crypto-js';

const securityKeyBytes = 32;
const secretPhraseEncryption = process.env.REACT_APP_SECRET_PHRASE_ENCRYPTION;

function getSecretPhraseEncryption(password = null) {
  if (secretPhraseEncryption) {
    return CryptoJS.enc.Utf8.parse(secretPhraseEncryption);
  }
  if (password) {
    return CryptoJS.enc.Utf8.parse(password);
  }
  return CryptoJS.lib.WordArray.random(securityKeyBytes);
}

export async function decrypt(cipherText = '', secretKey = null) {
  try {
    const [ivHex, cipherHex] = cipherText.split(':');
    const iv = CryptoJS.enc.Hex.parse(ivHex);

    const cipher = CryptoJS.enc.Hex.parse(cipherHex);
    const key = getSecretPhraseEncryption(secretKey);

    const cipherParams = CryptoJS.lib.CipherParams.create({
      ciphertext: cipher,
      iv: iv,
      key: key
    });

    const decryptedCipher = CryptoJS.AES.decrypt(cipherParams, key, {
      mode: CryptoJS.mode.CBC,
      padding: CryptoJS.pad.Pkcs7,
      iv: iv
    });

    return decryptedCipher.toString(CryptoJS.enc.Utf8);
  } catch (e) {
    return cipherText;
  }
}
