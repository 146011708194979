import { LeftOutlined, RightOutlined } from '@ant-design/icons';
import { useAppState } from 'context/AppState';
import { TOTAL_STEPS } from 'context/store/flowReducer';
import { useTranslation } from 'react-i18next';
import {
  ProgressStepperStyled,
  ProgressStyled,
  ProgressBarOverlayStyled,
} from './ProgressBar.styles';
import {
  isMobile as isMobileByUserAgent,
} from 'react-device-detect';

interface ProgressBarProps {
  disabledPrevBtn?: boolean;
  handlePreviousAction: () => void;
  disabledNextBtn?: boolean;
  handleNextAction: () => void;
  nextStepText?: string;
  backButtonText?: string;
  showProgressLine?: boolean;
  isLandingPage?: boolean;
}

export default function ProgressBar(props: ProgressBarProps): JSX.Element {
  const {
    disabledPrevBtn = false,
    handlePreviousAction,
    disabledNextBtn = false,
    handleNextAction,
    nextStepText = 'Next',
    showProgressLine,
    backButtonText = 'Back',
    isLandingPage,
  } = props;
  const {
    flow: { currentStep, showGetRecommendationModal },
  } = useAppState();
  const [t] = useTranslation();
  const stepNumberStr = currentStep.replace(/[^0-9.]/g, '');
  const step = isNaN(Number(stepNumberStr)) ? 1 : Number(stepNumberStr);
  const progress = isLandingPage ? 0 : (step / TOTAL_STEPS) * 100;

  return (
    <>
      {showProgressLine ? (
        <>
          {showGetRecommendationModal && <ProgressBarOverlayStyled />}
          <ProgressStyled data-testid="progressBar" percent={progress} showInfo={false} strokeWidth={isMobileByUserAgent ? 8 : 12} />
        </>
      ) : (
        <ProgressStepperStyled
          stepLabel={<span>{t('stepLabel', { step, totalSteps: TOTAL_STEPS })}</span>}
          progressProps={{ percent: progress }}
          btnPrevProps={{
            icon: <LeftOutlined />,
            disabled: disabledPrevBtn,
            onClick: handlePreviousAction,
          }}
          btnNextProps={{
            icon: <RightOutlined />,
            disabled: disabledNextBtn,
            onClick: handleNextAction,
          }}
          btnPrevText={t(backButtonText)}
          btnNextText={t(nextStepText)}
        />
      )}
    </>
  );
}
