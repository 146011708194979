import styled from 'styled-components';
import { Collapse, Button, colors, SYT_PREFIX } from 'syngenta-digital-cropwise-react-ui-kit';

const { Panel } = Collapse;

interface StyledPanelProps {
  expandIcon?: any;
}

// Collapse
const StyledCollapsed = styled(Collapse)<StyledPanelProps>`
  position: absolute;
  width: 170px;
  top: 16px;
  left: 16px;
  z-index: 2;

  &.${SYT_PREFIX}-collapse {
    height: 10px;
    border: none;
  }

  .syt-antd-collapse-item,
  .syt-antd-collapse-content,
  .syt-antd-collapse-content {
    border: none;
  }

  .${SYT_PREFIX}-collapse-header-text {
    padding-left: 20px;
    padding-right: 10px;
    color: ${colors.neutral80};
    font-style: normal;
    font-weight: 600;
    font-size: 14px;
    line-height: 20px;
    letter-spacing: -0.0075em;
    border: none;
  }
`;

// Panel
const StyledPanel = styled(Panel)`
  .syt-antd-collapse-header {
    background-color: ${colors.neutral10};
    border-radius: 4px !important;
  }

  &.syt-antd-collapse-item-active {
    .syt-antd-collapse-header {
      border-radius: 4px 4px 0 0 !important;
    }
  }

  .syt-antd-collapse-content {
    background-color: ${colors.neutral00};
    border: none;

    &-box {
      padding: 16px 8px;
      padding-bottom: 6px;
    }
  }
`;

const StyledButton = styled(Button)`
  background-color: ${colors.neutral00};
  color: ${colors.neutral100};
  width: 100%;
  height: 40px;
  margin-bottom: 10px;

  &.selected {
    background-color: ${colors.blue50};
    color: ${colors.neutral00};

    div > svg > path {
      fill: ${colors.neutral00};
    }
  }

  // Pressed Effect
  &:not([disabled]):active {
    background-color: ${colors.blue50} !important;
    color: ${colors.neutral00} !important;

    div > svg > path {
      fill: ${colors.neutral00} !important;
    }
  }

  &:hover {
    background-color: ${colors.neutral00};
    color: ${colors.neutral100};

    div > svg > path {
      fill: ${colors.neutral60};
    }

    &.selected {
      background-color: ${colors.blue50};
      color: ${colors.neutral00};

      div > svg > path {
        fill: ${colors.neutral00};
      }
    }
  }
`;

const StyledDivIcon = styled.div`
  vertical-align: middle;
  display: inline-block;
  width: 30%;

  svg {
    width: 18px;
    height: 18px;
  }

  svg > path {
    fill: ${colors.neutral60};
  }
`;

const StyledDivText = styled.div`
  display: inline-block;
  width: 70%;
  text-align: left;

  font-size: 14px;
  font-weight: 400;
  line-height: 20px;
  letter-spacing: -0.0075em;
`;

export { StyledCollapsed, StyledButton, StyledPanel, StyledDivIcon, StyledDivText };
