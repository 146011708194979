import { useCallback, useEffect, useRef } from 'react';
import delay from 'utils/delay';

function useIsMounted(time = 500) {
  const isMounted = useRef(false);

  useEffect(() => {
    delay(time).then(() => {
      isMounted.current = true;
    });

    return () => {
      isMounted.current = false;
    };
  }, [time]);

  return useCallback(() => isMounted.current, []);
}

export default useIsMounted;
