import { SVGProps } from 'react';

import { colors } from '../../theme/colors';

const IconDropPin = ({
  height = '18px',
  width = '18px',
  color = colors.neutral60,
  ...props
}: SVGProps<SVGSVGElement>) => (
  <svg
    data-testid="dropPinIcon"
    width={width}
    height={height}
    viewBox="0 0 18 18"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
    {...props}
  >
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M9 3C10.4475 3 12.75 4.05 12.75 6.8625C12.75 8.4825 11.46 10.365 9 12.3525C6.54 10.365 5.25 8.475 5.25 6.8625C5.25 4.05 7.5525 3 9 3ZM9 1.5C6.5475 1.5 3.75 3.345 3.75 6.8625C3.75 9.2025 5.4975 11.67 9 14.25C12.5025 11.67 14.25 9.2025 14.25 6.8625C14.25 3.345 11.4525 1.5 9 1.5ZM9 5.25C8.175 5.25 7.5 5.925 7.5 6.75C7.5 7.575 8.175 8.25 9 8.25C9.39782 8.25 9.77936 8.09196 10.0607 7.81066C10.342 7.52936 10.5 7.14782 10.5 6.75C10.5 6.35218 10.342 5.97064 10.0607 5.68934C9.77936 5.40804 9.39782 5.25 9 5.25ZM14.25 16.5V15H3.75V16.5H14.25Z"
      fill={color}
    />
  </svg>
);

export default IconDropPin;
