import styled from 'styled-components';
import {
  Layout,
  Typography,
  colors,
  Button,
  ButtonType,
  SYT_PREFIX,
} from 'syngenta-digital-cropwise-react-ui-kit';
import breakpoints from 'base/constants/breakpoints';

const { Paragraph, Title } = Typography;

const { Sider } = Layout;

export const StyledSider = styled(Sider)`
  background-color: ${colors.neutral00} !important;
  height: 100%;
  flex: 1;

  .${SYT_PREFIX}-layout-sider-children {
    display: flex;
    flex-direction: column;
    margin: 0;
  }

  .button-down,
  .button-up {
    background-color: ${colors.neutral10};
  }

  @media only screen and ${breakpoints.maxWidth.sm} {
    min-width: 100vw !important;
  }
`;

export const StyledContainer = styled.div`
  display: flex;
  flex: 1;
  flex-direction: column;
  padding: 21px;
  overflow-y: auto;
`;

export const StyledTitle = styled(Title)`
  color: ${colors.neutral70} !important;
  font-size: 20px;
  font-weight: 600;
  margin-bottom: 19px;
`;

export const StyledParagraph = styled(Paragraph)`
  font-size: 14px;
  color: ${colors.neutral70};

  &.cwsTypography {
    margin-bottom: 26px;
  }
`;

export const Footer = styled.div`
  display: flex;
  margin-top: auto;
  gap: 16px;
`;

export const StyledButton = styled(Button)`
  height: 44px;
  display: flex;
  align-items: center;
  justify-content: center;
  border: none;
  gap: 10px;
  flex: 1;

  font-style: normal;
  font-weight: 400;
  font-size: 16px;
  line-height: 24px;
  /* identical to box height, or 150% */
  letter-spacing: -0.0075em;
  background-color: ${(props) => props.type === ButtonType.default && `${colors.neutral00}`};

  color: ${(props) => props.type === ButtonType.default && `${colors.neutral100}`};

  &:hover {
    color: ${(props) => props.type === ButtonType.default && `${colors.neutral100}`};
  }

  @media screen and ${breakpoints.maxWidth.sm} {
    height: 44px;
    border: ${(props) => props.type === ButtonType.default && `1px solid ${colors.neutral30}`};

    &:hover {
      border: ${(props) => props.type === ButtonType.default && `1px solid ${colors.neutral30}`};
    }
  }
`;
