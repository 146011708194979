import { SVGProps } from 'react';

import { colors } from 'syngenta-digital-cropwise-react-ui-kit';

const IconCheck = ({
  height = '15px',
  width = '15px',
  color = colors.neutral60,
  ...props
}: SVGProps<SVGSVGElement>) => (
  <svg
    width={width}
    height={height}
    color={color}
    {...props}
    viewBox="0 0 18 18"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M1.5 9C1.5 4.86 4.8525 1.5 8.9925 1.5C13.14 1.5 16.5 4.86 16.5 9C16.5 13.14 13.14 16.5 8.9925 16.5C4.8525 16.5 1.5 13.14 1.5 9ZM11.9774 6.00105H14.1899C13.4699 4.76355 12.3224 3.80355 10.9424 3.33105C11.3924 4.16355 11.7374 5.06355 11.9774 6.00105ZM8.99988 3.0293C9.62238 3.9293 10.1099 4.9268 10.4324 5.9993H7.56738C7.88988 4.9268 8.37738 3.9293 8.99988 3.0293ZM3 9C3 9.5175 3.075 10.02 3.195 10.5H5.73C5.67 10.005 5.625 9.51 5.625 9C5.625 8.49 5.67 7.995 5.73 7.5H3.195C3.075 7.98 3 8.4825 3 9ZM3.81006 12H6.02256C6.26256 12.9375 6.60756 13.8375 7.05756 14.67C5.67756 14.1975 4.53006 13.245 3.81006 12ZM3.81006 6.00105H6.02256C6.26256 5.06355 6.60756 4.16355 7.05756 3.33105C5.67756 3.80355 4.53006 4.75605 3.81006 6.00105ZM8.99988 14.97C8.37738 14.07 7.88988 13.0725 7.56738 12H10.4324C10.1099 13.0725 9.62238 14.07 8.99988 14.97ZM7.245 10.5H10.755C10.8225 10.005 10.875 9.51 10.875 9C10.875 8.49 10.8225 7.9875 10.755 7.5H7.245C7.1775 7.9875 7.125 8.49 7.125 9C7.125 9.51 7.1775 10.005 7.245 10.5ZM10.9424 14.67C11.3924 13.8375 11.7374 12.9375 11.9774 12H14.1899C13.4699 13.2375 12.3224 14.1975 10.9424 14.67ZM12.3753 9C12.3753 9.51 12.3303 10.005 12.2703 10.5H14.8053C14.9253 10.02 15.0003 9.5175 15.0003 9C15.0003 8.4825 14.9253 7.98 14.8053 7.5H12.2703C12.3303 7.995 12.3753 8.49 12.3753 9Z"
      fill={color}
    />
  </svg>
);

export default IconCheck;
