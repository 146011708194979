import { CSSProperties } from 'react';
import styled from 'styled-components';
import { Typography, colors, Divider, Collapse } from 'syngenta-digital-cropwise-react-ui-kit';
import breakpoints from 'base/constants/breakpoints';

const { Panel } = Collapse;

interface ITitleProps {
  borderColor?: string;
  selected?: boolean;
  isTitleInformation?: boolean;
  margin?: string;
}

const Title = styled(Typography.Title)<ITitleProps>`
  font-family: 'Noto Sans';
  font-style: normal;
  font-weight: 600;
  font-size: 14px;
  line-height: 0px;
  letter-spacing: -0.0075em;
  margin: 0;

  color: ${colors.neutral00};

  @media screen and ${breakpoints.maxWidth.lg} {
    font-size: 14px;
    line-height: 20px;
  }
`;

const ProductNameText = styled(Typography.Text)<ITitleProps>`
  font-family: 'Noto Sans';
  font-style: normal;
  font-weight: 600;
  font-size: 20px;
  line-height: 28px;
  letter-spacing: -0.0175em;
  color: ${colors.neutral90};

  @media screen and ${breakpoints.maxWidth.lg} {
    font-size: 16px;
    line-height: 24px;
  }
`;

const HybridProfileText = styled(Typography.Text)<ITitleProps>`
  font-family: 'Noto Sans';
  font-style: normal;
  font-weight: 600;
  font-size: 14px;
  line-height: 22px;
  letter-spacing: -0.0075em;
`;

const TitleInformationText = styled(Typography.Text)<ITitleProps>`
  font-family: 'Noto Sans';
  font-style: normal;
  font-weight: 600;
  font-size: 14px;
  line-height: 22px;
  display: flex;
  align-items: center;

  letter-spacing: -0.0075em;
  color: #232630;

  @media screen and ${breakpoints.maxWidth.lg} {
    font-weight: 600;
    font-size: 14px;
    line-height: 20px;
  }
`;

const ValueInformationText = styled(Typography.Text)<ITitleProps>`
  font-family: 'Noto Sans';
  font-style: normal;
  font-weight: 400;
  font-size: 14px;
  line-height: 22px;
  margin-left: 3px;

  letter-spacing: -0.0075em;
  color: ${colors.neutral60};

  @media screen and ${breakpoints.maxWidth.lg} {
    font-size: 14px;
    line-height: 20px;
  }
`;
const ValueInformationDescription = styled(Typography.Text)<ITitleProps>`
  font-family: 'Noto Sans';
  font-style: normal;
  font-weight: 400;
  font-size: 14px;
  line-height: 22px;

  letter-spacing: -0.0075em;
  color: ${colors.neutral60};

  @media screen and ${breakpoints.maxWidth.lg} {
    font-size: 14px;
    line-height: 20px;
  }
`;

const ValueText = styled(Typography.Text)<ITitleProps>`
  font-family: 'Noto Sans';
  font-style: normal;
  font-weight: 600;
  font-size: 14px;
  line-height: 22px;
  letter-spacing: -0.0075em;
  color: ${colors.neutral60};

  @media screen and ${breakpoints.maxWidth.lg} {
    font-size: 14px;
    line-height: 20px;
  }
`;

interface ICardContainerProps {
  width?: string;
  isMobile?: boolean;
  selected?: boolean;
  isFirstProduct?: boolean;
  isLastProduct?: boolean;
  isDDG?: boolean;
  size?: number;
}

const CardContainer = styled.div<ICardContainerProps>`
  border: 2px solid #dfe2e7;
  border-bottom: 0;
  cursor: pointer;
  display: flex;
  flex-direction: column;
  border-color: ${({ isDDG }) => !isDDG && colors.blue60};
  ${({ isMobile }) => isMobile && 'width: 302px;'}
  ${({ isMobile }) => isMobile && 'max-width: 302px;'}
  
  &:first-child {
    margin-bottom: ${({ isDDG }) => isDDG && `9px`};
    border-radius: ${({ isDDG }) => (isDDG ? `9px` : `9px 9px 0 0`)};
    border: ${({ isDDG }) => isDDG && `2px solid #0071cd`};
  }

  &:nth-child(2) {
    border-radius: ${({ isDDG }) => isDDG && `9px 9px 0 0`};
  }

  &:last-child {
    border-bottom: ${({ size, isDDG }) =>
    (size && size > 1 && isDDG ? `2px solid #dfe2e7` : `2px solid #0071cd`)};
    border-radius: ${({ size }) => (size && size > 1 ? `0 0 9px 9px` : `9px`)};
  }

  @media screen and ${breakpoints.maxWidth.sm} {
    background-color: #ffffff;
    width: 90vw;
    max-width: 90vw;
  }
`;
interface IContainer extends CSSProperties {
  selected?: boolean;
  applyMargin?: boolean;
  isMobile?: boolean;
  color?: string;
  isDDG?: boolean;
}

const CardTitleContainer = styled.div<IContainer>`
  height: 36px;
  width: 100%;
  border-top-left-radius: 6px;
  border-top-right-radius: 6px;
  background-color: ${({ selected }) => (selected ? colors.blue60 : colors.neutral60)};
  display: flex;
  justify-content: center;
  align-items: center;
`;

const CardContentContainer = styled.div<IContainer>`
  border-bottom-left-radius: 8px;
  border-bottom-right-radius: 8px;
  flex-grow: 1;
  display: flex;
  flex-direction: row;

  @media screen and ${breakpoints.maxWidth.sm} {
    flex-direction: column;
  }
`;

const getBackgroundColor = (isDDG: boolean | undefined, selected: boolean | undefined) => {
  if (!isDDG) {
    return colors.blue10;
  } else if (isDDG && selected) {
    return colors.blue10;
  } else {
    return colors.neutral10;
  }
};

const ProductNameContainer = styled.div<IContainer>`
  width: 26%;
  padding: 10px 15px;
  border-bottom-left-radius: 6px;
  align-items: left;
  background-color: ${({ isDDG, selected }) => getBackgroundColor(isDDG, selected)};

  @media screen and ${breakpoints.maxWidth.sm} {
    background-color: #ffffff;
    width: 100%;
  }
`;

const CropProductNameContainer = styled.div<IContainer>`
  width: 26%;
  padding: 10px 15px;
  border-bottom-left-radius: 6px;
  align-items: left;
  background-color: ${({ isDDG, selected }) => getBackgroundColor(isDDG, selected)};

  @media screen and ${breakpoints.maxWidth.sm} {
    background-color: #ffffff;
    width: 100%;
    display: flex;
    justify-content: space-between;
  }
`;

const ProductInformationContainer = styled.div<IContainer>`
  width: 40%;
  padding: 10px 15px;
  @media screen and ${breakpoints.maxWidth.sm} {
    width: 100%;
    padding: 0px;
  }
`;

const BreakoutsInformationContainer = styled.div<IContainer>`
  width: 34%;
  padding: 10px 15px;

  @media screen and ${breakpoints.maxWidth.sm} {
    width: 100%;
    padding: 0px;
  }
`;

const BreakoutsOptionContainer = styled.div<IContainer>`
  display: flex;
  justify-content: space-between;
  margin-bottom: 8px;
`;

const DividerStyled = styled(Divider)<IContainer>`
  width: 1px;
  height: 140px;
  background-color: ${colors.neutral20};
  transform: rotate(0deg);

  @media screen and ${breakpoints.maxWidth.sm} {
    height: 1px;
    width: 311px;
    margin: 0px;
  }
`;

const DividerContainer = styled.div`
  text-align: center;
  margin: -10px 0px;
  @media screen and ${breakpoints.maxWidth.sm} {
    margin: 10px 0px;
  }
`;

const FaoContainer = styled.div<IContainer>`
  width: 67px;
  height: 22px;
  background-color: ${colors.neutral20};
  display: flex;
  align-items: center;
  justify-content: center;
  border-radius: 4px;
  padding: 1px 6px;
  gap: 8px;
  margin: 8px 0px;

  @media screen and ${breakpoints.maxWidth.sm} {
    margin-top: 5px;
    position: relative;
    top: -5px;
    background-color: ${colors.blue10};
  }
`;
const MainContainer = styled.div`
  margin-top: 12px;
`;

const FaoText = styled(Typography.Text)<ITitleProps>`
  font-family: 'Noto Sans';
  font-style: normal;
  font-weight: 600;
  font-size: 14px;
  line-height: 20px;

  letter-spacing: -0.0075em;
  text-transform: uppercase;
  color: ${colors.neutral60};
`;

const GrainTypeContainer = styled.div<IContainer>`
  margin-bottom: 16px;
`;

const CategoryContainer = styled.div<IContainer>`
  margin-top: 8px;
  display: flex;
  align-items: center;
`;

const StyledTitleContainer = styled.div`
  width: fit-content;
  margin-bottom: 8px;
  border-top: 1px solid #dfe2e7;
  border-bottom: 1px solid #dfe2e7;
  border-left: 6px solid #dfe2e7;
  border-right: 6px solid #dfe2e7;
  border-radius: 4px;
  background-color: ${colors.neutral20};
`;

const ValueInformationIcon = styled.div`
  width: 24px;
  height: 24px;
  border-radius: 8px;
  display: flex;
  justify-content: center;
  align-items: center;
  margin-right: 8px;
  background-color: ${colors.neutral10};
`;

const ViewApplicableField = styled.div<IContainer>`
  margin-left: auto;
  width: fit-content;
  border: 2px;
  border-radius: 4px;
  background-color: ${({ color }) => `${color}4D`};
  display: flex;
  cursor: pointer;

  @media screen and ${breakpoints.maxWidth.sm} {
    margin: 10px 0px 10px 20px;
  }
`;
const ViewApplicableIcon = styled.div<IContainer>`
  width: 25px;
  display: flex;
  justify-content: center;
  padding: 1px 4px;
`;

const ViewApplicableText = styled.div<IContainer>`
  font-family: 'Noto Sans';
  font-style: normal;
  font-weight: 600;
  font-size: 12px;
  line-height: 12px;
  letter-spacing: -0.0075em;
  color: ${({ color }) => color};
  display: flex;
  justify-content: center;
  align-items: center;
  text-decoration: underline;
  padding: 1px 4px 1px 1px;
`;

const HybridCollapse = styled(Collapse)`
  border: none;
  margin-top: -20px;
  .syt-antd-collapse-item {
    border: none;
  }
`;
const HybridPanel = styled(Panel)`
  border: none;
`;

export {
  Title,
  ProductNameText,
  HybridProfileText,
  TitleInformationText,
  ValueInformationText,
  ValueInformationDescription,
  ValueText,
  CardContainer,
  CardTitleContainer,
  CardContentContainer,
  ProductNameContainer,
  ProductInformationContainer,
  BreakoutsInformationContainer,
  BreakoutsOptionContainer,
  DividerStyled,
  DividerContainer,
  FaoContainer,
  FaoText,
  GrainTypeContainer,
  CategoryContainer,
  StyledTitleContainer,
  MainContainer,
  ValueInformationIcon,
  ViewApplicableField,
  ViewApplicableIcon,
  ViewApplicableText,
  CropProductNameContainer,
  HybridCollapse,
  HybridPanel,
};
