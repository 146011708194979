/* eslint-disable react-hooks/exhaustive-deps */
import { useCallback, useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useNavigate } from 'react-router-dom';
import { useAppState, useAppDispatch } from 'context/AppState';
import { useFlowActions } from 'context/actions/flowActions';
import { useRecommendationFormActions } from 'context/actions/recommendationFormActions';
import { useApiDataActions, ActionTypes } from 'context/actions/ApiDataActions';
import SelectCropDrawer from 'components/SelectCropDrawer';
import { ConfirmationModal } from 'components/ConfirmationModal';
import { ICrop } from 'base/types/Crop';
import { getRankingCrop } from 'utils/rankingCrop';
import { ErrorsTypeMap } from 'utils/constants/ErrorsType';
import { calculateFAOFromProductCatalog } from 'utils/fao';
import ProxyLayer from 'base/api/ProxyLayer';
import { getTraitOptions } from 'utils/trait';
import { getBroomrapeValues } from 'utils/broomrape';
import { CropConstants } from 'utils/constants/Crop';
import { Country } from 'base/types/Countries';
import { FlowSteps, OptionType } from 'context/store/flowReducer';
import { UsageGroups } from 'utils/constants/UsageGroups';
import routes from 'base/constants/routes';
import { useLDClient } from 'launchdarkly-react-client-sdk';
import disableSwipeBack from '../../utils/mobileDisableBackSwipe';
import { getCentroidForCircle, getCentroidOfFields } from 'utils/getCentroidFields';
import track from 'utils/amplitudeWrapper';
import { trackServiceUnavailable, trackValidationAndDownyMildewError } from 'utils/helpers/amplitude';

interface IProps {
  onClickPrev: () => void;
  onClickNext: () => void;
  isCornSelected: boolean;
  isMobile: boolean;
}

const SecondStep = ({ onClickNext, onClickPrev, isCornSelected, isMobile }: IProps): JSX.Element => {
  const [t] = useTranslation();

  const {
    flow: { showAgronomicWeightings, optionType, isAnotherRecommendationCreated,
      isLongTermMobileDesign,
    },
    recommendationForm: {
      fields,
      countryCode,
      areNewBoundaryChages,
      selectedCrop,
      agronomicInputs: { productMadurityRange: faoFromStore },
    },
    apiData: {
      countryList,
      countryListError,
      attributesLoading,
      attributesError,
      productMaturityRange,
      productMaturityRangeError,
      productCatalog,
      productCatalogLoading,
      tppData,
      tppDataError,
      downyMildewError,
      downyMildewLoading,
      fieldSoilsLoading,
      recommenationValidateLoading,
      recommenationValidateError,
    },
  } = useAppState();
  const [faoEmpty, setFaoEmpty] = useState(false);
  const {
    setShowSpinner, setFlowStep, setShowPrompt
  } = useFlowActions();
  const recommendationFormActions = useRecommendationFormActions();
  const apiDataActions = useApiDataActions();
  const appDispatcher = useAppDispatch();
  const navigate = useNavigate();
  const selectedCropType = selectedCrop?.id;
  const [displayConfirmationModal, setDisplayConfirmationModal] = useState(false);
  const [isRetryRecommendationPopupShown, setIsRetryRecommendationPopupShown] = useState(false);
  const ldClient = useLDClient();

  // Reset values onMount
  useEffect(() => {
    recommendationFormActions.setProductMadurityRange({ productMadurityRange: [] });
  }, []);

  useEffect(() => {
    const updateLdClient = async () => {
      if (ldClient && countryCode) {
        const user = {
          key: `${countryCode.toLowerCase()}-country-users`,
          country: countryCode.toLowerCase(),
        };
        try {
          await ldClient.identify(user);
        } catch (error) {
          console.error('Error identifying information in launchdarkly:', error);
        }
      }
    };

    updateLdClient();
  }, [ldClient, countryCode]);

  useEffect(() => {
    if (isMobile) {
      document.addEventListener('touchstart', (e) => disableSwipeBack(e), { passive: false });
      return () => {
        document.removeEventListener('touchstart', disableSwipeBack);
      };
    }
  }, []);

  useEffect(() => {
    const onBackButtonEvent = (e: PopStateEvent) => {
      if (!isAnotherRecommendationCreated) {
        return;
      }
      e.preventDefault();
      appDispatcher({ type: 'reset-app' });
      window.removeEventListener('popstate', onBackButtonEvent);
      navigate(routes.home);
    };
    window.addEventListener('popstate', onBackButtonEvent, { once: true });
  }, [navigate]);

  const getCountryName = () => {
    const selectedCountry = countryList?.find((item: Country) => item.shortCode === countryCode);
    return selectedCountry?.name;
  };

  const isCropDataLoading = () => {
    const cropType = selectedCrop?.type?.toLowerCase();
    switch (true) {
      case cropType?.includes('sunflower'):
        return tppData?.ID_0?.length === 0;
      case cropType?.includes('grain corn'):
      case cropType?.includes('summer corn'):
      case cropType?.includes('silage corn'):
        return (
          countryList.length === 0 ||
          productCatalogLoading ||
          fieldSoilsLoading ||
          (productMaturityRange.minFAO === 0 && productMaturityRange.maxFAO === 0)
        );
      case cropType?.includes('wheat'):
        return tppData?.spatialClass?.length === 0 || countryList.length === 0;
      default:
        return false;
    }
  };

  const handleResetCropsDataFromAPI = useCallback(() => {
    recommendationFormActions.setAGProducts({ agProducts: [] });
    recommendationFormActions.setProductMadurityRange({ productMadurityRange: [] });
    recommendationFormActions.resetAGInputs();
    appDispatcher({
      type: ActionTypes.setProductMaturityRangeFAO,
      payload: { data: {} },
    });
  }, [selectedCrop, recommendationFormActions]);

  const fetchDataFromBMZService = useCallback(async () => {
    let centroid: number[] = [];
    if (fields.length) {
      if ([OptionType.Draw, OptionType.Detect].includes(optionType)) {
        centroid = getCentroidOfFields(fields);
      } else if (optionType === OptionType.Drop) {
        centroid = getCentroidForCircle(fields);
      }
    }
    try {
      if (centroid.length > 0) {
        const bmzTPPResponse = await apiDataActions.getTPPForFields(getCountryName(), selectedCrop?.id, centroid);
        appDispatcher({
          type: ActionTypes.setTPPForFields,
          payload: { data: bmzTPPResponse },
        });
      }
    } catch (e) {
      appDispatcher({
        type: ActionTypes.setTPPForFieldsError,
        payload: { error: e },
      });
    }
  }, [fields.length, selectedCrop]);

  useEffect(() => {
    if (selectedCrop?.id === CropConstants.SUNFLOWER_CROP_ID) {
      fetchDataFromBMZService();
    }

    if (isAnotherRecommendationCreated) {
      handleResetCropsDataFromAPI();
    }
  }, [selectedCrop, fields.length]);

  useEffect(() => {
    if (!selectedCrop) {
      return;
    }
    let rankingCrop = '';

    if (selectedCrop?.name) {
      rankingCrop = getRankingCrop(selectedCrop.name, countryCode, countryList);
    }

    recommendationFormActions.setRankingCrop({ rankingCrop });

    // Clear FAO cache
    if (countryCode && countryCode !== window.sessionStorage.getItem('countryCode') && isCornSelected) {
      appDispatcher({
        type: ActionTypes.setProductMaturityRangeFAO,
        payload: { data: { minFAO: 0, maxFAO: 0 } },
      });
      window.sessionStorage.setItem('countryCode', countryCode);
    }
  }, [selectedCrop, countryCode, countryList]);

  useEffect(() => {
    setShowSpinner({ show: recommenationValidateLoading });

    const showDisplayConfirmationModal = Boolean(
      (countryListError && countryListError.code !== 'ERR_CANCELED') ||
      attributesError ||
      productMaturityRangeError ||
      faoEmpty ||
      tppDataError
    );
    const showRetryRecommendationPopup = Boolean(recommenationValidateError || downyMildewError);

    setDisplayConfirmationModal(showDisplayConfirmationModal);
    setIsRetryRecommendationPopupShown(showRetryRecommendationPopup);
    if (showRetryRecommendationPopup || showDisplayConfirmationModal) {
      setShowPrompt({ show: false });
    }

  }, [recommenationValidateLoading, countryListError, attributesError, productMaturityRangeError, faoEmpty, tppDataError, downyMildewError, recommenationValidateError]);

  useEffect(() => {
    if (displayConfirmationModal) {
      trackServiceUnavailable(attributesError, productMaturityRangeError, tppDataError);
    }
    if (isRetryRecommendationPopupShown) {
      trackValidationAndDownyMildewError(recommenationValidateError, downyMildewError);
    }
  }, [displayConfirmationModal, isRetryRecommendationPopupShown]);

  useEffect(() => {
    if ((countryList.length === 0 && countryListError === undefined) || areNewBoundaryChages) {
      recommendationFormActions.setAreNewBoundaryChages({ areNewBoundaryChanges: false });
      apiDataActions.getCountries(false, countryCode);
    } else {
      const selectedCountry = countryList?.find((item: Country) => item.shortCode === countryCode);
      recommendationFormActions.setCountryRegion({ countryRegion: selectedCountry?.region });
    }
  }, [
    apiDataActions,
    countryList,
    countryListError,
    areNewBoundaryChages,
    recommendationFormActions,
  ]);

  useEffect(() => {
    const fetchAttributes = async () => {
      await apiDataActions.getAttributes(undefined, countryCode, selectedCropType, [
        UsageGroups.WEIGHTING,
      ]);
    };

    let canFetchAttributes =
      (isLongTermMobileDesign &&
        selectedCropType &&
        countryCode &&
        !attributesLoading) ||
      showAgronomicWeightings;

    if (!canFetchAttributes && !isLongTermMobileDesign && selectedCropType && countryCode) {
      canFetchAttributes = true;
    }
    if (canFetchAttributes) {
      fetchAttributes();
    }
  }, [selectedCropType, countryCode, showAgronomicWeightings, isLongTermMobileDesign]);

  // Get Products Catalog
  useEffect(() => {
    const cancelToken = ProxyLayer.cancelToken();

    const fetchProductsCatalog = async () => {
      if (productCatalog && !productCatalog.length) {
        try {
          const products = await apiDataActions.getProductCatalog(
            '',
            countryCode || 'HUN',
            cancelToken.token
          );
          const productsFilteredByCountryCode = products.filter(
            (item) => item.country.code === countryCode
          );

          appDispatcher({
            type: ActionTypes.setProductCatalog,
            payload: { data: productsFilteredByCountryCode },
          });
        } catch (e) {
          // TODO:
        }
      } else {
        recommendationFormActions.setAGProducts({ agProducts: productCatalog });
      }
    };
    fetchProductsCatalog();

    return () => {
      cancelToken?.cancel();
    };
  }, [countryCode, selectedCrop]);

  useEffect(() => {
    // Calculate FAO range when selectedCrop changes
    if (
      selectedCrop && isCornSelected &&
      (selectedCrop.name || selectedCrop.type) &&
      productCatalog.length > 0 &&
      faoFromStore?.[0] !== 0 &&
      faoFromStore?.[1] !== 0
    ) {
      const updatedName =
        selectedCrop?.name === CropConstants.CORN_SMALLCASE
          ? CropConstants.GRAIN_CORN
          : selectedCrop?.name;
      const productsFilteredBySelectedCropName = productCatalog.filter(
        (item) => item?.crop?.name?.toLowerCase() === updatedName?.toLowerCase() && item.fao
      );

      if (productsFilteredBySelectedCropName.length === 0) {
        // eslint-disable-next-line no-console
        console.warn(
          'Issue was caused due to null or empty values for FAO and Crop, products will not be found'
        );
        setFaoEmpty(true);
      }

      const fao = calculateFAOFromProductCatalog(productsFilteredBySelectedCropName);

      recommendationFormActions.setAGProducts({ agProducts: productsFilteredBySelectedCropName });
      recommendationFormActions.setProductMadurityRange({
        productMadurityRange: [fao.minFAO, fao.maxFAO],
      });

      appDispatcher({ type: ActionTypes.setProductMaturityRangeFAO, payload: { data: fao } });
    }

    // Calculate herbicide trait & broomrape values when selectedCrop changes
    if (selectedCrop?.name === CropConstants.SUNFLOWER_SMALLCASE && productCatalog.length > 0) {
      const broomRapeSet = new Set<string>();
      const traitSet = new Set<string>();
      productCatalog.forEach(item => {
        if (item.crop.id === CropConstants.SUNFLOWER_CROP_ID) {
          if (item.broomrape.length > 0 && item.broomrape !== 'S') {
            broomRapeSet.add(item.broomrape.replace('BR ', ''));
          }
          if (item.trait.length > 0) {
            traitSet.add(item.trait);
          }
        }
      });
      let traitValues = getTraitOptions(Array.from(traitSet), countryCode);
      traitValues = traitValues.map((item) => {
        return { ...item, label: t(item.label), value: item.value };
      });

      recommendationFormActions.setAGProducts({ agProducts: productCatalog });
      recommendationFormActions.setHerbicideTraitOptions({
        herbicideTraitOptions: traitValues
      });
      const brData = getBroomrapeValues(Array.from(broomRapeSet));
      recommendationFormActions.setBroomrapeOptions({
        broomrapeOptions: brData
      });
    }
    if (selectedCrop?.name === 'Wheat' && productCatalog.length > 0) {
      recommendationFormActions.setAGProducts({ agProducts: productCatalog });
    }
  }, [productCatalog, selectedCrop, t]);

  const handleSelectedCropChange = useCallback((selectedCrop: ICrop) => {
    track('select crop', { 'selected crop': selectedCrop.type });
    recommendationFormActions.setSelectedCrop({ crop: selectedCrop });
  }, []);

  const handleRetryRecommendationClick = () => {
    appDispatcher({ type: ActionTypes.setDownyMildewLoading, payload: { loading: downyMildewLoading } });
    appDispatcher({ type: ActionTypes.setRecommendationValidationLoading, payload: { loading: recommenationValidateLoading } });
    setFlowStep({ step: FlowSteps.STEP2 });
    setIsRetryRecommendationPopupShown(false);
  };

  return (
    <>
      <SelectCropDrawer
        title={`${t('Step {{stepNumber}}', { stepNumber: 2 })}: ${t('Select a Crop')}`}
        description={t('Select a Crop Description')}
        onClickPrev={onClickPrev}
        onClickNext={onClickNext}
        countryList={countryList}
        areNewBoundaryChages={areNewBoundaryChages}
        countryCode={countryCode}
        cropId={selectedCrop?.id}
        handleSelectedCropChange={handleSelectedCropChange}
        handleResetCropsDataFromAPI={handleResetCropsDataFromAPI}
        isLoading={isCropDataLoading()}
      />
      {displayConfirmationModal && (
        <ConfirmationModal
          id={'ServiceUnavailableModal'}
          title={t(ErrorsTypeMap.MAX_ATTEMPTS.statusTitle ?? '')}
          body={t(ErrorsTypeMap.MAX_ATTEMPTS.statusMessage)}
          confirmButtonText={t('Back to Home')}
        />
      )}
      {isRetryRecommendationPopupShown && (
        <ConfirmationModal
          id={'ServiceUnavailableModal'}
          title={t(ErrorsTypeMap.MAX_ATTEMPTS.statusTitle ?? '')}
          body={t(ErrorsTypeMap.MAX_ATTEMPTS.statusMessage)}
          buttonStyle={'primary'}
          isFieldViewInformation={true}
          cancelButtonText={t('Retry')}
          confirmButtonText={t('Back to Home')}
          onClickCancel={handleRetryRecommendationClick}
        />
      )}
    </>
  );
};

export default SecondStep;
