import styled from 'styled-components';
import { SYT_PREFIX, Skeleton } from 'syngenta-digital-cropwise-react-ui-kit';
import { Spinner } from 'components/Spinner';
import breakpoints from 'base/constants/breakpoints';

interface StickyBarProps {
  isLoading?: boolean;
  showDownloadBtn?: boolean;
  isDDG?: boolean;
}

export const StickyBar = styled.div<StickyBarProps>`
  position: sticky;
  top: 0;
  display: flex;
  justify-content: flex-end;
  padding: 16px 16px 16px 10px;
  gap: 10px;
  z-index: ${({ showDownloadBtn }) => (showDownloadBtn ? 99 : 48)};

  @media screen and ${breakpoints.maxWidth.sm} {
    padding: ${({ isLoading }) => isLoading && '24px 16px'};
    flex-direction: ${({ isLoading }) => (isLoading ? 'column' : 'row')};
    justify-content: space-between;
    gap: ${({ isDDG }) => (isDDG ? '16px' : '0px')};
    padding: ${({ showDownloadBtn }) => (showDownloadBtn ? '10px 16px' : '16px 0')};
    z-index: 1;
  }

  button {
    padding: 10px 16px;
  }

  .${SYT_PREFIX}-skeleton {
    display: flex;
    flex: 1;
    max-width: 216px;

    span {
      flex: 1;
    }
  }
  .headerBtnWrap > span {
    display: flex;
  }
`;

export const StyledSpinner = styled(Spinner)`
  position: fixed;
  top: 0;
  bottom: 0;
  width: 100%;
  height: inherit !important;
`;

const SkeletonStyled = styled(Skeleton.Button)`
  width: 100%;
  max-width: none !important;
`;

const SkeletonMobileContainer = styled.div`
  display: flex;
  gap: 12px;
  flex-direction: column;
`;

const SkeletonMobileTopContainer = styled.div`
  display: flex;
  gap: 12px;
  width: 100%;
`;

const ButtonWrapperContainer = styled.div`
  display: flex;
  justify-content: center;
  padding: 24px 8px;
  gap: 24px;
  background-color: #00004b;
  margin: -1px 0px;
`;

export {
  SkeletonStyled,
  SkeletonMobileContainer,
  SkeletonMobileTopContainer,
  ButtonWrapperContainer,
};
