import styled from 'styled-components';
import { SectionContainer, FullScreenChartContainer } from '../../RecommendationV2/V2Common.styles';
import trialedProduct from 'assets/images/map-pins.png';
import userLocation from 'assets/images/user-location.png';
import { Spinner } from 'components/Spinner';
import { colors, Button } from 'syngenta-digital-cropwise-react-ui-kit';
import breakpoints from 'base/constants/breakpoints';

interface IsMobileProps {
  isMobile: boolean;
}

export const SectionContainerStyled = styled(SectionContainer)`
  flex: 1;

  .syt-antd-notification-bottom {
    position: absolute;
  }

  .mapboxgl-ctrl-top-left .mapboxgl-ctrl {
    float: inherit;
    display: inline;
  }

  .mapboxgl-popup {
    z-index: 10;

    &-content {
      border-radius: 8px;
      background-color: ${colors.neutral90};
      color: ${colors.neutral20};
      padding: 30px 40px 30px 24px;
      width: 420px;
      display: flex;
      flex-direction: column;

      & div {
        display: flex;
        margin-bottom: 10px;
      }
      span {
        flex: 1;
        font-family: 'Noto Sans';
        font-weight: 400;
        font-size: 16px;
      }

      .popup-value {
        flex: none;
        font-size: 20px;
      }
    }

    &-close-button {
      font-size: 22px;
      right: 4px;
      top: 4px;
    }

    &-anchor-bottom .mapboxgl-popup-tip {
      border-top-color: ${colors.neutral90};
    }

    &-anchor-right .mapboxgl-popup-tip {
      border-left-color: ${colors.neutral90};
    }

    &-anchor-top-right .mapboxgl-popup-tip {
      border-bottom-color: ${colors.neutral90};
    }
    &-anchor-top .mapboxgl-popup-tip {
      border-bottom-color: ${colors.neutral90};
    }

    &-anchor-top-left .mapboxgl-popup-tip {
      border-bottom-color: ${colors.neutral90};
    }

    &-anchor-left .mapboxgl-popup-tip {
      border-right-color: ${colors.neutral90};
    }

    &-anchor-bottom-left .mapboxgl-popup-tip {
      border-top-color: ${colors.neutral90};
    }

    &-anchor-bottom-right .mapboxgl-popup-tip {
      border-top-color: ${colors.neutral90};
    }

    &-anchor-top-left .mapboxgl-popup-content {
      border-top-left-radius: 0;
    }

    &-anchor-bottom-left .mapboxgl-popup-content {
      border-bottom-left-radius: 0;
    }

    &-anchor-bottom-right .mapboxgl-popup-content {
      border-bottom-right-radius: 0;
    }

    &-anchor-top-right .mapboxgl-popup-content {
      border-top-right-radius: 0;
    }
  }
  background-color: #f8fafe;
  border-color: #f8fafe;
  @media screen and ${breakpoints.maxWidth.sm} {
    background-color: #ffffff;
    border-color: #ffffff;
  }
`;

export const StyledSpinner = styled(Spinner)`
  position: absolute;
  top: 0;
`;

export const MapContainer = styled(FullScreenChartContainer)<IsMobileProps>`
  display: flex;
  flex: 1;
  min-height: 636px;
  width: 100%;
  border-radius: ${({ isMobile }) => (isMobile ? '0px' : '10px')};
  @media screen and ${breakpoints.maxWidth.sm} {
    min-height: 418px;
  }
  overflow: ${({ fullScreen, isMobile }) => (!fullScreen && isMobile ? 'visible' : 'hidden')};
  padding: ${({ fullScreen }) => (fullScreen ? '0 !important' : '')};

  @media screen and (orientation: landscape) {
    min-height: ${({ isMobile }) => (isMobile ? '100%' : '636px')} !important;
  }

  .mapboxgl-map {
    overflow: visible;
    height: auto !important;
  }
  .mapboxgl-ctrl-logo {
    @media screen and ${breakpoints.maxWidth.sm} {
      position: ${({ fullScreen }) => (fullScreen ? 'fixed' : 'static')};
    }
  }
`;

export const StyledOverlay = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;

  height: 100%;
  width: 100%;
  background-color: ${colors.neutral100}75;
  position: absolute;
  z-index: 4;
`;

export const StyledButton = styled(Button)`
  font-family: 'Noto Sans';
  font-style: normal;
  font-weight: 400;
  font-size: 14px;
  line-height: 20px;
  letter-spacing: -0.0075em;
  color: ${colors.neutral100};

  &:hover {
    color: ${colors.neutral100};
  }
`;

export const StyledDiv = styled.div`
  &.hidden {
    display: none;
  }
`;

export const StyledDivProducts = styled.div<IsMobileProps>`
  @media screen and (orientation: landscape) {
    margin-top: ${({ isMobile }) => (isMobile ? '-37px' : '')} !important;
  }

  &.hidden {
    display: none;
  }
  display: ${({ isMobile }) => (isMobile ? 'auto' : 'inline')};
`;

export const StyledCloseContainer = styled.div`
  display: flex;
  flex-direction: row-reverse;
  margin-right: 10px;

  &.hidden {
    display: none;
  }
`;

export const StyledUserMarker = styled.img.attrs((props) => ({
  src: props.src ?? userLocation,
}))`
  background-size: cover;
  width: 32px;
  height: 32px;
  cursor: pointer;
`;

export const StyledProductMarker = styled.img.attrs((props) => ({
  src: props.src ?? trialedProduct,
}))`
  background-size: cover;
  width: 29px;
  height: 34px;
  cursor: pointer;
`;

export const StyledNotificationContent = styled.div`
  font-weight: 600;
`;
