import { Feature, Polygon } from '@turf/helpers';
import { ActionTypes } from 'context/actions/mapActions';
import { AppAction } from 'context/AppState';
import produce from 'immer';
import { MapsConstants, MapStyles } from 'utils/constants/MapsConstants';
import mapboxgl from 'mapbox-gl';

export interface MapState {
  mapStyle: string;
  center: [longitude: number, latitude: number];
  confirmedBoundaries: Feature<Polygon>[] | [];
  ipApiLoading: boolean;
  mapToolsOptionSelected: number;
  userDropPinPosition: mapboxgl.LngLat;
}

export const mapInitialState: MapState = {
  mapStyle: MapStyles.HYBRID,
  center: [MapsConstants.LONGITUDE_DEFAULT, MapsConstants.LATITUDE_DEFAULT],
  confirmedBoundaries: [],
  mapToolsOptionSelected: 0,
  userDropPinPosition: new mapboxgl.LngLat(0, 0),
  ipApiLoading: false,
};

export const mapReducer = produce((draft: MapState, action: AppAction) => {
  const { payload = {} } = action;

  switch (action.type) {
    case ActionTypes.setMapStyle:
      draft.mapStyle = payload.mapStyle;
      break;
    case ActionTypes.setMapCenter:
      draft.center = payload.center;
      break;
    case ActionTypes.setIPApiLoading:
      draft.ipApiLoading = payload.ipApiLoading;
      break;
    case ActionTypes.saveNewBoundary:
      draft.confirmedBoundaries = [
        ...draft.confirmedBoundaries,
        payload.newBoundaries as Feature<Polygon>,
      ];
      break;

    case ActionTypes.setMapToolsOptionSelected:
      draft.mapToolsOptionSelected = payload.mapToolsOptionSelected;
      break;
    case ActionTypes.deleteLastBoundary:
      draft.confirmedBoundaries = draft.confirmedBoundaries.slice(0, -1);
      break;
    case ActionTypes.setUserDropPinPosition:
      draft.userDropPinPosition = payload.position;
      break;
    default:
      return draft;
  }
});
