import breakpoints from 'base/constants/breakpoints';
import styled from 'styled-components';
import { colors, SYT_PREFIX } from 'syngenta-digital-cropwise-react-ui-kit';
import isMobileTool from 'ismobilejs';

const isMobile = isMobileTool(window.navigator);
export const StyledFilters = styled.div`
  position: relative;
  margin-top: 50px;

  @media screen and (orientation: landscape) {
    margin-top: ${() => (isMobile.phone ? '20px' : '50px')};
  }
  .ant-checkbox-group {
    margin: auto;
    width: 70%;
    text-align: center;
    font-size: 16px;
    gap: 10px;
    .ant-checkbox-wrapper {
      margin: 5px;
      cursor: pointer;
      display: inline-flex;
      .ant-checkbox {
        margin-right: 3px;
      }
    }
  }
`;

export const FilterContainer = styled.div`
  display: flex;
  margin-bottom: 30px;
  justify-content: center;
  flex-wrap: wrap;
  gap: 10px;

  label {
    font-size: 16px;
  }

  @media screen and ${breakpoints.maxWidth.md} {
    gap: 10px 5px;
  }

  @media screen and ${breakpoints.minWidth.md} {
    justify-content: flex-start;
    gap: 10px 35px;
    .${SYT_PREFIX}-checkbox-wrapper + .${SYT_PREFIX}-checkbox-wrapper {
      margin-left: 0;
    }
  }

  @media screen and (orientation: landscape) {
    justify-content: ${() => (isMobile.phone ? 'flex-start' : 'center')};
    gap: 10px;
  }
`;

export const RotateLabel = styled.div`
  display: flex;
  font-size: 12px;
  color: ${colors.neutral70};
  justify-content: flex-end;
  align-items: center;
  gap: 10px;
  margin-bottom: 20px;

  @media screen and (orientation: landscape) and ${breakpoints.maxWidth.xl} {
    position: absolute;
    top: 7px;
    right: 0;
    span {
      display: ${() => `${isMobile.phone ? 'none' : 'initial'}`};
    }
  }
`;

export const FilterContainerWeatherChart = styled(FilterContainer)`
  margin-top: 24px;
  justify-content: end;
`;

const FilterContainerWeatherChartLandscape = styled(FilterContainer)`
  margin-top: 24px;
  justify-content: end;
  display: flex;
  flex-direction: row;
  justify-content: space-between;
`;

interface CustomChart {
  chartType: 'Temperature' | 'GrowingDegree';
  temperatureGradientId?: string;
}

export const RootWeatherChart = styled.div`
  height: auto;

  @media screen and ${breakpoints.maxWidth.md} {
    width: 700px;
  }
`;

export const WeatherChartContainer = styled.div<CustomChart>`
  overflow-x: auto;
  overflow-y: hidden;
  position: absolute !important;
  top: 0;
  left: 0;

  .c3-axis {
    font-size: 12px;
  }
  .c3-axis-x {
    text {
      text-transform: capitalize;
      font-size: 16px;
      fill: ${colors.neutral70};
    }
  }
  .c3-axis-y {
    text,
    .tick {
      fill: ${({ chartType }) => (chartType === 'Temperature' ? colors.red60 : colors.yellow50)};
    }

    @media screen and ${breakpoints.maxWidth.sm} {
      text,
      .tick {
        font-size: ${() => (isMobile.phone ? '11px' : '16px')};
      }
    }
  }

  .c3-axis-y2 {
    text,
    .tick {
      fill: ${colors.blue60};
    }
  }

  .c3-axis-y-label,
  .c3-axis-y2-label {
    font-size: 16px;
  }

  .c3-xgrid {
    stroke-dasharray: unset;
    fill: ${colors.neutral40};
  }

  .c3-xgrid-focus {
    line {
      stroke: ${colors.neutral80};
      stroke-width: 2px;
      stroke-dasharray: 4;
    }
  }

  .c3-tooltip {
    border: 1px solid red;
  }

  .c3-bar {
    path {
      shape-rendering: auto;
    }
  }

  .chart-tooltip {
    padding: 8px;
    background: ${colors.neutral100};
    box-shadow: 0px 2px 8px rgba(0, 0, 0, 0.15);
    border-radius: 4px;
    color: white;
    transform: translate(-10px, -55%);
  }

  ._expanded_ {
    fill-opacity: 1;
  }

  .c3-target-fill-temperature {
    line-height: 2px;
    path {
      fill: ${({ temperatureGradientId }) => `url(#${temperatureGradientId})`};
      stroke-width: 2px;
    }
  }

  .c3-target-fill-growing-degree-days {
    line-height: 4px;
    path {
      stroke-width: 4px;
    }
  }

  .c3-target-fill-growing-degree-daysmin {
    line-height: 4px;
    path {
      stroke-width: 4px;
    }
  }

  .c3-target-fill-growing-degree-daysmax {
    line-height: 4px;
    path {
      stroke-width: 4px;
    }
  }

  .c3-area-growingDegreeMax {
    fill: url(#growing-degree-days-gradient) !important;
  }

  .c3-area-growingDegreeMin {
    opacity: 0 !important;
    visibility: hidden;
  }

  .heat {
    fill: ${colors.red30};
  }

  .cold {
    fill: ${colors.blue20};
  }

  .c3-area-growingDegreeMin {
    fill: ${colors.neutral00} !important;
  }
`;

export const LoadingChartSkeleton = styled.div`
  position: relative;
  width: 50%;
  height: 400px;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  background-color: rgba(0, 0, 0, 0.5);
  & > * {
    position: relative;
  }
`;

const ChipsContainerStyled = styled.div`
  display: flex;
  flex-direction: row;
  gap: 24px;
`;

const ChartContainer = styled.div`
  position: relative;
`;

export { ChipsContainerStyled, FilterContainerWeatherChartLandscape, ChartContainer };
