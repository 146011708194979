import { MainDiv, Text } from './FarmSearchMobile.style';
import FarmSearch from '../FarmSearch';
import { PropertyData } from '../SoilCharacteristics';

interface Field {
  title: string;
  farmData: PropertyData[];
  handleFarmClick: (_id: string) => void;
  selectFarmName: string;
}
const FarmSearchMobile = ({ title, farmData, selectFarmName, handleFarmClick }: Field): JSX.Element => {
  return (
    <>
      <Text>{title}</Text>
      <MainDiv data-testid='farm-search-section'>
        <FarmSearch
          farmData={farmData}
          handleFarmClick={handleFarmClick}
          selectFarmName={selectFarmName}
        />
      </MainDiv>
    </>
  );
};

export default FarmSearchMobile;
