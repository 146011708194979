import { Row } from 'syngenta-digital-cropwise-react-ui-kit';
import { useMemo, useRef, useEffect, useState } from 'react';
import {
  StyledTopText,
  StyledSquare,
  StyledTopTextContainer,
  StyledProductPerformanceChart,
  StyledGradientBar,
  StyledTriangle,
  StyleStressTextContainer,
  StyledRow,
} from './ProductPerformance.styles';

import { useTranslation } from 'react-i18next';
import { useBreakpoint } from 'hooks';

interface ProductPerformanceChartProps {
  data: any[];
  yMin?: number;
  yMax?: number;
  yStep?: number;
}

export const ProductPerformanceChart = ({
  data = [],
  yMin = 0,
  yMax = 10,
  yStep = 1,
}: ProductPerformanceChartProps) => {
  const containerRef = useRef<HTMLDivElement>(null);
  const { isMobile, landscape, isTablet } = useBreakpoint();
  const [parentHeight, setParentHeight] = useState(330);
  const [t] = useTranslation();
  const distanceBetweenBars = useMemo(() => {
    if (isTablet && !landscape) {
      return 50;
    } else if (isMobile && landscape) {
      return 60;
    } else {
      return 24;
    }
  }, [isTablet, landscape, isMobile]);

  useEffect(() => {
    if (isMobile && landscape) {
      setParentHeight(containerRef.current?.offsetParent?.clientHeight ?? 330);
    }
  }, [containerRef, isMobile, landscape]);

  return (
    <div ref={containerRef}>
      {!isMobile && (
        <Row justify="end">
          <StyledTopTextContainer>
            <StyledSquare />
            <StyledTopText>{t('Product Performance Score')}</StyledTopText>
          </StyledTopTextContainer>
        </Row>
      )}
      <StyledProductPerformanceChart
        yMin={yMin}
        yMax={yMax}
        yStep={yStep}
        data={data}
        yLabel={t('Product Performance Score')}
        areValuesDecimal
        id={'product-performance-chart'}
        showTooltip={isMobile || (isMobile && landscape)}
        height={isMobile && landscape ? parentHeight : 360}
        paddingLeft={isTablet ? 70 : undefined}
        distanceBetweenBars={distanceBetweenBars}
      />

      <StyledRow justify="center">
        <StyledGradientBar />
        <StyledTriangle />
        <StyleStressTextContainer>
          <div>{t('Low Stress')}</div>
          <div>{t('Medium Stress')}</div>
          <div>{t('High Stress')}</div>
        </StyleStressTextContainer>
      </StyledRow>
    </div>
  );
};

export default ProductPerformanceChart;
