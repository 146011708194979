import styled from 'styled-components';
import ModalView from 'components/ModalView';
import { SYT_PREFIX } from 'syngenta-digital-cropwise-react-ui-kit';
import { showSlidingAnimation } from 'utils/modalBoxAnimations';
import { IStyledModalProps } from 'base/types/ModalBox';

export const StyledModalView = styled(ModalView)<IStyledModalProps>`
  background: ${(props) => (props.isFieldInfo ? '#FFFFFF' : '#363948')};
  border-top-right-radius: 18px;
  border-top-left-radius: 18px;
  right: 0 !important;

  .${SYT_PREFIX}-modal-content {
    border-radius: 8px;
  }
  ${(props) => showSlidingAnimation(props.slideDirection)}
  @media screen and (max-height: 769px) {
    .${SYT_PREFIX}-modal-content {
      top: 56px !important;
      overflow: auto !important;
    }
  }
  .${SYT_PREFIX}-modal-header {
    border-radius: 8px;
  }

  .clIVIc {
    padding: 0px;
  }
  .iMmxge {
    border: 0px;
  }
`;
