import * as turf from '@turf/turf';

export default function gridPointToPolygon(
  latlong: { longitude: number; latitude: number },
  resolution: number
) {
  return turf.polygon([
    [
      [latlong.longitude - resolution, latlong.latitude + resolution],
      [latlong.longitude - resolution, latlong.latitude - resolution],
      [latlong.longitude + resolution, latlong.latitude - resolution],
      [latlong.longitude + resolution, latlong.latitude + resolution],
      // end on same latlong as start
      [latlong.longitude - resolution, latlong.latitude + resolution],
    ],
  ]);
}
