import { CSSProperties } from 'styled-components';
import {
  Container,
  TextContainer,
  StyledTitle,
  StyledTemperatureContainer,
  StyledPrecipitationContainer,
  StyledIconDropletSize,
  StyledIconThermostat,
  StyledYearsContainer,
  StyledYearRectangle,
  StyledYearText,
} from './BarChartLabel.styles';

export interface BarChartLabelProps {
  title: string;
  value: number | number[];
  temperatureLabel: string;
  precipitationLabel: string;
  barColor: string;
  style?: CSSProperties
  years?: string[];
  titleColor: string;
  isPdfView?: boolean;
  id?: number;
}

function BarChartLabel({
  title,
  temperatureLabel,
  precipitationLabel,
  years,
  barColor,
  style,
  titleColor,
  isPdfView = false
}: Readonly<BarChartLabelProps>): JSX.Element {
  return (
    <Container style={style} isPdfView={isPdfView}>
      <StyledTitle color={titleColor} isPdfView={isPdfView}>{title}</StyledTitle>

      <TextContainer>
        <StyledTemperatureContainer isPdfView={isPdfView}>
          <StyledIconThermostat isPdfView={isPdfView}/>
          <div>{temperatureLabel}</div>
        </StyledTemperatureContainer>
        <StyledPrecipitationContainer isPdfView={isPdfView}>
          <StyledIconDropletSize isPdfView={isPdfView}/>
          <div>{precipitationLabel}</div>
        </StyledPrecipitationContainer>
      </TextContainer>

      {(years && years.length > 0) && (
        <StyledYearsContainer>
          <StyledYearRectangle color={barColor} />
          <StyledYearText isPdfView={isPdfView}>
            {years?.join(', ')}
          </StyledYearText>
        </StyledYearsContainer>
      )}
    </Container>
  );
}

export default BarChartLabel;
