import { useCallback, useEffect, useState, useMemo } from 'react';
import { useTranslation } from 'react-i18next';

import {
  StyledDrawer,
  ParagraphStyled,
  WeightingsContainerStyled,
  AgronomicSliderContainerStyled,
} from './AgronomicWeightingsDrawer.styles';

import AgronomicSlider from 'components/AgronomicWeightingsSlider/AgronomicWeightingsSlider';
import { AgronomicWeighting } from 'context/store/recommendationFormReducer';
import ButtonBase, { EButtonType } from 'components/Buttons/ButtonBase';
import { useBreakpoint } from 'hooks';
import { Attribute } from 'base/types/Attributes';
import { useAppState } from '../../context/AppState';

interface IProps {
  showAgronomicWeightings: boolean;
  mobileActiveTab?: 'Map' | 'List';
  agronomicWeightings: AgronomicWeighting[];
  attributes: Attribute[];
  handleSetShowAgronomicWeightings?: (showAgronomicWeightings: boolean) => void;
  handleFillAgronomicWeightings: (agronomicWeighting: AgronomicWeighting[]) => void;
}

const arrayToObject = (mapKey: string, array = []) => {
  return array.reduce(
    (accumulator, item: any) => ({ ...accumulator, [item[mapKey]]: { ...item } }),
    {}
  );
};

const AgronomicWeightingsDrawer = ({
  agronomicWeightings,
  attributes,
  mobileActiveTab,
  showAgronomicWeightings,
  handleSetShowAgronomicWeightings,
  handleFillAgronomicWeightings,
}: IProps): JSX.Element => {
  const {
    recommendationForm: { selectedCrop },
    flow: { isLongTermMobileDesign }
  } = useAppState();
  const [localSelectedCrop, setLocalSelectedCrop] = useState('');
  const [localWeightings, setLocalWeightings] = useState<AgronomicWeighting[]>([]);
  const [isApplyDisabled, setIsApplyDisabled] = useState(true);
  const { t } = useTranslation();
  const { isMobile } = useBreakpoint();

  useEffect(() => {
    if (!isMobile) {
      const handleOutsideClick = (e: any) => {
        const wrapper = document.getElementsByClassName('syt-antd-drawer-content-wrapper');
        if (wrapper.length === 1 && !wrapper[0].contains(e.target)) {
          handleSetShowAgronomicWeightings && handleSetShowAgronomicWeightings?.(false);
        }
        if (wrapper.length === 2 && !wrapper[1].contains(e.target)) {
          handleSetShowAgronomicWeightings && handleSetShowAgronomicWeightings?.(false);
        }
      };
      document.addEventListener('click', handleOutsideClick, true);
      return () => {
        document.removeEventListener('click', handleOutsideClick, true);
      };
    }
  }, [handleSetShowAgronomicWeightings, isMobile]);

  useEffect(() => {
    if (showAgronomicWeightings === false) {
      setIsApplyDisabled(true);
    }
  }, [showAgronomicWeightings]);

  useEffect(() => {
    agronomicWeightings && !localWeightings.length && setLocalWeightings(agronomicWeightings);
  }, [agronomicWeightings, localSelectedCrop, localWeightings, selectedCrop?.name]);

  useEffect(() => {
    if (selectedCrop?.name !== localSelectedCrop) {
      // cleanup the controls if selected crop changes
      setLocalSelectedCrop(selectedCrop?.name ?? '');
      setLocalWeightings([]);
    }
  }, [localSelectedCrop, selectedCrop?.name]);

  const localWeightingsMapById: { [key: number]: AgronomicWeighting } = useMemo(
    () => arrayToObject('id', localWeightings as never),
    [localWeightings]
  );

  const agronomicWeightingsMapById: { [key: number]: AgronomicWeighting } = useMemo(
    () => arrayToObject('id', agronomicWeightings as never),
    [agronomicWeightings]
  );

  const handleChange = useCallback(
    (id: number, attributeId: string, value: number, attributeName: string) => {
      const weighting: AgronomicWeighting = {
        id,
        attributeId,
        value,
        attributeName,
      };
      // Disable Apply Button if all weighting sliders are reset to zero.
      const canDisableApplyButton =
        localWeightings.filter((weight) => weight.value > 0).length === 1 && value === 0;
      setIsApplyDisabled(canDisableApplyButton);

      const weightingObj = localWeightings.find((w) => w.id === weighting.id);
      let newWeightings = [];
      if (!weightingObj) {
        newWeightings = [...localWeightings, weighting];
        setLocalWeightings(newWeightings);
      } else {
        newWeightings = localWeightings.reduce((acc, curr) => {
          if (curr.id === weighting.id) {
            return [...acc, weighting];
          } else {
            return [...acc, curr];
          }
        }, [] as AgronomicWeighting[]);
        setLocalWeightings(newWeightings);
      }
      if (isLongTermMobileDesign) {
        handleFillAgronomicWeightings?.(newWeightings);
      }
    },
    [handleFillAgronomicWeightings, isLongTermMobileDesign, localWeightings]
  );

  const handleCancel = useCallback(() => {
    if (agronomicWeightings.length === 0) {
      handleFillAgronomicWeightings([]);
    }
    handleSetShowAgronomicWeightings?.(false);
    setLocalWeightings(agronomicWeightings);
  }, [agronomicWeightings, handleFillAgronomicWeightings, handleSetShowAgronomicWeightings]);

  const handleApply = useCallback(() => {
    handleFillAgronomicWeightings(localWeightings);
    handleSetShowAgronomicWeightings?.(false);
  }, [
    localWeightings,
    handleFillAgronomicWeightings,
    handleSetShowAgronomicWeightings,
  ]);

  const handleAgronomicSliderChange = useCallback(
    (agronomicSliderValue: number, attribute: Attribute) => {
      handleChange(
        attribute.id,
        attribute.attributeId,
        agronomicSliderValue,
        attribute.attributeName
      );
    },
    [handleChange]
  );

  const renderAttributes = useCallback(
    () =>
      attributes.map((attribute) => {
        const attributeExtremaMin =
          !attribute.attributeMinWeight || attribute.attributeMinWeight === 0
            ? 0
            : attribute.attributeMinWeight;
        const attributeExtremaMax =
          !attribute.attributeMaxWeight || attribute.attributeMaxWeight === 0
            ? 10
            : attribute.attributeMaxWeight;
        const agronomicValue = localWeightingsMapById[attribute.id]?.value ?? 0;
        const agronomicDefaultValue = agronomicWeightingsMapById[attribute.id]?.value ?? 0;

        return (
          <AgronomicSliderContainerStyled
            key={attribute.attributeName}
            style={attribute.attributeId === 'yieldRanking' ? { display: 'none'} : {}} // Hide yieldRanking from ag. weighting list as we want to promote the use of max yield as default
          >
            <AgronomicSlider
              attributeId={attribute.id.toString()}
              attributeExtrema={{
                id: attribute.id,
                min: attributeExtremaMin,
                max: attributeExtremaMax,
                default: 0,
              }}
              active={false}
              attributeName={t(attribute.attributeName)}
              attributeOriginal={attribute.attributeOriginal ? t(attribute.attributeOriginal) : ''}
              value={agronomicValue}
              defaultValue={agronomicDefaultValue}
              onChange={(value) => handleAgronomicSliderChange(value, attribute)}
              id={`agroSlider-${attribute.id.toString()}`}
              isMobile={isMobile}
            />
          </AgronomicSliderContainerStyled>
        );
      }),
    [attributes, localWeightingsMapById, agronomicWeightingsMapById, t, isMobile, handleAgronomicSliderChange]
  );

  const isDrawerOpen = useMemo(() => {
    return isMobile
      ? mobileActiveTab === 'List' && showAgronomicWeightings
      : showAgronomicWeightings;
  }, [isMobile, mobileActiveTab, showAgronomicWeightings]);

  const drawerComponent = <StyledDrawer
    open={isDrawerOpen}
    title={t('Agronomic Weightings')}
    placement={isMobile ? 'bottom' : 'right'}
    width={550}
    onClose={handleCancel}
    maskClosable={false}
    getContainer={false}
    mask={!isMobile}
    closable={!isMobile}
    footer={<>
      <ButtonBase
        type={isMobile ? EButtonType.outlined : EButtonType.default}
        onClick={handleCancel}
        text={t('Cancel')}
        textSize={'MEDIUM'}
        fullWidth={isMobile} />
      <ButtonBase
        type={EButtonType.primary}
        isDisabled={isApplyDisabled}
        onClick={handleApply}
        text={t('Apply')}
        textSize="MEDIUM"
        fullWidth={isMobile} />
    </>}
  >
    <ParagraphStyled>{t('Agronomic Weightings description')}</ParagraphStyled>
    <WeightingsContainerStyled>{renderAttributes()}</WeightingsContainerStyled>
  </StyledDrawer>;

  const longTermMobileDesignAttributesComponent =
    <>{renderAttributes()}</>;

  return isLongTermMobileDesign ? longTermMobileDesignAttributesComponent : drawerComponent;
};

export default AgronomicWeightingsDrawer;
