import breakpoints from 'base/constants/breakpoints';
import styled from 'styled-components';
import { colors, Divider } from 'syngenta-digital-cropwise-react-ui-kit';

const StyledPopup = styled.div`
  width: 296px;
  z-index: 10;
  .mapboxgl-popup-content {
    background-color: ${colors.neutral00};
    padding: 12px;
    left: 124px !important;
    border-radius: 8px;
  }

  .mapboxgl-popup-tip {
    border-top-color: ${colors.neutral00};
  }
  @media screen and (${breakpoints.maxWidth.sm}) {
    width: 100%;
  }
`;

const FieldInfoSection = styled.div`
  display: flex;
  flex-direction: row;
`;

const SeedInfoSection = styled.div`
  margin-top: 5px;
  display: flex;
  flex-direction: row;
`;

const SeedImageSection = styled.div`
  display: flex;
  img {
    margin-top: 5px !important;
    width: 20px !important;
    height: 20px !important;
  }
`;

const ProductDiv = styled.div`
  display: flex;
  margin-left: 16px;
`;
const ProductSection = styled.div`
  padding: 4px 6px;
  border-radius: 4px;
  background-color: ${(props) => `${props.color}80`};
`;

const ProductText = styled.div`
  font-family: 'Noto Sans';
  font-weight: 800;
  color: ${(props) => `${props.color}`};
`;

const FieldDetailSection = styled.div`
  display: flex;
  flex-direction: column;
  margin-left: 7px;
`;

const SubTextSection = styled.div`
  display: flex;
  flex-direction: row;
  color: #4d5165;
`;

const HectareText = styled.div`
  font-size: 14px;
  font-weight: 400;
`;

const Text = styled.div`
  font-family: 'Noto Sans';
  font-weight: 600;
  font-size: 16px;
  line-height; 24px
  color: #2F3031;
`;

const StyledDivider = styled(Divider)`
  height: 1px;
  background-color: ${colors.neutral20};
  width: 100%;
  margin: 8px 0px;
`;

const FarmIconSection = styled.div`
  padding-top: 8px;
  img {
    width: 22px;
    height: 22px;
  }
`;

const SoilInfoSection = styled.div`
  margin-top: 3px;
  display: flex;
  flex-direction: column;
  padding: 11px;
  background-color: #f3f4f6;
  border-radius: 2px;
`;

const SoilHeaderText = styled.div`
  font-size: 14px;
  font-weight: 400;
  line-height: 24px;
  color: #696f88;
`;

const SoilDataSection = styled.div`
  margin-top: 5px;
  display: flex;
  flex-direction: row;
`;

const SoilColorDiv = styled.div`
  margin: 2px 10px 0 0;
  width: 16px;
  height: 16px;
  border-radius: 50%;
  background-color: ${(props) => props.color};
`;

const SoilText = styled.div`
  font-weight: 600;
  font-size: 14px;
  line-height: 20px;
  color: #4d5165;
`;

export {
  StyledPopup,
  FieldInfoSection,
  FieldDetailSection,
  Text,
  SubTextSection,
  HectareText,
  SeedInfoSection,
  SeedImageSection,
  ProductDiv,
  ProductSection,
  ProductText,
  StyledDivider,
  FarmIconSection,
  SoilInfoSection,
  SoilHeaderText,
  SoilDataSection,
  SoilColorDiv,
  SoilText,
};
