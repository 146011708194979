import { useState, useEffect } from 'react';
import { ERecommendationSections } from 'utils/constants/RecommendationSections';

interface IProps {
  isMobile: boolean;
  landscape: boolean;
  currentSection: typeof ERecommendationSections[keyof typeof ERecommendationSections];
}

enum ESectionByDevice {
  desktopOrMobile = 'desktopOrMobile',
  landscapeAllowed = 'landscapeAllowed',
  notAllowed = 'notAllowed',
}

const landscapeSectionAllowed = {
  // This defines what sections are allowed to be on landscape
  [ERecommendationSections.SECTION_HERO_BANNER]: false,
  [ERecommendationSections.SECTION_SOILS_CHARACTERISTICS]: true,
  [ERecommendationSections.SECTION_AG_PREFERENCES]: false,
  [ERecommendationSections.SECTION_AVG_WEATHER]: true,
  [ERecommendationSections.SECTION_ENV_STRESS]: true,
  [ERecommendationSections.SECTION_EXPERT_SEED_ADVICE]: false,
  [ERecommendationSections.SECTION_PRODUCT_COMPARISON]: false,
  [ERecommendationSections.SECTION_PRODUCT_PERFORMANCE]: true,
  [ERecommendationSections.SECTION_SEED_DENSITY_ADVICE]: true,
  [ERecommendationSections.SECTION_TRIALING_INFORMATION]: false,
};

const useSectionByDevice = ({ isMobile, landscape, currentSection }: IProps) => {
  const [currentSectionByDevice, setCurrentSectionByDevice] = useState<ESectionByDevice>(
    ESectionByDevice.desktopOrMobile
  );

  useEffect(() => {
    if ((isMobile && !landscape) || !isMobile) {
      setCurrentSectionByDevice(ESectionByDevice.desktopOrMobile);
    } else if (isMobile && landscape && landscapeSectionAllowed[currentSection]) {
      setCurrentSectionByDevice(ESectionByDevice.landscapeAllowed);
    } else {
      setCurrentSectionByDevice(ESectionByDevice.notAllowed);
    }
  }, [isMobile, landscape, currentSection]);

  return {
    currentSectionByDevice,
  };
};

export { useSectionByDevice, ESectionByDevice };
