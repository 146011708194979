import styled from 'styled-components';
import { Typography, colors } from 'syngenta-digital-cropwise-react-ui-kit';
import breakpoints from 'base/constants/breakpoints';
import { showSlidingAnimation } from 'utils/modalBoxAnimations';
import { IStyledModalProps } from 'base/types/ModalBox';

const StyledCard = styled.div<IStyledModalProps>`
  width: 424px;
  display: flex;
  flex-direction: row;
  z-index: 9999;
  position: fixed;
  border-radius: 4px;
  padding: 10px;
  box-shadow: 0 0 4px #c2c7d0;
  background-color: ${colors.neutral00};
  ${(props) => showSlidingAnimation(props.slideDirection)}
  right: 104px;
  top: 30px;
  @media only screen and ${breakpoints.maxWidth.sm} {
    width: 100vw;
    bottom: 0;
    right: auto;
    top: auto;
    padding-bottom: 12px;
  }
  .anticon {
    position: relative;
    left: 97%;
    top: 0;
  }
`;

const StyledTitle = styled(Typography.Title)`
  font-size: 16px;
  font-weight: 600;
`;

const StyledParagraph = styled(Typography.Paragraph)`
  font-size: 16px;
  color: ${colors.neutral100};
  font-style: normal;
  font-weight: 400;
  font-size: 14px;
  line-height: 20px;
  letter-spacing: -0.0075em;
`;

const StyledIcon = styled.div`
  margin-right: 14px;
  padding-top: 14px;
`;

const StyledTextContainer = styled.div`
  width: 328px;
  @media only screen and ${breakpoints.maxWidth.sm} {
    width: 74vw;
  }
  padding-top: 14px;
  .contact-detail-span {
    text-decoration: underline;
  }
`;

const StyledLink = styled.a`
  color: ${colors.blue60} !important;
  text-decoration: underline !important;
  font-weight: 400 !important;
`;

const StyledHr = styled.hr`
  background: rgb(223, 226, 231);
  height: 1px;
  border: 0px;
`;

const StyledLinkCookieSettings = styled.a`
  color: #696F88 !important;
  text-decoration: none;
  font-weight: 400 !important;
  font-size: 14px;
`;

export { StyledCard, StyledTitle, StyledParagraph, StyledIcon, StyledTextContainer, StyledLink, StyledHr, StyledLinkCookieSettings };
