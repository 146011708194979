import { SUPPORTED_LANGS } from 'base/constants/supportedLangs';
import { isQueryParameterPresent } from './urlSearchParams';

export const setLanguageAsperCountryCode = (
  isSupportedLanguage: boolean,
  country: string,
  i18n: any
): void => {
  const isUsersFirstVisit = sessionStorage.getItem('isUsersFirstVisit') !== 'true';
  if (!isUsersFirstVisit) {
    return;
  }

  sessionStorage.setItem('isUsersFirstVisit', 'true');

  const visitedFromCountryPage = isQueryParameterPresent('lang');
  if (visitedFromCountryPage) {
    return;
  }

  i18n.changeLanguage(isSupportedLanguage ? country : SUPPORTED_LANGS[0].value);
};
