import { OverlayContainer, StyledNotification, StyledSpinner } from './Spinner.styles';
import { useTranslation } from 'react-i18next';

const Spinner = (props: { className?: string; hideSpinner?: boolean }) => {
  const [t] = useTranslation();
  return (
    <OverlayContainer {...props}>
      {props.hideSpinner ? (
        <StyledNotification data-testid="detectLoader">{t('Detecting the fields')}</StyledNotification>
      ) : (
        <StyledSpinner data-testid="loaderSpinner"/>
      )}
    </OverlayContainer>
  );
};

export default Spinner;
