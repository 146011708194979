import { AppAction } from 'context/AppState';
import { RecommendationFormActions } from 'context/actions/recommendationFormActions';
import { MapsConstants } from 'utils/constants/MapsConstants';

import produce from 'immer';
import mapboxgl from 'mapbox-gl';
import { Feature, Polygon, Position } from '@turf/turf';
import { SoilType } from 'base/types/SoilType';
import { ProductSeed } from 'base/types/Products';
import { ICrop } from 'base/types/Crop';
import { WeatherResponse } from 'base/types/Weather';
import { SeedDensityField } from 'pages/RecommendationV2/Sections/SeedDensityAdvice';
import { UserContact } from 'base/types/RecommendationInput';

const MAX_HISTORY_ITEMS = 5;

export interface PinInformation {
  position?: mapboxgl.LngLat;
  positionHistory: mapboxgl.LngLat[];
  hectaresSize?: number;
}

export interface TempSoils {
  soils?: SoilType[];
  selectedSoils?: string[];
  fieldsResolution: number;
}

export type Field = {
  fieldName: string;
  hectares: string;
  boundary: Feature<Polygon>;
  id: string | number;
  soils?: SoilType[] | [];
  selectedSoils?: string[];
  weather?: WeatherResponse[] | [];
  fieldsResolution?: number;
  isSaved?: boolean;
  isDetectedField?: boolean;
  detectFielduniqueId?: string;
};

interface Geometry {
  type: string;
  coordinates: Position[][];
}
export type detectFieldFeature = {
  geometry: Geometry;
  id: string | number;
  properties: {
    fieldUniqueId: string;
    hectares: string;
    id: string | number;
  };
  type: string;
}
export interface AgronomicWeighting {
  id: number;
  attributeName: string;
  attributeId: string;
  value: number;
}

export interface DDLOptions {
  label: string;
  value: string;
  disabled?: boolean;
}

export interface AgronomicInputs {
  productMadurityRange: [number, number] | null;
  yieldRangeId: string;
  yieldRangeLabel: string;
  plantingDate: moment.Moment | null;
  harvestDate: moment.Moment | null;
  areFieldsIrrigated: boolean;
  agProducts: ProductSeed[];
  tillagePractice: string;
  rotationIntensity: string;
  herbicideTraitOptions: DDLOptions[];
  broomrapeOptions: DDLOptions[];
  selectedHerbicideTrait: DDLOptions[];
  selectedBroomrape: string[];
  isBroomrapeInfestation: boolean;
  selectedSegment: string[];
  adjustedProductMaturityRange: [number, number] | null;
  productCountBasedOnUserSelectedFAO: number;
}

export interface RecommendationFormState {
  countryCode?: string;
  countryRegion: string;
  rankingCrop?: string;
  agronomicInputs: AgronomicInputs;
  agronomicWeightings: AgronomicWeighting[];
  fields: Field[];
  tempBoundaryField?: Feature<Polygon> & TempSoils & { weather?: WeatherResponse[] };
  tempFields: Field[];
  fieldSnapshots: [number, number][][];
  fieldIdSelected: string;
  pin: PinInformation;
  isAtleastAVertex: boolean;
  enableFieldSaveButton?: boolean;
  isDeletingTempField?: boolean;
  fieldNameForm?: string;
  fieldNameSelected?: string;
  fieldSelected?: Field;
  focusFieldId: string;
  fieldIdToEdit?: string;
  fieldEditId?: string;
  currentFieldArea: string;
  currentFieldAreaMobile: string;
  previousFieldArea: string;
  editingFieldIndex?: number;
  tempField?: Field;
  sfdcId?: string;
  areNewBoundaryChages?: boolean;
  selectedCrop?: ICrop;
  voucherParams?: string;
  recommendationSeedsInfo?: SeedDensityField[];
  recommendationid: string;
  storedUserContact: UserContact | undefined;
  countryPhoneDialCode: string;
  fieldList: Field[];
  fieldToBeOpenedId?: string;
  previousFieldAreaMobile?: string;
}

export const recommendationFormInitialState: RecommendationFormState = {
  countryCode: '',
  countryRegion: '',
  rankingCrop: '',
  fields: [],
  tempBoundaryField: undefined,
  tempFields: [],
  fieldSnapshots: [],
  fieldIdSelected: '',
  pin: {
    positionHistory: [],
    position: new mapboxgl.LngLat(MapsConstants.LONGITUDE_DEFAULT, MapsConstants.LATITUDE_DEFAULT),
  },
  agronomicInputs: {
    productMadurityRange: null,
    yieldRangeId: '',
    yieldRangeLabel: '',
    plantingDate: null,
    harvestDate: null,
    areFieldsIrrigated: false,
    agProducts: [],
    rotationIntensity: 'Extensive',
    tillagePractice: 'Conventional/Ploughing',
    herbicideTraitOptions: [],
    broomrapeOptions: [],
    selectedHerbicideTrait: [],
    selectedBroomrape: [],
    isBroomrapeInfestation: false,
    selectedSegment: ['Linoleic', 'High oleic'],
    adjustedProductMaturityRange: null,
    productCountBasedOnUserSelectedFAO: -1, // this is a default value since 0 also has meaning keeping it as -1
  },
  isAtleastAVertex: false,
  enableFieldSaveButton: false,
  isDeletingTempField: false,
  agronomicWeightings: [],
  fieldNameForm: '',
  fieldNameSelected: '',
  fieldSelected: undefined,
  focusFieldId: '',
  fieldEditId: '',
  currentFieldArea: '',
  previousFieldArea: '',
  previousFieldAreaMobile: '',
  tempField: undefined,
  fieldList: [],
  editingFieldIndex: 0,
  sfdcId: '',
  areNewBoundaryChages: false,
  selectedCrop: undefined,
  currentFieldAreaMobile: '',
  voucherParams: '',
  recommendationSeedsInfo: [],
  recommendationid: '',
  storedUserContact: undefined,
  countryPhoneDialCode: '',
  fieldToBeOpenedId: '',
};

export const recommendationFormReducer = produce(
  (draft: RecommendationFormState, action: AppAction) => {
    const { payload = {} } = action;

    switch (action.type) {
      case RecommendationFormActions.setCountryCode:
        draft.countryCode = payload.countryCode;
        break;
      case RecommendationFormActions.setCountryRegion:
        draft.countryRegion = payload.countryRegion;
        break;
      case RecommendationFormActions.setPinPosition:
        draft.pin = {
          ...draft.pin,
          position: payload.lngLat,
        };
        break;
      case RecommendationFormActions.setPinHistory:
        draft.pin = {
          ...draft.pin,
          positionHistory: payload.history,
        };
        break;
      case RecommendationFormActions.addItemToHistory:
        draft.pin.positionHistory.unshift(action.payload as mapboxgl.LngLat);

        if (draft.pin.positionHistory.length > MAX_HISTORY_ITEMS) {
          draft.pin.positionHistory.length = MAX_HISTORY_ITEMS;
        }
        break;
      case RecommendationFormActions.setPinHectaresSize:
        draft.pin = {
          ...draft.pin,
          hectaresSize: payload.hectaresSize,
        };
        break;
      case RecommendationFormActions.saveField:
        draft.fields = [...draft.fields, { ...payload.field }];
        draft.areNewBoundaryChages = true;
        break;
      case RecommendationFormActions.saveFields:
        draft.fields = [...payload.fields];
        break;
      case RecommendationFormActions.setSelectedSoilsInFields:
        draft.fields = draft.fields.map((field) => {
          if (`${field.id}` === `${payload.fieldId}`) {
            return {
              ...field,
              selectedSoils: payload.selectedSoils,
              fieldsResolution: payload.fieldsResolution,
            };
          } else {
            return { ...field };
          }
        });
        break;
      case RecommendationFormActions.setSoilsInFields:
        draft.fields = draft.fields.map((field) => {
          if (`${field.id}` === `${payload.fieldId}`) {
            return { ...field, soils: payload.soils };
          } else {
            return { ...field };
          }
        });
        break;
      case RecommendationFormActions.setWeatherInField:
        draft.fields = draft.fields.map((field) => {
          if (`${field.id}` === `${payload.fieldId}`) {
            return { ...field, weather: payload.weather };
          } else {
            return { ...field };
          }
        });
        break;
      case RecommendationFormActions.setTempFields:
        draft.tempFields = [...payload.fields];
        draft.areNewBoundaryChages = true;
        break;
      case RecommendationFormActions.setFieldSnapshots:
        draft.fieldSnapshots = [...payload.snapshots];
        break;
      case RecommendationFormActions.setFieldIdSelected:
        draft.fieldIdSelected = payload.fieldId;
        break;
      case RecommendationFormActions.deleteField:
        draft.fields = [...draft.fields].filter(
          (field) => field.id.toString() !== payload.fieldId.toString()
        );
        draft.areNewBoundaryChages = true;
        break;
      case RecommendationFormActions.setTempBoundaryField:
        draft.tempBoundaryField = payload.boundary;
        break;
      case RecommendationFormActions.setFieldList:
        draft.fieldList = payload.fieldList;
        break;
      case RecommendationFormActions.setAreFieldsIrrigated:
        draft.agronomicInputs = {
          ...draft.agronomicInputs,
          areFieldsIrrigated: payload.areFieldsIrrigated,
        };
        break;
      case RecommendationFormActions.setHarvestDate:
        draft.agronomicInputs = {
          ...draft.agronomicInputs,
          harvestDate: payload.harvestDate,
        };
        break;
      case RecommendationFormActions.setRotationIntensity:
        draft.agronomicInputs = {
          ...draft.agronomicInputs,
          rotationIntensity: payload.rotationIntensity,
        };
        break;
      case RecommendationFormActions.setTillagePractice:
        draft.agronomicInputs = {
          ...draft.agronomicInputs,
          tillagePractice: payload.tillagePractice,
        };
        break;
      case RecommendationFormActions.setHerbicideTraitOptions:
        draft.agronomicInputs = {
          ...draft.agronomicInputs,
          herbicideTraitOptions: payload.herbicideTraitOptions,
        };
        break;
      case RecommendationFormActions.setSelectedHerbicideTrait:
        draft.agronomicInputs = {
          ...draft.agronomicInputs,
          selectedHerbicideTrait: payload.selectedHerbicideTrait,
        };
        break;
      case RecommendationFormActions.setBroomrapeOptions:
        draft.agronomicInputs = {
          ...draft.agronomicInputs,
          broomrapeOptions: payload.broomrapeOptions,
        };
        break;
      case RecommendationFormActions.setSelectedBroomrape:
        draft.agronomicInputs = {
          ...draft.agronomicInputs,
          selectedBroomrape: payload.selectedBroomrape,
        };
        break;
      case RecommendationFormActions.setIsBroomrapeInfestation:
        draft.agronomicInputs = {
          ...draft.agronomicInputs,
          isBroomrapeInfestation: payload.isBroomrapeInfestation,
        };
        break;
      case RecommendationFormActions.setSelectedSegment:
        draft.agronomicInputs = {
          ...draft.agronomicInputs,
          selectedSegment: payload.selectedSegment,
        };
        break;
      case RecommendationFormActions.resetAGInputs:
        draft.agronomicInputs = {
          ...draft.agronomicInputs,
          plantingDate: null,
          selectedHerbicideTrait: [],
          selectedBroomrape: [],
          isBroomrapeInfestation: false,
          selectedSegment: ['Linoleic', 'High oleic'],
        };
        break;
      case RecommendationFormActions.setPlantingDate:
        draft.agronomicInputs = {
          ...draft.agronomicInputs,
          plantingDate: payload.plantingDate,
        };
        break;
      case RecommendationFormActions.setYieldRangeId:
        draft.agronomicInputs = {
          ...draft.agronomicInputs,
          yieldRangeId: payload.yieldRangeId,
        };
        break;
      case RecommendationFormActions.setAGProducts:
        draft.agronomicInputs = {
          ...draft.agronomicInputs,
          agProducts: payload.agProducts,
        };
        break;
      case RecommendationFormActions.setYieldRangeLabel:
        draft.agronomicInputs = {
          ...draft.agronomicInputs,
          yieldRangeLabel: payload.yieldRangeLabel,
        };
        break;
      case RecommendationFormActions.setIsAtleastAVertex:
        draft.isAtleastAVertex = payload.isAtleastAVertex;
        break;
      case RecommendationFormActions.setEnableFieldSaveButton:
        draft.enableFieldSaveButton = payload.enableFieldSaveButton;
        break;
      case RecommendationFormActions.setIsDeletingTempField:
        draft.isDeletingTempField = payload.isDeletingTempField;
        break;
      case RecommendationFormActions.setProductMadurityRange:
        draft.agronomicInputs = {
          ...draft.agronomicInputs,
          productMadurityRange: payload.productMadurityRange,
        };
        break;
      case RecommendationFormActions.setAdjustedProductMaturityRange:
        draft.agronomicInputs = {
          ...draft.agronomicInputs,
          adjustedProductMaturityRange: payload.adjustedProductMaturityRange,
        };
        break;
      case RecommendationFormActions.setProductCountBasedOnUserSelectedFAO:
        draft.agronomicInputs = {
          ...draft.agronomicInputs,
          productCountBasedOnUserSelectedFAO: payload.productCountBasedOnUserSelectedFAO,
        };
        break;
      case RecommendationFormActions.setAgronomicWeightings:
        {
          const weightingPayload = payload.agronomicWeighting as AgronomicWeighting;

          const weighting = draft.agronomicWeightings.find((w) => w.id === weightingPayload.id);
          if (!weighting) {
            draft.agronomicWeightings.push(weightingPayload);
          } else {
            draft.agronomicWeightings = draft.agronomicWeightings.reduce((acc, curr) => {
              if (curr.id === weightingPayload.id) {
                return [...acc, weightingPayload];
              } else {
                return [...acc, curr];
              }
            }, [] as AgronomicWeighting[]);
          }
        }
        break;
      case RecommendationFormActions.fillAgronomicWeightings:
        draft.agronomicWeightings = payload.agronomicWeighting;
        break;
      case RecommendationFormActions.setRankingCrop:
        draft.rankingCrop = payload.rankingCrop;
        break;
      case RecommendationFormActions.setFieldNameForm:
        draft.fieldNameForm = payload.fieldNameForm;
        break;
      case RecommendationFormActions.setFieldNameSelected:
        draft.fieldNameSelected = payload.fieldNameSelected;
        break;
      case RecommendationFormActions.deleteLastField:
        draft.fields = draft.fields.slice(0, -1);
        break;
      case RecommendationFormActions.setFieldSelected:
        draft.fieldSelected = payload.field;
        break;
      case RecommendationFormActions.setFocusFieldId:
        draft.focusFieldId = payload.fieldId;
        break;
      case RecommendationFormActions.setFieldIdToEdit:
        draft.fieldIdToEdit = payload.fieldId;
        break;
      case RecommendationFormActions.setFieldEditId:
        draft.fieldEditId = payload.fieldId;
        break;
      case RecommendationFormActions.setCurrentFieldArea:
        draft.currentFieldArea = payload.currentFieldArea;
        break;
      case RecommendationFormActions.setPreviousFieldArea:
        draft.previousFieldArea = payload.previousFieldArea;
        break;
      case RecommendationFormActions.setPreviousFieldAreaMobile:
        draft.previousFieldAreaMobile = payload.previousFieldAreaMobile;
        break;
      case RecommendationFormActions.setCurrentFieldAreaMobile:
        draft.currentFieldAreaMobile = payload.currentFieldAreaMobile;
        break;
      case RecommendationFormActions.setFieldSelectedById: {
        const fieldSelected = draft.fields.find(
          (field) => field.id.toString() === payload.fieldId.toString()
        );
        draft.fieldSelected = fieldSelected;
        break;
      }
      case RecommendationFormActions.setEditingFieldIndex:
        draft.editingFieldIndex = payload.index;
        break;
      case RecommendationFormActions.setFieldToBeOpenedId:
        draft.fieldToBeOpenedId = payload.fieldId;
        break;
      case RecommendationFormActions.setSfdcId:
        draft.sfdcId = payload.sfdcId;
        break;
      case RecommendationFormActions.setAreNewBoundaryChages:
        draft.areNewBoundaryChages = payload.areNewBoundaryChages;
        break;
      case RecommendationFormActions.setSelectedCrop:
        draft.selectedCrop = payload.crop;
        break;
      case RecommendationFormActions.setVoucherParams:
        draft.voucherParams = payload.voucherParams;
        break;
      case RecommendationFormActions.setRecommendationSeedsInfo:
        draft.recommendationSeedsInfo = payload.recommendationSeedsInfo;
        break;
      case RecommendationFormActions.setRecommendationId:
        draft.recommendationid = payload.recommendationId;
        break;
      case RecommendationFormActions.setStoredUserContact:
        draft.storedUserContact = payload.storedUserContact;
        break;
      case RecommendationFormActions.setCountryPhoneDialCode:
        draft.countryPhoneDialCode = payload.countryPhoneDialCode;
        break;
      default:
        return draft;
    }
  }
);
