import { SVGProps } from 'react';

const IconRotatePhone = (props: SVGProps<SVGSVGElement>) => {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width="24"
      height="24"
      fill="none"
      viewBox="0 0 24 24"
      {...props}
    >
      <path
        fill="#696F88"
        fillRule="evenodd"
        d="M22.45 11c-.36-3.76-2.7-6.93-5.97-8.48l-1.33 1.32L11.34.03 12 0c6.29 0 11.44 4.84 11.95 11h-1.5zM8.11 1.751a1.49 1.49 0 012.12 0l12.02 12.02c.59.58.59 1.53 0 2.12l-6.36 6.36c-.58.59-1.53.59-2.12 0L1.75 10.231a1.49 1.49 0 010-2.12l6.36-6.36zm6.72 19.438L2.81 9.169l6.36-6.36 12.02 12.02-6.36 6.36zM1.55 13c.36 3.76 2.7 6.94 5.97 8.48l1.33-1.32 3.81 3.81L12 24C5.71 24 .56 19.16.05 13h1.5z"
        clipRule="evenodd"
      />
    </svg>
  );
};

export default IconRotatePhone;
