import { SoilType } from 'base/types/SoilType';
import { STD_SOIL_COLOR_GROUPS } from 'utils/constants/SoilColors';

export const getUniqueSoils = ({ soils = [] }: { soils: SoilType[] }): string[] => {
  // remove duplicated soil types
  const uniqueSoils = soils
    .map((item) => `${item.texture} ${item.texture_raw}`)
    .filter((value, index, self) => self.indexOf(value) === index);
  return uniqueSoils;
};

export const getStdSoilColor = (soilEntries: string[], soilName: string): string => {
  const maxColorGroups = Object.keys(STD_SOIL_COLOR_GROUPS).length;
  const singleColorGroupIndex = Math.min(soilEntries.length, maxColorGroups);
  const soilIndex = soilEntries.indexOf(soilName);
  const color = STD_SOIL_COLOR_GROUPS[singleColorGroupIndex][soilIndex];
  return color;
};
