import styled, { css } from 'styled-components';
import { Radio, Divider } from 'syngenta-digital-cropwise-react-ui-kit';

export const MainSecion = styled.div`
  display: flex;
  width: 100%;
  flex-direction: column;
`;

export const FieldSection = styled.div`
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  margin-bottom: 5px;
`;

export const FieldText = styled.div`
  font-weight: 400;
  font-size: 16px;
  color: #ffffff;
  line-height: 20px;
  padding-top: 2px;
  margin-left: 10px;
`;

export const Title = styled.div`
  display: flex;
  width: 100%;
  justify-content: center;
  color: #ffffff;
  font-size: 20px;
  font-weight: 600;
`;

export const FieldSelectionSection = styled.div`
  display: flex;
  width: 100%;
  flex-direction: column;
`;

export const FieldLeftSection = styled.div`
  display: flex;
  margin-left: 8px;
`;

export const FieldRightSection = styled.div`
  display: flex;
`;

export const TooltipRadio = styled(Radio)`
  margin-left: 15px;

  .syt-antd-radio-inner::after {
    top: 50%;
    left: 50%;
  }
`;
const sharedStyles = css`
  border-radius: 8px;
  width: 380px;
  background-color: #232630;
  display: flex;
  justify-content: left;
  flex-direction: column;
  max-height: 84px;
  align-items: flex-start;
  overflow-y: auto;
  ::-webkit-scrollbar {
    display: none;
  }
`;

export const FieldInfoCardProduct = styled.div`
  padding: 0 8px 8px 8px;
  ${sharedStyles}
`;

export const SoilInfoCardProduct = styled.div`
  padding: 8px 8px 0px 8px;
  ${sharedStyles}
`;

export const FieldSectionBottom = styled.div`
  display: flex;
  margin-top: 2px;
`;

export const StyledDivider = styled(Divider)`
  height: 1px;
  background-color: #4d5165;
  width: 100%;
`;
