/* eslint-disable array-bracket-newline */
import { useState, useMemo, useCallback, useEffect } from 'react';

import { ModeTypes } from 'context/store/flowReducer';

interface IProps {
  currentModeType: ModeTypes;
}

const useValidation = ({ currentModeType }: IProps) => {
  const [showMarkers, setShowMarkers] = useState(true);
  const [showBoundaryLayer, setShowBoundaryLayer] = useState(false);
  const [showFieldsLayer] = useState(true);

  const validateConfirmingMode = useCallback(() => {
    setShowMarkers(false);
    setShowBoundaryLayer(true);
  }, []);

  const validateCreatingMode = useCallback(() => {
    setShowMarkers(true);
    setShowBoundaryLayer(true);
  }, []);

  const validateNoActionMode = useCallback(() => {
    setShowMarkers(false);
    setShowBoundaryLayer(false);
  }, []);

  const validateEditingMode = useCallback(() => {
    setShowMarkers(true);
    setShowBoundaryLayer(true);
  }, []);

  const validationModeTypeMap = useMemo(
    (): { [key in ModeTypes]: () => void | undefined } => ({
      [ModeTypes.CREATING]: validateCreatingMode,
      [ModeTypes.EDITING]: validateEditingMode,
      [ModeTypes.SAVING]: () => undefined,
      [ModeTypes.CANCELLING]: () => undefined,
      [ModeTypes.NO_ACTION]: validateNoActionMode,
      [ModeTypes.CONFIRMING_AFTER_EDIT]: validateConfirmingMode,
      [ModeTypes.DELETING]: () => undefined,
    }),
    [validateCreatingMode, validateEditingMode, validateNoActionMode, validateConfirmingMode]
  );

  useEffect(() => {
    validationModeTypeMap[currentModeType]();
  }, [currentModeType, validationModeTypeMap]);

  return {
    showMarkers,
    showBoundaryLayer,
    showFieldsLayer,
  };
};

export default useValidation;
