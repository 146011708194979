import { useSwipeable, SwipeableHandlers } from 'react-swipeable';
import { isAndroid, isIOS } from 'react-device-detect';

type Direction = 'Up' | 'Down' | 'Left' | 'Right';

export const useSwipeAction = (directions: Direction[], callback?: () => void): SwipeableHandlers => {
  const handlers = useSwipeable({
    onSwiped: (eventData) => {
      if (callback &&
        directions.includes(eventData.dir as Direction) &&
          ((eventData.dir === 'Down' && isIOS) ||
          (eventData.dir === 'Down' && isAndroid))) {
        callback();
      }
    },
  });

  return handlers;
};
