import { SVGProps } from 'react';

import { colors } from '../../theme/colors';

const IconPlus = ({
  height = '18px',
  width = '18px',
  color = colors.neutral60,
  ...props
}: SVGProps<SVGSVGElement>) => (
  <svg
    width={width}
    height={height}
    viewBox="0 0 18 18"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
    {...props}
  >
    <path fillRule="evenodd" clipRule="evenodd" d="M14 8H8V14H6V8H0V6H6V0H8V6H14V8Z" fill={color} />
  </svg>
);

export default IconPlus;
