import styled from 'styled-components';
import { Button, Typography } from 'syngenta-digital-cropwise-react-ui-kit';

export const Root = styled.div`
  display: flex;
  flex: 1;
  flex-direction: column;
  background-color: white;
  align-items: center;
  overflow: auto;
  z-index: 99999;
`;

export const Content = styled.div`
  display: flex;
  max-width: 560px;
  flex-direction: column;
  align-items: center;
  padding: 30px;
`;

export const Title = styled(Typography.Text)`
  font-size: 38px;
  line-height: 40px;
  font-weight: 600;
  text-align: center;
  margin-top: 45px;
  margin-bottom: 45px;
`;

export const Message = styled(Typography.Text)`
  font-size: 16px;
`;

export const Image = styled.img`
  width: 100%;
  max-width: 535px;
  margin-bottom: 36px;
`;

export const ButtonStyled = styled(Button)`
  margin-top: 56px;
  width: 100%;
  max-width: 373px;
`;
