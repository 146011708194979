import React, { Dispatch, SetStateAction, useState } from 'react';
import { ModalAnimationDirections } from 'base/types/ModalBox';
import SendRecommendationImage from 'assets/images/illustration_email.svg';

import {
  StyledUpdatePhoneForSMSWebModal,
  StyledContent,
  StyledUpdatePhoneForSMSMobileModal,
  StyledPrimaryButtonRow,
  StyledPhoneNumberWrapper,
  ButtonStyled,
  StyledSecondaryButtonRow,
} from './UpdatePhoneForSMSModal.styles';
import PhoneNumberWrapper from 'containers/PhoneNumberWrapper';
import { getCountryPostalCode } from 'utils/helpers/geospatial';
import { useAppState } from 'context/AppState';
import { t } from 'i18next';
import { EButtonType } from 'components/Buttons/ButtonBase';

interface UpdatePhoneForSMSModalProps {
  setShowSendSMSModal: Dispatch<SetStateAction<boolean>>;
  onClickSendRecommendationViaSMSBtn: (phoneNumber: string) => void;
  title: string;
  isMobile: boolean;
}

const UpdatePhoneForSMSModal: React.FC<UpdatePhoneForSMSModalProps> = ({
  setShowSendSMSModal,
  onClickSendRecommendationViaSMSBtn,
  title,
  isMobile,
}: UpdatePhoneForSMSModalProps) => {
  const [slidingDirection, setSlidingDirection] = useState<ModalAnimationDirections | undefined>(isMobile ? ModalAnimationDirections.Up : undefined);

  const {
    recommendationForm: {
      storedUserContact,
      countryCode,
    }} = useAppState();
  const [hasInputErrors, setHasInputErrors] = useState({
    name: false,
    email: false,
    phoneNumber: false,
    lastName: false,
  });
  const [phoneNumber, setPhoneNumber] = useState<string | null>(
    storedUserContact?.phoneNumber ?? null
  );
  const postalCode: string = getCountryPostalCode(countryCode);
  const isSendRecommendationBtnDisabled =
    !phoneNumber ||
    Object.values(hasInputErrors).some((value) => value);

  const handleClickOverlay = () => {
    setSlidingDirection(ModalAnimationDirections.Down);
    const timeOutId = setTimeout(() => {
      setShowSendSMSModal(false);
      clearTimeout(timeOutId);
    }, 400);
  };

  return (
    <>
      {isMobile ? (
        <StyledUpdatePhoneForSMSMobileModal
          data-testid="update-phone-sms-modal-mobile"
          isClosable={true}
          isViewFooter
          title={title}
          slideDirection={slidingDirection}
          content={
            <StyledContent>
              <img src={SendRecommendationImage} width="400" height="165" alt="" />
              <StyledPrimaryButtonRow>
                <StyledPhoneNumberWrapper>
                  <PhoneNumberWrapper
                    label={t('Phone number')}
                    isRequired={true}
                    inputName="phoneNumber"
                    requiredFailMessage={t('Phone number is required')}
                    regexFailMessage={t(
                      'Phone number cannot contain more than 15 digits or less than 7 digits'
                    )}
                    inputValue={phoneNumber}
                    setInputValue={setPhoneNumber}
                    setHasErrorsForParent={setHasInputErrors}
                    country={postalCode}
                  />
                </StyledPhoneNumberWrapper>
              </StyledPrimaryButtonRow>
            </StyledContent>
          }
          onClickOverlay={handleClickOverlay}
          onCancel={handleClickOverlay}
          footer={
            <StyledSecondaryButtonRow>
              <ButtonStyled
                type={EButtonType.primary}
                onClick={() => onClickSendRecommendationViaSMSBtn(phoneNumber ?? '')}
                isDisabled={isSendRecommendationBtnDisabled}
                text={t('Send Recommendation')}
              />
            </StyledSecondaryButtonRow>
          }
        />
      ) : (
        <StyledUpdatePhoneForSMSWebModal
          data-testid="update-phone-sms-web-modal"
          open
          centered
          title={title}
          width={495}
          footer={null}
          maskClosable={false}
          onCancel={() => setShowSendSMSModal(false)}
        >
          <img src={SendRecommendationImage} width="400" height="165" alt="" />
          <StyledPrimaryButtonRow>
            <StyledPhoneNumberWrapper>
              <PhoneNumberWrapper
                label={t('Phone number')}
                isRequired={true}
                inputName="phoneNumber"
                requiredFailMessage={t('Phone number is required')}
                regexFailMessage={t(
                  'Phone number cannot contain more than 15 digits or less than 7 digits'
                )}
                inputValue={phoneNumber}
                setInputValue={setPhoneNumber}
                setHasErrorsForParent={setHasInputErrors}
                country={postalCode}
              />
            </StyledPhoneNumberWrapper>
          </StyledPrimaryButtonRow>
          <StyledSecondaryButtonRow>
            <ButtonStyled
              type={EButtonType.primary}
              onClick={() => onClickSendRecommendationViaSMSBtn(phoneNumber ?? '')}
              isDisabled={isSendRecommendationBtnDisabled}
              text={t('Send Recommendation')}
            />
          </StyledSecondaryButtonRow>
        </StyledUpdatePhoneForSMSWebModal>
      )}
    </>
  );
};

export default UpdatePhoneForSMSModal;
