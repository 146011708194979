export const colors = {
  neutral00: '#FFFFFF',
  neutral10: '#F3F4F6',
  neutral20: '#DFE2E7',
  neutral30: '#C2C7D0',
  neutral60: '#696F88',
  neutral70: '#4D5165',
  neutral90: '#232630',
  neutral100: '#14151C',

  blue10: '#EAF6FF',
  blue50: '#0092E4',
  red50: '#EB4B4B',

  green40: '#5EBB7F',
  green50: '#19A04B',
  green60: '#14803C',

  syngentaNavy: '#00004B',
};
