import { useState } from 'react';
import { ButtonStyled, ProductsContainer } from './ProductsTrialed.styles';
import { TrialProduct } from 'base/types/RecommendationCropwise';
import { useBreakpoint } from 'hooks';

interface ProductsTrialedProps {
  id?: string;
  products?: TrialProduct[];
  onClick?: (index: number) => void;
}

const ProductsTrialed = ({ id, products = [], onClick }: ProductsTrialedProps): JSX.Element => {
  const [indexActive, setIndexActive] = useState(0);
  const { isMobile } = useBreakpoint();

  const onClickHandler = (index: number) => {
    setIndexActive(index);
    onClick?.(index);
  };

  return (
    <ProductsContainer data-testid='productButtons' id={id} isMobile={isMobile}>
      {products.map((product, index) => (
        <ButtonStyled
          key={`${product.commercialName}-${index}`}
          text={product.commercialName}
          className={index === indexActive ? 'active' : ''}
          onClick={() => onClickHandler(index)}
        />
      ))}
    </ProductsContainer>
  );
};

export default ProductsTrialed;
