import styled from 'styled-components';

export const SearchSection = styled.div`
  display: flex;
  width: 100%;
  padding: 6px 10px;
  flex-direction: row;
  background-color: #232630;
  border-radius: 4px;
  margin-bottom: 2px;
`;

export const SearchIcon = styled.div`
  margin-right: 1px;
`;

export const StyledInput = styled.input`
  width: 100%;
  border: none;
  color: #868ca2;
  background-color: #232630;
  &:focus {
    outline: none;
    border: none;
  }
`;

export const FieldSection = styled.div`
  width: 100%;
  padding: 6px 10px;
  display: flex;
  cursor: pointer;
  border-radius: 4px;
  flex-direction: row;
  justify-content: space-between;
  font-weight: 400;
  font-size: 14px;
  line-height: 24px;
  color: #ffffff;
  &:hover {
    background-color: #232630;
  }
`;

export const FieldNameSection = styled.div`
  max-width: 50%;
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
`;

export const FieldSizeSection = styled.div`
  display: flex;
  flex-direction: row;
`;

export const SelectedFieldSection = styled.div`
  display: flex;
  flex-direction: row;
  width: 100%;
  padding: 8px 12px;
  font-weight: 600;
  font-size: 15px;
  line-height: 24px;
  color: #82CFFF;
  justify-content: space-between;
  .selected-farm-size {
   margin-right: 20px;
  }
`;
