/* eslint-disable array-bracket-newline */
import { useCallback, useMemo, useState, useEffect } from 'react';

import { FlowSteps } from 'context/store/flowReducer';
import { ICrop } from 'base/types/Crop';
import { ProductSeed } from 'base/types/Products';
import { CropConstants } from 'utils/constants/Crop';
import { trackStepTwoEditingStarted } from 'utils/helpers/amplitude';
import { useBreakpoint } from 'hooks';
import { t } from 'i18next';
import { DDLOptions } from 'context/store/recommendationFormReducer';

interface IProps {
  currentStep: FlowSteps;
  plantingDate: moment.Moment | null;
  yieldRangeId: string;
  agProducts: ProductSeed[];
  selectedCrop?: ICrop;
  isPinInfoValid: boolean;
  rotationIntensity: string;
  tillagePractice: string;
  selectedHerbicideTrait: DDLOptions[] | [];
  selectedSegment: string[] | [];
  isBroomrapeInfestation: boolean;
  selectedBroomrape: string[];
  isAnotherRecommendationCreated: boolean;
}

const useValidationSteps = ({
  currentStep,
  agProducts,
  plantingDate,
  selectedCrop,
  yieldRangeId,
  isPinInfoValid,
  rotationIntensity,
  tillagePractice,
  selectedHerbicideTrait,
  selectedSegment,
  isBroomrapeInfestation,
  selectedBroomrape,
  isAnotherRecommendationCreated,
}: IProps) => {
  const { isMobile } = useBreakpoint();

  const [disabledPrevBtn, setDisabledPrevBtn] = useState(false);
  const [disabledNextBtn, setDisabledNextBtn] = useState(false);
  const [nextButtonText, setNextButtonText] = useState(isMobile ? 'Save' : 'Next');
  const [backButtonText, setBackButtonText] = useState(isMobile ? 'Cancel' : 'Back');

  const validateFirstStep = useCallback(() => {
    setNextButtonText(isMobile ? 'Save' : 'Next');
    setBackButtonText(isMobile ? 'Cancel' : 'Back');
    setDisabledNextBtn(!isPinInfoValid);
    setDisabledPrevBtn(true);
  }, [isPinInfoValid]);

  const validateSecondStep = useCallback(() => {
    setNextButtonText('Finish');
    setBackButtonText('Back');
    setDisabledPrevBtn(isAnotherRecommendationCreated);
    if (selectedCrop?.id) {
      switch (selectedCrop?.id) {
        case CropConstants.CORN_CROP_ID:
        case CropConstants.SUMMER_CORN_CROP_ID:
          setDisabledNextBtn(Boolean(!plantingDate || !yieldRangeId || agProducts.length < 3 || !selectedCrop?.name));
          break;
        case CropConstants.SILAGE_CORN_CROP_ID:
          setDisabledNextBtn(Boolean(!plantingDate || !yieldRangeId || agProducts.length < 3 || !selectedCrop?.name));
          break;
        case CropConstants.SUNFLOWER_CROP_ID:
          if (isBroomrapeInfestation) {
            setDisabledNextBtn(Boolean(
              !rotationIntensity || !tillagePractice ||
              !(selectedBroomrape.length > 0) || !(selectedSegment.length > 0) ||
              !plantingDate || !yieldRangeId ||
              agProducts.length < 3 || !selectedCrop?.name));
          } else {
            setDisabledNextBtn(Boolean(
              !rotationIntensity || !tillagePractice ||
              !(selectedSegment.length > 0) || !plantingDate ||
              !yieldRangeId || agProducts.length < 3 || !selectedCrop?.name));
          }
          break;
        default:
          setDisabledNextBtn(true);
      }
    } else {
      setDisabledNextBtn(true);
    }

    trackStepTwoEditingStarted();
  }, [selectedCrop, plantingDate, yieldRangeId, agProducts, rotationIntensity, tillagePractice, selectedHerbicideTrait, selectedSegment,
    isBroomrapeInfestation, selectedBroomrape]);

  const previousStepMap = useMemo(
    (): { [key in FlowSteps]: FlowSteps | undefined } => ({
      [FlowSteps.STEP1]: undefined,
      [FlowSteps.STEP2]: FlowSteps.STEP1,
      [FlowSteps.STEP3]: FlowSteps.STEP2,
      [FlowSteps.STEP4]: undefined,
      [FlowSteps.empty]: undefined,
    }),
    []
  );

  const nextStepMap = useMemo(
    (): { [key in FlowSteps]: FlowSteps | undefined } => ({
      [FlowSteps.STEP1]: FlowSteps.STEP2,
      [FlowSteps.STEP2]: FlowSteps.STEP3,
      [FlowSteps.STEP3]: undefined,
      [FlowSteps.STEP4]: undefined,
      [FlowSteps.empty]: undefined,
    }),
    []
  );

  const validationStepMap = useMemo(
    (): { [key in FlowSteps]: () => void | undefined } => ({
      [FlowSteps.STEP1]: validateFirstStep,
      [FlowSteps.STEP2]: validateSecondStep,
      [FlowSteps.STEP3]: () => undefined,
      [FlowSteps.STEP4]: () => undefined,
      [FlowSteps.empty]: () => undefined,
    }),
    [validateFirstStep, validateSecondStep]
  );

  useEffect(() => {
    validationStepMap[currentStep]();
  }, [currentStep, validationStepMap]);

  return {
    nextStepMap,
    previousStepMap,
    disabledPrevBtn,
    disabledNextBtn,
    nextButtonText,
    backButtonText,
  };
};

export default useValidationSteps;
