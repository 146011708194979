import styled from 'styled-components';
import { colors, Slider, Typography, SYT_PREFIX } from 'syngenta-digital-cropwise-react-ui-kit';

const { Title } = Typography;

type SliderNoDotsStyledType = {
  isLongTermMobileDesign?: boolean;
}

const SliderContainerStyled = styled.div`
  height: '56px';
`;

const SliderNoDotsStyled = styled(Slider)<SliderNoDotsStyledType>`
  margin: 0 !important;

  .${SYT_PREFIX}-slider-dot {
    display: none;
  }

  .${SYT_PREFIX}-slider-mark {
    margin-top: 4px;
    display: flex;
    justify-content: space-between;
    pointer-events: none;
  }

  .${SYT_PREFIX}-slider-mark-text {
    color: ${colors.neutral50};
  }

  .${SYT_PREFIX}-slider-mark span {
    transform: none !important;
    left: 0 !important;
    position: relative !important;
    font-size: 14px;
    font-weight: 400;
    font-size: 14px;
    line-height: 20px;
    color: ${colors.neutral60};
  }

  .syt-antd-slider-rail, .syt-antd-slider-track {
    height: 6px;
  }

  .${SYT_PREFIX}-slider-handle {
    width: 24px;
    height: 24px;
    ${(props) => !props.isLongTermMobileDesign && `z-index: 1;`}
    top: -2px;
    ${(props) => props.isLongTermMobileDesign && `transform: translateX(0) !important;`}

    &::after {
      inset: -10px;
    }
  }
`;

const TitleStyled = styled(Title)`
  font-weight: 400;
  font-size: 14px;
  line-height: 16px;
  color: ${colors.neutral100};
  letter-spacing: -0.0075em;
  margin: 0;
  margin-bottom: 10px;
`;

export { SliderNoDotsStyled, TitleStyled, SliderContainerStyled };
