import IconNextArrow from 'components/Icons/IconNextArrow';
import styled from 'styled-components';
import { colors } from 'syngenta-digital-cropwise-react-ui-kit';

export const StyledIconHub = () => {
  return <IconNextArrow data-testid="detectFieldNextIcon" color={colors.green40} />;
};
export const StyledDetectFieldIcon = styled.div`
  margin: 0 10px 25px 0;
  & .detectFieldNextBtn {
    padding-left: 16px;
  }
`;
