import styled from 'styled-components';
import ModalView from 'components/ModalView';
import { showSlidingAnimation } from 'utils/modalBoxAnimations';
import { IStyledModalProps } from 'base/types/ModalBox';

const ContentContainerStyled = styled.div`
  text-align: center;
  font-family: Noto Sans;
  font-size: 14px;
  font-weight: 400;
  line-height: 22px;
  letter-spacing: -0.0075em;
`;

const StyledModal = styled(ModalView)<IStyledModalProps>`
  ${(props) => showSlidingAnimation(props.slideDirection)}
`;

export { ContentContainerStyled, StyledModal };
