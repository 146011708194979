import ModalView from 'components/ModalView';
import styled from 'styled-components';
import { colors, Modal, SYT_PREFIX, Typography } from 'syngenta-digital-cropwise-react-ui-kit';
import { showSlidingAnimation } from 'utils/modalBoxAnimations';
import { IStyledModalProps } from 'base/types/ModalBox';

export const StyledSmsSentModal = styled(Modal)`
  .${SYT_PREFIX}-modal-title {
    font-weight: 600;
    font-size: 16px;
    line-height: 24px;
    text-align: center;
  }

  .${SYT_PREFIX}-typography {
    font-size: 16px;
    line-height: 24px;
    color: ${colors.neutral70};
    margin: 0;
  }

  img {
    display: block;
    margin: auto;
    margin-bottom: 20px;
    width: 100%;
  }

  .modal-view-footer {
    display: none;
  }
`;

export const StyledContent = styled.div`
  display: flex;
  justify-content: center;
  flex-direction: column;
  align-items: center;
`;

export const StyledParagraph = styled(Typography.Paragraph)`
  text-align: center;
`;

export const StyledSmsSentModalMobile = styled(ModalView)<IStyledModalProps>`
  ${(props) => showSlidingAnimation(props.slideDirection)}
  top: auto !important;
  height: 315px !important;

  .${SYT_PREFIX}-modal-content {
    border-radius: 8px;
    justify-content: center;
  }
  img {
    margin-bottom: 12px;
  }
  .${SYT_PREFIX}-modal-header {
    border-radius: 8px;
  }

  @media screen and (max-height: 769px) {
    .${SYT_PREFIX}-modal-content {
      overflow: auto !important;
      justify-content: center;
    }
  }
`;
