import { AppStateProvider } from 'context/AppState';
import { ReactNode } from 'react';
import { I18nextProvider } from 'react-i18next';
import { AntdConfigProvider } from 'syngenta-digital-cropwise-react-ui-kit';

import i18next from 'i18n';
import { MapProvider } from 'react-map-gl';

export default function AppProviders(props: { children: ReactNode }) {
  const { children } = props;
  return (
    <I18nextProvider i18n={i18next}>
      <AntdConfigProvider>
        <MapProvider>
          <AppStateProvider>{children}</AppStateProvider>
        </MapProvider>
      </AntdConfigProvider>
    </I18nextProvider>
  );
}
