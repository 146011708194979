import IconCorn from 'components/Icons/IconCorn';
import IconHybridBarley from 'components/Icons/IconHybridBarley';
import IconSunflower from 'components/Icons/IconSunflower';
import IconSilageCorn from 'components/Icons/IconSilageCorn';
import IconWheat from 'components/Icons/IconWheat';
import IconRye from 'components/Icons/IconRye';
import IconSoyabean from 'components/Icons/IconSoyabean';

export type CropTypeIconsType = {
  [key: string]: () => JSX.Element;
};

const CropTypeIcons: CropTypeIconsType = {
  corn: () => <IconCorn width={22} height={32} />,
  'summer corn': () => <IconCorn width={22} height={32} />,
  barley: () => <IconHybridBarley width={18} height={36} />,
  sunflower: () => <IconSunflower width={31} height={31} />,
  'silage corn': () => <IconSilageCorn width={40} height={40} />,
  wheat: () => <IconWheat width={19} height={36} />,
  rye: () => <IconRye width={18} height={36} />,
  soybeans: () => <IconSoyabean width={36} height={36} />,
};

export default CropTypeIcons;
