import styled from 'styled-components';

interface MobileProps {
  isMobile: boolean;
}
export const VoucherContainer = styled.div<MobileProps>`
  background-color: #00004b;
  margin-bottom: ${({ isMobile }) => isMobile && '-1px'};
`;
export const SectionContainer = styled.div<MobileProps>`
  background: #eaf6ff;
  color: #000;
  border-radius: 8px;
  font-size: 16px;
  line-height: 24px;
  padding: ${({ isMobile }) => (isMobile ? '20px' : '32px')};
  margin: ${({ isMobile }) => (isMobile ? '24px' : '48px')};
  margin-bottom: 0px !important;
  display: flex;
  flex-direction: ${({ isMobile }) => (isMobile ? 'column' : 'row')};
  justify-content: space-between;
  align-items: center;
  column-gap: 10%;
`;

export const Subtitle = styled('div')`
  padding-top: 16px;
`;

export const Title = styled.div`
  font-weight: 600;
  font-size: 24px;
  line-height: 40px;
`;

export const DescriptionContainer = styled.div<MobileProps>`
  width: ${({ isMobile }) => (isMobile ? '100%' : '65%')};
`;

export const ButtonContainer = styled.div<MobileProps>`
  width: ${({ isMobile }) => (isMobile ? '100%' : '25%')};
`;
