import axios, { AxiosRequestConfig } from 'axios';

const API = (baseURL: string) => {
  const axiosRequestConfig: AxiosRequestConfig = {
    baseURL,
  };

  const axiosInstance = axios.create(axiosRequestConfig);
  return axiosInstance;
};

export default API;
