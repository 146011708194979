import { SVGProps } from 'react';

import { colors } from 'syngenta-digital-cropwise-react-ui-kit';

const IconClose = ({
  height = '18px',
  width = '18px',
  color = colors.neutral70,
  ...props
}: SVGProps<SVGSVGElement>) => (
  <svg
    width={width}
    height={height}
    viewBox="0 0 18 18"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
    {...props}
  >
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M14.25 4.8075L13.1925 3.75L9 7.9425L4.8075 3.75L3.75 4.8075L7.9425 9L3.75 13.1925L4.8075 14.25L9 10.0575L13.1925 14.25L14.25 13.1925L10.0575 9L14.25 4.8075Z"
      fill={color}
    />
  </svg>
);

export default IconClose;
