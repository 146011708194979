import { Dispatch, SetStateAction, useEffect, useState } from 'react';
import { MapRef } from 'react-map-gl';

import { MainContainer } from './FullscreenControl.styles';
import FarmControlMain from 'pages/RecommendationV2/Sections/FarmControlMain';

interface FullscreenableElement extends HTMLElement {
  webkitRequestFullscreen?: () => void;
  webkitCancelFullScreen?: () => void;
}

interface FullscreenableDocument extends Document {
  webkitCancelFullScreen?: () => void;
  webkitFullscreenElement?: () => void;
}

interface IProps {
  mapRef?: MapRef;
  setShowFarmSearchPopUp?: Dispatch<SetStateAction<boolean>>;
  webFarmSelectClick: boolean;
  setWebFarmSelectClick: Dispatch<SetStateAction<boolean>>;
  selectFarmName: string;
  isMultiFarm: boolean;
}

const FullscreenControl = ({
  mapRef,
  webFarmSelectClick,
  selectFarmName,
  isMultiFarm,
  setWebFarmSelectClick,
  setShowFarmSearchPopUp,
}: IProps): JSX.Element => {
  const [isFullScreen, setIsFullScreen] = useState(false);
  const [nextFullScreenStatus, setNextFullScreenStatus] = useState(false);

  const isFullscreenableDocument = (
    doc: Document | FullscreenableDocument
  ): doc is FullscreenableDocument =>
    (doc as FullscreenableDocument).webkitCancelFullScreen !== undefined;

  const onClick = () => {
    const mapContainer = mapRef?.getContainer() as FullscreenableElement;
    if (!mapContainer) {
      return;
    }

    setNextFullScreenStatus(!isFullScreen);
    if (isFullScreen) {
      if (window.document.exitFullscreen) {
        mapRef?.getMap().dragPan.disable();
        window.document.exitFullscreen();
      } else if (isFullscreenableDocument(document) && document.webkitCancelFullScreen) {
        mapRef?.getMap().dragPan.disable();
        document.webkitCancelFullScreen();
      }
    } else if (mapContainer.requestFullscreen) {
      mapRef?.getMap().dragPan.enable();
      mapContainer.requestFullscreen();
    } else if (mapContainer.webkitRequestFullscreen) {
      mapRef?.getMap().dragPan.enable();
      mapContainer.webkitRequestFullscreen();
    }
  };

  useEffect(() => {
    const onFullscreenChange = () => {
      setIsFullScreen(
        !!document.fullscreenElement ||
          !!(document as FullscreenableDocument).webkitFullscreenElement
      );
    };
    document.addEventListener('fullscreenchange', onFullscreenChange);
    document.addEventListener('webkitfullscreenchange', onFullscreenChange);

    return () => {
      document.removeEventListener('fullscreenchange', onFullscreenChange);
      document.removeEventListener('webkitfullscreenchange', onFullscreenChange);
    };
  }, []);

  useEffect(() => {
    setIsFullScreen(nextFullScreenStatus);
  }, [nextFullScreenStatus]);

  return (
    <MainContainer id="fullscreenControl">
      {/* Hiding the full screen button temporarily */}
      {/* <StyledButton onClick={onClick}>
        <img src={Icon} alt="image" />
      </StyledButton> */}
      {isMultiFarm && (
        <FarmControlMain
          setShowFarmSearchPopUp={setShowFarmSearchPopUp}
          webFarmSelectClick={webFarmSelectClick}
          setWebFarmSelectClick={setWebFarmSelectClick}
          selectFarmName={selectFarmName}
          isMobile={true}
        />
      )}
    </MainContainer>
  );
};
export default FullscreenControl;
