import { useRef } from 'react';
import { useAppDispatch } from 'context/AppState';
import {
  AgronomicWeighting,
  Field,
  DDLOptions,
  TempSoils,
} from 'context/store/recommendationFormReducer';
import { Feature, Polygon } from '@turf/turf';
import { SoilType } from 'base/types/SoilType';
import { ProductSeed } from 'base/types/Products';
import { ICrop } from 'base/types/Crop';
import { WeatherResponse } from 'base/types/Weather';
import { CheckboxValueType } from 'antd/lib/checkbox/Group';
import { SeedDensityField } from 'pages/RecommendationV2/Sections/SeedDensityAdvice';
import { UserContact } from 'base/types/RecommendationInput';

export enum RecommendationFormActions {
  setCountryCode = 'recommendationForm/setCountryCode',
  setPinPosition = 'recommendationForm/setPinPosition',
  addItemToHistory = 'recommendationForm/addItemToHistory',
  setPinHistory = 'recommendationForm/setPinHistory',
  setPinHectaresSize = 'recommendationForm/setPinHectaresSize',
  saveField = 'recommendationForm/saveField',
  saveFields = 'recommendationForm/saveFields',
  deleteField = 'recommendationForm/deleteField',
  setTempBoundaryField = 'recommendationForm/setTempBoundaryField',
  setFieldList = 'recommendationForm/setFieldList',
  setTempFields = 'recommendationForm/setTempFields',
  setFieldSnapshots = 'recommendationForm/setFieldSnapshots',
  setFieldIdSelected = 'recommendationForm/setFieldIdSelected',
  setYieldRangeId = 'recommendationForm/setYieldRangeId',
  setYieldRangeLabel = 'recommendationForm/setYieldRangeLabel',
  setPlantingDate = 'recommendationForm/setPlantingDate',
  setHarvestDate = 'recommendationForm/setHarvestDate',
  setAreFieldsIrrigated = 'recommendationForm/setAreFieldsIrrigated',
  setIsAtleastAVertex = 'setIsAtleastAVertex',
  setEnableFieldSaveButton = 'setEnableFieldSaveButton',
  setIsDeletingTempField = 'setIsDeletingTempField',
  setProductMadurityRange = 'recommendationForm/setProductMadurityRange',
  setAdjustedProductMaturityRange = 'recommendationForm/setAdjustedProductMaturityRange',
  setProductCountBasedOnUserSelectedFAO = 'recommendationForm/setProductCountBasedOnUserSelectedFAO',
  setAgronomicWeightings = 'recommendationForm/setAgronomicWeightings',
  fillAgronomicWeightings = 'recommendationForm/fillAgronomicWeightings',
  setRankingCrop = 'recommendationForm/setRankingCrop',
  setSoilsInFields = 'recommendationForm/setSoilsInFields',
  setSelectedSoilsInFields = 'recommendationForm/setSelectedSoilsInFields',
  setWeatherInField = 'recommendationForm/setWeatherInField',
  setRotationIntensity = 'recommendationForm/setRotationIntensity',
  setTillagePractice = 'recommendationForm/setTillagePractice',
  setHerbicideTraitOptions = 'recommendationForm/setHerbicideTraitOptions',
  setBroomrapeOptions = 'recommendationForm/setBroomrapeOptions',
  setSelectedHerbicideTrait = 'recommendationForm/setSelectedHerbicideTrait',
  setIsBroomrapeInfestation = 'recommendationForm/setIsBroomrapeInfestation',
  setSelectedBroomrape = 'recommendationForm/setSelectedBroomrape',
  setSelectedSegment = 'recommendationForm/setSelectedSegment',
  setFieldNameForm = 'recommendationForm/setFieldNameForm',
  setFieldIdToEdit = 'recommendationForm/setFieldIdToEdit',
  setFieldNameSelected = 'recommendationForm/setFieldNameSelected',
  deleteLastField = 'recommendationForm/deleteLastField',
  setFieldSelected = 'recommendationForm/setFieldSelected',
  setFocusFieldId = 'recommendationForm/setFocusFieldId',
  setCurrentFieldArea = 'recommendationForm/setCurrentFieldArea',
  setPreviousFieldArea = 'recommendationForm/setPreviousFieldArea',
  setPreviousFieldAreaMobile = 'recommendationForm/setPreviousFieldAreaMobile',
  setFieldSelectedById = 'setFieldSelectedById',
  setEditingFieldIndex = 'recommendationForm/setEditingFieldIndex',
  setSfdcId = 'recommendationForm/sfdcId',
  setAreNewBoundaryChages = 'flow/setAreNewBoundaryChages',
  resetAGInputs = 'recommendationForm/resetAGInputs',
  setAGProducts = 'recommendationForm/setAGProducts',
  setFieldEditId = 'recommendationForm/setFieldEditId',
  setSelectedCrop = 'recommendationForm/setSelectedCrop',
  setVoucherParams = 'recommendationForm/setVoucherParams',
  setCurrentFieldAreaMobile = 'recommendationForm/setCurrentFieldAreaMobile',
  setRecommendationId = 'recommendationForm/setRecommendationId',
  setRecommendationSeedsInfo = 'recommendationForm/setRecommendationSeedsInfo',
  setStoredUserContact = 'recommendationForm/setStoredUserContact',
  setCountryPhoneDialCode = 'recommendationForm/setCountryPhoneDialCode',
  setCountryRegion = 'recommendationForm/setCountryRegion',
  setFieldToBeOpenedId = 'recommendationForm/setFieldToBeOpenedId',
}

export function useRecommendationFormActions() {
  const appDispatcher = useAppDispatch();

  const actions = useRef({
    setAGProducts: (payload: { agProducts: ProductSeed[] }) => {
      appDispatcher({ type: RecommendationFormActions.setAGProducts, payload });
    },
    setCountryCode: (payload: { countryCode: string }) => {
      appDispatcher({ type: RecommendationFormActions.setCountryCode, payload });
    },
    setCountryRegion: (payload: { countryRegion?: string }) => {
      appDispatcher({ type: RecommendationFormActions.setCountryRegion, payload });
    },
    setPinPosition: (payload: { lngLat: mapboxgl.LngLat; countryCode?: string }) => {
      appDispatcher({ type: RecommendationFormActions.setPinPosition, payload: payload });
    },
    addItemToHistory: (lngLat: mapboxgl.LngLat) => {
      appDispatcher({ type: RecommendationFormActions.addItemToHistory, payload: lngLat });
    },
    setPinHistory: (payload: { history: mapboxgl.LngLat[] }) => {
      appDispatcher({ type: RecommendationFormActions.setPinHistory, payload });
    },
    setPinHectaresSize: (payload: { hectaresSize: number }) => {
      appDispatcher({ type: RecommendationFormActions.setPinHectaresSize, payload });
    },
    saveField: (payload: { field: Field }) => {
      appDispatcher({ type: RecommendationFormActions.saveField, payload });
    },
    saveFields: (payload: { fields: Field[] | undefined }) => {
      appDispatcher({ type: RecommendationFormActions.saveFields, payload });
    },
    setSoilsInFields: (payload: { soils: SoilType[]; fieldId: string | number }) => {
      appDispatcher({ type: RecommendationFormActions.setSoilsInFields, payload });
    },
    setSelectedSoilsInFields: (payload: {
      selectedSoils: string[];
      fieldId: string | number;
      fieldsResolution: number;
    }) => {
      appDispatcher({ type: RecommendationFormActions.setSelectedSoilsInFields, payload });
    },
    setWeatherInField: (payload: { weather: WeatherResponse[]; fieldId: string | number }) => {
      appDispatcher({ type: RecommendationFormActions.setWeatherInField, payload });
    },
    deleteField: (payload: { fieldId: string | number }) => {
      appDispatcher({ type: RecommendationFormActions.deleteField, payload });
    },
    setTempBoundaryField: (payload: {
      boundary:
        | Feature<Polygon>
        | (Feature<Polygon> & TempSoils & { weather?: WeatherResponse[] })
        | undefined;
    }) => {
      appDispatcher({ type: RecommendationFormActions.setTempBoundaryField, payload });
    },
    setTempFields: (payload: { fields: Field[] }) => {
      appDispatcher({ type: RecommendationFormActions.setTempFields, payload });
    },
    setFieldList: (payload: { fieldList: Field[] }) => {
      appDispatcher({ type: RecommendationFormActions.setFieldList, payload });
    },
    setFieldSnapshots: (payload: { snapshots: [number, number][][] }) => {
      appDispatcher({ type: RecommendationFormActions.setFieldSnapshots, payload });
    },
    setFieldIdSelected: (payload: { fieldId: string }) => {
      appDispatcher({ type: RecommendationFormActions.setFieldIdSelected, payload });
    },
    setYieldRangeId: (payload: { yieldRangeId: string }) => {
      appDispatcher({ type: RecommendationFormActions.setYieldRangeId, payload });
    },
    setYieldRangeLabel: (payload: { yieldRangeLabel: string }) => {
      appDispatcher({ type: RecommendationFormActions.setYieldRangeLabel, payload });
    },
    setPlantingDate: (payload: { plantingDate: moment.Moment | null }) => {
      appDispatcher({ type: RecommendationFormActions.setPlantingDate, payload });
    },
    setHarvestDate: (payload: { harvestDate: moment.Moment | null }) => {
      appDispatcher({ type: RecommendationFormActions.setHarvestDate, payload });
    },
    setRotationIntensity: (payload: { rotationIntensity: string }) => {
      appDispatcher({ type: RecommendationFormActions.setRotationIntensity, payload });
    },
    setTillagePractice: (payload: { tillagePractice: string }) => {
      appDispatcher({ type: RecommendationFormActions.setTillagePractice, payload });
    },
    setHerbicideTraitOptions: (payload: { herbicideTraitOptions: DDLOptions[] }) => {
      appDispatcher({ type: RecommendationFormActions.setHerbicideTraitOptions, payload });
    },
    setBroomrapeOptions: (payload: { broomrapeOptions: DDLOptions[] }) => {
      appDispatcher({ type: RecommendationFormActions.setBroomrapeOptions, payload });
    },
    setSelectedHerbicideTrait: (payload: { selectedHerbicideTrait: DDLOptions[] }) => {
      appDispatcher({ type: RecommendationFormActions.setSelectedHerbicideTrait, payload });
    },
    setSelectedBroomrape: (payload: { selectedBroomrape: string[] | [] }) => {
      appDispatcher({ type: RecommendationFormActions.setSelectedBroomrape, payload });
    },
    setIsBroomrapeInfestation: (payload: { isBroomrapeInfestation: boolean }) => {
      appDispatcher({ type: RecommendationFormActions.setIsBroomrapeInfestation, payload });
    },
    setSelectedSegment: (payload: { selectedSegment: CheckboxValueType[] }) => {
      appDispatcher({ type: RecommendationFormActions.setSelectedSegment, payload });
    },
    resetAGInputs: () => {
      appDispatcher({ type: RecommendationFormActions.resetAGInputs });
    },
    setAreFieldsIrrigated: (payload: { areFieldsIrrigated: boolean }) => {
      appDispatcher({ type: RecommendationFormActions.setAreFieldsIrrigated, payload });
    },
    setIsAtleastAVertex: (payload: { isAtleastAVertex: boolean }) => {
      appDispatcher({ type: RecommendationFormActions.setIsAtleastAVertex, payload });
    },
    setEnableFieldSaveButton: (payload: { enableFieldSaveButton: boolean }) => {
      appDispatcher({ type: RecommendationFormActions.setEnableFieldSaveButton, payload });
    },
    setIsDeletingTempField: (payload: { isDeletingTempField: boolean }) => {
      appDispatcher({ type: RecommendationFormActions.setIsDeletingTempField, payload });
    },
    setProductMadurityRange: (payload: { productMadurityRange: [number, number] | [] }) => {
      appDispatcher({ type: RecommendationFormActions.setProductMadurityRange, payload });
    },
    setAdjustedProductMaturityRange: (payload: {
      adjustedProductMaturityRange: [number, number] | [];
    }) => {
      appDispatcher({ type: RecommendationFormActions.setAdjustedProductMaturityRange, payload });
    },
    setProductCountBasedOnUserSelectedFAO: (payload: {
      productCountBasedOnUserSelectedFAO: number;
    }) => {
      appDispatcher({
        type: RecommendationFormActions.setProductCountBasedOnUserSelectedFAO,
        payload,
      });
    },
    setAgronomicWeightings: (payload: { agronomicWeighting: AgronomicWeighting }) => {
      appDispatcher({ type: RecommendationFormActions.setAgronomicWeightings, payload });
    },
    setRankingCrop: (payload: { rankingCrop: string }) => {
      appDispatcher({ type: RecommendationFormActions.setRankingCrop, payload });
    },
    fillAgronomicWeightings: (payload: { agronomicWeighting: AgronomicWeighting[] }) => {
      appDispatcher({ type: RecommendationFormActions.fillAgronomicWeightings, payload });
    },
    setFieldNameForm: (payload: { fieldNameForm: string }) => {
      appDispatcher({ type: RecommendationFormActions.setFieldNameForm, payload });
    },
    setFieldNameSelected: (payload: { fieldNameSelected: string }) => {
      appDispatcher({ type: RecommendationFormActions.setFieldNameSelected, payload });
    },
    deleteLastField: () => {
      appDispatcher({ type: RecommendationFormActions.deleteLastField });
    },
    setFieldSelected: (payload: { field: Field | undefined }) => {
      appDispatcher({ type: RecommendationFormActions.setFieldSelected, payload });
    },
    setFocusFieldId: (payload: { fieldId: string }) => {
      appDispatcher({ type: RecommendationFormActions.setFocusFieldId, payload });
    },
    setCurrentFieldArea: (payload: { currentFieldArea: string }) => {
      appDispatcher({ type: RecommendationFormActions.setCurrentFieldArea, payload });
    },
    setCurrentFieldAreaMobile: (payload: { currentFieldAreaMobile: string }) => {
      appDispatcher({ type: RecommendationFormActions.setCurrentFieldAreaMobile, payload });
    },
    setPreviousFieldArea: (payload: { previousFieldArea: string }) => {
      appDispatcher({ type: RecommendationFormActions.setPreviousFieldArea, payload });
    },
    setPreviousFieldAreaMobile: (payload: { previousFieldAreaMobile: string }) => {
      appDispatcher({ type: RecommendationFormActions.setPreviousFieldAreaMobile, payload });
    },
    setFieldSelectedById: (payload: { fieldId: string | number }) => {
      appDispatcher({ type: RecommendationFormActions.setFieldSelectedById, payload });
    },
    setFieldIdToEdit: (payload: { fieldId: string | number }) => {
      appDispatcher({ type: RecommendationFormActions.setFieldIdToEdit, payload });
    },
    setFieldEditId: (payload: { fieldId: string | number }) => {
      appDispatcher({ type: RecommendationFormActions.setFieldEditId, payload });
    },
    setEditingFieldIndex: (payload: { index: number }) => {
      appDispatcher({ type: RecommendationFormActions.setEditingFieldIndex, payload });
    },
    setSfdcId: (payload: { sfdcId: string }) => {
      appDispatcher({ type: RecommendationFormActions.setSfdcId, payload });
    },
    setAreNewBoundaryChages: (payload: { areNewBoundaryChanges: boolean }) => {
      appDispatcher({ type: RecommendationFormActions.setAreNewBoundaryChages, payload });
    },

    setSelectedCrop: (payload: { crop: ICrop }) => {
      appDispatcher({ type: RecommendationFormActions.setSelectedCrop, payload });
    },
    setFieldToBeOpenedId: (payload: { fieldId: string | number }) => {
      appDispatcher({ type: RecommendationFormActions.setFieldToBeOpenedId, payload });
    },
    setVoucherParams: (payload: { voucherParams: string }) => {
      appDispatcher({ type: RecommendationFormActions.setVoucherParams, payload });
    },
    setRecommendationSeedsInfo: (payload: { recommendationSeedsInfo: SeedDensityField[] }) => {
      appDispatcher({ type: RecommendationFormActions.setRecommendationSeedsInfo, payload });
    },

    setRecommendationId: (payload: { recommendationId: string }) => {
      appDispatcher({ type: RecommendationFormActions.setRecommendationId, payload });
    },
    setStoredUserContact: (payload: { storedUserContact: UserContact | undefined }) => {
      appDispatcher({ type: RecommendationFormActions.setStoredUserContact, payload });
    },
    setCountryPhoneDialCode: (payload: { countryPhoneDialCode: string }) => {
      appDispatcher({ type: RecommendationFormActions.setCountryPhoneDialCode, payload });
    },
  });

  return actions.current;
}
