import { Helmet } from 'react-helmet';

// Facebook Pixel Code

// hack to allow gtag bypass typescript
declare global {
  interface Window {
    fbq: any;
  }
}

const ENV = 'prod';
// Add the conversion event snippet if form is submitted successfully
// Event snippet for Lead conversion page
// Run this script for production environment
export const sendConversionEventToGA = (): void => {
  const fbq = window?.fbq;
  if (process.env.REACT_APP_ENV === ENV && fbq) {
    fbq('track', 'CompleteRegistration');
  }
};
export const HeadSectionGA = (): JSX.Element => {
  // do not add script for non-production environment
  if (process.env.REACT_APP_ENV !== ENV) {
    return <></>;
  }

  return (
    <>
      <Helmet>
        <script>
          {`
            !function(f,b,e,v,n,t,s)
            {if(f.fbq)return;n=f.fbq=function(){n.callMethod?
            n.callMethod.apply(n,arguments):n.queue.push(arguments)};
            if(!f._fbq)f._fbq=n;n.push=n;n.loaded=!0;n.version='2.0';
            n.queue=[];t=b.createElement(e);t.async=!0;
            t.src=v;s=b.getElementsByTagName(e)[0];
            s.parentNode.insertBefore(t,s)}(window, document, 'script', 'https://connect.facebook.net/en_US/fbevents.js');
            fbq('init', '793469612815208');
            fbq('track', 'PageView');
          `}
        </script>
        {/* Google Tag Manager (Head) */}
        <script>
          {`
            (function(w,d,s,l,i){w[l]=w[l]||[];w[l].push({'gtm.start':
            new Date().getTime(),event:'gtm.js'});var f=d.getElementsByTagName(s)[0],
            j=d.createElement(s),dl=l!='dataLayer'?'&l='+l:'';j.async=true;j.src=
            'https://www.googletagmanager.com/gtm.js?id='+i+dl;f.parentNode.insertBefore(j,f);
            })(window,document,'script','dataLayer','GTM-WW6HZNPW');
          `}
        </script>
      </Helmet>
      {/* Paste this code immediately after the opening <body> tag */}
      {/* Google Tag Manager (noscript) */}
      <noscript>
        <iframe
          src="https://www.googletagmanager.com/ns.html?id=GTM-WW6HZNPW"
          height="0"
          width="0"
          style={{ display: 'none', visibility: 'hidden' }} />
      </noscript>
    </>
  );
};
