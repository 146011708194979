import styled from 'styled-components';
import { Menu, colors } from 'syngenta-digital-cropwise-react-ui-kit';

const StyledMenu = styled(Menu)`
  width: 160px;
  padding: 0;
  background-color: ${colors.neutral00};
  border-radius: 8px;
  box-shadow: 0px 2px 8px rgba(0, 0, 0, 0.15);
`;

const StyledMenuItem = styled(Menu.Item)`
  list-style-type: none;
  font-size: 14px;
  text-align: right;
  padding: 4px 16px 4px 0;
  height: 28px;

  &:hover {
    background-color: ${colors.neutral20};
    cursor: pointer;
  }

  &:first-child {
    border-top-left-radius: 8px;
    border-top-right-radius: 8px;
  }

  &:last-child {
    border-bottom-left-radius: 8px;
    border-bottom-right-radius: 8px;
    padding-bottom: 7px;
  }
`;

export { StyledMenu, StyledMenuItem };
