import styled from 'styled-components';
import { colors, Typography, Drawer, SYT_PREFIX } from 'syngenta-digital-cropwise-react-ui-kit';
import breakpoints from 'base/constants/breakpoints';

const { Title } = Typography;

export const StyledDrawer = styled(Drawer)`
  .${SYT_PREFIX}-drawer {
    z-index: 100;
    &-content-wrapper {
      top: 56px;

      @media only screen and ${breakpoints.maxWidth.sm} {
        width: 100% !important;
        height: auto !important;
        top: 104px;
        background-color: ${colors.neutral20};
      }
    }

    &-close {
      padding: 0;
    }

    &-header {
      padding: 24px 24px 8px;

      &-title {
        display: flex;
        flex-direction: row-reverse;
      }
    }

    &-title {
      font-weight: 600;
      font-size: 20px;
    }

    &-body {
      padding: 0 24px;
    }

    &-footer {
      display: flex;
      justify-content: space-between;
      padding: 24px 16px;
      gap: 16px;

      .ant-btn {
        @media only screen and ${breakpoints.minWidth.md} {
          width: 164px;
        }
      }
    }
  }

  p {
    font-size: 14px;
    color: ${colors.neutral70};
  }
`;

const ParagraphStyled = styled(Title)`
  color: ${colors.neutral70};
  font-size: 14px;
  font-weight: 400;
  line-height: 20px;
  margin-top: 8px;
  margin: 0;
  margin-bottom: 24px;
  margin-top: 12px;
`;

const WeightingsContainerStyled = styled.div`
  flex-grow: 1;
`;

const AgronomicSliderContainerStyled = styled.div`
  margin-bottom: 24px !important;
  height: 56px;
`;

const FooterContainerStyled = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding: 3px 0;
`;

export {
  ParagraphStyled,
  WeightingsContainerStyled,
  AgronomicSliderContainerStyled,
  FooterContainerStyled,
};
