import { useMemo } from 'react';
import { Layer, Source } from 'react-map-gl';
import { colors } from 'syngenta-digital-cropwise-react-ui-kit';

interface FieldsLayerProps {
  fieldData: any;
  isFieldsSaved: boolean;
  unSavedfield: boolean;
  selectedField: boolean;
}

interface SublayerProps {
  sourceId: string;
  layerId: string;
  lineId: string;
  data: any;
  colorCode: string;
}

const SubLayers = ({ sourceId, layerId, lineId, data, colorCode }: SublayerProps) => {

  return (
    <Source id={sourceId} type="geojson" data={data}>
      <Layer
        id={layerId}
        type="fill"
        source={sourceId}
        paint={{
          'fill-color': colorCode,
          'fill-opacity': 0.3,
        }}
      />
      <Layer
        id={lineId}
        type="line"
        source={sourceId}
        layout={{
          'line-join': 'round',
          'line-cap': 'round',
        }}
        paint={{
          'line-color': colorCode,
          'line-width': 2,
        }}
      />
    </Source>
  );
};
const FieldsLayer = ({
  fieldData,
  isFieldsSaved,
  unSavedfield,
  selectedField,
}: FieldsLayerProps): JSX.Element => {
  const fieldJsonData = useMemo(() => {
    return {
      type: 'FeatureCollection' as const,
      features: fieldData,
    };
  }, [fieldData]);

  return (
    <>
      {unSavedfield && (
        <SubLayers
          sourceId={'fields-source'}
          layerId={'fields-fill'}
          lineId={'fields-line'}
          data={fieldJsonData}
          colorCode={colors.neutral00}
        />
      )}
      {selectedField && (
        <SubLayers
          sourceId={'selected-fields-source'}
          layerId={'selected-fields-fill'}
          lineId={'selected-fields-line'}
          data={fieldJsonData}
          colorCode={colors.blue40}
        />
      )}
      {isFieldsSaved && (
        <SubLayers
          sourceId={'saved-fields-source'}
          layerId={'saved-fields-fill'}
          lineId={'saved-fields-line'}
          data={fieldJsonData}
          colorCode={colors.blue40}
        />
      )}
    </>
  );
};

export default FieldsLayer;
