const ERecommendationSections = {
  SECTION_HERO_BANNER: 'Hero_Banner',
  SECTION_SOILS_CHARACTERISTICS: 'Soil_Characteristics',
  SECTION_AG_PREFERENCES: 'Agronomic_Preferences',
  SECTION_AVG_WEATHER: 'Average_Weather',
  SECTION_ENV_STRESS: 'Environmental_Stress',
  SECTION_EXPERT_SEED_ADVICE: 'Expert_Seed_Advice',
  SECTION_PRODUCT_COMPARISON: 'Product_Comparison',
  SECTION_PRODUCT_PERFORMANCE: 'Product_Performance',
  SECTION_SEED_DENSITY_ADVICE: 'Seed_Density_Advice',
  SECTION_TRIALING_INFORMATION: 'Trialing_Information',
};

export { ERecommendationSections };
