import breakpoints from 'base/constants/breakpoints';
import isMobileTool from 'ismobilejs';
import { FullScreenChartContainer, Tabs } from '../../V2Common.styles';
import styled from 'styled-components';
import { Row, Typography, colors, SYT_PREFIX } from 'syngenta-digital-cropwise-react-ui-kit';
import BarChart from 'components/BarChart';
import IconRotatePhone from 'components/Icons/IconRotatePhone';

const isMobile = isMobileTool(window.navigator);

export const SectionElement = styled.div`
  display: flex;
  width: 100%;
  padding: 32px;
  flex-direction: column;

  @media screen and ${breakpoints.maxWidth.xl} {
    padding: ${() => `${isMobile.phone ? '0px' : '80px'} ${isMobile.phone ? '20px' : '50px'}`};
  }
  @media screen and ${breakpoints.maxWidth.sm} {
    padding: 20px 16px 48px 16px;
  }
`;

export const SectionElementContainer = styled.div`
  display: flex;
  flex-direction: column;
  width: 100%;
  margin: ${isMobile.phone ? '0px' : '20px auto'};
  align-items: center;
  background-color: ${!isMobile.phone ? '#ffffff' : '#f8fafe'};
  border: ${!isMobile.phone ? '1px solid #C2C7D0' : ''};
  border-radius: ${isMobile.phone ? '0' : '16px'};
`;

export const StyledTitle = styled(Typography.Title)`
  font-weight: 400;
  font-size: 24px;
  line-height: 32px;
  text-align: start;
  margin-bottom: 15px;

  @media screen and ${breakpoints.maxWidth.sm} {
    margin-bottom: 16px;
    font-family: Noto Sans;
    font-size: 16px !important;
    font-weight: 550 !important;
    line-height: 24px !important;
    letter-spacing: -0.0075em;
  }
`;

export const FullScreenChartContainerStyled = styled(FullScreenChartContainer)`
  padding: ${({ fullScreen }) => (fullScreen ? '0 45px 0 15px' : '')};
  background-color: ${!isMobile.phone ? '#ffffff' : '#f8fafe'};
  overflow: unset;
`;

export const SectionContent = styled.div`
  display: flex;
  width: 100%;
  max-width: 1126px;
  margin: 0 9%;
  padding: 80px 0;
  flex-direction: column;

  @media screen and ${breakpoints.maxWidth.xl} {
    padding: ${() => `${isMobile.phone ? '0px' : '80px'} ${isMobile.phone ? '20px' : '50px'}`};
  }
  @media screen and ${breakpoints.maxWidth.sm} {
    padding: 48px 16px 48px 16px;
  }
`;

export const StyledFullScreenChartContainer = styled(FullScreenChartContainer)`
  padding: ${({ fullScreen }) => (fullScreen ? '0 45px 0 15px' : '')};
  background-color: ${colors.neutral10};
  overflow: unset;
`;

interface TabsContainerProps {
  isMobilePortraitOrDesktop: boolean;
}

export const TabsContainer = styled.div<TabsContainerProps>`
  margin-top: 64px;
  margin-bottom: 20px;

  .syt-antd-tabs-top > .syt-antd-tabs-nav::before {
    border-bottom: 2px solid ${colors.neutral20};
  }

  @media screen and ${breakpoints.maxWidth.xl} {
    margin-top: ${({ isMobilePortraitOrDesktop }) => (isMobilePortraitOrDesktop ? '40px' : '0px')};
    margin-bottom: ${({ isMobilePortraitOrDesktop }) =>
    (!isMobilePortraitOrDesktop ? '0px' : '20px')};
  }

  @media screen and ${breakpoints.maxWidth.sm} {
    margin-top: 40px;
  }
`;

export const StyledTopText = styled.div`
  font-style: normal;
  font-weight: 400;
  font-size: 16px;
  line-height: 24px;

  letter-spacing: -0.0075em;
`;

export const StyledSquare = styled.div`
  background-color: ${colors.blue60};
  width: 24px;
  height: 24px;
  border-radius: 4px;
  margin-right: 8px;
`;

export const StyledTopTextContainer = styled.div`
  display: flex;
  flex-direction: row;
  padding-bottom: 45px;
`;

export const StyledProductPerformanceChart = styled(BarChart)`
  @media screen and ${breakpoints.minWidth.md} and ${breakpoints.maxWidth.lg} {
    margin-left: -35px;
  }

  @media screen and (orientation: landscape) {
    margin-left: ${isMobile.phone ? '0' : 'unset'};
  }

  svg .y-axis-label {
    fill: ${colors.blue60};
    padding-right: 15px;

    @media screen and ${breakpoints.maxWidth.sm} {
      padding-right: 5px;
    }
  }

  svg .y-axis {
    color: ${colors.blue60};
  }
`;

export const StyledGradientBar = styled.div`
  width: 98%;
  height: 10px;
  background: linear-gradient(
    90deg,
    #0071cd 0%,
    #82cfff 25.52%,
    #f0c355 47.4%,
    #ffb3b3 75.52%,
    #cf3537 100%
  );
  @media screen and ${breakpoints.maxWidth.sm} {
    width: 95%;
  }
`;

export const StyledTriangle = styled.div`
  width: 0;
  height: 0;
  border-top: 5px solid transparent;
  border-bottom: 5px solid transparent;
  border-left: 10px solid ${colors.red60};
`;

export const StyleStressTextContainer = styled.div`
  display: flex;
  flex-direction: row;
  width: 100%;
  justify-content: space-between;
`;

export const StyledProductPerformanceChartPdf = styled(BarChart)`
  .y-axis-label {
    fill: ${colors.neutral90};
  }

  .y-axis {
    color: ${colors.neutral90};
  }
`;

export const StyledRow = styled(Row)`
  width: 100%;
  padding-left: 50px;
  margin-top: 24px;

  @media screen and (orientation: landscape) {
    margin-top: ${isMobile.phone ? '12px' : '24px'};
  }
`;

export const StyledTabs = styled(Tabs)`
  .${SYT_PREFIX}-tabs-tab {
    flex: 1;
    justify-content: center;
  }

  .${SYT_PREFIX}-tabs-nav-list {
    flex: 1;
  }
`;

export const StyledIconRotatePhone = styled(IconRotatePhone)`
  position: absolute;
  top: 0;
  right: 0;
  margin-right: 10px;
  margin-top: 10px;
`;
