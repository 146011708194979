import { MapRef } from 'react-map-gl';
import { pointInCountry } from './helpers/geospatial';
import { minimumZoomLevelToStartWith } from './constants/MapsConstants';

export function isEnoughZoomForDetectField(flowMapMobile: MapRef): boolean {
  if (!flowMapMobile) {
    return true;
  }

  const center = flowMapMobile.getCenter();
  if (!center) {
    return false;
  }

  const countryCode = pointInCountry({ lng: center.lng, lat: center.lat }, [], true);
  if (!countryCode) {
    return false;
  }

  return (
    flowMapMobile.getZoom() >=
    (minimumZoomLevelToStartWith[countryCode as keyof typeof minimumZoomLevelToStartWith] ?? 12)
  );
}

export function isEnoughZoomForDrawBoundaryAndDropPin(current: MapRef): boolean {
  if (!current) {
    return false;
  }

  const center = current.getMap().getCenter();
  if (!center) {
    return false;
  }

  const countryCode = pointInCountry({ lng: center.lng, lat: center.lat }, [], true);
  if (!countryCode) {
    return false;
  }

  return (
    current.getZoom() >= (minimumZoomLevelToStartWith[countryCode as keyof typeof minimumZoomLevelToStartWith] ?? 12)
  );
}
