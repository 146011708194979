import styled from 'styled-components';

export const Text = styled.div`
  display: flex;
  width: 100%;
  justify-content: center;
  color: #FFFFFF;
  font-size: 20px;
  font-weight: 600;
`;

export const MainDiv = styled.div`
  width: 100%;
  padding: 8px;
  background-color: #363948;
`;
