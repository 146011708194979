import track from 'utils/amplitudeWrapper';
import ProxyLayer from 'base/api/ProxyLayer';
import { UserCategories } from 'base/types/AmplitudeClassifiers';
import { isEmail } from 'utils/validator';
import { AgronomicWeighting, AgronomicInputs } from 'context/store/recommendationFormReducer';
import { CropConstants } from 'utils/constants/Crop';
import { formatTraits, getTraitLabel } from 'utils/trait';
import { AxiosError } from 'axios';

interface AgweightingtrackProps {
  agronomicWeightings: AgronomicWeighting[];
}

type ErrorType = AxiosError | undefined;

const errorReasons = {
  attribute: 'attribute api error',
  productMaturityRange: 'product maturity range api error',
  tpp: 'TPP api error',
  emptyProductList: 'products list empty after filters',
  noSeedsAvailable: 'no seed available from RecommendationPackageAPIResponse',
  recommendationValidation: 'recommendation validation Api error',
  downyMildew: 'downy mildew api error',
};

const trackError = (errorReason: string): void => {
  track('Service unavailable', { reason: errorReason });
};

export const trackStepTwoEditingStarted = (): void => {
  const editingStepTwo = sessionStorage.getItem('editingStepTwo');
  if (editingStepTwo === null) {
    sessionStorage.setItem('editingStepTwo', 'true');
  }
};

export const trackDrawBoundaryIncomplete = (): void => {
  const isDrawingBoundary = sessionStorage.getItem('FirstBoundaryPointCreation');
  if (isDrawingBoundary) {
    track('user not finishing field creation', { 'field creation not finished': true });
    sessionStorage.removeItem('FirstBoundaryPointCreation');
  }
};

export const trackUserCategory = (email: string): void => {
  if (isEmail(email)) {
    ProxyLayer.isSyngentaUser(email)
      .then((result) => {
        track('user classification', {
          'user category':
            email.includes('@syngenta.co') || result.status
              ? UserCategories.SYNGENTA_USER
              : UserCategories.NON_SYNGENTA_USER,
        });
      })
      .catch(() => {
        console.error('isSyngentaUser error');
        track('user classification', {
          'user category': email.includes('@syngenta.co')
            ? UserCategories.SYNGENTA_USER
            : UserCategories.NON_SYNGENTA_USER,
        });
      });
  }
};

export const trackAgweightings = ({ agronomicWeightings }: AgweightingtrackProps): void => {
  const changedWeightings = agronomicWeightings.filter((lw) => lw.value > 0);
  changedWeightings.forEach((attribute) => {
    track('ag weightings', { weigthing: attribute.attributeName });
  });
  track('ag weightings', { 'ag weightings applied': changedWeightings.length });
};

export const trackSunflowerInputs = (agronomicInputs: AgronomicInputs): void => {
  track('intensity for sunflower', { 'sunflower intensity': agronomicInputs.rotationIntensity });
  track('tillage practice', {
    tillage:
      agronomicInputs.tillagePractice === CropConstants.NO_TILLAGE
        ? CropConstants.MINIMAL_TILLAGE
        : agronomicInputs.tillagePractice,
  });
  const formatedTrait = formatTraits(agronomicInputs.selectedHerbicideTrait);
  const herbicideTechnologyNames = formatedTrait
    .map((tName: string) => getTraitLabel(tName))
    .filter((tName: string) => tName);
  if (herbicideTechnologyNames.length > 0) {
    track('herbicide technology', { herbicide: herbicideTechnologyNames.join(', ') });
  }
  track('broomrape infestation', { broomRape: agronomicInputs.isBroomrapeInfestation });
  if (agronomicInputs.selectedBroomrape.length > 0) {
    track('broomrape resistance technology', {
      'broomrape resistance': agronomicInputs.selectedBroomrape.join(', '),
    });
  }
  track('crop segment', { 'crop segment selected': agronomicInputs.selectedSegment.join(', ') });
};

export const trackServiceUnavailable = (
  attributesError: ErrorType,
  productMaturityRangeError: ErrorType,
  tppDataError: ErrorType
): void => {
  if (attributesError) {
    trackError(errorReasons.attribute);
  }
  if (productMaturityRangeError) {
    trackError(errorReasons.productMaturityRange);
  }
  if (tppDataError) {
    trackError(errorReasons.tpp);
  }
};

export const trackAdjustInputPopUp = (productEmpty: boolean, noSeedsAvailable: boolean): void => {
  if (productEmpty) {
    trackError(errorReasons.emptyProductList);
  }
  if (noSeedsAvailable) {
    trackError(errorReasons.noSeedsAvailable);
  }
};

export const trackValidationAndDownyMildewError = (
  recommendationValidateError: ErrorType,
  downyMildewError: ErrorType
): void => {
  if (recommendationValidateError) {
    trackError(errorReasons.recommendationValidation);
  }
  if (downyMildewError) {
    trackError(errorReasons.downyMildew);
  }
};
