import React from 'react';
import upArrow from '../../assets/icons/keyboard_arrow_up_24px.svg';
import downArrow from '../../assets/icons/keyboard_arrow_down_24px.svg';

interface CustomExpandIconProps {
  isActive: boolean | undefined;
}

const CustomExpandIcon: React.FC<CustomExpandIconProps> = ({ isActive }) => (
  <img src={isActive ? upArrow : downArrow} alt={isActive ? 'Up Arrow' : 'Down Arrow'} />
);

export default CustomExpandIcon;
