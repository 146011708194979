import {
  ComponentType,
  ForwardRefExoticComponent,
  MouseEventHandler,
  SVGProps,
  CSSProperties,
} from 'react';
import cn from 'classnames';
import type { CustomIconComponentProps } from '@ant-design/icons/lib/components/Icon';
import { useTranslation } from 'react-i18next';

import ButtonCircle from '../Buttons/ButtonCircle';
import { StyledContainer } from './ButtonHub.styles';
import { ButtonProps, TooltipProps } from 'antd';

interface IOption {
  tooltipLabel?: string;
  icon?: React.ReactNode;
  iconCustom?:
    | ComponentType<CustomIconComponentProps | SVGProps<SVGSVGElement>>
    | ForwardRefExoticComponent<CustomIconComponentProps>;
  iconCustomProps?: Partial<CustomIconComponentProps>;
  onClick?: MouseEventHandler<HTMLLIElement>;
  label?: string;
  style?: CSSProperties;
  tooltipProps?: TooltipProps;
  [key: string]: any;
}

interface IProps {
  options?: Array<IOption>;
  isOpen?: boolean;
  onClick?: MouseEventHandler<HTMLLIElement>;

  buttonRootTooltipLabel?: string;
  buttonRootIcon?: React.ReactNode;
  buttonRootIconCustom?:
    | ComponentType<CustomIconComponentProps | SVGProps<SVGSVGElement>>
    | ForwardRefExoticComponent<CustomIconComponentProps>
    | null;
  buttonRootIconCustomProps?: Partial<CustomIconComponentProps>;
  buttonRootLabel?: string;
  buttonRootStyle?: CSSProperties;
  buttonRootProps?: ButtonProps;
}

const ButtonHub = ({
  options,
  isOpen,
}: IProps): JSX.Element => {
  const marginBottomStyle: CSSProperties = { marginBottom: '10px', marginTop: '5px' };
  const [t] = useTranslation();

  return (
    <StyledContainer className={cn({ 'open-root': isOpen })}>
      { options?.map((option, index) => {
        const { tooltipProps, tooltipLabel, buttonProps } = option;
        const className = cn('fab-action', { open: isOpen });
        const btnProps = { ...buttonProps, className };

        return (
          <ButtonCircle
            key={`${option.label}-${index}`}
            {...option}
            tooltipProps={{
              title: tooltipLabel && t(tooltipLabel),
              placement: 'left',
              getPopupContainer: (triggerNode: HTMLElement) =>
                (triggerNode.parentElement ? triggerNode.parentElement : document.body),
              ...tooltipProps,
            }}
            buttonProps={btnProps}
            style={{ ...marginBottomStyle }}
            fixedTooltipLabel={true}
          />
        );
      })}
    </StyledContainer>
  );
};

export default ButtonHub;

export type { IProps, IOption };
