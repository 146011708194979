import React, { Dispatch, SetStateAction, useState } from 'react';
import { ModalAnimationDirections } from 'base/types/ModalBox';
import ConfirmationImage from 'assets/images/illustration_terms_conditions.svg';

import {
  StyledSmsSentModal,
  StyledContent,
  StyledParagraph,
  StyledSmsSentModalMobile,
} from './ConfirmationModalAnimated.styles';

interface ConfirmationModalAnimatedProps {
    setShowSmsSentModal: Dispatch<SetStateAction<boolean>>;
    title: string;
    message: string;
    isMobile: boolean;
  }

const ConfirmationModalAnimated: React.FC<ConfirmationModalAnimatedProps> = ({
  setShowSmsSentModal,
  title,
  message,
  isMobile,
}: ConfirmationModalAnimatedProps) => {
  const [slidingDirection, setSlidingDirection] = useState<ModalAnimationDirections | undefined>(isMobile ? ModalAnimationDirections.Up : undefined);
  const handleClickOverlay = () => {
    setSlidingDirection(ModalAnimationDirections.Down);
    const timeOutId = setTimeout(() =>{
      setShowSmsSentModal(false);
      clearTimeout(timeOutId);
    }, 400);
  };

  return (
    <>
      {isMobile ? (
        <StyledSmsSentModalMobile
          data-testid="sms-sent-modal-mobile"
          isClosable={true}
          isViewFooter
          title={title}
          slideDirection={slidingDirection}
          content={
            <StyledContent>
              <img src={ConfirmationImage} width="400" height="165" alt='' />
              <StyledParagraph>{message}</StyledParagraph>
            </StyledContent>
          }
          onClickOverlay={handleClickOverlay}
          onCancel={handleClickOverlay}
          footer={<></>}
        />
      ) : (
        <StyledSmsSentModal
          data-testid="sms-sent-modal"
          open
          centered
          title={title}
          width={495}
          footer={null}
          maskClosable={false}
          onCancel={() => setShowSmsSentModal(false)}
        >
          <img src={ConfirmationImage} width="400" height="165" alt=''/>
          <StyledParagraph>{message}</StyledParagraph>
        </StyledSmsSentModal>
      )}
    </>
  );
};

export default ConfirmationModalAnimated;
