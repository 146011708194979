import { Radio } from 'antd';
import BarChart from 'components/BarChart';
import styled from 'styled-components';
import { colors } from 'syngenta-digital-cropwise-react-ui-kit';
import { FullScreenChartContainer } from '../../V2Common.styles';
import breakpoints from 'base/constants/breakpoints';

export const RadioGroup = styled(Radio.Group)`
  margin-left: auto;
  margin-top: 30px;

  .ant-radio-button {
    display: none;
  }

  .ant-radio-button-wrapper {
    display: inline-flex;
    width: 50px;
    height: 38px;
    border: 1px solid ${colors.neutral40};
    justify-content: center;
    align-items: center;
    cursor: pointer;
    color: ${colors.neutral60};

    span:not(.ant-radio-button) {
      display: inline-flex;
      justify-content: center;
      align-items: center;
    }

    &:first-child {
      border-radius: 4px 0px 0px 4px;
    }
    &:last-child {
      border-radius: 0px 4px 4px 0px;
    }

    &-checked {
      border: 1px solid ${colors.blue60};
      background-color: ${colors.blue60};
      color: white;
    }
  }
`;

export const StyledBarChart = styled(BarChart)`
  margin-top: 32px;

  @media screen and ${breakpoints.minWidth.md} and ${breakpoints.maxWidth.xl} {
    margin-left: -35px;
  }
`;

export const StyledColorRectangle = styled.div<{ color: string }>`
  display: inline-flex;
  min-width: 16px;
  height: 16px;
  border-radius: 4px;
  background-color: ${({ color }) => color};
`;

export const StyledMobileLegendContainer = styled.div`
  display: flex;
  gap: 12px 24px;
  flex-wrap: wrap;
  justify-content: center;
  margintop: 32px;
`;

export const StyledLegend = styled.div`
  display: flex;
  align-items: center;
  gap: 4px;
`;

export const StyledFullScreenChartContainer = styled(FullScreenChartContainer)`
  padding: 0;
`;

export const IconRotatePhoneContainer = styled.div`
  position: absolute;
  top: 0;
  right: 0;
  padding: 16px 16px 0 0;
`;

export const RadioButton = styled(Radio.Button)``;
