import { EButtonType } from 'components/Buttons/ButtonBase';
import {
  OnboardingContainer,
  TextContainer,
  Button,
  ModalContainer,
} from './MobileOnboardModal.styles';
import IconClose from 'components/Icons/IconClose';
import { colors } from 'syngenta-digital-cropwise-react-ui-kit';
import poweredBySyngentaSVG from '../../assets/images/powered-by-syngenta.svg';
import onboardingFieldSVG from '../../assets/images/onboarding-field.svg';
import { useTranslation } from 'react-i18next';
import { useEffect } from 'react';
import amplitudeWrapper from 'utils/amplitudeWrapper';

const MobileOnboardModal = ({
  closeOnboardModal,
}: {
  closeOnboardModal: () => void;
}): JSX.Element => {
  const { t } = useTranslation();

  useEffect(() => {
    amplitudeWrapper('user lands on onboarding page', {
      'lands on onboarding page': true,
    });
  }, []);
  return (
    <OnboardingContainer>
      <ModalContainer>
        <div className="icon-container">
          <img src={poweredBySyngentaSVG} alt="syngenta-icon" />
          <IconClose data-testid='close-icon' width={24} height={24} color={colors.neutral60} onClick={closeOnboardModal} />
        </div>
        <TextContainer>
          <img src={onboardingFieldSVG} alt="onboarding-field-icon" />
          <h2>{t('Get your data-driven personalised seed recommendation')}</h2>
          <p>
            {t(
              'More than 50,000 trials, 376,000 individual yield result comparisons and 20 years of weather data observations'
            )}
          </p>
          <p>
            {t(
              'More than 16,000 farmers have already received seed recommendations for 87,000 fields and 8.9 million hectares in Europe'
            )}
          </p>
        </TextContainer>
        <Button buttonTestId="get-started-btn" onClick={closeOnboardModal} text={t('Get started')} type={EButtonType.primary} />
      </ModalContainer>
    </OnboardingContainer>
  );
};
export default MobileOnboardModal;
