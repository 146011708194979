import styled from 'styled-components';
import { Tag, colors, SYT_PREFIX } from 'syngenta-digital-cropwise-react-ui-kit';

const { CheckableTag } = Tag;

export const StyledTag = styled(CheckableTag)`
  &.${SYT_PREFIX}-tag.${SYT_PREFIX}-tag-checkable {
    display: inline-flex;
    padding-right: 16px;
    padding-left: 0px;
    gap: 8px;
    height: auto;
    border-color: ${colors.neutral20} !important;
    font-weight: 500;
    text-transform: none;
    font-size: 16px;
    margin: 0;
    border-radius: 10px;
    &:not(.${SYT_PREFIX}-tag-checkable-checked) {
      &:hover {
        color: ${(props) => props.theme || colors.neutral100} !important;
        border-color: ${(props) => props.theme || colors.neutral30} !important;
      }
      &:active {
        color: ${colors.neutral100} !important;
        border: 1px solid ${colors.neutral20} !important;
        background: none !important;
      }
    }
    &&-checked {
      &:hover {
        background: none !important;
      }
      color: ${colors.neutral100} !important;
      border: 2px solid ${colors.blue60} !important;
      background: none !important;
    }
  }
`;

export const SoilColor = styled.div`
  display: flex;
  width: 18px;
  height: 18px;
  background: ${colors.neutral30};
  border-radius: 50%;
  margin: 3px;
`;
export const SoilTexture = styled.div`
  display: flex;
  align-items: center;
  margin-left: 25px;
  font-size: 15px;
`;

// css for selected soilColour
export const SelectedSoilColor = styled.div`
  display: inline-block;
  width: 8px;
  height: 44px;
  background: ${colors.neutral30};
  border-radius: 10px 0px 0px 10px;
  padding: 0px;
`;
