import styled from 'styled-components';
import { Button } from 'syngenta-digital-cropwise-react-ui-kit';
import { colors } from 'theme/colors';

export const StyledButton = styled(Button)`
  margin-top: 25px;
  margin-left: 10px;
  height: 40px;
  width: 40px;
  display: flex;
  align-items: center;
  justify-content: center;
  border-radius: 8px;
  box-shadow: 0px 2px 8px rgba(0, 0, 0, 0.15);
  background-color: ${colors.neutral90};
`;

export const MainContainer = styled.div`
  display: flex;
  flex-direction: row;
  margin-top: 5px;
  .syt-antd-btn {
    background: ${colors.neutral90};
  }
`;

export const FarmSelect = styled.div`
  display: flex;
  z-index: 2;
`;
