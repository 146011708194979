import breakpoints from 'base/constants/breakpoints';
import styled from 'styled-components';
import { colors, Card, Text, Typography } from 'syngenta-digital-cropwise-react-ui-kit';

export const SectionContent = styled.div`
  display: flex;
  width: 100%;
  margin: 0;
  flex-direction: column;
  @media screen and ${breakpoints.maxWidth.sm} {
    padding-left: 16px;
  }
`;

export const MobileSectionContent = styled.div`
  width: 100%;
  padding: 0px 16px 48px 16px;
  display: flex;
  justify-content: center;
  align-items: center;
`;

export const Title = styled(Typography.Title)`
  font-style: normal;
  font-weight: 400;
  font-size: 24px;
  line-height: 32px;
  /* identical to box height, or 140% */
  letter-spacing: -0.0125em;
  color: ${colors.neutral100};

  @media screen and ${breakpoints.maxWidth.sm} {
    font-family: Noto Sans;
    font-size: 16px;
    font-weight: 550;
    line-height: 24px;
    letter-spacing: -0.0075em;
  }
`;

export const CardStyled = styled(Card)`
  margin-top: 28px;
  border-radius: 8px 8px 8px 8px;
  border-color: ${colors.neutral20} !important;
  width: 99%;
  .syt-antd-card-body {
    padding-right: 16px;
    padding-left: 16px;
  }
`;

export const CoverContainerStyled = styled.div`
  width: 100%;
  height: 122px;
  flex: 1;
  display: flex;
  align-items: center;
  justify-content: center;
  border-radius: 8px 8px 0px 0px;
  background-color: ${colors.neutral20};
`;

export const FieldNameContainer = styled.div`
  font-style: normal;
  font-weight: 600;
  font-size: 20px;
  line-height: 28px;

  letter-spacing: -0.0075em;
  color: ${colors.neutral100};
`;

export const VarietyContainer = styled.div`
  display: flex;
  justify-content: space-between;
  width: 100%;
  margin-top: 24px;
`;

export const BoldText = styled(Text)`
  font-family: 'Noto Sans';
  font-style: normal;
  font-weight: 600;
  font-size: 14px;
  line-height: 22px;

  letter-spacing: -0.0075em;
  color: ${colors.neutral90};
`;

export const SoftText = styled(Text)`
  font-style: normal;
  font-weight: 400;
  font-size: 14px;
  line-height: 22px;

  letter-spacing: -0.0075em;
`;

export const RankContainer = styled.div`
  display: flex;
  justify-content: space-between;
  width: 100%;
  margin-top: 16px;
`;

export const MobileStyledCard = styled.div`
  width: 100%;
  display: flex;
  flex-direction: column;
`;
