import { PhoneInputProps } from 'react-phone-input-2';
import 'react-phone-input-2/lib/style.css';

import { PhoneStyled } from './PhoneNumberInput.styles';

interface IProps extends PhoneInputProps {
  error?: boolean;
  autoFocus?: boolean;
}

const PhoneNumberInput = ({ value: _value, autoFocus, country = 'de', ...props }: IProps): JSX.Element => {
  const value = _value ?? null;

  return (
    <PhoneStyled
      {...props}
      country={country}
      value={value}
      placeholder={'e.g. 1234 56789'}
      inputProps={{
        id: 'phone-input',
        name: 'phone',
        required: true,
        enableSearch: true,
        autoFocus,
        tabIndex: 1,
        type: 'tel',
      }}
    />
  );
};

export default PhoneNumberInput;
