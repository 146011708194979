import { Country, Crop } from 'base/types/Countries';

export const getRankingCrop = (cropName: string, countryCode?: string, countryList?: Country[]) => {
  if (countryCode && cropName) {
    const country: Country | undefined = countryList?.find((item: Country) => item.shortCode === countryCode);
    const rankingCrop = country?.crops.find((item: Crop) => item.CropName === cropName)?.RankingCrop;
    return rankingCrop ?? '';
  }
  return '';
};
