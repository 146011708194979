import { useMemo } from 'react';
import { useBreakpoint } from 'hooks';
import { Tooltip } from 'syngenta-digital-cropwise-react-ui-kit';
import { TooltipPlacement } from 'antd/es/tooltip';

interface CustomTooltipProps {
  title?: string;
  children?: React.ReactNode;
  placement: string;
  trigger?: string | string[];
  zIndex?: number;
}
const CustomTooltip = ({ title, children, placement, trigger, zIndex }: CustomTooltipProps): JSX.Element => {
  const { isMobile, isTablet } = useBreakpoint();

  const tooltipProps = useMemo(() => {
    return isMobile || isTablet
      ? {
        open: false,
      }
      : undefined;
  }, [isMobile, isTablet]);
  return (
    <Tooltip
      data-testid="custom-tooltip-test"
      placement={placement as TooltipPlacement}
      title={title}
      trigger={trigger}
      zIndex={zIndex}
      {...tooltipProps}
    >
      {children}
    </Tooltip>
  );
};

export default CustomTooltip;
