export const showCookiebotIcon = (): void => {
  const cookieBotWidget = document.getElementById('CookiebotWidget');
  if (!cookieBotWidget) {
    return;
  }

  cookieBotWidget.style.visibility = 'visible';
};

export const hideCookiebotIcon = (): void => {
  const cookieBotWidget = document.getElementById('CookiebotWidget');
  if (!cookieBotWidget) {
    return;
  }

  cookieBotWidget.style.visibility = 'hidden';
};
