import ModalView from 'components/ModalView';
import breakpoints from 'base/constants/breakpoints';
import { colors, SYT_PREFIX, ButtonType } from 'syngenta-digital-cropwise-react-ui-kit';
import styled from 'styled-components';
import { showSlidingAnimation } from 'utils/modalBoxAnimations';
import { IStyledModalProps } from 'base/types/ModalBox';
import { Button } from 'antd';

export const StyledModalView = styled(ModalView) <IStyledModalProps>`
  background-color: #363948;
  ${(props) => showSlidingAnimation(props.slideDirection)}
  .${SYT_PREFIX}-modal-header {
    border-radius: 8px;
  }
  .modal-view-footer {
    display : grid;
    padding-top: 0px;
  }
  .iMmxge {
    border : none;
  }
`;

export const StyledButton = styled(Button)`
  height: 44px;
  display: flex;
  align-items: center;
  justify-content: center;
  border: none;
  gap: 10px;
  flex: 1;
  z-index: 20;

  font-style: normal;
  font-weight: 400;
  font-size: 16px;
  line-height: 24px;
  /* identical to box height, or 150% */
  letter-spacing: -0.0075em;
  background-color: ${(props) => (props.type === ButtonType.default ? '#F3F4F6' : '#14803C')};
  border-radius: 4px;
  color: ${(props) => (props.type === ButtonType.default ? '#14151C' : '#FFFFFF')};
  &:disabled {
    background-color: #F3F4F6;
    color: #C2C7D0;
    border-color: @cws-neutral-20 !important;
  }
  .phoneIconClass {
    position: relative;
    width: 17px;
    height: 17px;
  }

  @media screen and ${breakpoints.maxWidth.sm} {
    height: 44px;
    border: ${(props) => props.type === ButtonType.default && `1px solid ${colors.neutral30}`};

    &:hover {
      border: ${(props) => props.type === ButtonType.default && `1px solid ${colors.neutral30}`};
    }
  }
`;

export const StyledButtonFooter = styled.div`
  position: fixed;
  bottom: 0;
  width: 100%;
  background-color: #363948; 
  padding: 16px; 
  display: flex;
  justify-content: space-between;
  gap:10px;
  left:0;
  padding-top:15px;
`;

