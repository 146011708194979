import styled from 'styled-components';
import {
  Collapse as AntdCollapse,
  Tabs as AntdTabs,
  Drawer,
  SYT_PREFIX,
  Select,
  colors,
  Divider,
} from 'syngenta-digital-cropwise-react-ui-kit';
import breakpoints from 'base/constants/breakpoints';

export const StyledTitle = styled('div')`
  font-weight: 600;
  font-size: 20px;
  padding: 24px 0px 8px;
`;
interface IsMobileProps {
  isMobile: boolean;
}

interface StyledCollapseProps {
  expandIcon?: any;
}

export const StyledDrawer = styled(Drawer)<IsMobileProps>`
  .${SYT_PREFIX}-drawer {
    &-content-wrapper {
      top: 56px;
      @media only screen and ${breakpoints.maxWidth.sm} {
        width: 100% !important;
        height: auto !important;
        background-color: ${colors.neutral20};
      }
      @media screen and (orientation: landscape) {
        width: ${({ isMobile }) => (isMobile ? '100%' : '')} !important;
        background-color: ${colors.neutral20};
        top: ${({ isMobile }) => (isMobile ? 0 : '56px')};
      }
    }

    &-close {
      padding: 0;
    }

    &-header {
      padding: 24px 16px 8px;

      &-title {
        display: flex;
        flex-direction: row-reverse;
      }
      @media screen (orientation: landscape) {
        display: ${({ isMobile }) => (isMobile ? 'none' : '')} !important;
      }
    }

    &-title {
      font-weight: 600;
      font-size: 20px;
    }

    &-body {
      padding: 5px 16px;
      p,
      .syt-antd-tabs-nav {
        @media screen and (orientation: landscape) {
          display: ${({ isMobile }) => (isMobile ? 'none' : '')} !important;
        }
      }
    }

    &-footer {
      display: flex;
      justify-content: space-between;
      padding: 24px 16px;
      @media screen and (orientation: landscape) {
        display: ${({ isMobile }) => (isMobile ? 'none' : '')} !important;
      }
    }
  }

  p {
    font-size: 14px;
    color: ${colors.neutral70};
  }
`;

export const Tabs = styled(AntdTabs)`
  .${SYT_PREFIX}-tabs-tab-btn {
    font-size: 16px;

    &:hover {
      color: ${colors.neutral100};
    }
  }

  .${SYT_PREFIX}-tabs-tab-active .${SYT_PREFIX}-tabs-tab-btn {
    font-weight: 600;
    color: ${colors.neutral100};
  }

  .${SYT_PREFIX}-tabs-ink-bar {
    background: ${colors.blue50};
  }
`;

export const TagContainer = styled.div`
  display: flex;
  flex-wrap: wrap;
  gap: 24px 16px;
`;

export const Collapse = styled(AntdCollapse)<StyledCollapseProps>`
  border: 0;

  .${SYT_PREFIX}-collapse-header {
    background: white;
    flex-direction: row-reverse;
    padding: 12px 0 !important;
  }

  .${SYT_PREFIX}-collapse-item {
    border: 0;
  }

  .${SYT_PREFIX}-collapse-content-box {
    padding: 16px 0;
  }

  .${SYT_PREFIX}-collapse-header-text {
    font-size: 16px;
    line-height: 22px;
    letter-spacing: -0.0075em;
  }
`;

export const StyledSelect = styled(Select)`
  font-size: 14px;
  .${SYT_PREFIX}-select-selection-placeholder {
    color: ${colors.neutral60};
    font-size: 14px;
  }

  .${SYT_PREFIX}-select-arrow {
    color: ${colors.neutral60};
  }

  .${SYT_PREFIX}-select-selection-item {
    color: ${colors.neutral100};
  }
`;

export const StyledChartContainer = styled('div')<IsMobileProps>`
  @media only screen and ${breakpoints.maxWidth.sm} {
    width: 100%;
    overflow-y: hidden;
  }
  @media screen and (orientation: landscape) {
    width: ${({ isMobile }) => (isMobile ? '100%' : '')};
  }
`;

interface StyledPanelProps {
  showContent: boolean;
  showHeader: boolean;
}
export const Panel = styled(AntdCollapse.Panel)<StyledPanelProps>`
  .syt-antd-collapse-header {
    display: ${({ showHeader }) => (showHeader ? 'flex' : 'none')} !important;
  }
  display: ${({ showContent }) => (showContent ? 'block' : 'none')} !important;
`;
export const StyledPanelContainer = styled('div')`
  @media only screen and ${breakpoints.maxWidth.sm} {
    width: 100%;
    flex: 1;
  }
`;

const StyledDivider = styled(Divider)`
  background-color: ${colors.neutral20};
  margin-top: 10px;
  margin-bottom: 10px;
`;

const StyledSelectedSoilsContainer = styled.div`
  display: flex;
  overflow-x: auto;
  gap: 10px;
  margin-bottom: 12px;
`;

export const StyledSoilSelect = styled(Select)`
  background-color: ${colors.neutral00};
  width: 100%;
  font-size: 16px;
  margin-bottom: 14px;
  .${SYT_PREFIX}-select-selection-placeholder {
    color: ${colors.blue30};
    font-size: 16px;
  }

  .${SYT_PREFIX}-select-arrow {
    color: ${colors.blue30};
  }

  .${SYT_PREFIX}-select-selection-item {
    color: ${colors.neutral100};
    margin-top: 3px;
  }

  .${SYT_PREFIX}-select-item-option-active {
    background-color: ${colors.blue10};
    font-weight: bold;
  }
`;
export { StyledDivider, StyledSelectedSoilsContainer };

export const StyledSoilTitle = styled.div`
  display: flex;
  font-family: Noto Sans;
  font-size: 14px;
  font-weight: 600;
  line-height: 22px;
  letter-spacing: -0.0075em;
  text-align: left;
`;
export const StyledSoilTitle1 = styled.div`
  display: flex;
  font-family: Noto Sans;
  font-size: 14px;
  font-weight: 400;
  line-height: 22px;
  letter-spacing: -0.0075em;
  text-align: left;
`;
