import { useState, useCallback } from 'react';
import { useGoogleReCaptcha } from 'react-google-recaptcha-v3';
import { captureExceptionInSentry } from 'utils';
import { RecaptchaVerificationResponse, RecaptchaAPIResponse } from 'base/types/Recaptcha';
import RetryFunc from 'utils/RetryFunc';
import ProxyLayer from 'base/api/ProxyLayer';

export const useRecaptchaVerification = (): RecaptchaVerificationResponse => {
  const { executeRecaptcha } = useGoogleReCaptcha();
  const [isHumanUser, setIsHumanUser] = useState<boolean | undefined>(undefined);

  const verifyRecaptcha = useCallback(async () => {
    if (!executeRecaptcha) {
      return;
    }

    const verifyToken = async (token: string): Promise<RecaptchaAPIResponse> =>
      (await RetryFunc(async () => {
        return ProxyLayer.verifyRecaptcha({ params: { token } });
      })) as Promise<RecaptchaAPIResponse>;

    try {
      const token = await executeRecaptcha();
      const response = await verifyToken(token);
      const isVerificationSuccessful =
        response?.success && response?.score > 0.5;
      setIsHumanUser(isVerificationSuccessful);
    } catch (error) {
      setIsHumanUser(false);
      captureExceptionInSentry(`Recaptcha verification failed ${error}`);
    }
  }, [executeRecaptcha]);

  return { isHumanUser, verifyRecaptcha };
};
