export const SegmentOptions = [
  {
    label: 'Linoleic',
    value: 'Linoleic'
  },
  {
    label: 'High oleic',
    value: 'High oleic'
  }
];
export const SS_AG_InputOptions = {
  BETWEEN_1_TO_3_YEARS: 'BETWEEN_1_TO_3_YEARS',
  PLOUGHING: 'PLOUGHING',
  Less_than_3_Years: 'Less than 3 Years',
  Over_3_Years: 'Over 3 Years',
  Conventional_Tillage_Ploughing: 'Conventional Tillage/Ploughing',
  Minimal_or_No_Tillage: 'Minimal or No-Tillage'
};
