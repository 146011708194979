import Confirmation from './Confirmation';
import { createBrowserRouter } from 'react-router-dom';
import Home from './Home';
import ErrorPage from './ErrorPage';
import routes from 'base/constants/routes';
import ConfirmationUpdateContact from './Confirmation/UpdateContact';
import DropAPinFlow from './DropAPinFlow';

import BoundaryWizard from './BoundaryWizard';
import RecommendationInProgress from './Confirmation/RecommendationInProgress';
import RecommendationV2 from './RecommendationV2';

const Router = createBrowserRouter([
  {
    path: '*',
    element: <ErrorPage />,
  },
  {
    path: routes.error,
    element: <ErrorPage type="error" />,
  },
  {
    path: routes.home,
    element: <Home />,
    errorElement: <ErrorPage type="error" />,
  },
  {
    path: routes.wizard,
    element: <BoundaryWizard />,
    errorElement: <ErrorPage type="error" />,
  },
  {
    path: routes.wizardMobile,
    element: <BoundaryWizard isMobileLongTermChanges />,
    errorElement: <ErrorPage type="error" />,
  },
  {
    path: routes.wizardDropAPin,
    element: <DropAPinFlow />,
    errorElement: <ErrorPage type="error" />,
  },
  {
    path: routes.wizardDropAPinMobile,
    element: <DropAPinFlow isMobileLongTermChanges />,
    errorElement: <ErrorPage type="error" />,
  },
  {
    path: routes.confirmation,
    element: <Confirmation />,
    errorElement: <ErrorPage type="error" />,
  },
  {
    path: routes.recommendationInProgress,
    element: <RecommendationInProgress />,
    errorElement: <ErrorPage type="error" />,
  },
  {
    path: routes.confirmationUpdateContact,
    element: <ConfirmationUpdateContact />,
    errorElement: <ErrorPage type="error" />,
  },
  {
    path: routes.recommendation,
    element: <RecommendationV2 />,
    errorElement: <ErrorPage type="error" />,
  },
  {
    path: routes.onboarding,
    element: <Home />,
    errorElement: <ErrorPage type="error" />,
  },
]);

export default Router;
