import { colors, Layout, Button } from 'syngenta-digital-cropwise-react-ui-kit';
import styled from 'styled-components';

import LogoCropwise from 'components/Icons/IconLogoCropwise';

const { Header: AntHeader } = Layout;

export const StyledHeader = styled(AntHeader)({
  paddingTop: '8px',
  paddingRight: '16px',
  paddingBottom: '8px',
  paddingLeft: '5px',
  height: '56px',
  width: '100%',
  display: 'flex',
  flexDirection: 'row',
  justifyContent: 'space-between',
  alignItems: 'center',
  backgroundColor: colors.neutral00,
  borderBottom: `2px solid ${colors.neutral20}`,
  zIndex: 2000,
});

export const StyledButtonHelp = styled(Button)`
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  padding: 7px;
  gap: 8px;

  width: 32px;
  height: 32px;

  /* Neutral / neutral-10

  F3F4F6
  */
  background: ${colors.neutral10};
  border-radius: 4px;

  /* Inside auto layout */

  flex: none;
  order: 0;
  flex-grow: 0;
`;

export const StyledButtonLanguage = styled(Button)`
  /* Auto layout */
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  padding: 0px;

  width: 65px;
  height: 32px;

  /* Neutral / neutral-10

  F3F4F6
  */
  background: ${colors.neutral10};
  border-radius: 4px;

  /* Inside auto layout */

  flex: none;
  order: 2;
  flex-grow: 0;
`;

export const StyledDivider = styled.div({
  paddingRight: '16px',
});

export const IconLogoCrop = styled(LogoCropwise)({
  cursor: 'pointer',
});

export const StyledDiv = styled.div`
  display: flex;
  align-items: center;
`;

export const StyledLanguageItems = styled.div`
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: center;
`;

export const StyledLanguageText = styled.div`
  color: ${colors.neutral100};
`;

export const StyledLanguageDropdownDivider = styled.div`
  padding-right: 3px;
`;
