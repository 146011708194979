import breakpoints from 'base/constants/breakpoints';
import styled, { css } from 'styled-components';
import { colors } from 'syngenta-digital-cropwise-react-ui-kit';

interface BarChartProps {
  isPdfView?: boolean;
}

export const StyledSvg = styled.svg<BarChartProps>`
  .tick {
    font-size: 14px;

    ${({ isPdfView }) =>
    !isPdfView &&
      css`
        @media only screen and ${breakpoints.maxWidth.sm} {
          font-size: 12px;
        }
      `}
  }

  .domain {
    display: none;

    @media only screen and ${breakpoints.maxWidth.sm} {
      font-size: 12px;
    }
  }

  .y-axis-label {
    font-size: 14px;
    fill: ${colors.neutral90};
    text-anchor: middle;
    transform: rotate(-90deg);

    ${({ isPdfView }) =>
    !isPdfView &&
      css`
        @media only screen and ${breakpoints.maxWidth.sm} {
          font-size: 12px;
        }
      `}
  }

  .custom-x-axis-label:focus {
    outline: none;
  }
`;

export const StyledContainer = styled.div`
  position: relative;

  .tooltip {
    background-color: ${colors.neutral90};
    color: ${colors.neutral00};
    position: absolute;
    border-radius: 5px;
    padding: 6px 8px;
    min-width: 192px;
    max-width: 200px;
    opacity: 0;
    transform: translate(-50%, 0);
  }

  .tooltip-arrow {
    position: absolute;
    content: '';
    width: 0;
    height: 0;
    border-left: 5px solid transparent;
    border-right: 5px solid transparent;
    border-top: 7px solid ${colors.neutral90};
    opacity: 0;
    transform: translate(-50%, 0);
  }
`;

export const TooltipYearsContainer = styled.div`
  margin-left: 23px;
  font-size: 14px;
`;
