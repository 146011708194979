import {
  SearchSection,
  StyledInput,
  FieldNameSection,
  FieldSection,
  SearchIcon,
  FieldSizeSection,
  SelectedFieldSection,
} from './FarmSearch.styles';
import { useTranslation } from 'react-i18next';
import search from '../../../assets/icons/search_24px.png';
import { useState } from 'react';
import selectArrow from '../../../assets/icons/icon-only-square.png';
import { PropertyData } from './SoilCharacteristics';

  interface FarmSelectProp {
    farmData: PropertyData[];
    handleFarmClick: (_id: string) => void;
    selectFarmName: string;
  }
const FarmSelect = ({ farmData, selectFarmName, handleFarmClick }: FarmSelectProp): JSX.Element => {
  const [t] = useTranslation();
  const [searchTerm, setSearchTerm] = useState('');
  let farm = farmData;
  const handleSearchChange = (event: { target: { value: string } }) => {
    setSearchTerm(event.target.value.toLowerCase());
  };

  if (selectFarmName) {
    farm = farmData.filter((item) => item.farmName !== selectFarmName);
  }
  const filteredFarms = farm.filter((_farm) => _farm.farmName.toLowerCase().includes(searchTerm));
  const getSelectedFarmSize = (name: string) => {
    return farmData.find((item) => item.farmName === name)?.farmSize;
  };

  return (
    <>
      <SearchSection>
        <SearchIcon>
          <img src={search} alt="search-icon" />
        </SearchIcon>
        <StyledInput
          type="text"
          placeholder={`${t('Search farms')}...`}
          value={searchTerm}
          onChange={handleSearchChange}
        />
      </SearchSection>
      {selectFarmName && (
        <SelectedFieldSection>
          <FieldNameSection>{selectFarmName}</FieldNameSection>
          <FieldSizeSection>
            <div className="selected-farm-size">
              {getSelectedFarmSize(selectFarmName)} {`${t('ha')}`}
            </div>
          </FieldSizeSection>
        </SelectedFieldSection>
      )}
      {filteredFarms.map((item) => (
        <FieldSection
          key={item.farmName}
          onClick={() => handleFarmClick(item.farmId)}
          id={item.farmName}
        >
          <FieldNameSection>{item.farmName}</FieldNameSection>
          <FieldSizeSection>
            <div>
              {item.farmSize} {`${t('ha')}`}
            </div>
            <div>
              <img src={selectArrow} alt="right-arrow" />
            </div>
          </FieldSizeSection>
        </FieldSection>
      ))}
    </>
  );
};

export default FarmSelect;
