import { createContext, useContext, useReducer } from 'react';
import produce from 'immer';

import { flowInitialState, flowReducer, FlowState } from './store/flowReducer';
import {
  recommendationFormInitialState,
  recommendationFormReducer,
  RecommendationFormState,
} from './store/recommendationFormReducer';
import { MapState, mapInitialState, mapReducer } from './store/mapReducer';
import { ApiDataInitialState, ApiDataReducer } from './store/ApiDataReducer';
import type { ApiDataState } from './store/ApiDataTypes';

export interface AppState {
  flow: FlowState;
  recommendationForm: RecommendationFormState;
  map: MapState;
  apiData: ApiDataState;
}

export type AppAction = { type: string; payload?: { [key: string]: any } };

const getInitialAppState = (): AppState => ({
  flow: flowInitialState,
  recommendationForm: recommendationFormInitialState,
  map: mapInitialState,
  apiData: ApiDataInitialState,
});

const rootReducer = produce((draft: AppState, action: AppAction) => {
  draft.flow = flowReducer(draft.flow, action);
  draft.recommendationForm = recommendationFormReducer(draft.recommendationForm, action);
  draft.map = mapReducer(draft.map, action);
  draft.apiData = ApiDataReducer(draft.apiData, action);

  if (action.type === 'reset-app') {
    const initialAppState = getInitialAppState();
    draft.flow = initialAppState.flow;
    draft.recommendationForm = initialAppState.recommendationForm;
    draft.map = initialAppState.map;
  }

  return draft;
});

export const AppStateContext = createContext(getInitialAppState());
const AppDispatchContext = createContext<(action: AppAction) => void>(() => ({}));

export const AppStateProvider = ({ children }: any) => {
  const [state, dispatch] = useReducer((state: AppState, action: AppAction) => {
    const nextState = rootReducer(state, action);
    return nextState;
  }, getInitialAppState());

  return (
    <AppDispatchContext.Provider value={dispatch}>
      <AppStateContext.Provider value={state}>{children}</AppStateContext.Provider>
    </AppDispatchContext.Provider>
  );
};

export const useAppState = () => useContext(AppStateContext);
export const useAppDispatch = () => useContext(AppDispatchContext);
