import { featureCollection, center, feature } from '@turf/turf';
import { Field } from 'context/store/recommendationFormReducer';

export const getCentroidOfFields = (fields: Field[]) => {
  if (fields.length > 0) {
    const featureBoundary = fields.map((item)=> item.boundary);
    // combine all of our field geometry into a feature collection
    const collection = featureCollection(
      featureBoundary.filter((field) => !!field?.geometry).map((field) => feature(field?.geometry))
    );
    const centerPointFeature = center(collection);
    if (centerPointFeature?.geometry) {
      const centroidCoords = [centerPointFeature.geometry.coordinates[1], centerPointFeature.geometry.coordinates[0]];
      return centroidCoords;
    }
  };
  return [];
};

export const getCentroidForCircle = (fields: Field[]) => {
  if (fields[0]?.boundary?.properties?.center) {
    return [fields[0]?.boundary?.properties?.center[1], fields[0]?.boundary?.properties?.center[0]];
  } else {
    return [];
  }
};
