import { useCallback, useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useNavigate } from 'react-router-dom';
import { Checkbox } from 'syngenta-digital-cropwise-react-ui-kit';

import { InputTextHandlerType } from 'components/input-text-handler/InputTextHandler';
import { EButtonType } from 'components/Buttons/ButtonBase';
import routes from 'base/constants/routes';
import { useAppState } from 'context/AppState';
import { useFlowActions } from 'context/actions/flowActions';
import Header from 'containers/Header/Header';
import ImageSrc from 'assets/images/illustration_email.svg';
import ConfirmationImage from 'assets/images/illustration_terms_conditions.svg';
import PhoneNumberWrapper from '../../containers/PhoneNumberWrapper';
import { getCountryPostalCode, TARGET_COUNTRIES_CODES } from '../../utils/helpers/geospatial';

import {
  Root,
  Content,
  ButtonStyled,
  Title,
  Image,
  Footer,
  StyledSpinner,
  StyledCountdownText,
  StyledInputTextHandler,
  StyledPrimaryButtonRow,
  StyledSecondaryButtonRow,
  StyledPhoneNumberWrapper,
  StyledSpanText,
} from './UpdateContact.styles';

import { ResendEmailModal } from 'components/ResendEmailModal';
import { ConfirmationModal } from 'components/ConfirmationModal';
import { useBreakpoint } from 'hooks';
import track from 'utils/amplitudeWrapper';
import ProxyLayer from 'base/api/ProxyLayer';
import { ErrorsTypeMap } from 'utils/constants/ErrorsType';
import { useRecommendationFormActions } from 'context/actions/recommendationFormActions';
import { ModalAnimationDirections } from 'base/types/ModalBox';
import { COUNTRY_PHONE_DIAL_CODES, SMS_ENABLED_COUNTRIES } from 'utils/constants/CountryData';
import { trackUserCategory } from 'utils/helpers/amplitude';

const UPDATE_LIMIT = 10;
const EMAIL_UPDATE_DELAY = 0;

export default function UpdateContact(): JSX.Element {
  const navigate = useNavigate();
  const [t] = useTranslation();
  const { isMobile } = useBreakpoint();
  const {
    recommendationForm: { recommendationid, storedUserContact, countryCode },
    flow: { emailRecommendationSent, emailUpdatedCount, emailUpdatedList, phoneNumberUpdatedCount },
  } = useAppState();
  const { setEmailUpdatedCount, setPhoneNumberUpdatedCount, setShowUpdateInfoMessage } =
    useFlowActions();
  const { setStoredUserContact } = useRecommendationFormActions();
  const [email, setEmail] = useState(storedUserContact?.email ?? '');
  const [loading, setLoading] = useState(false);
  const [showConfirmation, setShowConfirmation] = useState(false);
  const [showMaximumUpdateLimitModal, setShowMaximumUpdateLimitModal] = useState(false);
  const [remainingSecondsToUpdateEmail, setRemainingSecondsToUpdateEmail] = useState(0);
  const [apiError, setApiError] = useState(false);
  const [phoneNumber, setPhoneNumber] = useState<string | null>(
    storedUserContact?.phoneNumber ?? null
  );
  const duplicatedEmail = emailUpdatedList.some(
    (previousEmail) => previousEmail.toLowerCase() === email.toLowerCase()
  );
  const [isReceiveSMSChecked, setIsReceiveSMSChecked] = useState(storedUserContact?.smsOptin);
  const postalCode: string = getCountryPostalCode(countryCode);
  const [slidingDirection, setSlidingDirection] = useState<ModalAnimationDirections | undefined>(
    isMobile ? ModalAnimationDirections.Up : undefined
  );

  const [hasInputErrors, setHasInputErrors] = useState({
    name: false,
    email: false,
    phoneNumber: false,
    lastName: false,
  });
  const flowActions = useFlowActions();
  const isUpdateContactBtnDisabled =
    !email ||
    (!phoneNumber && countryCode !== TARGET_COUNTRIES_CODES.POL) ||
    Object.values(hasInputErrors).some((value) => value);
  let isPhoneNumberMandatory = true;

  if (
    countryCode === TARGET_COUNTRIES_CODES.POL ||
    phoneNumber?.startsWith(COUNTRY_PHONE_DIAL_CODES.POL)
  ) {
    if (!storedUserContact?.phoneNumber && !storedUserContact?.smsOptin) {
      isPhoneNumberMandatory = false;
    }
  }
  const isReceiveSMSCheckedDisabled =
    !isPhoneNumberMandatory &&
    (phoneNumber === COUNTRY_PHONE_DIAL_CODES.POL || hasInputErrors.phoneNumber || !phoneNumber);

  const isSmsEnabledCountry = countryCode ? SMS_ENABLED_COUNTRIES.includes(countryCode?.toUpperCase() || '') : true;

  useEffect(() => {
    if (!emailRecommendationSent) {
      navigate(routes.home);
    }
  }, [navigate, emailRecommendationSent]);

  const onCancelResendEmailModal = useCallback(() => {
    if (isMobile) {
      setSlidingDirection(ModalAnimationDirections.Down);
    } else {
      navigate(routes.confirmation);
    }
  }, [isMobile, navigate]);

  const onAnimationEnd = useCallback(() => {
    if (!isMobile || slidingDirection === ModalAnimationDirections.Up) {
      return;
    }
    navigate(routes.confirmation);
  }, [navigate, isMobile, slidingDirection]);

  const handleReceiveSMSChecked = useCallback(() => {
    setIsReceiveSMSChecked((prev) => !prev);
  }, []);

  const onClickUpdateContactBtn = async () => {
    if (emailUpdatedCount >= UPDATE_LIMIT || phoneNumberUpdatedCount >= UPDATE_LIMIT) {
      return setShowMaximumUpdateLimitModal(true);
    }

    track('update email', { 'update email changed': true });
    if (isReceiveSMSChecked) {
      track('sms optin', { 'user sms optin': isReceiveSMSChecked });
      track('page user opting for sms', { 'user optin for sms page': 'update contact' });
    }

    trackUserCategory(email);
    setLoading(true);
    setApiError(false);

    ProxyLayer.updateContact(
      storedUserContact?.email ?? '',
      email,
      phoneNumber ?? '',
      isReceiveSMSChecked ?? false,
      recommendationid
    )
      .then(() => {
        flowActions.setEmailUpdatedList({ list: [...emailUpdatedList, email] });
        if (storedUserContact) {
          setStoredUserContact({
            storedUserContact: {
              ...storedUserContact,
              email,
              phoneNumber,
              smsOptin: isReceiveSMSChecked ?? false,
            },
          });
        }
        setLoading(false);
        setShowConfirmation(true);
        setShowUpdateInfoMessage({ show: true });
        setEmailUpdatedCount({ count: emailUpdatedCount + 1 });
        setPhoneNumberUpdatedCount({ count: phoneNumberUpdatedCount + 1 });
        setEmail('');
        setPhoneNumber(null);
        setIsReceiveSMSChecked(false);
      })
      .catch(() => {
        setLoading(false);
        setApiError(true);
        setEmail('');
        setPhoneNumber(null);
        setIsReceiveSMSChecked(false);
        navigate(routes.recommendationInProgress);
      })
      .finally(() => {
        setRemainingSecondsToUpdateEmail(EMAIL_UPDATE_DELAY);
      });
  };

  const onCloseConfirmationModal = () => {
    setShowMaximumUpdateLimitModal(false);
    setRemainingSecondsToUpdateEmail(EMAIL_UPDATE_DELAY);
  };

  useEffect(() => {
    const intervalId = setInterval(() => {
      if (remainingSecondsToUpdateEmail > 0) {
        setRemainingSecondsToUpdateEmail(remainingSecondsToUpdateEmail - 1);
      }
    }, 1000);

    return () => {
      clearInterval(intervalId);
    };
  }, [remainingSecondsToUpdateEmail]);

  return (
    <Root>
      <Header />
      <Content>
        <Title>{t('Update Contact Details')}</Title>
        <Image src={ImageSrc} width="590" height="237" />
        <StyledPrimaryButtonRow>
          <StyledInputTextHandler
            showRequiredIndicator={true}
            label={`${t('Email Address')}`}
            placeholder={t('Please enter your email address')}
            inputName="email"
            requiredFailMessage={t('Email address is required')}
            invalidMailMessage={t('Email address is invalid.')}
            inputValue={email}
            setInputValue={setEmail}
            isRequired
            type={InputTextHandlerType.EMAIL}
            inputMode="email"
            setHasErrorsForParent={setHasInputErrors}
            showingCountdown={remainingSecondsToUpdateEmail !== 0}
            inputProps={{
              disabled: remainingSecondsToUpdateEmail !== 0,
            }}
          />
          <StyledPhoneNumberWrapper>
            <PhoneNumberWrapper
              label={t('Phone number')}
              isRequired={isPhoneNumberMandatory}
              inputName="phoneNumber"
              requiredFailMessage={t('Phone number is required')}
              regexFailMessage={t(
                'Phone number cannot contain more than 15 digits or less than 7 digits'
              )}
              inputValue={phoneNumber}
              setInputValue={setPhoneNumber}
              setHasErrorsForParent={setHasInputErrors}
              country={postalCode}
            />
          </StyledPhoneNumberWrapper>
        </StyledPrimaryButtonRow>
        {isSmsEnabledCountry && (
          <StyledSecondaryButtonRow>
            <Checkbox
              data-testid="receive-sms-checkbox"
              checked={isReceiveSMSChecked}
              disabled={isReceiveSMSCheckedDisabled}
              onClick={handleReceiveSMSChecked}
            >
              <StyledSpanText>{`${t('Also send recommendation via SMS')} `}</StyledSpanText>
            </Checkbox>
          </StyledSecondaryButtonRow>
        )}
        {/* TODO: Restore when requirement is clear
        {duplicatedEmail && (
          <StyledCountdownText style={{ color: 'red' }}>
            {t('Email already updated.')}
          </StyledCountdownText>
        )*/}
        {remainingSecondsToUpdateEmail !== 0 && (
          <StyledCountdownText>
            {t('Please wait for sending again {{seconds}} sec', {
              seconds: remainingSecondsToUpdateEmail,
            })}
          </StyledCountdownText>
        )}
        <Footer showingCountdown={remainingSecondsToUpdateEmail !== 0}>
          <ButtonStyled
            type={EButtonType.primary}
            onClick={onClickUpdateContactBtn}
            isDisabled={isUpdateContactBtnDisabled}
            text={t('Update Contact Details')}
          />
        </Footer>
      </Content>
      {loading && <StyledSpinner />}
      {showConfirmation && (
        <ResendEmailModal
          showConfirmation={showConfirmation}
          setShowConfirmation={setShowConfirmation}
          title={t('Contact Details Updated')}
          image={<img src={ConfirmationImage} width="400" height="165" />}
          description={t('Your contact details have been updated successfully.')}
          isMobile={isMobile}
          slideDirection={slidingDirection}
          onCancel={onCancelResendEmailModal}
          onAnimationEnd={onAnimationEnd}
        />
      )}
      {showMaximumUpdateLimitModal && (
        <ConfirmationModal
          title={t('Information')}
          body={
            emailUpdatedCount >= UPDATE_LIMIT
              ? t('You have reached the maximum number of times to update the email.')
              : t('You have reached the maximum number of times to update the phone number.')
          }
          confirmButtonText={t('OK')}
          onClickConfirm={onCloseConfirmationModal}
        />
      )}

      {apiError && (
        <ConfirmationModal
          title={t(ErrorsTypeMap.MAX_ATTEMPTS.statusTitle ?? '')}
          body={t(ErrorsTypeMap.MAX_ATTEMPTS.statusMessage)}
          confirmButtonText={t('OK')}
          onClickConfirm={() => setApiError(false)}
        />
      )}
    </Root>
  );
}
