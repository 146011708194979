import React, {
  ComponentType,
  ForwardRefExoticComponent,
  MouseEventHandler,
  SVGProps,
  CSSProperties,
} from 'react';
import { ButtonProps, TooltipProps } from 'antd';
import type { CustomIconComponentProps } from '@ant-design/icons/lib/components/Icon';

import { StyledButton, StyledButtonIcon, StyledTooltip } from './ButtonCircle.styles';

import { colors } from 'theme/colors';

export interface IProps {
  buttonProps?: ButtonProps;
  tooltipProps?: TooltipProps;
  text?: string;
  icon?: React.ReactNode;
  iconCustom?:
  | ComponentType<CustomIconComponentProps | SVGProps<SVGSVGElement>>
  | ForwardRefExoticComponent<CustomIconComponentProps>
  | null;
  iconCustomProps?: Partial<CustomIconComponentProps>;
  onClick?: MouseEventHandler<HTMLElement>;
  tooltipLabel?: string;
  style?: CSSProperties;
  fixedTooltipLabel?: boolean;
  id?: string;
}

const ButtonCircle = ({
  buttonProps,
  tooltipProps,
  text,
  icon,
  iconCustom,
  iconCustomProps,
  onClick,
  tooltipLabel,
  style,
  fixedTooltipLabel = false,
  id
}: IProps): JSX.Element => (
  <div>
    <StyledTooltip
      color={colors.neutral90}
      title={tooltipLabel}
      fixedTooltipLabel={fixedTooltipLabel}
      {...tooltipProps}
    >
      <StyledButton shape="circle" icon={icon} onClick={onClick} style={style} {...buttonProps} data-testid={id}>
        {text}
        {iconCustom && <StyledButtonIcon component={iconCustom} {...iconCustomProps} />}
      </StyledButton>
    </StyledTooltip>
  </div >
);

export default ButtonCircle;
