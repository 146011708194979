import { TooltipProps } from 'antd';
import { colors, Tooltip } from 'syngenta-digital-cropwise-react-ui-kit';

export type InfoBubbleProps = {
  width?: string | number;
  height?: string | number;
  iconColor?: string;
} & TooltipProps;

const InfoBubble = ({
  width = 18,
  height = 18,
  iconColor = colors.blue60,
  ...tooltipProps
}: InfoBubbleProps): JSX.Element => {
  return (
    <Tooltip {...tooltipProps}>
      <svg
        width={width}
        height={height}
        viewBox="0 0 16 16"
        fill="none"
        xmlns="http://www.w3.org/2000/svg"
      >
        <path
          fillRule="evenodd"
          clipRule="evenodd"
          d="M8 0.5C3.86 0.5 0.5 3.86 0.5 8C0.5 12.14 3.86 15.5 8 15.5C12.14 15.5 15.5 12.14 15.5 8C15.5 3.86 12.14 0.5 8 0.5ZM7.25 4.25V5.75H8.75V4.25H7.25ZM7.25 7.25V11.75H8.75V7.25H7.25ZM2 8C2 11.3075 4.6925 14 8 14C11.3075 14 14 11.3075 14 8C14 4.6925 11.3075 2 8 2C4.6925 2 2 4.6925 2 8Z"
          fill={iconColor}
        />
      </svg>
    </Tooltip>
  );
};

export default InfoBubble;
