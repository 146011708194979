import { IStyledModalProps } from 'base/types/ModalBox';
import { FlattenSimpleInterpolation, css, keyframes } from 'styled-components';

const slideUp = keyframes`
  0% {
    transform: translateY(100%);
  }
  100% {
    transform: translateY(0);
  }
`;

const slideDown = keyframes`
  0% {
    transform: translateY(0);
  }
  100% {
    transform: translateY(100%);
  }
`;

export const showSlidingAnimation = (
  slideDirection: IStyledModalProps['slideDirection']
): FlattenSimpleInterpolation => css`
  animation: ${slideDirection === 'up' ? slideUp : slideDirection ? slideDown : ''} 0.3s ease-out
    forwards;
`;
