export default {
  home: '/',
  wizard: '/draw-boundaries',
  wizardDropAPin: '/drop-a-pin',
  wizardMobile: '/draw-boundaries-mobile',
  wizardDropAPinMobile: '/drop-a-pin-mobile',
  error: '/error',
  confirmation: '/confirmation',
  recommendationInProgress: '/recommendation-in-progress',
  confirmationUpdateContact: '/confirmation-update-contact',
  recommendation: '/recommendation/:recommendation_data',
  onboarding: '/onboarding'
};
