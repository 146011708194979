import * as turf from '@turf/turf';
import { Polygon } from '@turf/turf';
import { GridItem } from 'base/types/SoilGrid';
import gridPointToPolygon from './gridPointToPolygon';

type GetBiggestSoilAreaParams = {
  soilGrid: GridItem[];
  fieldBoundaries: Polygon;
  fieldsResolution: number;
};

export default function getBiggestSoilArea(params: GetBiggestSoilAreaParams) {
  const { soilGrid, fieldBoundaries, fieldsResolution } = params;
  const areaBySoilType: {
    [soiltype: string]: {
      accumArea: number;
      texture: string;
      texture_raw: string;
      uniqueSoilType: string;
    };
  } = {};

  // Grid items inside the field
  const features = soilGrid
    .map((point) => {
      const polygon = gridPointToPolygon(point, fieldsResolution);
      const feature = turf.intersect(polygon, fieldBoundaries);

      if (feature) {
        feature.properties = {
          id: point.id,
          texture: point.texture,
          texture_raw: point.texture_raw,
          uniqueSoilType: `${point.texture} ${point.texture_raw}`,
          area: turf.area(feature),
        };
      }

      return feature;
    })
    .filter((f) => f !== null);

  // Sum grid areas
  features.forEach((feature) => {
    const {
      area = 0,
      texture,
      uniqueSoilType = '',
      texture_raw: textureRaw,
    } = feature?.properties ?? {};

    if (!areaBySoilType[uniqueSoilType]) {
      areaBySoilType[uniqueSoilType] = {
        accumArea: 0,
        texture,
        texture_raw: textureRaw,
        uniqueSoilType,
      };
    }

    areaBySoilType[uniqueSoilType].accumArea += area;
  });

  // select the biggest area
  let biggestArea = { accumArea: 0, texture: '', texture_raw: '', uniqueSoilType: '' };
  Object.keys(areaBySoilType).forEach((key) => {
    if (biggestArea.accumArea < areaBySoilType[key].accumArea) {
      biggestArea = areaBySoilType[key];
    }
  });

  return biggestArea;
}
