import styled from 'styled-components';
import { colors, SYT_PREFIX } from 'syngenta-digital-cropwise-react-ui-kit';

interface NotificationFooterBarProps {
  notificationColor?: string;
}

const NotificationFooterContainer = styled.div`
  position: absolute;
  bottom: 0;
  z-index: 1080;
  display: flex;
  flex-direction: column;
  width: 100%;
  border-top-right-radius: 8px;
  border-top-left-radius: 8px;

  button {
    border: none;
  }

  .${SYT_PREFIX}-row {
    flex-wrap: nowrap;
  }

  .${SYT_PREFIX}-btn-default {
    border: 1px solid ${colors.neutral30};
    color: ${colors.neutral100};
  }
`;

const NotificationFooterWrapper = styled.div`
  height: calc(var(--vh, 1vh) * 100);
  z-index: 1070;
  display: block;
  position: fixed;
  height: 100%;
  top: 0;
  left: 0;
  right: 0;
`;

const NotificationFooterOverlay = styled.div`
  height: calc(var(--vh, 1vh) * 100);
  z-index: 100;
  display: block;
  position: fixed;
  height: 100%;
  top: 0;
  left: 0;
  right: 0;
  background: rgba(0, 0, 0, 0.5);
`;

const NotificationFooterBar = styled.div<NotificationFooterBarProps>`
  width: 100%;
  height: 4px;
  background-color: ${(props) => props.notificationColor};
  margin-bottom: 16px;
`;

const NotificationContent = styled.div`
  padding-right: 24px;
  padding-left: 24px;
  padding-bottom: 24px;
`;

export {
  NotificationFooterWrapper,
  NotificationFooterContainer,
  NotificationFooterBar,
  NotificationFooterOverlay,
  NotificationContent,
};
