import { useCallback, useRef } from 'react';

const useScrollToBottom = () => {
  const collapseRef = useRef<HTMLDivElement>(null);

  const scrollToBottom = useCallback(() => {
    setTimeout(() => {
      if (collapseRef.current) {
        collapseRef.current.scrollIntoView({ behavior: 'smooth', block: 'start' });
      }
    }, 500);
  }, [collapseRef]);

  return { collapseRef, scrollToBottom };
};

export default useScrollToBottom;
