import { MapsConstants } from 'utils/constants/MapsConstants';

import axios, { AxiosRequestConfig } from 'axios';

export const findRegionalLocation = async (config?: AxiosRequestConfig) => {
  try {
    const response = await axios.get(MapsConstants.IPAPI_URL, config);
    return response.data;
  } catch (error) {
    // eslint-disable-next-line
    console.error(error);
    return error;
  }
};
