import { useEffect, useState, useMemo } from 'react';
import { colors } from 'syngenta-digital-cropwise-react-ui-kit';
import { useTranslation } from 'react-i18next';
import {
  SectionContainer,
  EnvironmentalStressTitle as Title,
  Text,
  EnvironmentalStressContent,
} from '../../V2Common.styles';
import { LandscapeWarningMessageContainer } from '../../RecommendationV2.styles';
import {
  StyledBarChart,
  StyledColorRectangle,
  StyledMobileLegendContainer,
  StyledLegend,
} from './EnvironmentalStress.styles';
import { Tpr } from 'base/types/RecommendationCropwise';
import { BarChartLabelProps } from 'components/BarChart/BarChartLabel';
import { useFlowActions } from 'context/actions/flowActions';
import useBreakpoint from 'hooks/useBreakpoint';
import { getYMax } from 'utils/getYMaxEnvironmentalStress';

interface EnvironmentalStressProps {
  readonly chartInfo: Tpr | null;
  readonly sectionRef: any;
}

export default function EnvironmentalStress({ chartInfo, sectionRef }: EnvironmentalStressProps) {
  const { t } = useTranslation();
  const [formatedChartInfo, setFormatedChartInfo] = useState<BarChartLabelProps[]>([]);
  const { setStressBarChartData } = useFlowActions();
  const { isMobile, isTablet, isMobilePortraitOrDesktop, landscape } = useBreakpoint();

  useEffect(() => {
    if (chartInfo) {
      const data = [
        {
          value: chartInfo?.VLOW?.Percentage || 0,
          title: t('Wet & Cold'),
          temperatureLabel: t('Very Low Temperature'),
          precipitationLabel: t('High Precipitation'),
          barColor: colors.blue60,
          titleColor: colors.blue60,
          years: chartInfo?.VLOW?.Years || [],
        },
        {
          value: chartInfo?.LOW?.Percentage || 0,
          title: t('Cool & Rainy'),
          temperatureLabel: t('Low Temperature'),
          precipitationLabel: t('High Precipitation'),
          barColor: colors.blue30,
          titleColor: colors.blue30,
          years: chartInfo?.LOW?.Years || [],
        },
        {
          value: chartInfo?.MED?.Percentage || 0,
          title: t('Mild'),
          temperatureLabel: t('Medium Temperature'),
          precipitationLabel: t('Medium Precipitation'),
          barColor: colors.yellow30,
          titleColor: colors.yellow30,
          years: chartInfo?.MED?.Years || [],
        },
        {
          value: chartInfo?.HIGH?.Percentage || 0,
          title: t('Warm & Dry'),
          temperatureLabel: t('High Temperature'),
          precipitationLabel: t('Low Precipitation'),
          barColor: colors.red30,
          titleColor: colors.red30,
          years: chartInfo?.HIGH?.Years || [],
        },
        {
          value: chartInfo?.VHIGH?.Percentage || 0,
          title: t('Very Hot & Dry'),
          temperatureLabel: t('Very High Temperature'),
          precipitationLabel: t('Low Precipitation'),
          barColor: colors.red60,
          titleColor: colors.red60,
          years: chartInfo?.VHIGH?.Years || [],
        },
      ];
      setFormatedChartInfo(data);
      setStressBarChartData({ data: data as BarChartLabelProps[] });
    }
  }, [chartInfo, t, setStressBarChartData]);

  const distanceBetweenBars = useMemo(() => {
    if (isMobile) {
      return 16;
    } else if (isTablet) {
      return 48;
    } else {
      return 24;
    }
  }, [isMobile, isTablet]);

  return (
    <SectionContainer
      data-testid="environmental-stress-section"
      ref={sectionRef}
      className="secondary-container"
    >
      <EnvironmentalStressContent>
        {landscape && <LandscapeWarningMessageContainer />}
        {isMobilePortraitOrDesktop && (
          <>
            <Title>{t('Environmental Stress')}</Title>
            <Text>{t('environmentalStressDescription')}</Text>
          </>
        )}
        <StyledBarChart
          id="environmental-stress-bar-char"
          yMin={0}
          yMax={getYMax(formatedChartInfo)}
          yStep={5}
          yLabel={`${t('Environment Frequency')} %`}
          data={formatedChartInfo}
          paddingLeft={isMobile || isTablet ? 50 : undefined}
          distanceBetweenBars={distanceBetweenBars}
          showTooltip={isMobile}
          showTitleOnTooltip={false}
        />
        {isMobile && (
          <StyledMobileLegendContainer>
            {formatedChartInfo?.map((item) => (
              <StyledLegend key={`label-${item.title}`}>
                <StyledColorRectangle color={item.barColor} />
                <span>{item.title}</span>
              </StyledLegend>
            ))}
          </StyledMobileLegendContainer>
        )}
      </EnvironmentalStressContent>
    </SectionContainer>
  );
}
