import { useEffect, useState, useMemo } from 'react';

import {
  StyledButton,
  StyledButtonFooter,
  StyledModalView,
  ErrorMessage,
} from 'commonstyles/FieldInformationSheet.styles';
import { useTranslation } from 'react-i18next';
import { ButtonType } from 'syngenta-digital-cropwise-react-ui-kit';
import { useAppState, useAppDispatch } from 'context/AppState';
import { ModalAnimationDirections } from 'base/types/ModalBox';
import FieldInformationDrawerMobile from 'components/FieldInformationDrawerMobile';
import { useRecommendationFormActions } from 'context/actions/recommendationFormActions';
import { useFlowActions } from 'context/actions/flowActions';
import { ModeTypes } from 'context/store/flowReducer';
import EnvironmentDrawer from 'components/EnvironmentDrawer';
import useScrollToBottom from 'hooks/useScrollDown';
import { ActionTypes } from 'context/actions/ApiDataActions';

interface DrawInformationSheetProps {
  title: string;
  onClickCancel: Function;
  onClickNext: Function;
  onClickOutside?: Function;
}

const DrawBoundaryInformationSheet = ({
  title,
  onClickCancel,
  onClickNext,
  onClickOutside,
}: DrawInformationSheetProps): JSX.Element => {
  const [t] = useTranslation();
  const [hasInputErrors, setHasInputErrors] = useState({ fieldName: false, size: false });
  const [slideDirection, setSlideDirection] = useState(ModalAnimationDirections.Up);
  const [isAnimating, setIsAnimating] = useState(false);
  const [nextClicked, setNextClicked] = useState(false);
  const {
    flow: { isModalToBeClosed },
    recommendationForm: { fields },
  } = useAppState();
  const appDispatcher = useAppDispatch();
  const { collapseRef, scrollToBottom } = useScrollToBottom();
  const { setCurrentModeType, setIsModalToBeClosed } = useFlowActions();

  const {
    setTempBoundaryField,
    setFieldEditId,
    setFieldToBeOpenedId,
    setPreviousFieldAreaMobile,
    setCurrentFieldAreaMobile,
  } = useRecommendationFormActions();

  const handleAnimationEnd = () => {
    if (isAnimating) {
      if (onClickOutside) {
        onClickOutside();
      } else {
        onClickCancel();
      }
    }
    if (nextClicked) {
      onClickNext();
    }
  };

  const handleNextClick = () => {
    appDispatcher({ type: ActionTypes.setDetectField, payload: { formattedDetectField: {
      type: 'FeatureCollection',
      features: [],
    } }});
    setFieldToBeOpenedId({ fieldId: '' });
    setFieldEditId({ fieldId: '' });
    setSlideDirection(ModalAnimationDirections.Down);
    setTempBoundaryField({ boundary: undefined });
    setNextClicked(true);
  };

  const handleAddAnotherClick = () => {
    setFieldToBeOpenedId({ fieldId: '' });
    setFieldEditId({ fieldId: '' });
    setCurrentModeType({ modeType: ModeTypes.CREATING });
    onClickCancel();
  };
  const handleCloseDrawer = () => {
    setFieldToBeOpenedId({ fieldId: '' });
    setCurrentFieldAreaMobile({ currentFieldAreaMobile: '' });
    setPreviousFieldAreaMobile({ previousFieldAreaMobile: '' });
    setSlideDirection(ModalAnimationDirections.Down);
    setIsAnimating(true);
  };

  const { checkIfFieldsDataIsInvalid, isFieldSizeError } = useMemo(() => {
    if (fields.length) {
      const isEmptyFieldData = fields.some((obj) => obj.fieldName === '' || parseFloat(obj.hectares) < 0.01);
      const fieldSizeError = fields.some((obj) => parseFloat(obj.hectares) > 1000);
      return { checkIfFieldsDataIsInvalid: isEmptyFieldData, isFieldSizeError: fieldSizeError };
    }
    return { checkIfFieldsDataIsInvalid: false, isFieldSizeError: false };
  }, [fields]);

  useEffect(() => {
    if (isModalToBeClosed) {
      handleCloseDrawer();
      setIsModalToBeClosed({ isModalToBeClosed: false });
    }
  }, [isModalToBeClosed, setIsModalToBeClosed]);

  return (
    <StyledModalView
      data-testid="draw-boundary-information-sheet"
      title={title ?? 'Title goes here'}
      width={494}
      isViewFooter
      showHeaderRectangle
      onCancel={handleCloseDrawer}
      isDropAPinFlow={true}
      id='boundary-information-sheet'
      content={
        <>
          {checkIfFieldsDataIsInvalid && (
            <ErrorMessage>{t('*Please enter field information to continue')}</ErrorMessage>
          )}
          <FieldInformationDrawerMobile setHasInputErrors={setHasInputErrors} />
          <div ref={collapseRef} />
          <EnvironmentDrawer
            open={true}
            nextClicked={nextClicked}
            handleCollapseClick={scrollToBottom}
          />
        </>
      }
      footer={
        <StyledButtonFooter>
          <StyledButton
            data-testid="draw-boundary-information-sheet-add-another-button"
            type={ButtonType.default}
            size={'large'}
            disabled={hasInputErrors.fieldName || hasInputErrors.size || checkIfFieldsDataIsInvalid || isFieldSizeError}
            onClick={handleAddAnotherClick}
          >
            {t('Add another')}
          </StyledButton>
          <StyledButton
            data-testid="draw-boundary-information-sheet-next-button"
            type={ButtonType.primary}
            size={'large'}
            onClick={handleNextClick}
            disabled={hasInputErrors.fieldName || hasInputErrors.size || checkIfFieldsDataIsInvalid || isFieldSizeError}
          >
            {t('Next')}
          </StyledButton>
        </StyledButtonFooter>
      }
      slideDirection={slideDirection}
      onAnimationEnd={handleAnimationEnd}
    />
  );
};

export default DrawBoundaryInformationSheet;
