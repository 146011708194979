import { RequestError, ErrorTypes } from 'base/types/Error';

const ErrorsTypeMap: { [key in ErrorTypes]: RequestError } = {
  [ErrorTypes.MAX_ATTEMPTS]: {
    status: 429,
    statusMessage:
      'Cropwise is having a hard time reaching its back end services, please try again later.',
    statusTitle: 'Service Unavailable',
    errorType: ErrorTypes.MAX_ATTEMPTS,
  },
  [ErrorTypes.DEFAULT]: {
    status: 500,
    statusMessage: 'Default Error Message',
    statusTitle: 'Default Error Title',
  },
};

export { ErrorsTypeMap };
