import { DDLOptions } from 'context/store/recommendationFormReducer';

export const getBroomrapeValues = (broomRapeSet: string[]): DDLOptions[] => {
  broomRapeSet?.sort((a, b) => a.localeCompare(b));
  const data: DDLOptions[] = broomRapeSet?.map((item: any) => {
    return {
      label: item,
      value: item
    };
  });
  return data;
};
