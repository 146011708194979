import { track } from '@amplitude/analytics-browser';
import { getZoomLevelForLocation, pointInCountry } from './geospatial';
import { Result } from '@mapbox/mapbox-gl-geocoder';
import { LDFlagSet } from 'launchdarkly-react-client-sdk';

export const processGeocodingResult = (
  geocodingResult: Result,
  flags: LDFlagSet,
  setUserCountryCode: (countryCode: { country: string }) => void,
  mapLoaded?: any
) => {
  if (mapLoaded) {
    const lng = geocodingResult?.center[0];
    const lat = geocodingResult?.center[1];
    const country = pointInCountry({ lng, lat }, flags);
    setUserCountryCode({
      country: String(country).toLocaleLowerCase().replace(/\s+/g, ''),
    });
    mapLoaded.flyTo({
      essential: true,
      center: [geocodingResult.center[0], geocodingResult.center[1]],
      zoom: getZoomLevelForLocation(geocodingResult.place_type),
    });
  }
  track('user search location', { 'user search location on map': true });
  return '';
};
