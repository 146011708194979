import { SVGProps } from 'react';
import { colors } from 'syngenta-digital-cropwise-react-ui-kit';

const IconKeyboardArrowLeft = ({
  height = '10px',
  width = '7px',
  color = colors.neutral100,
  ...props
}: SVGProps<SVGSVGElement>) => (
  <svg
    width={width}
    height={height}
    viewBox="0 0 7 10"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
    {...props}
  >
    <path
      d="M6.58711 8.825L2.77044 5L6.58711 1.175L5.41211 0L0.412109 5L5.41211 10L6.58711 8.825Z"
      fill={color}
    />
  </svg>
);

export default IconKeyboardArrowLeft;
