import { useTranslation } from 'react-i18next';
import {
  FooterContent,
  FooterRoot,
  DisclaimerTextStyled,
  StyledParagraph,
  TopSection,
  DisclaimerTextContainer,
  BottomSection,
  ParagraphContainer,
  StyledAnchorText,
  GoogleAttributionTextStyled,
  GoogleAttributionAnchorText,
} from './Footer.styles';
import CropwiseLogo from '../../../assets/images/cropwise_logo.svg';
import { useAppState } from 'context/AppState';
import { getUrl } from 'utils/constants/TermsAndConditionsPrivacyPolicyURLConstants';
import { useBreakpoint } from 'hooks';

export interface TermsConditionsPrivacyPolicy {
  country: string;
  termsConditionURL: string;
  privacyPolicy: string;
  countryCode: string;
  countryId: string;
  cookiePolicyURL: string;
  priceDrawRulesPdf: string;
}

interface FooterProps {
  sectionRef?: () => void;
}

const Footer = ({ sectionRef }: FooterProps): JSX.Element => {
  const {
    apiData: { recommendationCropwise },
  } = useAppState();
  const { isMobile } = useBreakpoint();

  const country = recommendationCropwise?.country ?? '';
  const isDDG = recommendationCropwise?.is_ddg;
  const { t } = useTranslation();

  return (
    <FooterRoot isMobile={isMobile} ref={sectionRef}>
      <FooterContent isDDG={isDDG}>
        <TopSection>
          <DisclaimerTextContainer>
            <DisclaimerTextStyled>
              {t('Disclaimer')}: {t('Stock subject to availability.')}
            </DisclaimerTextStyled>
            <DisclaimerTextStyled>{t('recommendationFooterDisclaimer')}</DisclaimerTextStyled>
          </DisclaimerTextContainer>
        </TopSection>
        <BottomSection isMobile={isMobile}>
          <ParagraphContainer isMobile={isMobile}>
            <StyledParagraph>
              <a href={getUrl(country)?.privacyPolicy} target="_blank" rel="noopener noreferrer">
                <StyledAnchorText>
                  {t('Privacy Policy')}
                  {' |'}
                </StyledAnchorText>
              </a>
              &nbsp;
              <a
                href={getUrl(country)?.termsConditionURL}
                target="_blank"
                rel="noopener noreferrer"
              >
                <StyledAnchorText>
                  {t('Terms & Conditions')}
                  {' |'}
                </StyledAnchorText>
              </a>
              &nbsp;
              <a href={getUrl(country)?.cookiePolicyURL} target="_blank" rel="noopener noreferrer">
                <StyledAnchorText>{t('Cookies Policy')}</StyledAnchorText>
              </a>
            </StyledParagraph>
            <DisclaimerTextContainer>
              <GoogleAttributionTextStyled>
                {t('recaptcha protection notice', {
                  recaptcha: 'reCAPTCHA',
                  google: 'Google ',
                })}
                <a href={getUrl(country)?.privacyPolicy}>
                  <GoogleAttributionAnchorText>{t('Privacy Policy')}</GoogleAttributionAnchorText>
                </a>
                &nbsp;{t('and')}&nbsp;
                <a href={getUrl(country)?.termsConditionURL}>
                  <GoogleAttributionAnchorText>{t('Terms of Service')}</GoogleAttributionAnchorText>
                </a>
              </GoogleAttributionTextStyled>
            </DisclaimerTextContainer>
          </ParagraphContainer>
          <div>
            <img height={48} width={105} src={CropwiseLogo} alt="No img found" />
          </div>
        </BottomSection>
      </FooterContent>
    </FooterRoot>
  );
};
export { Footer };
