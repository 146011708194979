const trialLocationData = [
  {
    region: 'EAME',
    cropName: 'sunflower',
    trialLocation: '19,000',
  },
  {
    region: 'EAME',
    cropName: 'corn',
    trialLocation: '47,000',
  },
  {
    region: 'EAME',
    cropName: 'silage corn',
    trialLocation: '47,000',
  },
  {
    region: 'LATAM',
    cropName: 'summer corn',
    trialLocation: '534',
  },
  {
    region: 'LATAM',
    cropName: 'winter corn',
    trialLocation: '1135',
  },
  {
    region: 'LATAM',
    cropName: 'soybean',
    trialLocation: '3457',
  },
  {
    region: 'SA',
    cropName: 'Wheat',
    trialLocation: '211',
  },
];

export const getTrialLocation = (cropName: string, region: string): string => {
  return trialLocationData.find((data) => data.cropName === cropName && data.region === region)?.trialLocation ?? '';
};
