import styled from 'styled-components';
import { Card, colors, SYT_PREFIX } from 'syngenta-digital-cropwise-react-ui-kit';
import breakpoints from 'base/constants/breakpoints';

// Containers
export const Container = styled(Card)`
  width: 414px;
  height: 88px;
  @media screen and ${breakpoints.maxWidth.sm} {
    width: 88vw;
    height: 72px;
    line-height: 12px;
  }
  box-sizing: border-box;
  display: table;
  .container-img {
    padding-right: 15px;
    align-self: start;
    padding-top: 7px;
  }

  .${SYT_PREFIX}-card-body {
    padding: 10px 32px 10px 21px;
    display: table-cell;
    vertical-align: middle;
  }
  .anticon {
    vertical-align: -0.3em;
  }
  .title {
    &.${SYT_PREFIX}-typography {
      margin: 0;
      font-size: 20px;
    }
  }

  .paragraph {
    &.${SYT_PREFIX}-typography {
      margin: 0 18px 0 0;
      color: ${colors.neutral60};
    }
  }
`;

export const StyledMainContainer = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: center;
`;

export const StyledRowContainer = styled.div`
  display: flex;
  flex-direction: row;
  align-items: center;
`;

export const StyledColumnContainer = styled.div`
  display: flex;
  flex-direction: column;
`;
