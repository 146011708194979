export const isFloatNumber = (num: string | number) => {
  const floatNumber = parseFloat(num.toString());
  return !isNaN(floatNumber);
};

export const areOnlyLetters = (str: string) => {
  return /^[a-zA-Z\s]*$/.test(str);
};

//regex for special characters and numbers
export const regexSpecialCharAndNumbersValidation = /[_`.@!#$%^&*()+=[\]{};':"\\|,<>/?~\d]/g;

//regex for email with special characters and numbers
export const regexForEmailValidation = /[`!#$%^&*()+=[\]{};':"\\|,<>/?~•√π÷×§∆°¥€¢£©®™✓]/g;

export const areSpecialCharactersOrNumbers = (str: string) : boolean => {
  return regexSpecialCharAndNumbersValidation.test(str);
};
export const isEmail = (email: string) => {
  return RegExp(/^(([^.\s@]+(\.[^.\s@]+)*)|(".+"))@((([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/).exec(String(email)
    .toLowerCase());
};

export const numberWithCommas = (x: number) => {
  return x.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ',');
};
