import { useRef } from 'react';
import Maps from 'components/Maps/MapsDrawBoundary';
import { useAppState } from 'context/AppState';
import { MapContainer } from './MapView.styles';
import { useBreakpoint } from 'hooks';

interface IProps {
  stepControlsProps: any;
}
const MapView = ({ stepControlsProps }: IProps) => {
  const mapContainerRef = useRef(null);
  const screens = useBreakpoint();
  const { isMobile } = screens;
  const {
    map: { center: mapCenter, mapStyle },
  } = useAppState();
  const [longitude, latitude] = mapCenter;

  return (
    <MapContainer ref={mapContainerRef} id="map-container" className="draw-flow">
      <Maps
        mapId={isMobile ? 'flowMapMobile' : 'flowMap'}
        longitude={longitude}
        latitude={latitude}
        mapStyle={mapStyle}
        stepControlsProps={stepControlsProps}
      />
    </MapContainer>
  );
};

export default MapView;
