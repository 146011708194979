import { CloseOutlined } from '@ant-design/icons';
import { ReactNode } from 'react';
import { useSwipeAction } from 'hooks/useSwipeDown';
import { useFlowActions } from 'context/actions/flowActions';
import { colors } from 'syngenta-digital-cropwise-react-ui-kit';
import {
  StyledHeaderWithRectangle,
  StyledHeader,
  StyledTitle,
  ViewFooterOverlay,
  CloseContainer,
  Divider,
  MainContent,
  ViewFooterContainer,
  HeaderRectangle,
} from './ViewFooter.styles';

interface ViewFooterProps {
  [key: string]: any;
  children: ReactNode;
}

const ViewFooter: React.FC<ViewFooterProps> = ({
  children,
  title,
  onCancel,
  showHeaderRectangle,
  ...others
}) => {
  const flowActions = useFlowActions();
  const { setIsModalToBeClosed } = flowActions;

  const handleSwipe = () => {
    setIsModalToBeClosed({ isModalToBeClosed: true });
  };

  const swipeHandlers = useSwipeAction(['Down'], handleSwipe);

  return (
    <>
      <ViewFooterOverlay data-testid="view-footer-overlay" onClick={onCancel} />
      <ViewFooterContainer {...others}>
        {showHeaderRectangle ? (
          <StyledHeaderWithRectangle {...swipeHandlers}>
            <HeaderRectangle data-testid="header-rectangle" />
            <StyledTitle> {title} </StyledTitle>
          </StyledHeaderWithRectangle>
        ) : (
          <StyledHeader>
            <StyledTitle> {title} </StyledTitle>
            <CloseContainer onClick={onCancel}>
              <CloseOutlined color={colors.neutral60} />
            </CloseContainer>
          </StyledHeader>
        )}
        <Divider />
        <MainContent>{children}</MainContent>
      </ViewFooterContainer>
    </>
  );
};

export default ViewFooter;
