/* eslint-disable array-bracket-newline */
import { useCallback, useEffect, useRef, useState } from 'react';
import { LngLat, Style } from 'mapbox-gl';
import MapboxGeocoder, { Result } from '@mapbox/mapbox-gl-geocoder';
import { colors } from 'syngenta-digital-cropwise-react-ui-kit';
import { ZoomControl } from './Controls/ZoomControl';
import { LocationControl } from './Controls/LocationControl';
import { CustomControl } from './Controls/CustomControl';
import { StepsControl } from './Controls/StepsControl';
import IconClose from 'components/Icons/IconClose';
import NotificationTextTranslation from 'components/NotificationTextTranslation';
import { ButtonBaseProps } from 'components/Buttons/ButtonBase';
import { useTranslation } from 'react-i18next';

import { FlowSteps } from 'context/store/flowReducer';

import { browserDetector } from 'utils/helpers/browser';
import { MapsConstants } from 'utils/constants/MapsConstants';
import { BrowserConstants } from 'utils/constants/BrowserConstants';

import { StyledMarker, StyledPin, StyledNotificationContent } from './Maps.styles';
import IconCloseRounded from 'components/Icons/IconCloseRounded';
import 'mapbox-gl/dist/mapbox-gl.css';
import '@mapbox/mapbox-gl-geocoder/dist/mapbox-gl-geocoder.css';
import './map.less';

import {
  Map as MapReact,
  useMap,
  Marker as MarkerReact,
  ViewStateChangeEvent,
  Source,
  Layer,
} from 'react-map-gl';

import { useAppState } from 'context/AppState';
import { Feature, Polygon } from '@turf/turf';
import { pointInBbox } from 'utils/helpers/geospatial';
import { useBreakpoint } from 'hooks';
import openMapNotification from 'utils/openMapNotification';
import createCircleFeild from 'utils/createCircleField';
import { useFlags } from 'launchdarkly-react-client-sdk';
import { ConfirmationModal } from 'components/ConfirmationModal';
import { useNavigate } from 'react-router-dom';
import { useFlowActions } from 'context/actions/flowActions';
import routes from 'base/constants/routes';
import { processGeocodingResult } from 'utils/helpers/geocodingResult';

interface StepsProps {
  buttonNextProps?: ButtonBaseProps;
  buttonPreviousProps?: ButtonBaseProps;
}
interface IProps {
  id: string;
  latitude?: number;
  longitude?: number;
  zoom?: number;
  minZoom?: number;
  maxZoom?: number;
  mapStyle?: string | Style;
  stepsControlProps?: StepsProps | null;
  isLocationEnabled?: boolean;
  showPin?: boolean;
  onChangePolygon?: (polygons: Feature<Polygon> | undefined) => void;
  onMove?: () => void;
}

const browserDetected = browserDetector();
let locationURL = BrowserConstants.CHROME_LOCATION_URL;
switch (browserDetected) {
  case BrowserConstants.FIREFOX:
    locationURL = BrowserConstants.FIREFOX_LOCATION_URL;
    break;
  case BrowserConstants.SAFARI:
    locationURL = BrowserConstants.SAFARI_LOCATION_URL;
    break;
  case BrowserConstants.EDGE:
    locationURL = BrowserConstants.EDGE_LOCATION_URL;
    break;
  case BrowserConstants.OPERA:
    locationURL = BrowserConstants.OPERA_LOCATION_URL;
    break;
  default:
    break;
}

const MapsDropAPin = ({
  id,
  latitude,
  longitude,
  zoom = MapsConstants.ZOOM_DEFAULT,
  minZoom = 0,
  maxZoom = 22,
  mapStyle = MapsConstants.MAP_STYLE_URL,
  stepsControlProps,
  isLocationEnabled = false,
  onMove,
}: IProps): JSX.Element => {
  const { flow, recommendationForm } = useAppState();
  const [displayControls, setDisplayControls] = useState(false);
  const [userLocation, setUserLocation] = useState<LngLat | null>();
  const [intersectLocation, setIntersectLocation] = useState(false);
  const [showNotification, setShowNotification] = useState(true);
  const [latitudeCenter, setLatitudeCenter] = useState(MapsConstants.LATITUDE_DEFAULT);
  const [longitudeCenter, setLongitudeCenter] = useState(MapsConstants.LONGITUDE_DEFAULT);
  const [showUserMarker, setShowUserMarker] = useState(false);
  const [latitudeUser, setLatitudeUser] = useState(MapsConstants.LATITUDE_DEFAULT);
  const [longitudeUser, setLongitudeUser] = useState(MapsConstants.LONGITUDE_DEFAULT);
  const screens = useBreakpoint();
  const { [id]: map } = useMap();
  const geocoderRef = useRef<MapboxGeocoder | null>(null);
  const { t, i18n } = useTranslation();
  const { isMobile, isDesktop } = screens;
  const mapInteractive = !flow.arePinConfirmed;
  const interactiveProps = {
    zoomEnabled: mapInteractive,
    scrollEnabled: mapInteractive,
    pitchEnabled: mapInteractive,
    rotateEnabled: mapInteractive,
    dragPan: mapInteractive,
    dragRotate: mapInteractive,
    doubleClickZoom: mapInteractive,
  };
  let polygon;
  const flags = useFlags();
  const isExistingPinFocusedOnce = useRef(false);
  const navigate = useNavigate();
  const { setShowCancelBoundaryModal, setUserCountryCode } = useFlowActions();
  const isBrazilEnable = flags.isBrazilEnable;

  const addControls = () => {
    map?.addControl(new CustomControl('stepsControl'), 'bottom-left');
    map?.addControl(new CustomControl('zoomControl'), 'bottom-left');
    map?.addControl(new CustomControl('locationControl'), 'bottom-left');
    map?.addControl(new CustomControl('geocoderControl'), 'top-right');
  };

  const handleGeocodingResult = (geocodingResult: Result) => {
    processGeocodingResult(geocodingResult, flags, setUserCountryCode, map);
    return '';
  };

  const initializeGeocoding = () => {
    const geocoder = new MapboxGeocoder({
      accessToken: `${process.env.REACT_APP_MAPBOX_API_KEY}`,
      limit: MapsConstants.GEOCODER_LIMIT_SUGGESTIONS,
      minLength: MapsConstants.GEOCODER_MIN_LENGHT,
      countries: isBrazilEnable
        ? MapsConstants.GEOCODER_COUNTRIES_INCLUDING_BRAZIL
        : MapsConstants.GEOCODER_COUNTRIES,
      getItemValue: handleGeocodingResult,
      language: i18n.language,
      placeholder: t('Search Geocoder'),
    });
    geocoder.addTo('#geocoderControl');

    geocoderRef.current = geocoder;

    // replace clear icon
    const geocoderClearButton = document.querySelector(
      '.mapboxgl-ctrl-geocoder--button'
    ) as HTMLElement;
    const icon = document.getElementById('iconClear');
    // eslint-disable-next-line
    geocoderClearButton?.appendChild(icon!);

    const geocoderInput = document.querySelector('.mapboxgl-ctrl-geocoder--input') as HTMLElement;

    geocoderClearButton.style.backgroundColor = '#232630';
    geocoderInput.style.color = '#FFF';
    geocoderInput.style.backgroundColor = '#232630';
    geocoderInput.style.borderRadius = '4px';
    geocoderInput.addEventListener('input', () => {
      geocoderClearButton.style.display = 'block';
    });

    geocoder.on('loading', () => {
      geocoderClearButton.style.display = 'none';
    });

    geocoder.on('results', () => {
      geocoderClearButton.style.display = 'block';
    });

    geocoder.on('result', () => {
      if (isMobile) {
        const inputField = document.querySelector(
          '.mapboxgl-ctrl-geocoder--input'
        ) as HTMLInputElement;
        inputField?.blur();

        const closeButtons = Array.from(
          document.querySelectorAll('.syt-antd-notification-notice-close')
        );
        closeButtons.forEach((closeButton) => {
          const element = closeButton as HTMLElement;
          const spanElement = element?.firstChild as HTMLElement;
          spanElement.click();
        });
      }
    });

    if (isMobile) {
      const inputField = document.querySelector(
        '.mapboxgl-ctrl-geocoder--input'
      ) as HTMLInputElement;
      inputField.addEventListener('focus', () => {
        const closeButtons = Array.from(
          document.querySelectorAll('.syt-antd-notification-notice-close')
        );
        closeButtons.forEach((closeButton) => {
          const element = closeButton as HTMLElement;
          const spanElement = element?.firstChild as HTMLElement;
          spanElement.click();
        });
      });
    }
  };

  const zoomIn = () => {
    map?.getMap().zoomIn();
  };

  const zoomOut = () => {
    map?.getMap().zoomOut();
  };

  const onUserLocated = (lngLat: LngLat) => {
    if (map) {
      setLongitudeUser(lngLat.lng);
      setLatitudeUser(lngLat.lat);
      setShowUserMarker(true);

      setUserLocation(lngLat);
      if (flow.isAnotherRecommendationCreated && !isExistingPinFocusedOnce.current) {
        map?.once('moveend', () => {
          isExistingPinFocusedOnce.current = true;
        });
        return;
      }
      map.flyTo({
        center: [lngLat.lng, lngLat.lat],
        essential: true,
        zoom: 15,
      });
    }
  };

  const onLocationError = () => {
    if (!showNotification) {
      return;
    }

    openMapNotification({
      id: 'preciseLocation',
      className: 'toast-notification-without-icon',
      msg: (
        <StyledNotificationContent>
          <NotificationTextTranslation text={'Your precise location could not be determined.'} />{' '}
          <a target="_blank" rel="noreferrer" href={locationURL}>
            <NotificationTextTranslation text={'LEARN MORE'} />
          </a>
        </StyledNotificationContent>
      ),
      placement: isMobile ? 'top' : 'bottom',
      duration: 10,
      width: 480,
      closeIcon: <IconClose color={colors.neutral40} width={18} height={18} />,
      onClose: () => setShowNotification(true),
      icon: <></>,
    });
    setShowNotification(false);
  };

  const onClickHandler = (e: mapboxgl.MapMouseEvent & mapboxgl.EventData) => {
    e.originalEvent.stopPropagation();
  };

  const handleOnLoadMap = () => {
    setDisplayControls(true);
    addControls();
    initializeGeocoding();
  };

  const onDbleClickHandler = (e: mapboxgl.MapMouseEvent & mapboxgl.EventData) => {
    e.originalEvent.stopPropagation();
  };

  const onMouseMoveHandler = (e: mapboxgl.MapMouseEvent & mapboxgl.EventData) => {
    e.originalEvent.stopPropagation();
  };

  const checkIntersection = () => {
    if (!userLocation) {
      return;
    }
    // eslint-disable-next-line
    setIntersectLocation(pointInBbox(userLocation, map!.getBounds()));
  };

  const onMoveHandler = (e: ViewStateChangeEvent) => {
    onMove?.();
    if (!flow.arePinConfirmed) {
      setLatitudeCenter(e.viewState.latitude);
      setLongitudeCenter(e.viewState.longitude);
    }

    checkIntersection();
  };

  const handleGoBackModalConfirm = useCallback(() => {
    setShowCancelBoundaryModal({ show: false });
    navigate(routes.home);
  }, [navigate, setShowCancelBoundaryModal]);

  if (
    flow.arePinConfirmed &&
    recommendationForm.pin.position &&
    process.env.REACT_APP_SHOW_FIELD_AREA === 'true'
  ) {
    const { lat, lng } = recommendationForm.pin.position;
    const center = [lng, lat];
    polygon = createCircleFeild(center, 550);
  }

  useEffect(() => {
    geocoderRef.current?.setLanguage(i18n.language);
    geocoderRef.current?.setPlaceholder(t('Search Geocoder'));
  }, [i18n.language, t]);

  return (
    <MapReact
      id={id}
      onMove={(e) => onMoveHandler(e)}
      onLoad={handleOnLoadMap}
      onClick={onClickHandler}
      onDblClick={onDbleClickHandler}
      onMouseMove={onMouseMoveHandler}
      minZoom={minZoom}
      maxZoom={maxZoom}
      initialViewState={{
        longitude,
        latitude,
        zoom,
      }}
      mapStyle={mapStyle}
      style={{ flexGrow: 1 }}
      {...interactiveProps}
    >
      <div data-testid="mapContainer" style={{ display: displayControls ? 'block' : 'none' }}>
        <ZoomControl
          id="zoomControl"
          buttonPlusProps={{ 'aria-label': 'Zoom In', onClick: zoomIn }}
          buttonMinusProps={{ 'aria-label': 'Zoom Out', onClick: zoomOut }}
          style={{ display: isDesktop ? 'block' : 'none' }}
        />
        <LocationControl
          buttonProps={{
            id: 'locationControl',
            'aria-label': 'Find my location',
          }}
          onUserLocated={(position: LngLat) => onUserLocated(position)}
          onLocationError={onLocationError}
          isLocationEnabled={isLocationEnabled}
          mapIntersected={intersectLocation}
        />
        <StepsControl
          id="stepsControl"
          buttonNextProps={stepsControlProps?.buttonNextProps}
          buttonPreviousProps={stepsControlProps?.buttonPreviousProps}
        />
        <div
          id="geocoderControl"
          style={{ display: flow.currentStep === FlowSteps.STEP1 ? 'block' : 'none' }}
        >
          <IconCloseRounded id="iconClear" />
        </div>
      </div>
      {showUserMarker && (
        <MarkerReact style={{ zIndex: 1 }} latitude={latitudeUser} longitude={longitudeUser}>
          <StyledMarker />
        </MarkerReact>
      )}

      {polygon && (
        <Source id={'circle'} type="geojson" data={polygon}>
          <Layer
            type="fill"
            source="circle-field"
            paint={{ 'fill-color': colors.blue40, 'fill-opacity': 0.3 }}
          />
          <Layer
            type="line"
            source="circle-field-line"
            paint={{ 'line-color': colors.blue50, 'line-width': 2 }}
          />
        </Source>
      )}
      {flow.showCancelBoundaryModal && (
        <ConfirmationModal
          title={t('Exit Drop A Pin')}
          body={t(
            'Are you sure you want to navigate away from this page? All progress will be lost.'
          )}
          cancelButtonText={t('No, remain')}
          confirmButtonText={t('Yes, exit')}
          onClickConfirm={handleGoBackModalConfirm}
          onClickCancel={() => {
            setShowCancelBoundaryModal({ show: false });
          }}
        />
      )}

      <MarkerReact
        style={{ zIndex: 10 }}
        latitude={!flow.arePinConfirmed ? latitudeCenter : recommendationForm.pin.position?.lat}
        longitude={!flow.arePinConfirmed ? longitudeCenter : recommendationForm.pin.position?.lng}
      >
        <StyledPin src={flow.localCountry} className="animate-drop-pin" />
      </MarkerReact>
    </MapReact>
  );
};

export default MapsDropAPin;
