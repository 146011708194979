import { Button } from 'antd';
import styled from 'styled-components';
import Icon from '@ant-design/icons';

import { colors } from 'syngenta-digital-cropwise-react-ui-kit';
import breakpoints from 'base/constants/breakpoints';

const BorderStyles = `
  border: none;
  border-radius: 4px;
`;

interface StyledIconContainerProps {
  textSize: 'SMALL' | 'MEDIUM' | 'LARGE';
}
const StyledIconContainer = styled('span')<StyledIconContainerProps>`
  font-size: ${({ textSize }) => {
    switch (textSize) {
      case 'SMALL':
        return '14px';
      case 'MEDIUM':
        return '16px';
      case 'LARGE':
        return '18px';
    }
  }};
  align-items: center;
`;

const StyledTextContainer = styled('span')`
  margin-right: 10px;
`;

const StyledTextContainerInverted = styled('span')`
  margin-left: 10px;
  line-height: 14px;
  @media screen and ${breakpoints.maxWidth.sm} {
    margin-left: 3px;
    font-size: 15px;
  }
`;

const StyledButtonIcon = styled(Icon)`
  color: ${colors.neutral70};
  border: 0;
  margin: 0;
  padding: 0;
  line-height: 0;
`;

interface ButtonBasicsProps {
  fullWidth: boolean;
  heightSize: 'SMALL' | 'MEDIUM' | 'LARGE';
}
const ButtonBasics = styled(Button)<ButtonBasicsProps>`
  height: ${({ heightSize }) => (heightSize === 'SMALL' ? '40px' : '44px')};
  width: ${({ fullWidth }) => fullWidth && '100% !important'};
  display: flex;
  align-items: center;
  justify-content: center;
  line-height: 0;
  ${BorderStyles}
  cursor: pointer;
  &:disabled {
    cursor: not-allowed;
    background: ${colors.neutral10};
    color: ${colors.neutral30};
    border: 1px solid ${colors.neutral20};
  }
  &:focus {
    outline: none;
  }
`;
const StyledButtonRectangleSuccess = styled(ButtonBasics)`
  &:enabled {
    background-color: ${colors.green60};
    color: ${colors.neutral00};
    &:hover {
      background-color: ${colors.green50};
    }
    &:focus {
      background-color: ${colors.green50};
      box-shadow: 0px 0px 0px 3px rgba(25, 160, 75, 0.25);
    }
    &:active {
      background: ${colors.green70};
      box-shadow: none;
    }
  }
`;
const StyledButtonRectangleDanger = styled(ButtonBasics)`
  &:enabled {
    background-color: ${colors.red60};
    color: ${colors.neutral00};
    &:hover {
      background-color: ${colors.red50};
    }
    &:focus {
      background-color: ${colors.red50};
      box-shadow: 0px 0px 0px 3px rgba(235, 75, 75, 0.25);
    }
    &:active {
      background: ${colors.red70};
      box-shadow: none;
    }
  }
`;
const StyledButtonRectangleDefault = styled(ButtonBasics)`
  &:enabled {
    background-color: ${colors.neutral10};
    color: ${colors.neutral100};
    &:hover {
      background-color: ${colors.neutral20};
    }
    &:focus {
      background-color: ${colors.neutral10};

      box-shadow: 0px 0px 0px 3px rgba(0, 146, 228, 0.25);
    }
    &:active {
      background: ${colors.neutral30};
      box-shadow: none;
    }
  }
`;
const StyledButtonRectangleOutlined = styled(ButtonBasics)`
  &:enabled {
    background-color: ${colors.neutral00};
    color: ${colors.neutral100};
    border: 1px solid ${colors.neutral30};
    &:hover {
      background-color: ${colors.neutral10};
    }
    &:focus {
      background-color: ${colors.neutral10};
      box-shadow: 0px 0px 0px 3px rgba(0, 146, 228, 0.25);
    }
    &:active {
      background: ${colors.neutral20};
      box-shadow: none;
    }
  }
`;

export {
  StyledIconContainer,
  StyledTextContainer,
  StyledTextContainerInverted,
  StyledButtonRectangleDefault,
  StyledButtonIcon,
  StyledButtonRectangleOutlined,
  StyledButtonRectangleSuccess,
  StyledButtonRectangleDanger,
};
