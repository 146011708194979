import styled from 'styled-components';
import { Button, Tooltip } from 'syngenta-digital-cropwise-react-ui-kit';
import Icon from '@ant-design/icons';

import { colors } from '../../../theme/colors';
import { TooltipProps } from 'antd';

const StyledButton = styled(Button)`
  height: 40px;
  width: 40px;
  display: flex;
  align-items: center;
  justify-content: center;
  border: none;
  box-shadow: 0px 2px 8px rgba(0, 0, 0, 0.15);

  &:hover {
    border: none;
    background-color: ${colors.neutral30};
  }
`;

const StyledButtonIcon = styled(Icon)({
  color: colors.neutral70,
  border: 0,
  margin: 0,
  padding: 0,
  lineHeight: 0,
});

type TooltipCustomProps = TooltipProps & { fixedTooltipLabel?: boolean };

const StyledTooltip = styled(Tooltip)<TooltipCustomProps>`
  & + div div {
    left: ${(props) => (props.fixedTooltipLabel ? 'unset !important;' : '')};
    right: ${(props) => (props.fixedTooltipLabel ? '21px;' : '')};
    pointer-events: none;
  }
`;

export { StyledButton, StyledButtonIcon, StyledTooltip };
