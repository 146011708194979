import ModalView from 'components/ModalView';
import breakpoints from 'base/constants/breakpoints';
import { colors, SYT_PREFIX, Typography, Row, Col, ButtonType } from 'syngenta-digital-cropwise-react-ui-kit';
import styled from 'styled-components';
import { showSlidingAnimation } from 'utils/modalBoxAnimations';
import { IStyledModalProps } from 'base/types/ModalBox';
import { Button } from 'antd';

const { Paragraph } = Typography;

export const StyledModalView = styled(ModalView) <IStyledModalProps>`
  .${SYT_PREFIX}-modal-content {
    border-radius: 8px;
  }
  ${(props) => showSlidingAnimation(props.slideDirection)}
  @media screen and (max-height: 769px) {
    .${SYT_PREFIX}-modal-content {
      top: 56px !important;
      overflow: auto !important;
    }
  }
  .${SYT_PREFIX}-modal-header {
    border-radius: 8px;
  }
`;

export const StyledParagraph = styled(Paragraph)`
  font-size: 14px;
  color: ${colors.neutral70};

  &.cwsTypography {
    margin-bottom: 26px;
  }
`;

export const StyledButtonRow = styled(Row)`
  @media screen and (${breakpoints.maxWidth.md}) {
    margin-top: auto;
    bottom: 0px;
  }

  @media screen and (${breakpoints.maxWidth.xs}) {
    display: flex;
    flex-direction: column;
  }
`;

export const StyledButton = styled(Button)`
  height: 44px;
  display: flex;
  align-items: center;
  justify-content: center;
  border: none;
  gap: 10px;
  flex: 1;
  z-index: 20;

  font-style: normal;
  font-weight: 400;
  font-size: 16px;
  line-height: 24px;
  /* identical to box height, or 150% */
  letter-spacing: -0.0075em;
  background-color: ${(props) => (props.type === ButtonType.default ? '#F3F4F6' : '#14803C')};
  border-radius: 4px;
  color: ${(props) => (props.type === ButtonType.default ? '#14151C' : '#FFFFFF')};
  &:disabled {
    background-color: #F3F4F6;
    color: #C2C7D0;
    border-color: @cws-neutral-20 !important;
  }

  @media screen and ${breakpoints.maxWidth.sm} {
    height: 44px;
    border: ${(props) => props.type === ButtonType.default && `1px solid ${colors.neutral30}`};

    &:hover {
      border: ${(props) => props.type === ButtonType.default && `1px solid ${colors.neutral30}`};
    }
  }
`;

export const StyledAnchor = styled.a`
  color: ${colors.green60};

  text-decoration: underline;
  font-style: normal;
  font-weight: 400;
  font-size: 14px;
  line-height: 20px;
  /* identical to box height, or 143% */
  letter-spacing: -0.0075em;
`;

export const StyledSpanText = styled.span`
  color: ${colors.neutral60};
  font-style: normal;
  font-weight: 400;
  font-size: 14px;
  line-height: 20px;
  /* identical to box height, or 143% */
  letter-spacing: -0.0075em;
`;

export const StyledCheckboxContainer = styled.div`
  padding-bottom: 16px;
`;

export const StyledMainContainer = styled.div`
  display: flex;
  flex-direction: column;
`;

const ColButtonStyled = styled(Col)`
  @media screen and (${breakpoints.maxWidth.md}) {
    margin-bottom: 16px;
  }
`;

export const StyledContainer = styled.div`
  display: flex;
  flex: 1;
  flex-direction: column;
  overflow-y: auto;
`;
export const StyledButtonFooter = styled.div`
  position: fixed;
  bottom: 0;
  width: 100%;
  background-color: #fff; 
  padding: 16px; 
  display: flex;
  justify-content: space-between;
  gap:10px;
  left:0;
  padding-top:15px;
`;
export const ErrorMessage = styled.span`
  color: #CF3537; 
  fontFamily: Noto Sans;
  fontSize: 12px;
  fontWeight: 400;
  lineHeight: 12px;
  letterSpacing: -0.0075em;
  textAlign: left;
  width: 311px;
  height: 12px;
  left: 16px;
  padding-bottom:20px;
`;

export { ColButtonStyled };
