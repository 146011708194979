export const YIELD_RANGE_CATEGORY = {
  YIELD_LOW: 'YieldLow',
  YIELD_MEDIUM: 'Medium',
  YIELD_HIGH: 'YieldHigh',
};

// TODO: Remove and change once yield range mapping is confirmed
export const BRAZIL_YIELD_RANGE_CATEGORY = {
  VL: 'brazil yield range very low',
  L: 'brazil yield range low',
  M: 'brazil yield range med',
  H: 'brazil yield range high',
  VH: 'brazil yield range very high'
};

// TODO: Remove and change once yield range mapping is confirmed
export const BRAZIL_YIELD_RANGE_CATEGORY_FOR_SUMMER_CORN = {
  L: 'brazil summer corn yield range low',
  M: 'brazil summer corn yield range med',
  H: 'brazil summer corn yield range high',
};
