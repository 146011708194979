import styled, { css } from 'styled-components';
import { colors } from 'syngenta-digital-cropwise-react-ui-kit';

import IconDropletSize from 'components/Icons/IconDropletSize';
import IconThermostat from 'components/Icons/IconThermostat';
import breakpoints from 'base/constants/breakpoints';

interface BarChartLabelProps {
  isPdfView?: boolean;
}

const TextContainer = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
`;

const Container = styled(TextContainer)<BarChartLabelProps>`
  overflow: hidden;
  justify-content: start;
  transform: translate(-50%);
  padding: 0 15px;
  position: relative;

  ${({ isPdfView }) =>
    !isPdfView &&
    css`
      @media only screen and ${breakpoints.maxWidth.xl} {
        padding: 0 0;
      }
  `}

  ${({ isPdfView }) =>
    isPdfView &&
    css`
      transform: translate(0);
      padding: 0 0;
  `}
`;

const StyledTitle = styled.h1<{ color: string } & BarChartLabelProps>`
  font-size: 16px;
  font-weight: bold;
  text-align: center;
  color: ${({ color }) => color};

  ${({ isPdfView }) =>
    !isPdfView &&
    css`
      @media only screen and ${breakpoints.minWidth.sm} and ${breakpoints.maxWidth.xl} {
        font-size: 14px;
      }
    `}
`;

const StyledPrecipitationContainer = styled.div<BarChartLabelProps>`
  display: flex;
  align-self: start;
  align-items: center;
  font-size: 14px;

  ${({ isPdfView }) =>
    !isPdfView &&
    css`
      @media only screen and ${breakpoints.minWidth.sm} and ${breakpoints.maxWidth.xl} {
        font-size: 12px;
      }

      @media only screen and ${breakpoints.maxWidth.sm} {
        align-items: start;
      }
    `}
`;

const StyledTemperatureContainer = styled(StyledPrecipitationContainer)`
  margin-bottom: 2px;
`;

const StyledTemperatureTitleContainer = styled(StyledPrecipitationContainer)`
  display: flex;
  justify-content: center;
  margin-bottom: 2px;
`;

const StyledTemperatureBoldText = styled.div`
  font-style: normal;
  font-weight: 700;
  font-size: 14px;
  line-height: 22px;
  letter-spacing: -0.0075em;
`;

const StyledIconDropletSize = styled(IconDropletSize)<BarChartLabelProps>`
  width: 15px;
  margin-right: 8px;

  ${({ isPdfView }) =>
    !isPdfView &&
    css`
      @media only screen and ${breakpoints.maxWidth.sm} {
        margin-top: 5px;
      }
    `}
`;

const StyledIconThermostat = styled(IconThermostat)<BarChartLabelProps>`
  width: 15px;
  margin-right: 8px;

  ${({ isPdfView }) =>
    !isPdfView &&
    css`
      @media only screen and ${breakpoints.maxWidth.sm} {
        margin-top: 5px;
      }
    `}
`;

const StyledYearsContainer = styled(TextContainer)`
  flex-direction: row;
  width: 100%;
  gap: 8px;
  margin-top: 16px;
`;

const StyledYearRectangle = styled.div<{ color: string }>`
  display: inline-flex;
  min-width: 24px;
  height: 24px;
  border-radius: 4px;
  background-color: ${({ color }) => color};
`;

const StyledYearText = styled.div<BarChartLabelProps>`
  display: inline-flex;
  font-size: 16px;
  color: ${colors.neutral90};

  ${({ isPdfView }) =>
    !isPdfView &&
    css`
      @media only screen and ${breakpoints.minWidth.sm} and ${breakpoints.maxWidth.xl} {
        font-size: 14px;
      }
    `}
`;

export {
  Container,
  TextContainer,
  StyledTitle,
  StyledTemperatureContainer,
  StyledPrecipitationContainer,
  StyledIconDropletSize,
  StyledIconThermostat,
  StyledYearsContainer,
  StyledYearRectangle,
  StyledYearText,
  StyledTemperatureTitleContainer,
  StyledTemperatureBoldText,
};
