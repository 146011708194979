export class CustomControl {
  private _map: unknown = null;
  private _container: HTMLElement | null = null;
  private _id = '';

  constructor(id: string) {
    this._id = id;
  }

  onAdd(map: any) {
    const mainDiv = document.createElement('div');
    // eslint-disable-next-line
    const control = document.getElementById(`${this._id}`)!;
    mainDiv.appendChild(control);

    this._map = map;
    this._container = mainDiv;
    this._container.className = 'mapboxgl-ctrl';
    return this._container;
  }

  onRemove() {
    this._container?.parentNode?.removeChild(this._container);
  }
}
