import styled from 'styled-components';
import { Layout, Typography, colors, SYT_PREFIX } from 'syngenta-digital-cropwise-react-ui-kit';
import breakpoints from 'base/constants/breakpoints';
import ButtonGroup from 'components/ButtonGroup';

const { Paragraph, Title } = Typography;
const { Sider } = Layout;

export const StyledSider = styled(Sider)`
  background-color: ${colors.neutral00} !important;
  height: 100%;
  flex: 1;

  .${SYT_PREFIX}-layout-sider-children {
    display: flex;
    flex-direction: column;
    margin: 0;
  }

  @media only screen and ${breakpoints.maxWidth.sm} {
    z-index: 2000;
    min-width: 100vw !important;
  }
`;

export const StyledContainer = styled.div`
  padding: 21px;
  padding-bottom: 0;
  overflow-y: auto;
`;

export const StyledTitle = styled(Title)`
  color: ${colors.neutral70} !important;
  font-size: 20px;
  font-weight: 600;
  margin-bottom: 19px;
`;

export const StyledParagraph = styled(Paragraph)`
  font-size: 14px;
  color: ${colors.neutral70};

  &.cwsTypography {
    margin-bottom: 26px;
  }
`;

export const FieldListContainer = styled.div`
  display: flex;
  flex-direction: column;
  gap: 24px;
  margin-bottom: 20px;
`;

export const StyledButtonGroup = styled.div`
  display: flex;
  margin-top: auto;
  padding: 21px;
  padding-top: 0px;
  background-color: ${colors.neutral00};
  gap: 16px;

  button {
    flex: 1;
  }
`;

export const FooterButtonGroup = styled(ButtonGroup)`
  position: relative;
  padding: 21px;
  padding-top: 0;
  margin-top: auto;
`;
