import { Feature } from '@turf/helpers';
import { useAppDispatch } from 'context/AppState';
import { Polygon } from 'geojson';
import { useRef } from 'react';

export enum ActionTypes {
  setMapStyle = 'map/setMapStyle',
  setMapCenter = 'map/setMapCenter',
  saveNewBoundary = 'map/saveNewBoundary',
  setMapToolsOptionSelected = 'map/setMapToolsOption',
  deleteLastBoundary = 'map/deleteLastBoundary',
  setUserDropPinPosition = 'map/setUserDropPinPosition',
  setIPApiLoading = 'map/setIPApiLoading',
}

export function useMapActions() {
  const appDispatcher = useAppDispatch();

  const actions = useRef({
    setMapStyle: (payload: { mapStyle: string }) => {
      appDispatcher({ type: ActionTypes.setMapStyle, payload });
    },
    setMapCenter: (payload: { center: [longitude: number, latitude: number] }) => {
      appDispatcher({ type: ActionTypes.setMapCenter, payload });
    },
    setIPApiLoading: (payload: { ipApiLoading: boolean }) => {
      appDispatcher({ type: ActionTypes.setIPApiLoading, payload });
    },
    saveNewBoundary: (payload: { boundaries: Feature<Polygon> }) => {
      appDispatcher({
        type: ActionTypes.saveNewBoundary,
        payload: { newBoundaries: payload.boundaries },
      });
    },
    setMapToolsOptionSelected: (mapToolsOptionSelected: number) => {
      appDispatcher({
        type: ActionTypes.setMapToolsOptionSelected,
        payload: { mapToolsOptionSelected },
      });
    },
    deleteLastBoundary: () => {
      appDispatcher({ type: ActionTypes.deleteLastBoundary });
    },
    setUserDropPinPosition: (payload: { position: mapboxgl.LngLat }) => {
      appDispatcher({ type: ActionTypes.setUserDropPinPosition, payload: payload });
    },
  });

  return actions.current;
}
