import styled from 'styled-components';
import { colors } from 'syngenta-digital-cropwise-react-ui-kit';
import ButtonBase from 'components/Buttons/ButtonBase';
import breakpoints from 'base/constants/breakpoints';

interface IsMobileProps {
  isMobile: boolean;
}

export const ProductsContainer = styled.div<IsMobileProps>`
  display: inline-flex;
  align-items: center;
  justify-content: center;
  gap: 2px;
  background-color: ${colors.neutral100}85;
  border-radius: 16px;
  min-width: 50px;
  padding: 24px;

  @media screen and ${breakpoints.maxWidth.sm} {
    margin-top: 0px;
    display: flex;
    background-color: ${colors.neutral100}00 !important;
  }
  @media screen and (orientation: landscape) {
    margin-top: ${({ isMobile }) => (isMobile ? '0px' : '')} !important;
    padding: ${({ isMobile }) => (isMobile ? '0px 12px' : '')} !important;
    background-color: ${({ isMobile }) => (isMobile ? `${colors.neutral100}00` : '')} !important;
  }
`;

export const ButtonStyled = styled(ButtonBase)`
  min-width: 120px;
  border-radius: 2px 2px 0px 0px;

  background: ${colors.neutral00};
  box-shadow: inset -1px 0px 0px ${colors.neutral30}, inset 1px 0px 0px ${colors.neutral30},
    inset 0px 1px 0px ${colors.neutral30};

  font-family: 'Noto Sans';
  font-style: normal;
  font-weight: 400;
  font-size: 14px;
  line-height: 20px;

  text-align: center;
  letter-spacing: -0.0075em;

  color: ${colors.neutral00};

  &.active {
    color: ${colors.blue60};
    font-weight: 600;
    box-shadow: inset -1px 0px 0px ${colors.neutral30}, inset 1px 0px 0px ${colors.neutral30},
      inset 0px 1px 0px ${colors.neutral30};
  }
`;
