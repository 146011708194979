/* eslint-disable array-bracket-newline */
import { useCallback, useEffect, useMemo, useRef, useState } from 'react';
import * as d3 from 'd3';
import { colors, Row } from 'syngenta-digital-cropwise-react-ui-kit';

import EnvironmentStressBarLabel, { BarChartLabelProps } from './BarChartLabel';
import { roundedRectangle } from 'utils/roundedRectangle';
import { StyledSvg, StyledContainer, TooltipYearsContainer } from './BarChart.styles';
import { useBreakpoint } from 'hooks';
import { NumberValue } from 'd3';
import {
  StyledTemperatureContainer,
  StyledIconThermostat,
  StyledPrecipitationContainer,
  StyledTemperatureTitleContainer,
  StyledIconDropletSize,
  StyledTemperatureBoldText,
} from './BarChartLabel/BarChartLabel.styles';

export interface BarChartProps {
  id?: string;
  width?: number;
  height?: number;
  yMin: number;
  yMax: number;
  yStep: number;
  yLabel: string;
  className?: string;
  data: BarChartLabelProps[];
  areValuesDecimal?: boolean;
  groupBarsColors?: string[];
  isGroupBar?: boolean;
  distanceBetweenBars?: number;
  paddingLeft?: number;
  showTooltip?: boolean;
  showTitleOnTooltip?: boolean;
  isPdfView?: boolean;
}

const BarChart = ({
  id,
  width = 1125,
  height = 450,
  yMin,
  yMax,
  yStep,
  data,
  yLabel,
  className,
  areValuesDecimal = false,
  groupBarsColors,
  isGroupBar = false,
  distanceBetweenBars = 24,
  showTooltip = false,
  paddingLeft = 75,
  showTitleOnTooltip = true,
  isPdfView = false,
}: BarChartProps): JSX.Element => {
  const { isMobile, landscape } = useBreakpoint();
  const isMobilePortraitOrDesktop = (isMobile && !landscape) || !isMobile;
  const [svgWidth, setSvgWidth] = useState(width);
  const [barWidth, setBarWidth] = useState(0);
  const [isWidthUpdated, setIsWidthUpdated] = useState(false);
  const [barCenters, setBarCenters] = useState<number[]>([]);
  const [selectedBarId, setSelectedBarId] = useState(-1);
  const svgRef = useRef<SVGSVGElement | null>(null);
  const paddingTop = 10;

  const customLabelYAxisHeight = 120;
  const distanceBetweenSubgroupsBars = 10;
  const chartHeight = height - paddingTop - customLabelYAxisHeight;
  const yScale = useMemo(
    () => d3.scaleLinear().domain([yMin, yMax]).range([chartHeight, paddingTop]),
    [chartHeight, yMin, yMax]
  );

  const calculateCenterBars = useCallback(() => {
    const newBarCenters: number[] = [];
    d3.select(svgRef.current)
      .selectAll('.bar')
      .each(function () {
        const element = (d3.select(this).node() as Element).parentElement;

        if (element) {
          const bbox = element?.getBoundingClientRect();
          newBarCenters.push(paddingLeft * 2 + bbox.width / 2);
        }
      });
    setBarCenters(newBarCenters);
  }, [paddingLeft]);

  const processString = (x: string): string[] => {
    if (x.length < 34) {
      return [x];
    }
    const words = x.split(' ');
    if (words.length > 3) {
      return [words.slice(0, 3).join(' '), words.slice(3).join(' ')];
    } else if (words.length === 3) {
      return [words.slice(0, 2).join(' '), words[2]];
    }
    return [
      x.slice(0, 30).trim() + (x[30] !== ' ' && x[29] !== ' ' ? '-' : ''),
      x.slice(30).trim(),
    ];
  };
  // Create bar chart
  useEffect(() => {
    // Create root container where we will append all other chart elements
    const svgEl = d3.select(svgRef.current);
    // Clear svg content before adding new elements
    svgEl.selectAll('*').remove();

    // Create the y axis
    const getDecimalValue = (d: d3.NumberValue): string => {
      if (areValuesDecimal) {
        return String((d as number).toFixed(2));
      }
      return String(d);
    };

    const yAxis = d3
      .axisLeft(yScale)
      .ticks(Math.floor((yMax - yMin) / yStep) + 1)
      .tickValues(d3.range(yMin, yMax + yStep, yStep))
      .tickFormat((d: d3.NumberValue, idx) =>
        (idx % 2 === 0 ? getDecimalValue(d) : '')
      );

    // Append the y axis to the SVG element
    const yAxisGroup = svgEl
      .append('g')
      .attr('class', 'y-axis')
      .attr('transform', `translate(${paddingLeft},0)`)
      .call(yAxis);

    // Hide y-axis lines
    yAxisGroup.selectAll('.tick').select('line').style('display', 'none');

    // Add y axis label
    const splitWord = processString(yLabel);
    yAxisGroup
      .append('text')
      .attr('class', 'y-axis-label')
      .attr('x', (chartHeight * -1) / 2)
      .attr('y', 13 - paddingLeft)
      .text(splitWord[0]);
    if (splitWord.length > 1) {
      yAxisGroup
        .append('text')
        .attr('class', 'y-axis-label')
        .attr('x', (chartHeight * -1) / 2)
        .attr('y', 31 - paddingLeft)
        .text(splitWord[1]);
    }
    // Add horizontal lines at every y tick mark
    svgEl
      .selectAll('.tick')
      .append('line')
      .attr('class', 'grid-line')
      .attr('x1', 0)
      .attr('x2', svgWidth - paddingLeft)
      .attr('y', 0)
      .attr('stroke-width', '1px')
      .attr('stroke', (_, i) => (i % 2 === 1 ? colors.neutral30 : colors.neutral40))
      .attr('stroke-dasharray', (_, i) => (i % 2 === 1 ? '3 3' : null));

    const groupBarWidth = 30;
    const borderRadius = 8;
    const newBarWidth = (svgWidth - paddingLeft + distanceBetweenBars) / data.length;
    setBarWidth(newBarWidth);
    // Create the bars
    svgEl
      .append('g')
      .attr('class', 'bars')
      .selectAll('g')
      .data(data)
      .enter()
      .append('g')
      .attr('transform', (_, i) => `translate(${i * newBarWidth + paddingLeft}, 0)`)
      .attr('id', (_, i) => `${id}-bar-${i}`)
      .attr('fill', (d) => d.barColor)
      .selectAll('g')
      .data((d) => (Array.isArray(d.value) ? d.value : [d.value]))
      .enter()
      .append('path')
      .attr('class', 'bar')
      .attr('d', (d, i, nodes) => {
        const y = d < 0 ? yScale(0) : yScale(d);
        let x = 0;
        const barHeight = Math.abs(yScale(d) - yScale(0));

        if (i > 0) {
          x += (groupBarWidth + distanceBetweenSubgroupsBars) * i;
        }

        // center group bars
        if (isGroupBar) {
          x += (newBarWidth - distanceBetweenBars) / 2;
          x -=
            (groupBarWidth * nodes.length + distanceBetweenSubgroupsBars * (nodes.length - 1)) / 2;
        }

        return roundedRectangle(
          x,
          y,
          isGroupBar ? groupBarWidth : newBarWidth - distanceBetweenBars,
          barHeight,
          borderRadius,
          !(d < 0),
          !(d < 0),
          d < 0,
          d < 0
        );
      })
      .attr('fill', (yValue, i) => (groupBarsColors ? groupBarsColors[i] : null));
    calculateCenterBars();
  }, [
    width,
    height,
    yMin,
    yMax,
    yStep,
    yLabel,
    svgWidth,
    paddingTop,
    paddingLeft,
    distanceBetweenBars,
    data,
    areValuesDecimal,
    groupBarsColors,
    isGroupBar,
    chartHeight,
    yScale,
    id,
    calculateCenterBars,
  ]);

  useEffect(() => {
    if (data) {
      const tooltip = d3.select(`#${id}`).select('.tooltip');
      const tooltipArrow = d3.select(`#${id}`).select('.tooltip-arrow');
      tooltip.style('opacity', 0);
      tooltipArrow.style('opacity', 0);
      setSelectedBarId(-1);
    }
  }, [data, id]);

  // Tooltip handler
  useEffect(() => {
    const bars = d3.select(svgRef.current).selectAll('.bar');
    const tooltip = d3.select(`#${id}`).select('.tooltip');
    const tooltipArrow = d3.select(`#${id}`).select('.tooltip-arrow');

    function handleClickBar(d: any) {
      if (!svgRef.current) {
        return;
      }

      const idSplit = d.target.parentNode.id.split('-');
      const currentBarId = Number(idSplit[idSplit.length - 1]);

      // to remove the tooltip from screen
      if (selectedBarId === currentBarId) {
        tooltip.style('opacity', 0);
        tooltipArrow.style('opacity', 0);
        return setSelectedBarId(-1);
      }

      const xPositionBar = d.target.parentNode.transform.baseVal.getItem(0).matrix.e;
      const barBoundingClient = d.target.getBoundingClientRect();
      const currentBarWidth = barBoundingClient.width;
      const currentBarHeight = barBoundingClient.height;
      const svgMarginTop = parseFloat(window.getComputedStyle(svgRef.current).marginTop);
      const bottomDefaultDistance = 15;
      const tooltipArrowHeight = 7;
      const svgHeight = height - customLabelYAxisHeight;
      const tooltipArrowPositionX = Number(xPositionBar) + Number(currentBarWidth / 2);

      let xPosition = Number(xPositionBar) + Number(currentBarWidth / 2);
      const yPosition = yScale(d3.select(d.target).datum() as NumberValue);

      // avoid tooltip going offscreen
      if (currentBarId === data.length - 1) {
        xPosition -= 62;
      }
      if (currentBarId === 0) {
        xPosition += 12;
      }

      setSelectedBarId(currentBarId);

      tooltip.style('opacity', 1);
      tooltipArrow.style('opacity', 1);

      if (yMin < 0) {
        const value = data[currentBarId] && (data[currentBarId].value as number);
        const yPositionTooltip = Math.ceil(svgHeight / 2) + currentBarHeight + tooltipArrowHeight;

        if (value > 0) {
          tooltip
            .style('top', 'unset')
            .style('bottom', `${yPositionTooltip - 1}px`)
            .style('left', `${xPosition}px`);
          tooltipArrow
            .style('left', `${tooltipArrowPositionX}px`)
            .style('top', `${Math.floor(svgHeight / 2) - currentBarHeight}px`)
            .style('transform', 'translate(-50%, 0)');
        } else {
          tooltip
            .style('top', `${yPositionTooltip}px`)
            .style('bottom', 'unset')
            .style('left', `${xPosition}px`);
          tooltipArrow
            .style('left', `${tooltipArrowPositionX}px`)
            .style('top', `${Math.ceil(svgHeight / 2) + currentBarHeight}px`)
            .style('transform', 'translate(-50%, 0) rotate(180deg)');
        }
      } else {
        tooltip
          .style('bottom', `${currentBarHeight + bottomDefaultDistance + tooltipArrowHeight}px`)
          .style('left', `${xPosition}px`);
        tooltipArrow
          .style('left', `${tooltipArrowPositionX}px`)
          .style('top', `${svgMarginTop + yPosition - tooltipArrowHeight}px`);
      }
    }

    if (showTooltip) {
      // Add the event listener to the bars
      bars.on('click', handleClickBar);
    }
    return () => {
      bars.on('click', null);
    };
  }, [
    svgRef,
    id,
    barWidth,
    yScale,
    selectedBarId,
    data,
    showTooltip,
    yMin,
    yMax,
    isMobile,
    landscape,
    height,
  ]);

  // Adapt table on resize
  useEffect(() => {
    const updateWidth = () => {
      if (svgRef.current) {
        const parent = getComputedStyle(svgRef.current.parentNode?.parentNode as HTMLElement);
        const containerStyles = getComputedStyle(svgRef.current.parentNode as HTMLElement);
        const parentpaddingLeft = parseInt(parent.getPropertyValue('padding-left'), 10);
        const parentPaddingRight = parseInt(parent.getPropertyValue('padding-right'), 10);
        const parentMarginLeft = parseInt(containerStyles.getPropertyValue('margin-left'), 10);
        const parentWidth = parseInt(parent.getPropertyValue('width'), 10);

        setSvgWidth(parentWidth - parentpaddingLeft - parentPaddingRight - parentMarginLeft);

        setTimeout(() => {
          setIsWidthUpdated(true);
        }, 0);
      }
    };

    updateWidth();

    window.addEventListener('resize', updateWidth);

    return () => {
      window.removeEventListener('resize', updateWidth);
    };
  }, [height, isMobilePortraitOrDesktop, width]);

  return (
    <StyledContainer id={id} className={`svg-bar-chart ${className}`}>
      <StyledSvg
        data-testid="bar-chart"
        ref={svgRef}
        width={svgWidth}
        height={height - customLabelYAxisHeight}
      />

      {/* labels for pdf */}
      {isPdfView && (
        <div
          style={{
            display: 'flex',
            paddingLeft: paddingLeft,
            gap: `${distanceBetweenBars}px`,
          }}
        >
          {data.map((item) => (
            <EnvironmentStressBarLabel
              isPdfView={isPdfView}
              key={`Bar-${item.id}`}
              {...item}
              style={{ width: barWidth }}
            />
          ))}
        </div>
      )}

      {isWidthUpdated && !isMobile && !isPdfView && (
        <Row id="BarChartLabel" style={{ flexWrap: 'nowrap' }}>
          {data.map((item, idx) => (
            <EnvironmentStressBarLabel
              isPdfView={isPdfView}
              key={`Bar-${item?.id}`}
              {...item}
              style={{
                width: barWidth,
                left: barCenters[idx] - paddingLeft,
              }}
            />
          ))}
        </Row>
      )}

      {showTooltip && (
        <>
          <div className="tooltip">
            {selectedBarId >= 0 && (
              <>
                {showTitleOnTooltip && (
                  <StyledTemperatureTitleContainer>
                    <StyledTemperatureBoldText>
                      {data[selectedBarId].title}
                    </StyledTemperatureBoldText>
                  </StyledTemperatureTitleContainer>
                )}
                <StyledTemperatureContainer>
                  <StyledIconThermostat color={colors.neutral00} />
                  <div>{data[selectedBarId].temperatureLabel}</div>
                </StyledTemperatureContainer>
                <StyledPrecipitationContainer>
                  <StyledIconDropletSize color={colors.neutral00} />
                  <div>{data[selectedBarId].precipitationLabel}</div>
                </StyledPrecipitationContainer>
                {data[selectedBarId].years && (
                  <TooltipYearsContainer>
                    {data[selectedBarId].years?.join(', ')}
                  </TooltipYearsContainer>
                )}
              </>
            )}
          </div>
          <div className="tooltip-arrow" />
        </>
      )}
    </StyledContainer>
  );
};

export default BarChart;
