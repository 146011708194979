import { BRAZIL_YIELD_RANGE_CATEGORY, BRAZIL_YIELD_RANGE_CATEGORY_FOR_SUMMER_CORN, YIELD_RANGE_CATEGORY } from 'base/constants/yieldRangeCategory';
import { areOnlyLetters } from './validator';

export const getYieldRangeOptions = () => {
  return [
    {
      default: true,
      label: '4-9 t/ha',
      id: 'L'
    },
    {
      default: false,
      label: '9-12 t/ha',
      id: 'M'
    },
    {
      default: false,
      label: '12-25 t/ha',
      id: 'H'
    }
  ];
};

const getYieldRangePhraseKey = (yieldLabel: string) => {
  // TODO: Remove and change once yield range mapping is confirmed
  if (yieldLabel.includes('brazil summer')) {
    switch (yieldLabel) {
      case BRAZIL_YIELD_RANGE_CATEGORY_FOR_SUMMER_CORN.L:
        return '4-9 t/ha';
      case BRAZIL_YIELD_RANGE_CATEGORY_FOR_SUMMER_CORN.M:
        return '9-12 t/ha';
      case BRAZIL_YIELD_RANGE_CATEGORY_FOR_SUMMER_CORN.H:
        return '12-25 t/ha';
      default:
        return '9-12 t/ha';
    }
  } else if (yieldLabel.includes('brazil')) {
    switch (yieldLabel) {
      case BRAZIL_YIELD_RANGE_CATEGORY.VL:
        return 'Very Low';
      case BRAZIL_YIELD_RANGE_CATEGORY.L:
        return '4-9 t/ha';
      case BRAZIL_YIELD_RANGE_CATEGORY.M:
        return '9-12 t/ha';
      case BRAZIL_YIELD_RANGE_CATEGORY.H:
        return '12-25 t/ha';
      case BRAZIL_YIELD_RANGE_CATEGORY.VH:
        return 'Very High';
      default:
        return '9-12 t/ha';
    }
  } else {
    switch (yieldLabel) {
      case YIELD_RANGE_CATEGORY.YIELD_LOW:
        return 'low';
      case YIELD_RANGE_CATEGORY.YIELD_HIGH:
        return 'YieldHigh';
      default:
        return 'Medium';
    }
  }
};

export const formatYieldRangeLabel = (yieldLabel: string) => {
  return areOnlyLetters(yieldLabel)
    ? getYieldRangePhraseKey(yieldLabel)
    : yieldLabel.split(' ')[0];
};

export const getYieldRangeMetricLabel = (yieldLabel: string) => {
  const metric = yieldLabel.split(' ')[1] || '';
  return metric;
};
