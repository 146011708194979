import { useTranslation } from 'react-i18next';
import ButtonCircle from 'components/Buttons/ButtonCircle/ButtonCircle';
import { StyledIconHub } from './AddBoundaryControl.styles';
import { TooltipProps } from 'antd';

interface IProps {
  id?: string;
  isVisible?: boolean;
  tooltipProps?: TooltipProps;
  onClick?: () => void;
}

const AddBoundaryControl = ({ id = '', isVisible = true, onClick, tooltipProps }: IProps): JSX.Element => {
  const [t] = useTranslation();

  return (
    <div id={id} data-testid="addBoundaryControl">
      {isVisible && (
        <ButtonCircle
          onClick={onClick}
          id={`${id}Button`}
          iconCustom={StyledIconHub}
          tooltipProps={{
            title: t('Draw another field'),
            placement: 'left',
            open: true,
            style: {
              zIndex: '1 !important',
            },
            className: 'tooltip-test',
            ...tooltipProps,
          }}
        />
      )}
    </div>
  );
};

export default AddBoundaryControl;
