import styled from 'styled-components';
import { Col, colors, Row } from 'syngenta-digital-cropwise-react-ui-kit';
import breakpoints from 'base/constants/breakpoints';
import { AgronomicPreferenceStyle } from 'base/types/RecommendationCropwise';

export const StyledRow = styled(Row)`
  width: 100%;
  row-gap: 40px;
  column-gap: 24px;
  justify-content: space-between;
  @media screen and ${breakpoints.maxWidth.md} {
    gap: 12px;
  }
`;

export const StyledCol = styled(Col)`
  display: flex;
  flex-direction: column;
  justify-content: flex-end;
  padding: 16px 0px;
  border-radius: 8px;
  background-color: #f8f8f8;
  row-gap: 8px;
  max-width: 22%;
  @media screen and ${breakpoints.maxWidth.md} {
    padding: 16px 12px;
    max-width: 48%;
  }
`;

export const StyledH1 = styled('h1')((props: AgronomicPreferenceStyle) => {
  return `
    min-height: auto !important;
    height: 100%;
    display: flex;
    justify-content: center;
    align-items: center;
    font-weight: 600 !important;
    line-height: 26px;
    align-items: baseline;
    @media screen and ${breakpoints.maxWidth.xl} {
      font-style: normal;
      font-size: 24px !important;
      line-height: 32px !important;
    }
    @media screen and ${breakpoints.maxWidth.lg} {
      font-size: 24px !important;
      line-height: 32px !important;
      word-break: ${props.isLongString ? 'break-word' : 'keep-all'};
    }
    @media screen and ${breakpoints.maxWidth.md} {
      font-size: 24px !important;
      line-height: 32px !important;
      word-break: ${props.isLongString ? 'break-word' : 'keep-all'};
    }
    @media screen and ${breakpoints.maxWidth.sm} {
      font-size: 16px !important;
      line-height: 24px !important;
      word-break: ${props.isLongString ? 'break-word' : 'keep-all'};
    }
  `;
});

export const AgronomicInputsContainer = styled.div((props: AgronomicPreferenceStyle) => {
  return `
    display: flex;
    justify-content: space-between;
    padding: 56px 0 0 0;
    margin-bottom: 56px;
    @media screen and ${breakpoints.maxWidth.md} {
    padding: 24px 0 32px 0;
    margin-bottom: 24px;
    border-bottom: 2px solid ${colors.neutral20};
    }
    div {
      text-align: center;
      font-family: 'Noto Sans';
      font-style: normal;
      color: ${colors.neutral90};
      p {
        font-weight: 400;
        font-size: 15px;
        line-height: 23px;
        letter-spacing: -0.0075em;
      }
    }
    `;
});

export const StyledParagraph = styled.div((props: AgronomicPreferenceStyle) => {
  return `
    text-transform: capitalize;
    span {
      text-transform: none;
    }
    height: auto !important;
    color: ${colors.neutral60} !important;
    font-weight: 400;
    font-size: 14px !important;
    line-height: 20px !important;
    word-break: ${props.isLongString ? 'break-word' : 'keep-all'};
    @media screen and ${breakpoints.maxWidth.md} {
      font-size: 12px !important;
      line-height: 20px !important;
    }
    @media screen and ${breakpoints.maxWidth.sm} {
      font-style: normal;
      font-weight: 400;
      font-size: 12px !important;
      line-height: 20px !important;
      letter-spacing: -0.0075em !important;
    }
  `;
});

export const SpinnerContainer = styled.div`
  display: flex;
  justify-content: center;
  padding: 30px 0;
`;
