import { useEffect, useState, useCallback } from 'react';
import {
  StyledContainer,
  StyledButton,
  StyledParagraph,
  StyledModalView,
} from 'commonstyles/FieldInformationSheet.styles';
import InputNumberHandler from '../input-number-handler';
import { useTranslation } from 'react-i18next';
import { ButtonType } from 'syngenta-digital-cropwise-react-ui-kit';
import { useBreakpoint } from 'hooks';
import { INPUT_MAX_VALUE, INPUT_MIN_VALUE } from 'base/constants/inputNumber';
import { useAppState } from 'context/AppState';
import { ModalAnimationDirections } from 'base/types/ModalBox';
import { useFlowActions } from 'context/actions/flowActions';

interface PinInformationSiderProps {
  title: string;
  setIsValid?: (isValid: boolean) => void;
  setPinSize?: (size: string) => void;
  isDisabled?: boolean;
  defaultSize?: string;
  onClickCancel: Function;
  onClickNext: Function;
  onClickOutside?: Function;
}

const PinInformationSheet = ({
  title,
  isDisabled = false,
  setIsValid,
  setPinSize,
  defaultSize,
  onClickCancel,
  onClickNext,
  onClickOutside,
}: PinInformationSiderProps): JSX.Element => {
  const [t] = useTranslation();
  const [size, setSize] = useState('12');
  const [hasInputErrors, setHasInputErrors] = useState({ fieldName: false, size: false });
  const [slideDirection, setSlideDirection] = useState(ModalAnimationDirections.Up);
  const [isAnimating, setIsAnimating] = useState(false);
  const [nextClicked, setNextClicked] = useState(false);
  const { isMobile } = useBreakpoint();
  const {
    flow: { optionType, isModalToBeClosed },
  } = useAppState();
  const { setIsModalToBeClosed } = useFlowActions();

  useEffect(() => {
    defaultSize && setSize(defaultSize);
  }, [defaultSize]);

  useEffect(() => {
    if (setIsValid) {
      setIsValid(!hasInputErrors.size);
    }
    // eslint-disable-next-line
  }, [hasInputErrors.size]);

  const handleEstimatedSizeChange = useCallback(
    (val: string) => {
      setPinSize?.(val);
      setSize(val);
    },
    [setPinSize]
  );

  const handleAnimationEnd = () => {
    if (isAnimating) {
      if (onClickOutside) {
        onClickOutside();
      } else {
        onClickCancel();
      }
    }
    if (nextClicked) {
      onClickNext();
    }
  };

  const handleNextClick = () => {
    setSlideDirection(ModalAnimationDirections.Down);
    setNextClicked(true);
  };

  const handleCancelClick = useCallback(() => {
    onClickCancel();
  }, [onClickCancel]);

  const handleCloseDrawer = () => {
    setSlideDirection(ModalAnimationDirections.Down);
    setIsAnimating(true);
  };

  useEffect(() => {
    if (isModalToBeClosed) {
      handleCloseDrawer();
      setIsModalToBeClosed({ isModalToBeClosed: false });
    }
  }, [isModalToBeClosed, setIsModalToBeClosed]);

  return (
    <div data-testid="pin-information">
      <StyledModalView
        data-testid="pin-information-sheet"
        title={title ?? 'Title goes here'}
        width={494}
        isViewFooter
        showHeaderRectangle
        onCancel={handleCloseDrawer}
        isDropAPinFlow={true}
        content={
          <StyledContainer>
            <StyledParagraph>
              {t(
                'The location for your hybrid recommendation will be saved. If required, adjust acreage size below.'
              )}
            </StyledParagraph>
            <InputNumberHandler
              data-testid="input-number-handler-input"
              inputName="size"
              inputValue={size}
              setInputValue={handleEstimatedSizeChange}
              step={1}
              label={t('Estimated Size (ha)')}
              min={INPUT_MIN_VALUE}
              max={INPUT_MAX_VALUE}
              setHasErrorsForParent={setHasInputErrors}
              inputProps={{
                disabled: isDisabled,
                optionType,
              }}
            />
          </StyledContainer>
        }
        footer={
          <>
            <StyledButton
              data-testid="pin-information-sheet-cancel-button"
              type={ButtonType.default}
              size={'large'}
              disabled={!isMobile}
              onClick={handleCancelClick}
            >
              {t('Cancel')}
            </StyledButton>
            <StyledButton
              type={ButtonType.primary}
              size={'large'}
              onClick={handleNextClick}
              disabled={hasInputErrors.size}
            >
              {t('Next')}
            </StyledButton>
          </>
        }
        slideDirection={slideDirection}
        onAnimationEnd={handleAnimationEnd}
      />
    </div>
  );
};

export default PinInformationSheet;
