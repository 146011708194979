import { Field } from 'context/store/recommendationFormReducer';
import getBiggestSoilArea from './getBiggestSoilArea';
import { SoilConstants } from './constants/ParentSoilConstants';

export const calculateSoilType = (fields: Field[]) => {
  const boundaries = fields
    .map((field) => {
      let biggestSoilArea = '';
      const biggestArea = getBiggestSoilArea({
        soilGrid: field.soils ?? [],
        fieldBoundaries: field.boundary.geometry,
        fieldsResolution: field.fieldsResolution ?? 0,
      });

      if (biggestArea.texture === SoilConstants.MED) {
        biggestSoilArea = SoilConstants.MEDIUM;
      } else if (biggestArea.texture === SoilConstants.FINE) {
        biggestSoilArea = SoilConstants.FINE;
      } else {
        biggestSoilArea = SoilConstants.COARSE;
      }

      return {
        estimatedSize: parseFloat(field.hectares),
        biggestSoilArea: biggestSoilArea,
      };
    })
    .sort(function (boundaryArea1, boundaryArea2) {
      return boundaryArea2.estimatedSize - boundaryArea1.estimatedSize;
    });

  return boundaries[0].biggestSoilArea;
};
