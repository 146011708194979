import breakpoints from 'base/constants/breakpoints';
import styled from 'styled-components';

const SoilDetailSection = styled.div`
  widht: 100%;
  margin-left: 12px;
  display: flex;
  flex-direction: row;
  padding-top: 2px;
`;

const SoilInfoSection = styled.div`
  display: flex;
  margin-bottom: 8px;
  flex-direction: row;
`;

const FieldSoilSection = styled.div`
  display: flex;
`;

const HectareText = styled.div`
  font-size: 12px;
  font-weight: 400;
  color: #c2c7d0;
`;

const Text = styled.div`
  font-family: 'Noto Sans';
  font-weight: 600;
  font-size: 14px;
  line-height; 20px;
  color: #FFFFFF;
  max-width: 174px;
   @media only screen and ${breakpoints.maxWidth.sm} {
   max-width: 214px;
}
`;
const SoilColor = styled.div`
  border: 2px solid white;
  width: 22px;
  height: 22px;
  border-radius: 50%;
  background-color: ${(props) => props.color};
`;

const DividerText = styled.div`
  margin: 0px 5px;
  color: #ffffff;
`;

export {
  SoilInfoSection,
  FieldSoilSection,
  SoilDetailSection,
  Text,
  HectareText,
  DividerText,
  SoilColor,
};
