import { convertSeedsToBagsForProduct } from 'utils/convertSeedsToBagsForProduct';
import { CropConstants } from './constants/Crop';
import { Country } from 'base/types/Countries';
import { ProductSeed } from 'base/types/Products';
import { getBagCountForSouthAfrica } from './getSeedRateAndBagSizeForSouthAfrica';

type GetBagsCountParams = {
  product?: ProductSeed;
  hectares: number;
  defaultSeedingRate: number;
  countryList: Country[];
  countryCode?: string | null;
  cropId?: string | number;
  spatialClass?: string;
};
const getBagCountForCrop = (country: Country, defaultSeedingRate: number, hectares: number, cropName: string, cropBagCapacity: number) => {
  const defaultBagSize = country.crops.find(
    (crop) => crop.CropName === cropName
  )?.BagSize;
  return defaultBagSize
    ? (defaultSeedingRate * hectares) / defaultBagSize
    : (defaultSeedingRate * hectares) / cropBagCapacity;
};

export const getBagsCount = (params: GetBagsCountParams, doNotApplyCeil?: boolean): number => {
  const { product, hectares, defaultSeedingRate, countryList, countryCode, cropId, spatialClass } = params;
  const {
    SUNFLOWER_SMALLCASE, SUNFLOWER_BAG_CAPACITY, SUNFLOWER_CROP_ID,
    CORN_BAG_CAPACITY, CORN_SMALLCASE, SUMMER_CORN_SMALLCASE, SUMMER_CORN_CROP_ID
  } = CropConstants;
  let bags = convertSeedsToBagsForProduct(defaultSeedingRate, hectares, product);
  if (bags === 0) {
    const country = countryList.find(
      (countryItem) => (
        countryItem.shortCode === String(countryCode?.trim()).toUpperCase() ||
        countryItem.alpha2.toUpperCase() === String(countryCode?.trim()).toUpperCase() ||
        countryItem.name.toUpperCase() === String(countryCode?.trim()).toUpperCase()
      )
    );
    if (country) {
      if (cropId === SUNFLOWER_CROP_ID.toString()) {
        bags = getBagCountForCrop(country, defaultSeedingRate, hectares, SUNFLOWER_SMALLCASE, SUNFLOWER_BAG_CAPACITY);
      } else if (cropId === SUMMER_CORN_CROP_ID.toString()) {
        bags = getBagCountForCrop(country, defaultSeedingRate, hectares, SUMMER_CORN_SMALLCASE, SUNFLOWER_BAG_CAPACITY);
      } else if (countryCode === CropConstants.SOUTH_AFRICA_CODE) {
        bags = getBagCountForSouthAfrica(country, spatialClass, product?.commercialName ?? '', hectares);
      } else {
        bags = getBagCountForCrop(country, defaultSeedingRate, hectares, CORN_SMALLCASE, CORN_BAG_CAPACITY);
      }
    }
  }
  return doNotApplyCeil ? parseFloat(bags?.toFixed(2)) : Math.ceil(bags);
};
