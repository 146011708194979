import { useAppState } from 'context/AppState';
import { Progress, colors } from 'syngenta-digital-cropwise-react-ui-kit';
import { AgronomicItem, ImportanceItems, ItemsContainer } from './AgronomicWeightingsList.styles';
import { useTranslation } from 'react-i18next';
import { useEffect, useState } from 'react';
import { AgroWeightingsTitle } from 'pages/RecommendationV2/V2Common.styles';

interface ArgonomicWeightingListItem {
  id: string;
  name: string;
  progress: number;
}

const AgronomicWeightingsList = (): JSX.Element => {
  const [t] = useTranslation();
  const {
    apiData: { recommendationCropwise },
  } = useAppState();

  const [agronomicsItemsWithProgress, setAgronomicsItemsWithProgress] = useState<
    ArgonomicWeightingListItem[]
  >([]);

  useEffect(() => {
    if (recommendationCropwise?.recommendations.length) {
      const agroWeights =
        recommendationCropwise?.recommendations[0]?.multi_field?.config?.agronomicWeights;
      if (agroWeights.length === 0) {
        return;
      }

      const isDDG = recommendationCropwise.is_ddg;
      // Hide yieldRanking from ag. weighting list as we want to promote the use of max yield as default
      const agronomicWeightingsData = agroWeights
        .filter((ag) => ag.Active && ag.Weight > 0 && (!isDDG || ag.Identifier !== 'yieldRanking'))
        .map((activeAg) => {
          const progress = activeAg?.Weight || 0;
          return {
            id: activeAg.Identifier,
            name: activeAg.Name,
            progress: progress,
          };
        });
      setAgronomicsItemsWithProgress(agronomicWeightingsData);
    }
  }, [recommendationCropwise]);

  return (
    <>
      {agronomicsItemsWithProgress?.length > 0 && (
        <>
          <AgroWeightingsTitle>{t('Your agronomic weightings')}</AgroWeightingsTitle>
          <ItemsContainer data-testid="agronomic-weighting-section">
            {agronomicsItemsWithProgress.map((agItem) => (
              <AgronomicItem key={agItem.id}>
                <h1>{t(agItem.name)}</h1>
                <Progress
                  percent={agItem.progress}
                  showInfo={false}
                  strokeColor={colors.blue60}
                  trailColor={colors.neutral20}
                />
                <ImportanceItems>
                  <p>{t('Low importance')}</p>
                  <p>{t('High importance')}</p>
                </ImportanceItems>
              </AgronomicItem>
            ))}
          </ItemsContainer>
        </>
      )}
    </>
  );
};

export { AgronomicWeightingsList };
