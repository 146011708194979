import { SVGProps } from 'react';

import { colors } from '../../theme/colors';

const IconThermostat = ({
  height = '14',
  width = '8',
  color = colors.neutral70,
  ...props
}: SVGProps<SVGSVGElement>) => (
  <svg
    width={width}
    height={height}
    viewBox={`0 0 ${width} ${height}`}
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
    {...props}
  >
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M3.99935 0.333496C5.10602 0.333496 5.99935 1.22683 5.99935 2.3335V7.66683C6.80602 8.2735 7.33268 9.24683 7.33268 10.3335C7.33268 12.1735 5.83935 13.6668 3.99935 13.6668C2.15935 13.6668 0.666016 12.1735 0.666016 10.3335C0.666016 9.24683 1.19268 8.2735 1.99935 7.66683V2.3335C1.99935 1.22683 2.89268 0.333496 3.99935 0.333496ZM3.99935 1.66683C3.63268 1.66683 3.33268 1.96683 3.33268 2.3335V6.3335H4.66602V5.00016H3.99935V4.3335H4.66602V3.00016H3.99935V2.3335H4.66602C4.66602 1.96683 4.36602 1.66683 3.99935 1.66683Z"
      fill={color}
    />
  </svg>
);

export default IconThermostat;
