const wait = (ms: number) => new Promise((r) => setTimeout(r, ms));

const RetryFunc = (operation: Function, retries = 3, delay = 1000) => {
  return new Promise((resolve, reject) => {
    return operation()
      .then(resolve)
      .catch((reason: any) => {
        if (retries > 1 && reason.code !== 'ERR_CANCELED') {
          return wait(delay)
            .then(RetryFunc.bind(null, operation, retries - 1, delay))
            .then(resolve)
            .catch(reject);
        }
        return reject(reason);
      });
  });
};

export default RetryFunc;
