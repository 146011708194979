import { useTranslation } from 'react-i18next';
import { useNavigate } from 'react-router-dom';

import routes from 'base/constants/routes';
import { getEmailId } from 'utils/contactEmail';
import Header from 'containers/Header/Header';
import {
  Root,
  Content,
  OutlineButton,
  StyledTitle,
  StyledParagraph,
  StyledLink,
  StyledPrimaryButtonRow,
} from './RecommendationInProgress.styles';

import inInprogressImg from 'assets/images/in-inprogress.svg';
import { useBreakpoint } from 'hooks';
import track from 'utils/amplitudeWrapper';
import { useEffect } from 'react';
import ButtonBase, { EButtonType } from 'components/Buttons/ButtonBase';
import { useFlowActions } from 'context/actions/flowActions';
import { FlowSteps, ModeTypes, OptionType } from 'context/store/flowReducer';
import { useAppState } from 'context/AppState';

export default function RecommendationInProgress() {
  const { isMobile } = useBreakpoint();
  const [t] = useTranslation();
  const navigate = useNavigate();
  const {
    setShowGetRecommendationModal,
    setFlowStep,
    setIsAnotherRecommendationCreated,
    setCurrentModeType,
  } = useFlowActions();
  const { flow: { optionType, isLongTermMobileDesign } } = useAppState();
  const countryCode = sessionStorage.getItem('countryCode');

  useEffect(() => {
    track('no update email option', {
      'option to update email not displayed': true,
    });
  }, []);

  const backToHome = () => {
    navigate(routes.home);
  };

  const handleCreateAnotherRecommendationClick = () => {
    sessionStorage.setItem('isCreatingAnotherRecommendation', 'true');
    track('click on create another recommendation', { 'user click on create another recommendation': true });
    setCurrentModeType({ modeType: ModeTypes.SAVING });
    setShowGetRecommendationModal({ show: false });
    setFlowStep({ step: FlowSteps.STEP2 });
    setIsAnotherRecommendationCreated({ isAnotherRecommendationCreated: true });
    navigate(
      [OptionType.Draw, OptionType.Detect].includes(optionType)
        ? isLongTermMobileDesign ? routes.wizardMobile : routes.wizard
        : optionType === OptionType.empty
          ? routes.home
          : isLongTermMobileDesign ? routes.wizardDropAPinMobile : routes.wizardDropAPin
    );
  };

  return (
    <Root>
      <Header logoAction={() => navigate(routes.home)} />
      <Content>
        <StyledTitle strong={true} data-testid="page-title">
          {t('Recommendation in progress')}
        </StyledTitle>
        <StyledParagraph style={{ marginTop: isMobile ? '0' : '' }}>
          <img src={inInprogressImg} width="189" height="189" />
        </StyledParagraph>
        <StyledParagraph>
          {t(
            'We are processing your recommendation, you will soon receive it via email. Thank you for your patience.'
          )}
          <br />
          <br />
          {t(
            'If you do not receive your recommendation, please contact customer service at {{email}}',
            { email: '' }
          )}{' '}
          <StyledLink href={`mailto:${getEmailId(countryCode)}`}>
            {getEmailId(countryCode)}
          </StyledLink>
        </StyledParagraph>
        <StyledPrimaryButtonRow>
          <ButtonBase
            fullWidth={isMobile}
            type={EButtonType.primary}
            onClick={handleCreateAnotherRecommendationClick}
            text={t('Create another recommendation')}
          />
          <OutlineButton data-testid='back-to-home-btn' onClick={backToHome}>{t('Back to Home')}</OutlineButton>
        </StyledPrimaryButtonRow>
      </Content>
    </Root>
  );
}
