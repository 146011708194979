import { useEffect, useRef, useState } from 'react';
import {
  StyledHeader,
  IconLogoCrop,
  StyledButtonHelp,
  StyledButtonLanguage,
  StyledDiv,
  StyledLanguageItems,
  StyledLanguageText,
  StyledLanguageDropdownDivider,
  LangSection,
  MobileExtendedHeader,
} from './Header.styles';

import IconLanguage from 'components/Icons/IconLanguage';
import { colors } from 'syngenta-digital-cropwise-react-ui-kit';
import { LangDropdown } from 'components/LangDropdown';
import { useTranslation } from 'react-i18next';
import { NONDDG_SUPPORTED_LANGS } from 'base/constants/supportedLangs';
import { useAppState } from 'context/AppState';
import { useBreakpoint } from 'hooks';
import track from 'utils/amplitudeWrapper';
import HelpContactPopup from 'components/HelpContactPopup/HelpContactPopup';
import { getContactDetails } from 'utils/contactEmail';
import ActionBar from 'pages/RecommendationV2/Sections/Header/ActionBar';
import Phone from 'assets/icons/phone_24px.svg';
import Separator from 'assets/icons/divider_vertical.svg';
import ShareBtn from 'assets/icons/share_btn.svg';
import KebabMenu from 'assets/icons/kebab_menu.svg';
import { useUrl } from 'nextjs-current-url';
import copy from 'copy-to-clipboard';
import KebabMenuBottomSheet from './ShareAndContactBottomSheet';
import IconClose from 'components/Icons/IconClose';
import { StyledNotificationContent } from 'components/Maps/Maps.styles';
import openMapNotification from 'utils/openMapNotification';
import { isBulgaria, isPoland, isRomania, isUkraine } from 'utils/countryCode';

interface HeaderProps {
  logoAction?: () => void;
  countryCode?: string;
  isRecommendationPageV2?: boolean;
}

const Header = ({ logoAction, countryCode = undefined }: HeaderProps): JSX.Element => {
  const [t, i18n] = useTranslation();
  const [currentLang, setCurrentLang] = useState('');
  const [showNeedHelpPopup, setShowNeedHelpPopup] = useState(false);
  const {
    flow: { localCountry },
    apiData: { recommendationCropwise },
  } = useAppState();
  const { isMobile } = useBreakpoint();
  const isDDG = recommendationCropwise?.is_ddg || false;
  const { email, phone } = getContactDetails(countryCode ?? localCountry, isDDG);
  const pageLoadStartTimeRef = useRef(Date.now());

  const handleHelpButtonClick = () => {
    setShowNeedHelpPopup(true);
    track('open help pop up', { 'user open help': true });
    // ToDo: As this header Page is only used for recomemndation screen following code is unreachable,
    // Once we use this same Header throughout application then we need to enable following code
    // const currentRoutePath = location.pathname.toLowerCase();
    // if (currentRoutePath.startsWith(routes.wizard)) {
    //   track('create boundary help center', {
    //     'map help center interaction': true,
    //   });
    // } else if (currentRoutePath.startsWith(routes.wizardDropAPin)) {
    //   track('drop a pin help center', {
    //     'map help center interaction': true,
    //   });
    // }
  };

  const handleCloseIconClick = () => {
    setShowNeedHelpPopup(false);
  };

  useEffect(() => {
    const foundLanguage = NONDDG_SUPPORTED_LANGS.find(
      (lang) => lang.value === i18n.language
    )?.shortLabel;

    setCurrentLang(foundLanguage ?? NONDDG_SUPPORTED_LANGS[0].shortLabel);
  }, [i18n.language, t]);

  const [showCopyMessage, setShowCopyMessage] = useState(true);
  const [showMoreSheet, setShowMoreSheet] = useState(false);

  const { href: currentUrl } = useUrl() ?? {};

  const copyToClipboard = () => {
    if (currentUrl) {
      copy(currentUrl);
      if (!showCopyMessage) {
        return;
      }
      openMapNotification({
        type: 'success',
        id: 'copy-recommendation-link',
        msg: (
          <StyledNotificationContent>
            {t('Recommendation weblink copied')}
          </StyledNotificationContent>
        ),
        duration: 10,
        width: 480,
        placement: 'bottom',
        closeIcon: (
          <IconClose
            data-testid="Sharecloseiconbutton"
            color={colors.neutral40}
            width={18}
            height={18}
          />
        ),
        onClose: () => setShowCopyMessage(true),
      });
      setShowCopyMessage(false);
    }
  };
  const handleKebabMenu = () => {
    setShowMoreSheet(true);
  };
  const handleOutsideClick = () => {
    setShowMoreSheet(false);
  };
  const handleContact = () => {
    setShowMoreSheet(false);
    setShowNeedHelpPopup(true);
  };
  const handleShare = () => {
    setShowMoreSheet(false);
  };

  const showContactUs =
    isDDG || isPoland(countryCode) || isBulgaria(countryCode) || isRomania(countryCode);

  return (
    <>
      <StyledHeader className={isMobile ? 'hideOnLandscape' : ''}>
        <IconLogoCrop width="140px" height="48px" onClick={logoAction} />
        <StyledDiv>
          {!isMobile && (
            <>
              {showContactUs && (
                <StyledButtonHelp
                  data-testid="headerHelpIcon"
                  icon={
                    <img
                      src={Phone}
                      alt="No img found"
                      style={{ position: 'absolute', width: '20px', height: '20px' }}
                    />
                  }
                  onClick={handleHelpButtonClick}
                />
              )}
              <ActionBar
                isLoading={false}
                cropId={recommendationCropwise?.recommendations[0]?.crop_id}
                pageLoadStartTime={pageLoadStartTimeRef.current}
              />
            </>
          )}
          <LangSection>
            {!isMobile && (
              <>
                {showContactUs && (
                  <img
                    src={Separator}
                    alt="No img found"
                    style={{ width: '1px', height: '16px' }}
                  />
                )}
                <button onClick={copyToClipboard} className="shareButtonClass">
                  <img src={ShareBtn} data-testid="share-icon-testid" alt="No img found" />
                </button>
              </>
            )}
            <LangDropdown isDDG={isDDG} isRecommendationPage={true}>
              <StyledButtonLanguage>
                <StyledLanguageItems>
                  <IconLanguage width="18px" height="18px" color={colors.neutral60} />
                  <StyledLanguageText
                    data-testid="button-language-text"
                    style={{ marginLeft: '10px', fontSize: '14px' }}
                  >
                    {currentLang}
                  </StyledLanguageText>
                  <StyledLanguageDropdownDivider />
                </StyledLanguageItems>
              </StyledButtonLanguage>
            </LangDropdown>
            {isMobile && (
              <button
                onClick={handleKebabMenu}
                className="kebabMenuButtonClass"
                data-testid="kebab-menu-button"
              >
                <img src={KebabMenu} alt="No img found" style={{ height: '40px' }} />
              </button>
            )}
          </LangSection>
        </StyledDiv>
      </StyledHeader>
      {isMobile && isDDG && !isUkraine(countryCode) && (
        <MobileExtendedHeader>
          <ActionBar
            isLoading={false}
            cropId={recommendationCropwise?.recommendations[0]?.crop_id}
            pageLoadStartTime={pageLoadStartTimeRef.current}
          />
        </MobileExtendedHeader>
      )}
      {showNeedHelpPopup && (
        <HelpContactPopup
          countryCode={countryCode}
          handleCloseIconClick={handleCloseIconClick}
          email={email}
          phone={phone}
        />
      )}
      {showMoreSheet && (
        <KebabMenuBottomSheet
          onClickContact={handleContact}
          onClickShare={handleShare}
          onClickOutside={handleOutsideClick}
          showContactUs={showContactUs}
        />
      )}
    </>
  );
};

// eslint-disable-next-line import/no-default-export
export default Header;
