import styled from 'styled-components';
import { colors, Typography } from 'syngenta-digital-cropwise-react-ui-kit';

interface MobileProps {
  isMobile: boolean;
}

export const FooterRoot = styled.div<MobileProps>`
  background-color: #00004b;
  padding: ${({ isMobile }) => (isMobile ? '20px' : '48px')};
  padding-bottom: 28px;
`;
interface FooterContentProp {
  isDDG?: boolean;
}
export const FooterContent = styled.div<FooterContentProp>`
  display: flex;
  flex-wrap: wrap;
  flex-direction: row;
  color: white;
  border-top: ${({ isDDG }) => (isDDG ? '1px solid ${colors.neutral20}' : 'none')};
  padding-top: 16px;

  a {
    color: white;
  }
`;

const DisclaimerTextStyled = styled(Typography.Title)`
  font-family: 'Noto Sans';
  font-style: normal;
  font-weight: 400;
  font-size: 16px;
  line-height: 24px;

  letter-spacing: -0.0075em;
  color: ${colors.neutral00};
`;

const StyledParagraph = styled.p`
  display: flex;
  flex-wrap: wrap;
`;

const TopSection = styled.div`
  width: 100%;
  display: flex;
  justify-content: space-between;
`;

const DisclaimerTextContainer = styled.div`
  width: 100%;
`;

const BottomSection = styled.div<MobileProps>`
  width: 100%;
  display: flex;
  margin-top: 18px;
  justify-content: space-between;
  flex-direction: ${({ isMobile }) => (isMobile ? 'column' : 'row')}; 
`;

const ParagraphContainer = styled.div<MobileProps>`
  width: 100%;
  order: ${({ isMobile }) => (isMobile ? '2' : '')};
  margin-top: ${({ isMobile }) => (isMobile ? '20px' : '')};
`;

const StyledAnchorText = styled(Typography.Title)`
  font-weight: 400;
  font-size: 16px;
  line-height: 24px;
  cursor: pointer;
  letter-spacing: -0.0075em;
  color: rgba(255, 255, 255, 0.5);
`;

const GoogleAttributionTextStyled = styled(DisclaimerTextStyled)`
  && {
    color: rgba(255, 255, 255, 0.5);
  }
`;

const GoogleAttributionAnchorText = styled.a`
  && {
    color: rgba(255, 255, 255, 0.5);
    font-weight: normal;
  }
`;

export {
  DisclaimerTextStyled,
  StyledParagraph,
  TopSection,
  DisclaimerTextContainer,
  BottomSection,
  ParagraphContainer,
  StyledAnchorText,
  GoogleAttributionTextStyled,
  GoogleAttributionAnchorText
};
