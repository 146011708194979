import { SVGProps } from 'react';

const IconHubOpen = ({ ...props }: SVGProps<SVGSVGElement>) => (
  <svg xmlns="http://www.w3.org/2000/svg" width={18} height={18} fill="none" {...props}>
    <path
      fill="#fff"
      fillRule="evenodd"
      d="M6.75 4.5c0 .914-.544 1.7-1.326 2.052l.397 4.771a2.25 2.25 0 0 0-1.495.125L3.93 6.677A2.251 2.251 0 1 1 6.75 4.5Zm-1.5 11.25c1.051 0 1.934-.72 2.181-1.695l4.024-.366a2.24 2.24 0 0 1-.136-1.494l-4.024.366A2.25 2.25 0 1 0 5.25 15.75Zm10.5-3a2.25 2.25 0 1 1-4.5 0 2.25 2.25 0 0 1 4.5 0Zm0-7.5a2.25 2.25 0 0 1-1.5 2.122v3.256a2.244 2.244 0 0 0-1.5 0V7.372a2.251 2.251 0 1 1 3-2.122Zm-8.7.184 1.25.125.15-1.492-1.25-.125-.15 1.492Zm2.5.25 1.25.125.15-1.492-1.25-.125-.15 1.492ZM6 13.5a.75.75 0 1 1-1.5 0 .75.75 0 0 1 1.5 0ZM4.5 5.25a.75.75 0 1 0 0-1.5.75.75 0 0 0 0 1.5Zm9.75 0a.75.75 0 1 1-1.5 0 .75.75 0 0 1 1.5 0Zm-.75 8.25a.75.75 0 1 0 0-1.5.75.75 0 0 0 0 1.5Z"
      clipRule="evenodd"
    />
  </svg>
);

export default IconHubOpen;
