import { CSSProperties, ReactNode } from 'react';
import NotificationTextTranslation from 'components/NotificationTextTranslation';
import { notification } from 'antd';

export type NotificationType = 'success' | 'info' | 'warning' | 'error';

export default function openMapNotification(config: {
  type?: NotificationType;
  id: string;
  msg: string | ReactNode;
  msgProps?: Object;
  width?: number;
  className?: string;
  placement?: string;
  duration?: number;
  top?: number;
  style?: CSSProperties;
  closeIcon?: ReactNode;
  container?: HTMLElement | null;
  [key: string]: any;
}) {
  const {
    type = 'info',
    id,
    msg,
    msgProps,
    width = 520,
    className = '',
    placement = 'top',
    duration = 10,
    style,
    closeIcon,
    container,
    ...others
  } = config;

  notification[type]({
    ...others,
    key: id,
    className: `toast-notification ${className}`,
    message: '',
    description:
      typeof msg === 'string' ? (
        <NotificationTextTranslation text={msg} translationOptions={msgProps} />
      ) : (
        msg
      ),
    placement: placement as any,
    closeIcon: closeIcon || <div />,
    duration,
    prefixCls: 'syt-antd-notification',
    style: {
      maxWidth: width,
      ...style,
    },
    getContainer: () => {
      return container ?? document.getElementById('map-container') ?? document.body;
    },
  });
}
