import { Field } from 'context/store/recommendationFormReducer';
import { TFunction } from 'react-i18next';

export const getFieldDefaultName = ({
  fields,
  fieldName,
  counter = 0,
}: {
  fields: Field[];
  fieldName?: string;
  counter?: number;
}): string => {
  const fieldDefaultName = fieldName || `Field ${fields.length + 1}`;

  const isRepeated = fields.find((field) => field.fieldName === fieldDefaultName);

  if (isRepeated) {
    return getFieldDefaultName({
      fields,
      fieldName: `Field ${counter + 1}`,
      counter: counter + 1,
    });
  } else {
    return fieldDefaultName;
  }
};

export const getTranslatedFieldName = ({
  defaultName,
  t,
}: {
  defaultName: string;
  t: TFunction;
}): string => {
  let fieldName = defaultName;

  if (fieldName?.startsWith('Field ')) {
    const fieldNumber = fieldName.split(' ')[1];
    const translatedField = t('Field');
    fieldName = `${translatedField} ${fieldNumber}`;
  }

  return fieldName;
};
