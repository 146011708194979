/* eslint-disable array-bracket-newline */
/* eslint-disable indent */
/* eslint-disable react-hooks/exhaustive-deps */
import { useTranslation } from 'react-i18next';
import { useCallback, useEffect, useRef, useState } from 'react';
import {
  FieldListContainer,
  StyledCollapse,
  StyledPanel,
  StyledDivider,
} from './FieldInformationDrawerMobile.styles';
import FieldForm from './FieldFormMobile';
import { useAppDispatch, useAppState } from 'context/AppState';
import { useRecommendationFormActions } from 'context/actions/recommendationFormActions';
import { ActionTypes } from 'context/actions/ApiDataActions';
import FieldActionCard from 'components/FieldActionsCard';
import { useFlowActions } from 'context/actions/flowActions';
import { ModeTypes } from 'context/store/flowReducer';
import { Field } from 'context/store/recommendationFormReducer';
import CustomExpandIcon from 'components/CustomExpandIcon/CustomExpandIcon';
import { getTranslatedFieldName } from 'utils/constants/Fields';

interface FieldInfoDrawerProps {
  setHasInputErrors: Function;
}
const FieldInformationDrawer = (props: FieldInfoDrawerProps): JSX.Element => {
  const { setHasInputErrors } = props;
  const bottomRef = useRef<HTMLDivElement>(null);
  const [t] = useTranslation();
  const appDispatcher = useAppDispatch();
  const {
    flow: { currentModeType, previousModeType },
    recommendationForm: {
      fields,
      tempFields,
      countryCode,
      fieldNameForm,
      fieldToBeOpenedId,
      fieldEditId,
      previousFieldAreaMobile,
      currentFieldAreaMobile,
    },
  } = useAppState();
  const { setPreviousModeType, setShowDeleteBoundaryModal, setShowFieldInformationSheet } =
    useFlowActions();
  const {
    setFieldSelectedById,
    setTempFields,
    setAGProducts,
    setProductMadurityRange,
    setFieldToBeOpenedId,
    setFieldEditId,
  } = useRecommendationFormActions();
  const newArray = Array.from({ length: fields.length }, () => false);
  const [openPanels, setOpenPanels] = useState(newArray);

  const handleSetPanel = (currentIndex: number) => {
    const panelBooleanArray = Array.from(
      { length: fields.length },
      (_, index) => index === currentIndex
    );
    setOpenPanels(panelBooleanArray);
  };

  useEffect(() => {
    if (fieldEditId && currentFieldAreaMobile !== previousFieldAreaMobile) {
      const currentIndex = fields.findIndex((field) => field.id === fieldEditId);
      handleSetPanel(currentIndex);
      return;
    }
    if (fieldToBeOpenedId) {
      const currentIndex = fields.findIndex((field) => field.id === fieldToBeOpenedId);
      handleSetPanel(currentIndex);
    }
  }, [fieldToBeOpenedId, fields, currentFieldAreaMobile, previousFieldAreaMobile]);

  useEffect(() => {
    setAGProducts({ agProducts: [] });
    setProductMadurityRange({ productMadurityRange: [] });
    appDispatcher({
      type: ActionTypes.setProductCatalog,
      payload: { data: [] },
    });
    appDispatcher({
      type: ActionTypes.setProductMaturityRangeFAO,
      payload: { data: {} },
    });
  }, [countryCode]);

  useEffect(() => {
    if (
      currentModeType === ModeTypes.CREATING ||
      currentModeType === ModeTypes.SAVING ||
      (currentModeType === ModeTypes.DELETING && previousModeType === ModeTypes.EDITING)
    ) {
      setTempFields({
        fields: tempFields.filter((tempField) => tempField.fieldName !== fieldNameForm),
      });
    }
  }, [currentModeType]);

  const scrollToBottom = useCallback(() => {
    bottomRef.current?.scrollIntoView({ behavior: 'smooth', block: 'end' });
  }, [bottomRef]);

  useEffect(() => {
    scrollToBottom();
  }, []);

  const handleFieldCardOnDeleteClick = useCallback(
    (fieldId: string | number) => {
      setFieldSelectedById({ fieldId });
      setPreviousModeType({ modeType: currentModeType });
      setShowDeleteBoundaryModal({ show: true });
      setShowFieldInformationSheet({ showFieldInformationSheet: false });
    },
    [currentModeType]
  );

  const handleOnCollapsibleClick = useCallback(
    (index: number) => {
      const newOpenPanels = [...openPanels];
      newOpenPanels[index] = !newOpenPanels[index];
      setFieldToBeOpenedId({ fieldId: '' });
      setFieldEditId({ fieldId: '' });
      setOpenPanels(newOpenPanels);
    },
    [openPanels]
  );

  const getActiveKey = (openPanelList: boolean[]) => {
    const result = openPanelList.map((value, index) => (value ? index : ''));
    const openPanelKeyList = result.filter((item) => item !== '');
    return openPanelKeyList;
  };

  const checkFieldInfoError = (index: number) => {
    const fieldInfo = fields[index];
    return (
      fieldInfo.fieldName === '' ||
      (parseFloat(fieldInfo.hectares) < 0.01 && parseFloat(fieldInfo.hectares) <= 1000)
    );
  };

  const FieldList = useCallback(
    (fieldsList: Field[]) =>
      fieldsList?.map(({ fieldName, hectares, boundary, id }, index) => {
        const coords = boundary.geometry.coordinates as unknown as [number, number][];
        const defaultFieldName = getTranslatedFieldName({ defaultName: fieldName, t });
        return (
          <StyledCollapse
            onChange={() => handleOnCollapsibleClick(index)}
            expandIconPosition="end"
            activeKey={getActiveKey(openPanels) || [0]}
            expandIcon={CustomExpandIcon}
            key={id}
            showErrorBoder={checkFieldInfoError(index) && !getActiveKey(openPanels).includes(index)}
          >
            <StyledPanel
              header={
                <FieldActionCard
                  title={defaultFieldName}
                  subtitle={`${hectares.toString()} ${t('ha')}`}
                  polygonCoords={coords}
                  key={`${defaultFieldName}-${id}`}
                  handleOnDeleteClick={() => handleFieldCardOnDeleteClick(id)}
                  disabled={false}
                  editIconTooltipLabel={t('Edit')}
                  deleteIconTooltipLabel={t('Delete')}
                  nextIconTooltipLabel={t('Environment Information')}
                />
              }
              key={`${index + 0}`}
            >
              <FieldForm
                setHasInputErrors={setHasInputErrors}
                fieldName={defaultFieldName}
                disableForm={false}
                size={hectares}
                fieldId={id}
              />
            </StyledPanel>
          </StyledCollapse>
        );
      }),
    [fields, currentModeType, openPanels]
  );

  return (
    <>
      <FieldListContainer>{FieldList(fields)}</FieldListContainer>
      <StyledDivider />
      <div ref={bottomRef} />
    </>
  );
};

export default FieldInformationDrawer;
