/* eslint-disable @typescript-eslint/no-unused-vars */
import { SVGProps } from 'react';

const IconSort = ({
  height = '40px',
  width = '40px',
  color = '#696F88',
  ...props
}: SVGProps<SVGSVGElement>) => (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    width="8"
    height="14"
    fill="none"
    viewBox="0 0 8 14"
    {...props}
  >
    <path
      fill={color}
      fillRule="evenodd"
      d="M6.378 4.75L4 2.373 1.623 4.75.558 3.692 4 .25l3.435 3.442L6.378 4.75zm-4.755 4.5L4 11.627 6.378 9.25l1.065 1.057L4 13.75.565 10.307 1.623 9.25z"
      clipRule="evenodd"
    />
  </svg>
);

export default IconSort;
