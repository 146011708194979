import { SVGProps } from 'react';

import { colors } from '../../theme/colors';

const IconDropletSize = ({
  height = '13',
  width = '13',
  color = colors.neutral70,
  ...props
}: SVGProps<SVGSVGElement>) => (
  <svg
    width={width}
    height={height}
    viewBox={`0 0 ${width} ${height}`}
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
    {...props}
  >
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M2.16735 3.73821C2.1708 4.10407 2.44826 4.33317 2.70898 4.33317C2.96971 4.33317 3.24717 4.10407 3.25062 3.73821C3.25023 3.73428 3.24903 3.72329 3.24575 3.70481C3.23994 3.67216 3.22989 3.62903 3.2148 3.57927C3.18291 3.47409 3.1414 3.38128 3.10739 3.32441C2.99912 3.14339 2.85856 2.9451 2.70899 2.75051C2.55941 2.9451 2.41885 3.14339 2.31058 3.32441C2.27657 3.38128 2.23506 3.47409 2.20317 3.57927C2.18808 3.62903 2.17803 3.67216 2.17222 3.70481C2.16894 3.72329 2.16774 3.73428 2.16735 3.73821ZM3.42306 1.90585C3.6391 2.17538 3.86456 2.47981 4.03713 2.76836C4.21303 3.06248 4.33398 3.4774 4.33398 3.73132C4.33398 4.66202 3.60645 5.4165 2.70898 5.4165C1.81152 5.4165 1.08398 4.66202 1.08398 3.73132C1.08398 3.4774 1.20494 3.06248 1.38084 2.76836C1.55341 2.47981 1.77887 2.17538 1.99491 1.90585C2.36583 1.44308 2.70898 1.08317 2.70898 1.08317C2.70898 1.08317 3.05213 1.44308 3.42306 1.90585Z"
      fill={color}
    />
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M9.75065 6.50016C9.2197 6.50016 8.66732 6.01743 8.66732 5.26637C8.66732 5.23191 8.68026 5.11094 8.73327 4.93066C8.78369 4.75919 8.8515 4.5986 8.91693 4.48579C9.13889 4.10307 9.45004 3.67722 9.75065 3.29794C10.0513 3.67722 10.3624 4.10307 10.5844 4.48579C10.6498 4.5986 10.7176 4.75919 10.768 4.93066C10.821 5.11094 10.834 5.23191 10.834 5.26637C10.834 6.01743 10.2816 6.50016 9.75065 6.50016ZM10.462 2.45348C10.8142 2.88717 11.226 3.43275 11.5215 3.94229C11.756 4.34671 11.9173 4.91723 11.9173 5.26637C11.9173 6.54608 10.9473 7.5835 9.75065 7.5835C8.55403 7.5835 7.58398 6.54608 7.58398 5.26637C7.58398 4.91723 7.74525 4.34671 7.9798 3.94229C8.27531 3.43275 8.68714 2.88717 9.03929 2.45348C9.4321 1.96971 9.75065 1.62516 9.75065 1.62516C9.75065 1.62516 10.0692 1.96971 10.462 2.45348Z"
      fill={color}
    />
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M5.41732 10.8332C4.60486 10.8332 3.79232 10.0865 3.79232 8.96743C3.79232 8.74295 3.92782 8.19987 4.14509 7.81843C4.48483 7.22195 4.9803 6.55486 5.41732 6.01005C5.85434 6.55486 6.3498 7.22195 6.68955 7.81843C6.90682 8.19987 7.04232 8.74295 7.04232 8.96743C7.04232 10.0865 6.22978 10.8332 5.41732 10.8332ZM6.12708 5.165C6.6001 5.74454 7.20992 6.54317 7.63089 7.28224C7.92407 7.79696 8.12565 8.52308 8.12565 8.96743C8.12565 10.5962 6.91309 11.9165 5.41732 11.9165C3.92155 11.9165 2.70898 10.5962 2.70898 8.96743C2.70898 8.52308 2.91057 7.79696 3.20375 7.28224C3.62472 6.54317 4.23453 5.74454 4.70755 5.165C5.11258 4.66876 5.41732 4.33317 5.41732 4.33317C5.41732 4.33317 5.72205 4.66876 6.12708 5.165Z"
      fill={color}
    />
  </svg>
);

export default IconDropletSize;
