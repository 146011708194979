import { ProductSeed } from 'base/types/Products';

export const calculateFAOFromProductCatalog = (products: ProductSeed[]) => {
  if (products && products.length > 0) {
    const faoValues = products
      .map((p) => p.fao)
      .filter((fao) => fao !== null && fao !== undefined);
    const minFAO = Math.min(...faoValues);
    const maxFAO = Math.max(...faoValues);

    return { minFAO, maxFAO };
  } else {
    return { minFAO: 0, maxFAO: 0 };
  }
};
