import styled from 'styled-components';
import { Row } from 'syngenta-digital-cropwise-react-ui-kit';
import ModalView from 'components/ModalView';
import { showSlidingAnimation } from 'utils/modalBoxAnimations';
import { IStyledModalProps } from 'base/types/ModalBox';

export const StyledModalView = styled(ModalView)<IStyledModalProps>`
${(props) => showSlidingAnimation(props.slideDirection)}
  @media screen and (orientation: landscape) {
    max-width: 50%;
    right: 0;
    top: 0;
    border-top-left-radius: 0px;
    border-top-right-radius: 0px;
    height: 100%;
    max-height: 100%;
  }
  .iMmxge{
    border: none;
  }
  .modal-view-content{
    gap: 8px;
  }
  .modal-view-footer{
    padding-top: 10px;
  }
  .modal-view-header{
    font-size: 20px
  }
    
`;

export const StyledRow = styled(Row)`
  display: flex;
  justify-content: space-between;
`;

export const StyledDivText = styled('div')`
  font-family: 'Noto Sans';
  font-style: normal;
  font-weight: 400;
  font-size: 14px;
  line-height: 22px;
`;

export const StyledDivValue = styled('div')`
  font-family: 'Noto Sans';
  font-style: normal;
  font-weight: 600;
  font-size: 16px;
  text-align: right;
  line-height: 24px;
`;
