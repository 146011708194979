import React from 'react';
import ReactDOM from 'react-dom/client';
// import smartlookClient from 'smartlook-client';
import mapboxgl from 'mapbox-gl';

import './index.css';
import App from './App';
import reportWebVitals from './reportWebVitals';

import AppProviders from 'AppProviders';
import { GoogleReCaptchaProvider } from 'react-google-recaptcha-v3';

// if (process.env.REACT_APP_ENV === 'prod' && process.env.REACT_APP_SMARTLOOK_PROJECT_KEY) {
//   smartlookClient.init(process.env.REACT_APP_SMARTLOOK_PROJECT_KEY || '');
// }

mapboxgl.accessToken = 'pk.eyJ1Ijoic3RyaWRlcmVuZ2luZWVyaW5nIiwiYSI6ImNsOHVzNW0xejAwbTMzbm51azJzbTkwc2gifQ.ird3F77vJDW9iecKeQfhaw';

const root = ReactDOM.createRoot(document.getElementById('root') as HTMLElement);
root.render(
  <React.StrictMode>
    <AppProviders>
      <GoogleReCaptchaProvider reCaptchaKey={process.env.REACT_APP_RECAPTCHA_SITE_KEY ?? ''}>
        <App />
      </GoogleReCaptchaProvider>
    </AppProviders>
  </React.StrictMode>
);

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals();
