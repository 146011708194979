import styled from 'styled-components';
import userLocation from 'assets/images/user-location.png';
import movePin from 'assets/images/movePin.png';
import mapPin from 'assets/images/map-pins.png';
import breakpoints from 'base/constants/breakpoints';

export const StyledMap = styled.div`
  height: 100%;
`;

export const StyledDivGeocoder = styled.div`
  margin-left: 181px;

  @media only screen and ${breakpoints.maxWidth.sm} {
    margin-left: 0;
  }
`;

export const StyledMarker = styled.img.attrs((props) => ({
  src: props.src || userLocation,
}))`
  background-size: cover;
  width: 32px;
  height: 32px;
  cursor: pointer;
`;

export const StyledPin = styled.img.attrs((props) => ({
  src: props?.src === 'hu' ? mapPin : movePin,
}))`
  width: 42px;
  cursor: pointer;
  margin-bottom: 35px;
`;

export const StyledNotificationContent = styled.div`
  font-weight: 600;
  color: #000000;
`;

export const CursorToolTip = styled.div`
  height: 30px;
  width: max-content;
  background: #232630;
  position: absolute;
  color: white;
  cursor: none;
  padding: 6px 10px;
  opacity: 0;
  border-radius: 4px;
`;

export const MapContainer = styled.div`
  position: relative;
  height: 100%;
  flex: 1;
`;
