import styled from 'styled-components';
import { Typography, Layout, colors, Row, Col } from 'syngenta-digital-cropwise-react-ui-kit';
import { ReactComponent as IllustrationWelcome } from 'assets/images/illustration-welcome.svg';
import { Spinner } from 'components/Spinner';
import breakpoints from 'base/constants/breakpoints';
import ButtonBase from 'components/Buttons/ButtonBase';

const Root = styled.div`
  flex: 1;
  overflow: auto;
`;

const StyledContent = styled(Layout.Content)<{
  showingCountdown?: boolean;
}>`
  display: flex;
  flex-direction: column;
  justify-content: unset;
  width: 100%;
  align-items: center;
  padding: 30px 15px;
  margin: auto;

  @media screen and ${breakpoints.maxWidth.sm} {
    width: 100vw;
    padding-bottom: ${(props) => (props.showingCountdown ? '0' : '30px')};
    height: calc(100dvh - 56px);
  }
`;

const StyledTitle = styled(Typography.Title)`
  font-size: 24px;
  font-weight: 600;
  text-align: center;
  @media screen and ${breakpoints.maxWidth.sm} {
    font-style: normal;
    font-size: 20px;
    line-height: 28px;

    display: flex;
    align-items: center;
    letter-spacing: -0.0075em;
  }
`;

const StyledIllustrationWelcome = styled(IllustrationWelcome)`
  width: 100%;
  margin: 53px 0;
  @media screen and ${breakpoints.maxWidth.sm} {
    width: 100%;
    margin: 0px 0px;
  }
`;

const StyledParagraph = styled(Typography.Paragraph)`
  font-size: 14px;
  text-align: center;
  margin-top: 48px;
  color: ${colors.neutral70};
`;

const StyledCountdownText = styled(Typography.Paragraph)`
  width: 360px;
  font-size: 14px;
  color: ${colors.neutral70};
  margin-top: 10px;
  @media screen and ${breakpoints.maxWidth.sm} {
    margin: 5px 0 !important;
  }
`;

const StyledCol = styled(Col)`
  width: 211px;
  @media screen and ${breakpoints.maxWidth.sm} {
    width: 100%;
  }
`;

const StyledPrimaryButtonRow = styled(Row)`
  gap: 24px;
  @media screen and ${breakpoints.maxWidth.sm} {
    gap: 16px;
    flex-direction: row;
    width: 100%;
    margin-top: auto;
    justify-content: flex-start;
  }
`;

const StyledSecondaryButtonRow = styled(Row)`
  gap: 24px;
  justify-content: space-between !important;
  margin-bottom: 10px;
  @media screen and ${breakpoints.maxWidth.sm} {
    gap: 15px;
    flex-direction: column-reverse;
    width: 100%;
    justify-content: flex-start;
    margin-top: auto;
    margin-bottom: 0;
  }
`;

const StyledButtonBase = styled(ButtonBase)`
  width: 180px !important;
  height: 44px !important;
  line-height: 16px !important;

  @media screen and ${breakpoints.maxWidth.sm} {
    width: 47% !important;
    height: 44px !important;
  }
`;

const StyledCreateAnotherRecommendationButton = styled(StyledButtonBase)`
  width: fit-content !important;
  padding: 0 30px !important;
  margin-bottom: 15px !important;

  @media screen and ${breakpoints.maxWidth.sm} {
    width: 100% !important;
  }
`;

const StyledSpinner = styled(Spinner)`
  position: fixed;
  top: 0;
  bottom: 0;
  width: 100%;
  height: inherit !important;
`;

const StyledMessageContainer = styled(Typography.Paragraph)`
width: 52%;
text-align: center;
margin: 48px auto;
color: ${colors.neutral70};
font-size: 14px;
line-height: 22px;
font-weight: 400;
@media screen and ${breakpoints.maxWidth.sm} {
  width: 100% !important;
}
`;

const StyledContactText = styled.span`
font-size: 14px;
 color: red;
 font-weight: 600;
 color: ${colors.neutral70};
`;

const StyledContactMessage = styled.div`
text-align: center;
color: ${colors.neutral70};
`;

const StyledMessage = styled.div`
font-size: 14px;
text-align: center;
color: ${colors.neutral70};
`;

const StyledLink = styled.div`
display: inline-block;
text-decoration: underline;
color: ${colors.blue50};
cursor: pointer;
`;

const ButtonGroupWrapper = styled.div`
 display: flex;
 justify-content: space-between;
 gap: 12px;
 font-size: 16px;
`;

const IllustationImageContainer = styled.div`
 img {
  width: 100%;
 }
`;

export {
  Root,
  StyledTitle,
  StyledParagraph,
  StyledContent,
  StyledIllustrationWelcome,
  StyledPrimaryButtonRow,
  StyledSecondaryButtonRow,
  StyledCol,
  StyledButtonBase,
  StyledSpinner,
  StyledCountdownText,
  StyledCreateAnotherRecommendationButton,
  StyledContactText,
  StyledContactMessage,
  StyledMessage,
  StyledLink,
  ButtonGroupWrapper,
  StyledMessageContainer,
  IllustationImageContainer,
};
