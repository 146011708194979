import { BrowserConstants } from 'utils/constants/BrowserConstants';

export const browserDetector = () => {
  const userAgent = navigator.userAgent;
  let browserName = null;

  if (userAgent.match(/chrome|chromium|crios/i)) {
    browserName = BrowserConstants.CHROME;
  } else if (userAgent.match(/firefox|fxios/i)) {
    browserName = BrowserConstants.FIREFOX;
  } else if (userAgent.match(/safari/i)) {
    browserName = BrowserConstants.SAFARI;
  } else if (userAgent.match(/opr\//i)) {
    browserName = BrowserConstants.OPERA;
  } else if (userAgent.match(/edg/i)) {
    browserName = BrowserConstants.EDGE;
  }

  return browserName;
};
