import { useEffect, useMemo, useState } from 'react';
import { useAppState } from 'context/AppState';
import { useTranslation } from 'react-i18next';

import { colors, Skeleton } from 'syngenta-digital-cropwise-react-ui-kit';
import { Text, StyledSpinner, Tabs } from '../../V2Common.styles';
import {
  SectionElement,
  SectionElementContainer,
  StyledTitle,
  FullScreenChartContainerStyled,
  TabsContainer,
  StyledTabs,
  StyledIconRotatePhone,
} from './ProductPerformance.styles';
import ProductPerformanceChart from './ProductPerformanceChart';
import track from 'utils/amplitudeWrapper';
import { useBreakpoint } from 'hooks';

interface ProductPerformanceProps {
  sectionRef?: any;
  landscapeAllowed?: boolean;
}

type ReadonlyProductPerformanceProps = Readonly<ProductPerformanceProps>;

const step = 0.25;

function ProductPerformance({
  sectionRef,
  landscapeAllowed,
}: ReadonlyProductPerformanceProps): JSX.Element {
  const { landscape, isMobile } = useBreakpoint();
  const [t] = useTranslation();
  const [activeProduct, setActiveProduct] = useState('');
  const [isLoading, setIsLoading] = useState(false);

  const {
    apiData: { productCatalog, recommendationCropwise, breakoutsGlobal },
  } = useAppState();

  const isMobilePortraitOrDesktop = (isMobile && !landscape) || !isMobile;
  const recommendation =
    recommendationCropwise?.recommendations[0].multi_field.config.recommendation;
  const recommendedProducts = recommendationCropwise?.recommendations[0].products;
  const seedRankings = recommendation?.[0]?.SeedRankings;
  const labels = useMemo(() => {
    return {
      VL: {
        title: t('Wet & Cold'),
        temperatureLabel: t('Very Low Temperature'),
        precipitationLabel: t('High Precipitation'),
      },
      VH: {
        title: t('Very Hot & Dry'),
        temperatureLabel: t('Very High Temperature'),
        precipitationLabel: t('Low Precipitation'),
      },
      L: {
        title: t('Cool & Rainy'),
        temperatureLabel: t('Low Temperature'),
        precipitationLabel: t('High Precipitation'),
      },
      H: {
        title: t('Warm & Dry'),
        temperatureLabel: t('High Temperature'),
        precipitationLabel: t('Low Precipitation'),
      },
      M: {
        title: t('Mild'),
        temperatureLabel: t('Medium Temperature'),
        precipitationLabel: t('Medium Precipitation'),
      },
    };
  }, [t]);

  const statsData = useMemo(
    () =>
      (breakoutsGlobal[activeProduct]?.breakouts || [])
        .find((item) => item.name === 'Temperature/Precipitation Ratio')
        ?.stats?.filter((item) => item.CATEGORY),
    [breakoutsGlobal, activeProduct]
  );

  const tableDatas = useMemo(
    () =>
      statsData?.map((item) => {
        return {
          value: item.WIN_PRCT,
          barColor: colors.blue60,
          color: colors.blue60,
          titleColor: colors.neutral90,
          ...labels[item.CATEGORY as 'VL'],
        };
      }),
    [statsData, labels]
  );

  const values = useMemo(() => tableDatas?.map((item) => item.value) || [0, 1], [tableDatas]);
  const max = useMemo(() => Math.ceil(Math.max(...values)), [values]);
  const min = useMemo(() => Math.floor(Math.min(...values)), [values]);

  const tabItemsData = useMemo(() => {
    if (!recommendedProducts) {
      return [];
    }
    return recommendedProducts
      .filter((item) => item.product_name !== 'None')
      .map((seed) => {
        const productData = productCatalog?.find((p) => p.productName === seed.product_name);
        return {
          key: productData?.productName,
          children: <div />,
          label: productData?.commercialName,
        };
      });
  }, [productCatalog, recommendation]);

  useEffect(() => {
    if (!recommendation) {
      return;
    }
    if (activeProduct === '') {
      const [firstProduct] = recommendedProducts || [];
      setActiveProduct(firstProduct?.product_name);
    }
  }, [recommendation, activeProduct]);

  const onChange = async (activeValue: string) => {
    if (!recommendation) {
      return;
    }

    track('recommendation interaction', { 'product performance interaction': true });
    if (seedRankings && seedRankings.length > 0) {
      const productData = seedRankings.find((product) => product?.Seed?.SeedName === activeValue);
      track('recommendation interaction', {
        'product performance': `Product ${productData?.Rank}`,
      });
    }
    setActiveProduct(activeValue);

    if (!breakoutsGlobal[activeValue]) {
      setIsLoading(true);
      // This api is already called in recommendation page, it should not be called again
      // await ApiDataActions.getBreakoutsGlobalRecommendationId({
      //   recommendationSolutionId: recommendationCropwise?.id,
      //   withFilters: true,
      //   activeProduct: activeValue,
      // });

      setIsLoading(false);
    }
  };

  const SkeletonLoader = useMemo(() => {
    return (
      <Skeleton
        active
        style={{ padding: '100px 64px 0px 64px' }}
        paragraph={{ rows: 4, width: '100%' }}
      />
    );
  }, []);

  return (
    <div ref={sectionRef}>
      <SectionElementContainer data-testid="product-performance-section">
        <SectionElement>
          {isMobile ? (
            <StyledTitle>{t('Product Performance')}</StyledTitle>
          ) : (
            <StyledTitle>{t('Product Performance by Stress Level')}</StyledTitle>
          )}
          <Text>
            {t(
              'We test our products in all environmental condition to understand their specific yield response to different stress levels. In the chart below you can find the average yield difference of our recommended product against the trial average of the compared hybrids.'
            )}
          </Text>
          {(!recommendedProducts?.length || !tableDatas?.length)
            ? SkeletonLoader
            : <FullScreenChartContainerStyled fullScreen={landscapeAllowed}>
              {landscape && isMobile && (
                <StyledIconRotatePhone height={20} color={colors.neutral60} width={20} />
              )}
              <TabsContainer isMobilePortraitOrDesktop={isMobilePortraitOrDesktop}>
                {landscape && isMobile ? (
                  <StyledTabs
                    items={tabItemsData as any}
                    activeKey={activeProduct}
                    onChange={onChange}
                  />
                ) : (
                  <Tabs items={tabItemsData as any} activeKey={activeProduct} onChange={onChange} />
                )}
              </TabsContainer>
              <ProductPerformanceChart yMin={min} data={tableDatas || []} yMax={max} yStep={step} />
            </FullScreenChartContainerStyled>
          }
        </SectionElement>
      </SectionElementContainer>
      {isLoading && <StyledSpinner />}
    </div>
  );
}

export default ProductPerformance;
