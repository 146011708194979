/* eslint-disable no-confusing-arrow */
import { CSSProperties } from 'react';
import styled from 'styled-components';
import { Carousel, Typography, colors } from 'syngenta-digital-cropwise-react-ui-kit';
import breakpoints from 'base/constants/breakpoints';

interface ITitleProps {
  borderColor?: string;
  selected?: boolean;
  isTitleInformation?: boolean;
  margin?: string;
}

const ProductNameText = styled(Typography.Text)<ITitleProps>`
  font-family: 'Noto Sans';
  font-style: normal;
  font-weight: 600;
  font-size: 16px;
  line-height: 24px;
  letter-spacing: 0.075em;
  padding-left: 8px;

  color: ${colors.neutral90};
`;

interface IContainer extends CSSProperties {
  selected?: boolean;
  applyMargin?: boolean;
  isMobile?: boolean;
  size?: number;
  isProductSelected?: boolean;
  isAlternateProduct?: boolean;
}

const ProductCards = styled.div<IContainer>`
  padding: 0 0 0 60px;
  cursor: pointer;
  @media screen and ${breakpoints.maxWidth.sm} {
    padding: 0 20px;
  }
`;

const RecommendationProducts = styled.div<IContainer>`
  position: absolute;
  right: 0;
  left: 0;
  bottom: 40px;
  z-index: 99;
`;

const ProductContainer = styled.div<IContainer>`
  position: relative;
  width: 378px;
  max-width: 378px;
  height: 128px;
  margin: 0px 5px;
  display: inline-flex;
  flex-direction: column;
  align-items: flex-start;
  border: 1px solid;
  border-radius: 8px;
  gap: 5px;
  justify-content: center;
  background-color: #ffffff;
  padding: 0 20px;
  border: ${({ isProductSelected }) =>
    isProductSelected ? '2px solid #0071CD' : '1px solid transparent'};

  @media screen and ${breakpoints.maxWidth.sm} {
    width: 300px;
    max-width: 300px;
  }
`;

const ProductContainerRow = styled.div`
  display: flex;
  align-items: center;
  margin-bottom: 5px;
  width: 100%;
  gap: 5px;

  @media screen and ${breakpoints.maxWidth.sm} {
    margin-bottom: 0;
  }
`;

const CircleContainer = styled.div`
  position: relative;
  width: 30px;
  height: 30px;
  border-radius: 50%;
  background-color: ${(props) => `${props.color}80`};
`;

const InnerCircle = styled.div`
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  width: 12.8px;
  height: 12.8px;
  border-radius: 50%;
  background-color: ${(props) => props.color};
`;

const IconContainer = styled.div<IContainer>`
  width: 24px;
  height: 24px;
  background-color: #f3f4f6;
  border-radius: 8px;
  display: flex;
  justify-content: center;
  align-items: center;
`;

const IconText = styled.div<IContainer>`
  font-family: 'Noto Sans';
  font-style: normal;
  font-weight: 400;
  font-size: 12px;
  line-height: 12px;
  letter-spacing: 0.075em;
  padding-left: 8px;

  color: ${colors.neutral90};
`;

const StyledCarousel = styled(Carousel)`
  .slick-dots li,
  .slick-dots li button,
  .slick-dots li.slick-active {
    width: 12px;
  }
  @media screen and ${breakpoints.maxWidth.sm} {
    .slick-list {
      padding: 0px !important;
      margin-left: -10px !important;
    }
  }
  .slick-dots-bottom {
    bottom: -30px;
  }
  .slick-dots li button {
    width: 12px;
    height: 12px;
    border-radius: 100%;
    border: 2px solid #fff;
    background-color: transparent;
    opacity: 1;
  }

  .slick-dots li.slick-active button {
    border-color: #fff;
    background-color: #fff;
  }
`;

const CarouselContainer = styled.div<{ width?: string }>`
  width: ${({ width }) => width ?? '1300px'};
`;

const ProductsTitle = styled.div<IContainer>`
  width: 300px;
  height: 40px;
  border-radius: 8px;
  background-color: rgba(0, 0, 0, 0.75);
  margin: 10px 65px;
  display: flex;
  align-items: center;
  justify-content: left;
  padding: 0 20px;
  cursor: pointer;
  position: absolute;
  right: 0;
  left: 0;
  bottom: 170px;
  z-index: 99;

  @media screen and ${breakpoints.maxWidth.sm} {
    margin: 10px 20px;
    width: 258px;
  }
`;

const ProductTitleText = styled.span<IContainer>`
  font-size: 16px;
  font-weight: 600;
  font-family: 'Noto Sans', sans-serif;
  line-height: 20px;
  color: #ffffff;
  text-align: center;
  padding: 0 10px;

  @media screen and ${breakpoints.maxWidth.sm} {
    padding: 0 3px;
    font-size: 14px;
  }
`;

const TopRatedProduct = styled.span<IContainer>`
  position: absolute;
  top: -1px;
  right: -1px;
  border: 1px;
  border-radius: 0px 8px;
  background-color: ${(props) => (props.isAlternateProduct ? '#696F88' : '#0071cd')};
  padding: 1px 8px;
  font-family: Noto Sans;
  font-style: normal;
  font-weight: 600;
  font-size: 12px;
  line-height: 20px;
  letter-spacing: 0.075em;
  color: #ffffff;
`;

type ProductDetailsStyledType = {
  isAlternateProduct?: boolean;
};

const ProductFieldDetails = styled.span<ProductDetailsStyledType>`
  visibility: ${(props) => (props.isAlternateProduct ? 'hidden' : 'visible')};
`;

export {
  ProductCards,
  ProductNameText,
  RecommendationProducts,
  ProductContainer,
  CircleContainer,
  InnerCircle,
  IconContainer,
  IconText,
  ProductContainerRow,
  StyledCarousel,
  CarouselContainer,
  ProductsTitle,
  ProductTitleText,
  TopRatedProduct,
  ProductFieldDetails,
};
