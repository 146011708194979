import { useEffect, useState } from 'react';
import { StyledTag, SelectedSoilColor, SoilColor } from './SoilTag.styles';

type SoilTagProps = {
  color?: string;
  checked?: boolean;
  'data-testid'?: string;
  onChange?: () => void;
  soilLabel: string;
  isMobile?: boolean;
};

export default function SoilTag(props: SoilTagProps) {
  const [soilTagsText, setSoilTagsText] = useState<string>('');
  const { soilLabel, color, checked = false, onChange, 'data-testid': testId, isMobile } = props;

  useEffect(() => {
    if (soilLabel) {
      const words = soilLabel.toString().split(' ');
      const soilTags = words.reduce((acc, word) => {
        return [...acc, `${word.charAt(0).toUpperCase()}${word.slice(1).toLowerCase()}`];
      }, [] as string[]);
      const soilTagText = soilTags.join(' ');
      setSoilTagsText(soilTagText);
    }
  }, [soilLabel]);

  return (
    <StyledTag
      checked={checked}
      onChange={onChange}
      theme={color}
      data-testid={testId}
      style={!isMobile ? { padding: '8px', borderRadius: '4px' } : {}}
    >
      {isMobile ? (
        <SelectedSoilColor style={{ backgroundColor: color }} data-testid={`${testId}-color`} />
      ) : (
        <SoilColor style={{ backgroundColor: color }} data-testid={`${testId}-color`} />
      )}
      {soilTagsText}
    </StyledTag>
  );
}
