/* eslint-disable array-bracket-newline */
import { useState } from 'react';
import { useTranslation } from 'react-i18next';
import { InputTextHandler } from 'components/input-text-handler';
import { InputTextHandlerType } from 'components/input-text-handler/InputTextHandler';
import InputNumberHandler from 'components/input-number-handler';
import { INPUT_MAX_VALUE, INPUT_MIN_VALUE } from 'base/constants/inputNumber';
import { useAppState } from 'context/AppState';
import track from 'utils/amplitudeWrapper';
import { useRecommendationFormActions } from 'context/actions/recommendationFormActions';

interface FieldFormProps {
  disableForm?: boolean;
  fieldName: string;
  size: string;
  fieldId: string | number;
  setHasInputErrors: Function;
}

// eslint-disable-next-line import/no-default-export
export default function FieldForm(props: Readonly<FieldFormProps>): JSX.Element {
  const { fieldName, size, disableForm, fieldId, setHasInputErrors } = props;
  const [t] = useTranslation();
  const [nameHasFocus, setNameHasFocus] = useState(false);
  const [nameChanged, setNameChanged] = useState(false);
  const {
    flow: { optionType },
    recommendationForm: {
      fields,
    },
  } = useAppState();
  const {
    saveFields,
  } = useRecommendationFormActions();

  const handleNameChange = (name: string, id: number | string) => {
    const updatedFields = fields.map((item) => {
      if (item.id === id) {
        return { ...item, fieldName: name };
      }
      return item;
    });
    const floatNumber = parseFloat(size.replace(',', ''));
    setHasInputErrors({ fieldName: !name, size: !((floatNumber >= 0.01) && (floatNumber <= 1000)) });
    saveFields({ fields: updatedFields });
  };

  const handleSizeChange = (num: string, id: number | string) => {
    const updatedFields = fields.map((item) => {
      if (item.id === id) {
        return { ...item, hectares: num };
      }
      return item;
    });
    const floatNumber = parseFloat(num.replace(',', ''));
    setHasInputErrors({ size: !((floatNumber >= 0.01) && (floatNumber <= 1000)), fieldName: !fieldName });
    saveFields({ fields: updatedFields });
  };

  return (
    <div style={{ marginLeft: '16px' }}>
      <InputTextHandler
        label={t('Field Name')}
        data-testid="fieldName"
        id={fieldId}
        isRequired
        isLongTermMobileChange
        inputName="fieldName"
        inputValue={fieldName}
        setInputValue={(e) => handleNameChange(e, fieldId)}
        type={InputTextHandlerType.FIELD_NAME}
        inputProps={{
          disabled: false,
          onFocus: (e: React.ChangeEvent<HTMLInputElement>) => {
            e.target.select();
            setNameHasFocus(true);
            setNameChanged(true);
          },
          onBlur: () => {
            setNameHasFocus(false);
            if (nameChanged && fieldName !== '') {
              track('user updates field name', { 'updates field name': true });
            }
          },
        }}
        allowClear={nameHasFocus}
      />
      <InputNumberHandler
        inputName={fieldId}
        inputValue={size}
        setInputValue={(num) => handleSizeChange(num, fieldId)}
        onChange={(num) => handleSizeChange(num, fieldId)}
        step={1}
        label={t('Estimated Size (ha)')}
        min={INPUT_MIN_VALUE}
        max={INPUT_MAX_VALUE}
        inputProps={{
          disabled: disableForm,
          optionType,
        }}
      />
    </div>
  );
}
