import i18next from 'i18next';
import { initReactI18next } from 'react-i18next';

import Backend from 'i18next-http-backend';
import LanguageDetector from 'i18next-browser-languagedetector';
import { NONDDG_SUPPORTED_LANGS } from 'base/constants/supportedLangs';

if (process.env.NODE_ENV !== 'test') {
  i18next
    .use(Backend)
    .use(LanguageDetector)
    .use(initReactI18next)
    .init({
      interpolation: { escapeValue: false }, // React already does escaping
      fallbackLng: 'en',
      supportedLngs: NONDDG_SUPPORTED_LANGS.map((lang) => lang.value),
      detection: {
        order: ['querystring', 'localStorage'],
        lookupQuerystring: 'lang',
        lookupLocalStorage: 'i18nextLng',
        convertDetectedLanguage: (lang: string) => lang.toLowerCase(),
      },
    });
} else {
  // Configuration for testing
  i18next.use(initReactI18next).init({
    lng: 'en',
    fallbackLng: 'en',
    interpolation: {
      escapeValue: false,
    },
    resources: { en: { translation: {} } },
  });
}

export default i18next;
