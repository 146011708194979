export enum UserCategories {
  SYNGENTA_USER = 'SYNGENTA_USER',
  NON_SYNGENTA_USER = 'NON_SYNGENTA_USER',
  UNKNOWN_USER = 'UNKNOWN',
};

export enum ArrivalSourceCategories {
  MARKETING_CAMPAIGN = 'MARKETING_CAMPAIGN',
  EMAIL = 'EMAIL',
  UNKNOWN = 'UNKNOWN',
};

export type AgInputType = {
  'ag input crop': string;
  'yield range'?: string;
  'planting date'?: string;
  'harvest date'?: string;
};
