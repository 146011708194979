import { SVGProps } from 'react';
import { colors } from 'syngenta-digital-cropwise-react-ui-kit';

const IconDelete = ({
  height = '12px',
  width = '14px',
  color = colors.red50,
  ...props
}: SVGProps<SVGSVGElement>) => (
  <svg
    data-testid="deleteIcon"
    width={width}
    height={height}
    viewBox="0 0 12 14"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
    {...props}
  >
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M7.875 0.25L8.625 1H11.25V2.5H0.75V1H3.375L4.125 0.25H7.875ZM1.5 12.25C1.5 13.075 2.175 13.75 3 13.75H9C9.825 13.75 10.5 13.075 10.5 12.25V3.25H1.5V12.25ZM3 4.75H9V12.25H3V4.75Z"
      fill={color}
    />
  </svg>
);

export default IconDelete;
