import { FC } from 'react';
import { Typography } from 'syngenta-digital-cropwise-react-ui-kit';
import { RightOutlined } from '@ant-design/icons';

import {
  Container,
  StyledMainContainer,
  StyledRowContainer,
  StyledColumnContainer,
} from './WelcomeCardButton.styles';

const { Title, Paragraph } = Typography;

interface CardButtonProps {
  title: string;
  description: string;
  img: string;
  onClick?: () => void;
  id: string;
}

const CardButton: FC<CardButtonProps> = ({ title, description, img, onClick, id }) => {
  const isWindowsOS = navigator.platform.indexOf('Win') > -1;
  return (
    <Container hoverable onClick={onClick} className={isWindowsOS ? 'windows-card' : ''} data-testid={id}>
      <StyledMainContainer>
        <StyledRowContainer>
          <div className="container-img">
            <img src={img} alt="No img found" />
          </div>
          <StyledColumnContainer>
            <Title level={4} className="title syt-antd-typography ">
              {title}
            </Title>
            <Paragraph className="paragraph">{description}</Paragraph>
          </StyledColumnContainer>
        </StyledRowContainer>
        <div>
          <RightOutlined />
        </div>
      </StyledMainContainer>
    </Container>
  );
};

export default CardButton;
