export const SoilColors = [
  {
    key: 'FINE CLAY',
    label: 'CLAY',
    color: '#654009',
  },
  {
    key: 'FINE SANDY CLAY',
    label: 'SANDY CLAY',
    color: '#89590F',
  },
  {
    key: 'FINE SANDY CLAY LOAM',
    label: 'SANDY CLAY LOAM',
    color: '#C17E19',
  },
  {
    key: 'FINE CLAY LOAM',
    label: 'CLAY LOAM',
    color: '#AF7216',
  },
  {
    key: 'FINE SILTY CLAY',
    label: 'SILTY CLAY',
    color: '#774C0C',
  },
  {
    key: 'FINE SILTY CLAY LOAM',
    label: 'SILTY CLAY LOAM',
    color: '#9C6513',
  },
  {
    key: 'MED LOAM',
    label: 'LOAM',
    color: '#19A04B',
  },
  {
    key: 'MED SILT LOAM',
    label: 'SILT LOAM',
    color: '#117938',
  },
  {
    key: 'MED SILT',
    label: 'SILT',
    color: '#0A5324',
  },
  {
    key: 'COARSE SAND',
    label: 'SAND',
    color: '#CFC5B4',
  },
  {
    key: 'COARSE LOAMY SAND',
    label: 'LOAMY SAND',
    color: '#B83637',
  },
  {
    key: 'COARSE SANDY LOAM',
    label: 'SANDY LOAM',
    color: '#852222',
  },
];

/* Color pattern referred from https://www.figma.com/file/QBMCjv7e5LTeEuHcL5GcH0/Analytics%2FConnect---Main?node-id=17088%3A421733&mode=dev
*/

export const STD_SOIL_COLOR_GROUPS: Record<number, string[]> = {
  1: ['#B18041'],
  2: ['#B18041', '#A34F6D'],
  3: ['#B18041', '#E7BC72', '#A34F6D'],
  4: ['#B18041', '#E7BC72', '#A34F6D', '#C3708E'],
  5: ['#B18041', '#865719', '#E7BC72', '#A34F6D', '#C3708E'],
  6: ['#B18041', '#865719', '#E7BC72', '#C84F2B', '#A34F6D', '#C3708E'],
  7: ['#B18041', '#865719', '#E7BC72', '#C84F2B', '#A34F6D', '#C3708E', '#367FB2'],
  8: ['#B18041', '#865719', '#E7BC72', '#C84F2B', '#8F3A2E', '#A34F6D', '#C3708E', '#367FB2'],
  9: [
    '#B18041',
    '#865719',
    '#E7BC72',
    '#C84F2B',
    '#8F3A2E',
    '#A34F6D',
    '#C3708E',
    '#455588',
    '#367FB2',
  ],
  10: [
    '#B18041',
    '#865719',
    '#E7BC72',
    '#DE8D4B',
    '#C84F2B',
    '#8F3A2E',
    '#A34F6D',
    '#C3708E',
    '#455588',
    '#367FB2',
  ],
  11: [
    '#E9D85C',
    '#B18041',
    '#865719',
    '#E7BC72',
    '#DE8D4B',
    '#C84F2B',
    '#8F3A2E',
    '#A34F6D',
    '#C3708E',
    '#455588',
    '#367FB2',
  ],
  12: [
    '#9C790C',
    '#E9D85C',
    '#B18041',
    '#865719',
    '#E7BC72',
    '#DE8D4B',
    '#C84F2B',
    '#8F3A2E',
    '#A34F6D',
    '#C3708E',
    '#455588',
    '#367FB2',
  ],
  13: [
    '#9C790C',
    '#E9D85C',
    '#B18041',
    '#865719',
    '#E7BC72',
    '#DE8D4B',
    '#C84F2B',
    '#8F3A2E',
    '#A34F6D',
    '#C3708E',
    '#455588',
    '#367FB2',
    '#4D9265',
  ],
  14: [
    '#9C790C',
    '#E9D85C',
    '#B18041',
    '#865719',
    '#E7BC72',
    '#DE8D4B',
    '#C84F2B',
    '#8F3A2E',
    '#A34F6D',
    '#C3708E',
    '#455588',
    '#367FB2',
    '#4D9265',
    '#A7CE7F',
  ],
  15: [
    '#9C790C',
    '#BBA11E',
    '#E9D85C',
    '#B18041',
    '#865719',
    '#E7BC72',
    '#DE8D4B',
    '#C84F2B',
    '#8F3A2E',
    '#A34F6D',
    '#C3708E',
    '#455588',
    '#367FB2',
    '#4D9265',
    '#A7CE7F',
  ],
};
