import ButtonHub from 'components/ButtonHub';
import {
  StyledIconDropPin,
  StyledIconHub,
  StyledIconHubOpen,
  StyledDiv,
} from './MenuControl.styles';
import { colors } from 'syngenta-digital-cropwise-react-ui-kit';
import { ButtonProps } from 'antd';
import { useAppState } from 'context/AppState';
import { OptionType } from 'context/store/flowReducer';

interface IProps {
  id?: string;
  isOpen?: boolean;
  isVisible?: boolean;
  options?: any[];
  openIconType?: 'PIN' | 'POLIGON';
  onToggleMenuControl?: () => void;
  buttonRootProps?: ButtonProps;
}

const MenuControl = ({
  id = '',
  isOpen = false,
  isVisible = true,
  openIconType = 'PIN',
  options,
  onToggleMenuControl,
  buttonRootProps,
}: IProps): JSX.Element => {
  const buttonRootIconCustom = openIconType === 'PIN' ? StyledIconDropPin : StyledIconHubOpen;
  const {
    flow: { isDropAPinFlow, optionType },
  } = useAppState();
  const displayMenuOption = optionType === OptionType.Draw;
  return (
    <StyledDiv
      id={id}
      data-testid="menuControl"
      isDropAPinFlow={isDropAPinFlow}
      displayMenuOption={displayMenuOption}
    >
      {isVisible && (
        <ButtonHub
          isOpen={isOpen}
          onClick={onToggleMenuControl}
          buttonRootIcon={!isOpen && StyledIconHub()}
          buttonRootIconCustom={isOpen ? buttonRootIconCustom : undefined}
          buttonRootStyle={{
            backgroundColor: `${isOpen ? colors.green60 : ''}`,
            color: `${isOpen ? colors.neutral10 : ''}`,
            boxShadow: '0px 2px 8px rgba(0, 0, 0, 0.15)',
          }}
          options={options}
          buttonRootProps={buttonRootProps}
        />
      )}
    </StyledDiv>
  );
};

export default MenuControl;
