import { RefAttributes } from 'react';
import styled, { ThemedStyledProps } from 'styled-components';
import { colors } from 'syngenta-digital-cropwise-react-ui-kit';

export const Container = styled.div`
  display: flex;
  flex: 1;
  flex-direction: column;
`;

export const TabsContainer = styled.div`
  display: flex;
`;

export const TabItem = styled.button<
  ThemedStyledProps<RefAttributes<HTMLButtonElement> & { active?: boolean }, any>
>`
  display: flex;
  border: 0;
  background-color: white;
  height: 40px;
  font-family: 'Noto Sans';
  font-size: 16px;
  flex: 1;
  justify-content: center;
  align-items: center;
  color: ${({ disabled }) => (disabled ? colors.neutral30 : colors.neutral100)};
  cursor: ${({ disabled }) => (disabled ? 'default' : 'pointer')};
  font-weight: ${({ active }) => (active ? '600' : '400')};
  border-bottom: 2px solid ${({ active }) => (active ? colors.blue50 : colors.neutral20)};
`;

export const Content = styled.div`
  display: flex;
  flex: 1;
  position: relative;
`;

export const ContentItem = styled.div<
  ThemedStyledProps<RefAttributes<HTMLDivElement> & { active?: boolean }, any>
>`
  display: flex;
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  visibility: ${({ active }) => (active ? 'visible' : 'hidden')};
  z-index: ${({ active }) => (active ? '1' : '0')};
`;
