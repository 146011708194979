import { init, captureException } from '@sentry/react';
import { Integrations } from '@sentry/tracing' ;

const initSentry = async () => {
  init({
    dsn: process.env.REACT_APP_SENTRY_DSN,
    integrations: [new Integrations.BrowserTracing()],
    tracesSampleRate: 0.5,
    ignoreErrors: [],
    environment: process.env.REACT_APP_ENV,
  });
};

const captureExceptionInSentry = (errorMsg: string) => {
  captureException(errorMsg);
};

export { initSentry, captureExceptionInSentry };
