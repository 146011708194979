import styled from 'styled-components';
import { colors, Modal, SYT_PREFIX } from 'syngenta-digital-cropwise-react-ui-kit';

export const View = styled.div`
  position: absolute;
  top: 0;
  left: 0;
  bottom: 0;
  z-index: 1080;
  background-color: white;
  padding: 24px;
  display: flex;
  flex-direction: column;
  width: 100%;

  button {
    border: none;
  }

  .${SYT_PREFIX}-row {
    flex-wrap: nowrap;
  }

  .${SYT_PREFIX}-typography.cwsTypography {
    margin-bottom: 34px;
  }

  .${SYT_PREFIX}-btn-default {
    border: 1px solid ${colors.neutral30};
    color: ${colors.neutral100};
  }
`;

interface IModalProps {
  displayBottomBorder?: boolean;
}

export const ModalStyled = styled(Modal) <IModalProps>`
  .${SYT_PREFIX}-modal-header {
    border-bottom: ${({ displayBottomBorder }) =>
    (displayBottomBorder ? '1px solid #f0f0f0' : 'none')};
  }

  .${SYT_PREFIX}-modal-title {
    font-weight: 600;
    font-size: 16px;
    line-height: 24px;
    text-align: center;
  }

  button {
    border: none;
  }

  .${SYT_PREFIX}-btn-default {
    color: ${colors.neutral100};
  }
`;

export const Header = styled.div`
  font-weight: 600;
  font-size: 16px;
  line-height: 24px;
  padding-bottom: 14px;
  color: ${colors.neutral80};
  text-align: center;
`;

export const ModalContent = styled.div`
  display: flex;
  flex-direction: column;
  gap: 16px;
  ${(props) => (props.id === 'boundary-information-sheet' && `gap: 0px !important;`)}

  .${SYT_PREFIX}-typography {
    font-size: 16px;
    line-height: 24px;
    color: ${colors.neutral70};
    margin: 0;
  }
`;

export const Footer = styled.div`
  display: flex;
  border: none;
  padding-top: 24px;
  gap: 16px;
  margin-top: auto;

  div {
    flex: 1;
  }

  button {
    flex: 1;
    width: 100%;
    height: 44px;
    font-size: 16px;
    line-height: 24px;
  }
`;
