import { ButtonBaseProps } from 'components/Buttons/ButtonBase';
import { StyledButtonGroup } from './StepsControl.styles';

import useValidation from './hooks/useValidation';
import { useAppState } from 'context/AppState';
import { ModeTypes, OptionType, OverlappingGeometryTypes } from 'context/store/flowReducer';
import { useBreakpoint } from 'hooks';

interface IProps {
  id?: string;
  buttonPreviousProps?: ButtonBaseProps;
  buttonNextProps?: ButtonBaseProps;
}

const StepsControl = ({ id, buttonPreviousProps, buttonNextProps }: IProps): JSX.Element => {
  const {
    flow: {
      currentModeType,
      currentStep,
      optionType,
      isDrawingBoundaries,
      overlappingGeometryType,
      hasDetectFieldFlowStarted,
    },
    recommendationForm: {
      fields,
      pin,
      fieldSnapshots,
      fieldSelected,
      isAtleastAVertex,
      enableFieldSaveButton,
      tempBoundaryField,
    },
    map: { userDropPinPosition },
  } = useAppState();
  const screens = useBreakpoint();
  const isMobile = screens.isMobile;
  const isPinDataAvailable = Boolean(pin?.position?.lat);
  const points = fieldSnapshots || [];
  const { nextButtonDisabled, prevButtonDisabled } = useValidation({
    currentModeType,
    currentStep,
    isAtLeastOneField: optionType === OptionType.Draw ? fields.length > 0 : isPinDataAvailable,
    optionType,
    points,
    isDrawingBoundaries,
    overlappingGeometryType,
  });

  const checkForCreatingMode = () => {
    if (overlappingGeometryType !== OverlappingGeometryTypes.NONE) {
      return true;
    }
    if (
      fields.length > 0 &&
      !enableFieldSaveButton &&
      (isAtleastAVertex || hasDetectFieldFlowStarted)
    ) {
      return true;
    }
    if (fields.length > 0 || (fields.length === 0 && enableFieldSaveButton)) {
      return false;
    }
    if (fields.length === 0 && !enableFieldSaveButton && !tempBoundaryField) {
      return true;
    }
    return nextButtonDisabled;
  };
  const checkForDrawBoundary = () => {
    if (currentModeType === ModeTypes.CREATING) {
      return checkForCreatingMode();
    } else if (currentModeType === ModeTypes.EDITING) {
      return false;
    } else if (fieldSelected) {
      return true;
    }
    return true;
  };
  const checkForDropPin = () => {
    if (userDropPinPosition?.lat !== 0 && userDropPinPosition?.lng !== 0) {
      return false;
    }
    return true;
  };
  const checkDisableSave = () => {
    if (isMobile && optionType === OptionType.Draw) {
      return checkForDrawBoundary();
    } else if (isMobile && optionType === OptionType.Drop) {
      return checkForDropPin();
    }
  };

  return (
    <StyledButtonGroup
      id={id}
      data-testid={id}
      buttonLeft={{
        buttonProps: {
          disabled: isMobile ? false : prevButtonDisabled,
        },
        buttonTestId: `${id}-leftBtn`,
        ...buttonPreviousProps,
      }}
      buttonRight={{
        buttonProps: { disabled: isMobile ? checkDisableSave() : nextButtonDisabled },
        buttonTestId: `${id}-rightBtn`,
        ...buttonNextProps,
      }}
      style={{
        display: isMobile && (optionType === OptionType.empty || optionType === OptionType.Detect) ? 'none' : '',
        background: 'rgba(20, 21, 28, 0.6)',
        boxShadow: '0px 3px 0px 12px rgba(20, 21, 28, 0.6)',
      }}
    />
  );
};

export default StepsControl;
