import { useCallback, useState, useEffect, useMemo } from 'react';

import { ModeTypes } from 'context/store/flowReducer';

interface IProps {
  currentModeType: ModeTypes;
  areTempBoundary: boolean;
  isAtleastAVertex: boolean;
  isFieldSelected: boolean;
  isMobile: boolean;
}

const useValidation = ({
  currentModeType,
  areTempBoundary,
  isAtleastAVertex,
  isFieldSelected,
  isMobile
}: IProps) => {
  const [collapsible, setCollapsible] = useState(true);
  const [disableConfirm, setDisableConfirm] = useState(false);
  const [disableUndo, setDisableUndo] = useState(false);
  const [disableDelete, setDisableDelete] = useState(false);
  const [disableDraw, setDisableDraw] = useState(false);

  const validateCreatingMode = useCallback(() => {
    setCollapsible(!isAtleastAVertex);
    setDisableDraw(false);
  }, [isAtleastAVertex]);

  const validateEditingMode = useCallback(() => {
    setCollapsible(false);
    setDisableDraw(false);
    setDisableConfirm(false);
    setDisableUndo(false);
  }, []);

  const validateSavingMode = useCallback(() => {
    setCollapsible(true);
    setDisableDraw(true);
  }, []);

  const validateNoActionMode = useCallback(() => {
    setCollapsible(true);
    setDisableDraw(true);
  }, []);

  const validateConfirmingMode = useCallback(() => {
    setCollapsible(false);
    setDisableDraw(true);
    setDisableConfirm(true);
    setDisableUndo(true);
  }, []);

  const validateDeletingMode = useCallback(() => {
    setCollapsible(true);
  }, []);

  const validationModeTypeMap = useMemo(
    (): { [key in ModeTypes]: () => void | undefined } => ({
      [ModeTypes.CREATING]: validateCreatingMode,
      [ModeTypes.EDITING]: validateEditingMode,
      [ModeTypes.SAVING]: validateSavingMode,
      [ModeTypes.CANCELLING]: () => undefined,
      [ModeTypes.NO_ACTION]: validateNoActionMode,
      [ModeTypes.CONFIRMING_AFTER_EDIT]: validateConfirmingMode,
      [ModeTypes.DELETING]: validateDeletingMode,
    }),
    [
      validateCreatingMode,
      validateEditingMode,
      validateSavingMode,
      validateNoActionMode,
      validateConfirmingMode,
      validateDeletingMode,
    ]
  );

  useEffect(() => {
    if (isFieldSelected && currentModeType !== ModeTypes.DELETING) {
      setCollapsible(false);
      setDisableConfirm(true);
      setDisableDelete(false);
      if (isMobile && areTempBoundary) {
        setDisableUndo(false);
      } else {
        setDisableUndo(true);
      }
      setDisableDraw(true);
    }
  }, [isFieldSelected, currentModeType]);

  useEffect(() => {
    validationModeTypeMap[currentModeType]();
  }, [currentModeType, validationModeTypeMap]);

  useEffect(() => {
    setDisableConfirm(!areTempBoundary);
    setDisableDelete(!isAtleastAVertex);
    setDisableUndo(false);
  }, [areTempBoundary, isAtleastAVertex]);

  useEffect(() => {
    if (isMobile) {
      setDisableUndo(!isAtleastAVertex);
      setDisableDelete(!isAtleastAVertex);
    } else {
      setDisableUndo(false);
      setDisableDelete(false);
    }
  }, [isMobile, isAtleastAVertex]);

  return {
    collapsible,
    disableConfirm,
    disableUndo,
    disableDelete,
    disableDraw,
  };
};

export default useValidation;
