export const BrowserConstants = {
  CHROME: 'chrome',
  FIREFOX: 'firefox',
  SAFARI: 'safari',
  OPERA: 'opera',
  EDGE: 'edge',
  CHROME_LOCATION_URL:
    'https://support.google.com/chrome/answer/142065?hl=en&co=GENIE.Platform%3DDesktop',
  FIREFOX_LOCATION_URL:
    'https://support.mozilla.org/en-US/kb/does-firefox-share-my-location-websites?redirectslug=does-firefox-share-my-location-web-sites&redirectlocale=en-US#w_how-does-it-work',
  SAFARI_LOCATION_URL: 'https://support.apple.com/lt-lt/guide/mac-help/mh35873/mac',
  OPERA_LOCATION_URL: 'https://help.opera.com/en/latest/web-preferences/#location',
  EDGE_LOCATION_URL:
    'https://support.microsoft.com/en-us/microsoft-edge/location-and-privacy-in-microsoft-edge-31b5d154-0b1b-90ef-e389-7c7d4ffe7b04',
};

export enum ORIENTATION_TYPE {
  LANDSCAPE = 'landscape',
  PORTRAIT = 'portrait',
};

export enum ORIENTATION_BASED_DISPLAY {
  SHOW_ON_LANDSCAPE = 'showOnLandscape',
  HIDE_ON_LANDSCAPE = 'hideOnLandscape',
};
