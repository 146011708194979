import styled from 'styled-components';
import { colors, SYT_PREFIX } from 'syngenta-digital-cropwise-react-ui-kit';

export const ViewFooterContainer = styled.div`
  position: fixed;
  bottom: 0;
  z-index: 1080;
  background-color: white;
  display: flex;
  flex-direction: column;
  width: 100%;
  border-top-right-radius: 8px;
  border-top-left-radius: 8px;
  max-height: calc(100% - 86px);

  button {
    border: none;
  }

  .${SYT_PREFIX}-row {
    flex-wrap: nowrap;
  }

  .${SYT_PREFIX}-btn-default {
    border: 1px solid ${colors.neutral30};
    color: ${colors.neutral100};
  }
`;

export const ViewFooterOverlay = styled.div`
  z-index: 100;
  display: block;
  position: absolute;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  background: rgba(0, 0, 0, 0.5);
`;

export const StyledHeader = styled.div`
  display: flex;
  flex-direction: row;
  justify-content: center;
  padding-top: 28px;
  padding-bottom: 16px;
  padding-right: 24px;
  padding-left: 24px;
`;

export const StyledHeaderWithRectangle = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: center;
  padding-top: 8px;
  // padding-bottom: px;
  padding-right: 24px;
  padding-left: 24px;
`;

export const StyledTitle = styled.div`
  font-style: normal;
  font-weight: 600;
  font-size: 16px;
  padding: 12px 0px;
  line-height: 24px;
  text-align: center;
  letter-spacing: -0.0075em;
`;

export const CloseContainer = styled.div`
  position: absolute;
  right: 6%;
`;

export const Divider = styled.div`
  border: 0.1px solid ${colors.neutral20};
`;

export const MainContent = styled.div`
  flex: 1;
  padding-top: 16px;
  padding-right: 16px;
  padding-left: 16px;
  padding-bottom: 16px;
  overflow: auto;
`;
export const HeaderRectangle = styled.div`
  height: 4px;
  width: 32px;
  top: 8px;
  border-radius: 8px;
  background-color: #DFE2E7;
  margin: 0 auto;
`;
