import breakpoints from 'base/constants/breakpoints';
import styled from 'styled-components';

const FieldInfoSection = styled.div`
  display: flex;
  flex-direction: row;
  margin-top: 8px;
`;

const FieldImageSection = styled.div`
  display: flex;
  align-items: center;
`;

const FieldDetailSection = styled.div`
  widht: 100%;
  display: flex;
  flex-direction: row;
  margin-left: 12px;
`;

const HectareText = styled.div`
  font-size: 12px;
  font-weight: 400;
  color: #c2c7d0;
`;

const Text = styled.div`
  font-family: 'Noto Sans';
  font-weight: 600;
  font-size: 14px;
  line-height; 20px;
  color: #FFFFFF;
  max-width: 174px;
   @media only screen and ${breakpoints.maxWidth.sm} {
   max-width: 214px;
}
`;

const DividerText = styled.div`
  margin: 0px 5px;
  color: #ffffff;
`;

export { FieldInfoSection, FieldImageSection, FieldDetailSection, Text, HectareText, DividerText };
