import { useEffect, useState, useMemo, useCallback } from 'react';
import { useTranslation } from 'react-i18next';
import { useNavigate } from 'react-router-dom';
import track from 'utils/amplitudeWrapper';
import IllustrationWelcome from 'assets/images/illustration-welcome.svg';

import routes from 'base/constants/routes';
import { useAppState } from 'context/AppState';
import { useFlowActions } from 'context/actions/flowActions';
import Header from 'containers/Header/Header';
import {
  Root,
  StyledTitle,
  StyledParagraph,
  StyledContent,
  StyledButtonBase,
  StyledSecondaryButtonRow,
  StyledSpinner,
  StyledCountdownText,
  StyledCreateAnotherRecommendationButton,
  StyledContactText,
  StyledMessage,
  StyledLink,
  ButtonGroupWrapper,
  StyledContactMessage,
  StyledMessageContainer,
  IllustationImageContainer,
} from './Confirmation.styles';
import { EButtonType } from 'components/Buttons/ButtonBase';
import { useRecommendationFormActions } from 'context/actions/recommendationFormActions';
import { ConfirmationModal } from 'components/ConfirmationModal';
import { useBreakpoint } from 'hooks';
import ProxyLayer from 'base/api/ProxyLayer';
import { FlowSteps, ModeTypes, OptionType } from 'context/store/flowReducer';
import { ConfirmationModalAnimated } from 'components/ConfirmationModalAnimated';
import { UpdatePhoneForSMSModal } from 'components/UpdatePhoneForSMSModal';
import PhoneInput from 'react-phone-input-2';
import { MISSING_PHONE_MASKS } from 'base/constants/inputNumber';
import { SMS_ENABLED_COUNTRIES } from 'utils/constants/CountryData';

const MAX_RESEND_ATTEMPTS = 10;
const EMAIL_RESEND_DELAY = 60;

export default function Confirmation() {
  const navigate = useNavigate();
  const screens = useBreakpoint();
  const isMobile = screens.isMobile;
  const [t] = useTranslation();
  const [loading, setLoading] = useState(false);
  const [showResendEmailConfirmation, setShowResendEmailConfirmation] = useState(false);
  const [showMaximumEmailsResendModal, setShowMaximumEmailsResendModal] = useState(false);
  const [remainingSecondsToResendEmail, setRemainingSecondsToResendEmail] = useState(0);
  const [showSmsSentModal, setShowSmsSentModal] = useState(false);
  const [showUpdatePhoneForSMSModal, setShowUpdatePhoneForSMSModal] = useState(false);
  const [showSmsSentForOptionalPhoneModal, setShowSmsSentForOptionalPhoneModal] = useState(false);
  const { setEmailSentCount } = useFlowActions();
  const {
    flow: {
      emailRecommendationSent, emailSentCount, optionType,
      showUpdateInfoMessage,
      isLongTermMobileDesign
    },
    recommendationForm: { recommendationid, storedUserContact, countryPhoneDialCode, countryCode },
  } = useAppState();
  const {
    setShowGetRecommendationModal,
    setFlowStep,
    setIsAnotherRecommendationCreated,
    setCurrentModeType,
  } = useFlowActions();
  const { setStoredUserContact } = useRecommendationFormActions();
  const email = storedUserContact?.email;
  const userLanguage = localStorage.getItem('i18nextLng')?.toUpperCase() ?? '';
  const isSmsEnabledCountry = countryCode ? SMS_ENABLED_COUNTRIES.includes(countryCode?.toUpperCase() || '') : true;
  useEffect(() => {
    if (!emailRecommendationSent) {
      navigate(routes.home);
    }
  }, [emailRecommendationSent, navigate]);

  const onClickResendEmailBtn = async () => {
    if (emailSentCount >= MAX_RESEND_ATTEMPTS) {
      return setShowMaximumEmailsResendModal(true);
    }
    try {
      setLoading(true);
      await ProxyLayer.resendMarketingCloudEmail(recommendationid);
      setLoading(false);
      setShowResendEmailConfirmation(true);
      setEmailSentCount({ count: emailSentCount + 1 });
      setRemainingSecondsToResendEmail(EMAIL_RESEND_DELAY);
    } catch (error) {
      navigate(routes.recommendationInProgress);
    }
  };

  const handleClickUpdateEmail = () => {
    track('update email', { 'update email changed': false });
    navigate(routes.confirmationUpdateContact);
  };

  const handleCreateAnotherRecommendationClick = () => {
    sessionStorage.setItem('isCreatingAnotherRecommendation', 'true');
    track('click on create another recommendation', {
      'user click on create another recommendation': true,
    });
    setCurrentModeType({ modeType: ModeTypes.SAVING });
    setShowGetRecommendationModal({ show: false });
    setFlowStep({ step: FlowSteps.STEP2 });
    setIsAnotherRecommendationCreated({ isAnotherRecommendationCreated: true });
    navigate(
      [OptionType.Draw, OptionType.Detect].includes(optionType)
        ? isLongTermMobileDesign ? routes.wizardMobile : routes.wizard
        : optionType === OptionType.empty
          ? routes.home
          : isLongTermMobileDesign ? routes.wizardDropAPinMobile : routes.wizardDropAPin
    );
  };

  const buttonStyles = useMemo(
    () => ({
      width: '180px !important',
    }),
    []
  );

  const resendButtonStyles = useMemo(
    () => ({
      width: '180px !important',
      backgroundColor: '#F3F4F6',
      borderColor: '#F3F4F6',
    }),
    []
  );

  const createRecomButtonStyles = useMemo(
    () => ({
      width: 'fit-content !important',
      padding: '0px 20px !important',
    }),
    []
  );

  const sendSMSToKnownPhoneNumber = useCallback(async () => {
    try {
      setLoading(true);
      track('page user opting for sms', { 'user optin for sms page': 'sms hyperlink' });
      await ProxyLayer.resendMarketingCloudSms(recommendationid, userLanguage);
      setLoading(false);
      if (storedUserContact) {
        setStoredUserContact({ storedUserContact: { ...storedUserContact, smsOptin: true } });
      }
      setShowSmsSentModal(true);
    } catch (error) {
      setLoading(false);
      navigate(routes.recommendationInProgress);
    }
  }, [navigate, recommendationid, setStoredUserContact, storedUserContact, userLanguage]);

  const handleSMSForOptionalPhoneNumber = useCallback(
    async (phoneNumber: string) => {
      try {
        setLoading(true);
        setShowUpdatePhoneForSMSModal(false);
        track('page user opting for sms', { 'user optin for sms page': 'sms hyperlink' });
        await ProxyLayer.resendMarketingCloudSms(recommendationid, userLanguage, phoneNumber);
        setLoading(false);
        if (storedUserContact) {
          setStoredUserContact({
            storedUserContact: { ...storedUserContact, smsOptin: true, phoneNumber },
          });
        }
        setShowSmsSentForOptionalPhoneModal(true);
      } catch (error) {
        setLoading(false);
        navigate(routes.recommendationInProgress);
      }
    },
    [navigate, recommendationid, setStoredUserContact, storedUserContact, userLanguage]
  );

  const getResendDescription = () => {
    return storedUserContact?.smsOptin ? t('Email Resent text') : t('Email sent text');
  };

  const handleSendViaSmsLink = useCallback(async () => {
    if (storedUserContact?.phoneNumber) {
      sendSMSToKnownPhoneNumber();
    } else {
      setShowUpdatePhoneForSMSModal(true);
    }
  }, [sendSMSToKnownPhoneNumber, storedUserContact?.phoneNumber]);

  useEffect(() => {
    const intervalId = setInterval(() => {
      if (remainingSecondsToResendEmail > 0) {
        setRemainingSecondsToResendEmail(remainingSecondsToResendEmail - 1);
      }
    }, 1000);

    return () => {
      clearInterval(intervalId);
    };
  }, [remainingSecondsToResendEmail]);

  const PhoneAsText = ({ phone = '' }) => (
    <PhoneInput
      containerClass='phone-text-only'
      value={phone}
      countryCodeEditable={false}
      masks={MISSING_PHONE_MASKS}
    />
  );

  const confirmationText = useMemo(() => {
    return (
      <>
        <StyledContactMessage>
          {showUpdateInfoMessage
            ? `${t('Your contact details have been updated and recommendation sent to')}:`
            : `${t('Your recommendation has been sent via')}:`}
        </StyledContactMessage>
        <StyledContactMessage>
          <StyledContactText>{`${t('email address')}: `}</StyledContactText>
          <span>{`${email} `}</span>
          {!isMobile && storedUserContact?.smsOptin && (
            <StyledContactText>{' | '}</StyledContactText>
          )}
          {storedUserContact?.smsOptin && (
            <span style={{ display: isMobile ? 'block' : 'inline' }}>
              <StyledContactText>{`${t('SMS')}: `}</StyledContactText>
              <PhoneAsText phone={storedUserContact?.phoneNumber ?? ''} />
            </span>
          )}
        </StyledContactMessage>
        <StyledParagraph>{t('If you have not received any confirmation message')}</StyledParagraph>
        {(!storedUserContact?.phoneNumber || !storedUserContact?.smsOptin) && isSmsEnabledCountry && (
          <StyledParagraph>
            <StyledMessage>
              {`${t('Still can not access your email')}? `}
              <StyledLink onClick={handleSendViaSmsLink}>{t('Send via SMS')}</StyledLink>
            </StyledMessage>
            {storedUserContact?.phoneNumber && (
              <StyledMessage>
                <StyledContactText>{`${t('Phone Number')}: `}</StyledContactText>
                <PhoneAsText phone={storedUserContact?.phoneNumber ?? ''} />
              </StyledMessage>
            )}
          </StyledParagraph>
        )}
      </>
    );
  }, [
    showUpdateInfoMessage,
    t,
    email,
    isMobile,
    storedUserContact?.smsOptin,
    storedUserContact?.phoneNumber,
    handleSendViaSmsLink,
    countryPhoneDialCode,
  ]);

  return (
    <Root>
      <Header />
      <StyledContent showingCountdown={remainingSecondsToResendEmail !== 0}>
        <StyledTitle strong={true} data-testid="page-title">
          {t('Confirmation Sent')}
        </StyledTitle>
        <IllustationImageContainer>
          <img src={IllustrationWelcome} alt="illustration" />
        </IllustationImageContainer>
        <StyledMessageContainer>{confirmationText}</StyledMessageContainer>
        <StyledSecondaryButtonRow justify="center">
          <ButtonGroupWrapper>
            <StyledButtonBase
              style={buttonStyles}
              type={EButtonType.outlined}
              onClick={handleClickUpdateEmail}
              text={t('Update Contact')}
            />
            <StyledButtonBase
              style={resendButtonStyles}
              type={EButtonType.outlined}
              onClick={onClickResendEmailBtn}
              text={t('Resend')}
              buttonProps={{ disabled: remainingSecondsToResendEmail !== 0 }}
            />
          </ButtonGroupWrapper>
          <ButtonGroupWrapper>
            <StyledCreateAnotherRecommendationButton
              style={createRecomButtonStyles}
              fullWidth={isMobile}
              type={EButtonType.primary}
              onClick={handleCreateAnotherRecommendationClick}
              text={t('Create another recommendation')}
            />
          </ButtonGroupWrapper>
          {remainingSecondsToResendEmail !== 0 && (
            <StyledCountdownText>
              {t('Please wait for sending again {{seconds}} sec', {
                seconds: remainingSecondsToResendEmail,
              })}
            </StyledCountdownText>
          )}
        </StyledSecondaryButtonRow>
      </StyledContent>
      {showResendEmailConfirmation && (
        <ConfirmationModalAnimated
          setShowSmsSentModal={setShowResendEmailConfirmation}
          title={t('Confirmation Resent')}
          message={getResendDescription()}
          isMobile={isMobile}
        />
      )}
      {showMaximumEmailsResendModal && (
        <ConfirmationModal
          title={t('Information')}
          body={t('You have reached the maximum number of times to resend the email.')}
          confirmButtonText={t('OK')}
          onClickConfirm={() => setShowMaximumEmailsResendModal(false)}
        />
      )}
      {showSmsSentModal && (
        <ConfirmationModalAnimated
          setShowSmsSentModal={setShowSmsSentModal}
          title={t('Confirmation Resent')}
          message={t('sms sent text')}
          isMobile={isMobile}
        />
      )}
      {showUpdatePhoneForSMSModal && (
        <UpdatePhoneForSMSModal
          setShowSendSMSModal={setShowUpdatePhoneForSMSModal}
          onClickSendRecommendationViaSMSBtn={handleSMSForOptionalPhoneNumber}
          isMobile={isMobile}
          title={t('Send Recommendation Via SMS')}
        />
      )}
      {showSmsSentForOptionalPhoneModal && (
        <ConfirmationModalAnimated
          setShowSmsSentModal={setShowSmsSentForOptionalPhoneModal}
          title={t('Confirmation Sent')}
          message={t('A confirmation message has been sent to the phone number you provided')}
          isMobile={isMobile}
        />
      )}
      {loading && <StyledSpinner />}
    </Root>
  );
}
