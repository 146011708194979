import { useTranslation } from 'react-i18next';
import ButtonCircle from 'components/Buttons/ButtonCircle/ButtonCircle';
import { StyledDetectFieldIcon, StyledIconHub } from './DetectFieldNextBtnControl.styles';
import { TooltipProps } from 'antd';

interface IProps {
  id?: string;
  isVisible?: boolean;
  tooltipProps?: TooltipProps;
  onClick?: () => void;
}

const DetectFieldNextBtnControl = ({
  id = '',
  isVisible = true,
  onClick,
  tooltipProps,
}: IProps): JSX.Element => {
  const [t] = useTranslation();

  return (
    <div id={id} data-testid="DetectFieldNextBtnControl">
      {isVisible && (
        <StyledDetectFieldIcon>
          <ButtonCircle
            id={`${id}Button`}
            onClick={onClick}
            iconCustom={StyledIconHub}
            tooltipProps={{
              title: t('Next'),
              placement: 'left',
              open: true,
              style: {
                zIndex: '1 !important',
              },
              className: 'tooltip-test detectFieldNextBtn',
              ...tooltipProps,
            }}
          />
        </StyledDetectFieldIcon>
      )}
    </div>
  );
};

export default DetectFieldNextBtnControl;
