import styled from 'styled-components';

export const ContentViewMobile = styled.div`
  display: flex;
  flex: 1;
  overflow-y: auto;
  position: sticky;
  height: 91vh;
`;

export const Root = styled.div`
  display: flex;
  flex-direction: column;
  flex: 1;
`;

export const ContentView = styled.div`
  position: relative;
  display: flex;
  flex: 1;
  overflow: hidden;
`;

export const LandscapeWarningMessageContainer = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
  padding: 142px 100px;
  font-size: 24px;
  line-height: 32px;
  weight: 400;
  text-align: center;
`;
