import { Row, Col } from 'syngenta-digital-cropwise-react-ui-kit';
import Cookies from 'js-cookie';
import {
  StyledContainer,
  StyledParagraph,
  StyledTitle,
  StyledMainContainer,
  StyledTextContainer,
  StyledButtonContainer,
  StyledOrText,
  StyledImage,
  StyledInnerButtonContainer,
  Overlay,
  MainText,
} from './WelcomeModal.styles';

import { WelcomeCardButton } from './WelcomeCardButton';

import DrawBoundariesSVG from 'assets/images/draw-boundaries.svg';
import PinDropSVG from 'assets/images/pin-drop.svg';

import WelcomeCropwiseSVG from 'assets/images/welcome-cropwise-desktop.svg';
import WelcomeCropwiseMobileSVG from 'assets/images/welcome-cropwise-mobile.svg';
import WelcomeCropwiseTabletSVG from 'assets/images/welcome-cropwise-tablet.svg';

import { useTranslation } from 'react-i18next';
import { useEffect, useState } from 'react';
import { useBreakpoint } from 'hooks';
import track from 'utils/amplitudeWrapper';

const WelcomeImg = () => {
  const screens = useBreakpoint();
  const isMobile = screens.xs;
  const isTablet = screens.md;

  if (isMobile) {
    return <StyledImage src={WelcomeCropwiseMobileSVG} alt="No img found" />;
  } else if (isTablet) {
    return <StyledImage src={WelcomeCropwiseTabletSVG} alt="No img found" />;
  } else {
    return <StyledImage src={WelcomeCropwiseSVG} alt="No img found" />;
  }
};

export type WelcomeModalProps = {
  open?: boolean;
  onClickDropAPin?: () => void;
  onClickDraw?: () => void;
};

const WelcomeModal = (props: WelcomeModalProps) => {
  const { onClickDropAPin, onClickDraw } = props;
  const [t] = useTranslation();
  const [cookieConsent, setCookieConsent] = useState<string | undefined>();

  useEffect(() => {
    setCookieConsent(Cookies.get('CookieConsent'));

    const timer = setInterval(() => {
      const cookieValue = Cookies.get('CookieConsent');
      if (cookieValue) {
        setCookieConsent(cookieValue);
        clearInterval(timer);
      }
    }, 100);

    return () => {
      clearInterval(timer);
    };
  }, []);

  return (
    <StyledContainer data-testid="welcome-modal">
      <WelcomeImg />
      <StyledMainContainer>
        <StyledTextContainer>
          <Row justify="center">
            <Col xs={16} sm={24}>
              <StyledTitle data-testid="ddg-home-page-title" level={4}>{t('Welcome to Cropwise Seed Selector')}</StyledTitle>
            </Col>
          </Row>
          <MainText>
            <div>
              <StyledParagraph>{t('Home main text')}</StyledParagraph>
            </div>
          </MainText>
        </StyledTextContainer>
      </StyledMainContainer>
      <StyledButtonContainer>
        <StyledInnerButtonContainer>
          <WelcomeCardButton
            title={t('Draw boundaries')}
            id="draw-boundary-button"
            description={t('Draw Boundaries Description')}
            img={DrawBoundariesSVG}
            onClick={onClickDraw}
          />
          <StyledOrText>{t('Or')}</StyledOrText>
          <WelcomeCardButton
            title={t('Drop a pin')}
            id="drop-a-pin-button"
            description={t('Drop a pin Description')}
            img={PinDropSVG}
            onClick={onClickDropAPin}
          />
        </StyledInnerButtonContainer>
      </StyledButtonContainer>
      {process.env.REACT_APP_HIDE_COOKIE_MESSAGE_OVERLAY !== 'true' &&
        Boolean(cookieConsent) === false && <Overlay />}
    </StyledContainer>
  );
};

export default WelcomeModal;
