import React from 'react';
import FieldPreview from 'components/FieldPreview';
import { SeedDensityField } from '../SeedDensityAdvice';
import {
  FieldCeilContainer,
  FieldCeilPreviewContainer,
  FieldCeilTextContainer,
  FieldNameText,
  FieldAreaText,
} from './DensityDemandTable/styles';
import { useTranslation } from 'react-i18next';
import { getTranslatedFieldName } from 'utils/constants/Fields';

const FieldCeil: React.FC<SeedDensityField> = ({ geometry, name, estimatedSize, FieldId }) => {
  const { t } = useTranslation();

  return (
    <FieldCeilContainer>
      <FieldCeilPreviewContainer>
        <FieldPreview
          id={`svg-field-${FieldId}`}
          size={50}
          coords={geometry.coordinates as unknown as [number, number][]}
          viewboxSize={50}
        />
      </FieldCeilPreviewContainer>
      <FieldCeilTextContainer>
        <div>
          <FieldNameText>{getTranslatedFieldName({ defaultName: name, t })}</FieldNameText>
          <br />
          <FieldAreaText>{`${(estimatedSize)?.toFixed(2)} ha`}</FieldAreaText>
        </div>
      </FieldCeilTextContainer>
    </FieldCeilContainer>
  );
};

export default FieldCeil;
