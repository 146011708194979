import { Tooltip } from 'syngenta-digital-cropwise-react-ui-kit';
import { TooltipProps } from 'antd';

import {
  StyledButtonReactangleBottom,
  StyledButtonReactangleLeft,
  StyledButtonReactangleRight,
  StyledButtonReactangleTop,
  StyledButtonIcon,
} from './ButtonRectangle.styles';

import { RoundedDirections } from '../../../base/enums/components/buttons';
import { colors } from 'theme/colors';
import { ButtonBaseProps } from 'components/Buttons/ButtonBase';

interface IProps extends ButtonBaseProps {
  roundedDirection?: RoundedDirections;
  tooltipProps?: TooltipProps;
}

const stylesDirectionMap = {
  [RoundedDirections.bottom]: StyledButtonReactangleBottom,
  [RoundedDirections.top]: StyledButtonReactangleTop,
  [RoundedDirections.left]: StyledButtonReactangleLeft,
  [RoundedDirections.right]: StyledButtonReactangleRight,
};

const ButtonRectangle = ({
  buttonProps,
  tooltipProps,
  text,
  icon,
  iconCustom,
  iconCustomProps,
  roundedDirection = RoundedDirections.bottom,
  onClick,
  style,
}: IProps): JSX.Element => {
  const StyledButton = stylesDirectionMap[roundedDirection];

  return (
    <Tooltip
      color={colors.neutral90}
      placement="left"
      {...tooltipProps}
      getPopupContainer={(trigger) => trigger.parentElement as HTMLElement}
    >
      <StyledButton shape="default" icon={icon} onClick={onClick} style={style} {...buttonProps}>
        {text}
        {iconCustom && <StyledButtonIcon component={iconCustom} {...iconCustomProps} />}
      </StyledButton>
    </Tooltip>
  );
};

export default ButtonRectangle;
