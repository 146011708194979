import { Button, Row, SYT_PREFIX, colors } from 'syngenta-digital-cropwise-react-ui-kit';
import ModalView from 'components/ModalView';
import styled from 'styled-components';
import { showSlidingAnimation } from 'utils/modalBoxAnimations';
import { IStyledModalProps } from 'base/types/ModalBox';

import breakpoints from 'base/constants/breakpoints';

// Modal
const StyledModalView = styled(ModalView)<IStyledModalProps>`
  background-color: ${colors.neutral10};

  ${(props) => showSlidingAnimation(props.slideDirection)};
  .${SYT_PREFIX}-modal-header {
    border-bottom: none;
  }

  .${SYT_PREFIX}-modal-body {
    padding: 26px 25px;
  }
`;

// Containers
const StyledContent = styled('div')`
  @media screen and ${breakpoints.maxWidth.md} {
    text-align: center;
  }
`;

const StyledLeftDiv = styled('div')`
  width: 48px;
  float: left;

  @media screen and ${breakpoints.maxWidth.md} {
    width: 0px;
  }
`;

const StyledRightDiv = styled('div')`
  width: calc(100% - 48px);
  float: right;

  @media screen and ${breakpoints.maxWidth.md} {
    width: 100%;
  }
`;

// Title
const StyledTitle = styled('div')`
  font-weight: 600;
  font-size: 16px;
  line-height: 24px;
  letter-spacing: -0.0075em;
  color: ${colors.neutral100};
  margin-bottom: 10px;
`;

// Text
const StyledParagraph = styled('div')`
  font-weight: 400;
  font-size: 14px;
  line-height: 20px;
  letter-spacing: -0.0075em;
  color: ${colors.neutral100};
`;

// Footer
const StyledButtonRow = styled(Row)`
  text-align: right;
`;

const StyledButton = styled(Button)`
  width: auto !important;

  @media screen and ${breakpoints.maxWidth.md} {
    width: 100% !important;
  }
`;

export {
  StyledButton,
  StyledParagraph,
  StyledButtonRow,
  StyledModalView,
  StyledContent,
  StyledLeftDiv,
  StyledRightDiv,
  StyledTitle,
};
