import styled from 'styled-components';
import {
  Layout,
  Typography,
  colors,
  SYT_PREFIX,
  Divider,
} from 'syngenta-digital-cropwise-react-ui-kit';
import breakpoints from 'base/constants/breakpoints';
import ButtonGroup from 'components/ButtonGroup';

const { Paragraph, Title } = Typography;

const { Sider } = Layout;

const StyledSider = styled(Sider)`
  background-color: ${colors.neutral00} !important;
  height: 100%;
  flex: 1;

  .${SYT_PREFIX}-layout-sider-children {
    display: flex;
    flex-direction: column;

    margin: 0;
  }

  @media only screen and ${breakpoints.maxWidth.sm} {
    z-index: 2000;
    min-width: 100vw !important;
  }
`;

const StyledContainer = styled.div`
  padding: 21px;
  overflow-y: auto;
  height: 100%;
  padding-bottom: 80px;
`;

const StyledRow = styled.div`
  display: flex;
  gap: 16px;
  flex-wrap: wrap;
  justify-content: flex-start;

  @media only screen and ${breakpoints.maxWidth.sm} {
    padding-bottom: 20px;
  }
`;

const StyledCol = styled.div`
  @media screen and ${breakpoints.maxWidth.xs} {
    width: 45%;

    & > div {
      width: 100%;
    }
  }
`;

const StyledTitle = styled(Title)`
  color: ${colors.neutral70} !important;
  font-size: 20px;
  font-weight: 600;
  margin-bottom: 19px;
`;

const StyledParagraph = styled(Paragraph)`
  font-size: 14px;
  color: ${colors.neutral70};

  &.cwsTypography {
    margin-bottom: 26px;
  }
`;

const StyledButtonGroup = styled(ButtonGroup)`
  display: none;
  flex: 1;
  width: auto;
  left: 0;
  right: 0;
  bottom: 0;
  padding: 21px;
  padding-top: 16px;

  @media screen and ${breakpoints.maxWidth.sm} {
    display: flex;
  }

  .ant-btn-default {
    background-color: ${colors.neutral00};
  }

  .ant-btn:disabled {
    background-color: ${colors.neutral20} !important;
    border: 1px solid ${colors.neutral30};
    opacity: 0.7;

    path {
      fill: ${colors.neutral30};
    }
  }
`;

const StyledButtonGroupNew = styled(ButtonGroup)`
  display: flex;
  flex: 1;
  left: 0;
  right: 0;
  padding: 21px;
  padding-top: 16px;
  background: ${colors.neutral00};
  
  .ant-btn-default {
    background-color: ${colors.neutral00};
  }

  .ant-btn:disabled {
    background-color: ${colors.neutral20} !important;
    border: 1px solid ${colors.neutral30};
    opacity: 0.7;

    path {
      fill: ${colors.neutral30};
    }
  }
`;

const StyledDivider = styled(Divider)`
  height: 1px;
  background-color: ${colors.neutral20};
  width: 100%;
  margin-top: 28px;
  margin-bottom: 20px;
`;

const SpinnerContainer = styled.div`
  width: 100%;
  margin-top: 28px;
  padding-top: 28px;
  margin-bottom: 20px;
  display: flex;
  justify-content: center;
`;

export {
  StyledSider,
  StyledRow,
  StyledParagraph,
  StyledCol,
  StyledTitle,
  StyledContainer,
  StyledButtonGroup,
  StyledDivider,
  StyledButtonGroupNew,
  SpinnerContainer,
};
