import { Card, Typography, colors } from 'syngenta-digital-cropwise-react-ui-kit';
import styled from 'styled-components';
import IconEdit from 'components/Icons/IconEdit';
import IconDelete from 'components/Icons/IconDelete';
import IconChevronRight from 'components/Icons/IconChevronRight';

const { Title } = Typography;

interface CardStyledProps {
  disabled?: boolean;
  isMobileScreen?: boolean;
}
interface IconProps {
  disabled?: boolean;
}

const CardStyled = styled(Card)<CardStyledProps>`
  border-radius: 8px;
  width: 100%;
  display: flex;
  padding: 0;
  flex-direction: row;
  background-color: ${colors.neutral10};
  flex: 1;

  .syt-antd-card-body {
    border-radius: 8px;
    width: 100%;
    display: flex;
    flex: 1;
    padding-left: 16px;
    padding-right: 16px;
    padding-top: 12px;
    padding-bottom: 12px;
    flex-direction: row;
  }

  opacity: ${({ disabled }) => (disabled ? '0.6' : '1')};
  &:hover {
    box-shadow: ${({ disabled, isMobileScreen}) => (disabled || isMobileScreen ? 'none' : '0 3px 10px rgb(0 0 0 / 0.2)')};
  }
`;

const TitleStyled = styled(Title)`
  font-weight: 400;
  font-size: 14px;
  line-height: 22px;
  letter-spacing: -0.0075em;
  color: ${colors.neutral100};
  margin: 0;
`;

const SubtitleStyled = styled(Title)`
  font-weight: 400;
  font-size: 12px;
  line-height: 12px;
  letter-spacing: -0.0075em;
  color: ${colors.neutral60};
  margin: 0;
`;

const PreviewTitleContainerStyled = styled.div`
  flex: 1;
  display: flex;
  align-items: center;
`;

const PreviewContainerStyled = styled.div`
  width: 48px;
  height: 48px;
  margin-right: 16px;
  display: flex !important;
  justify-content: center !important;
  align-items: center !important;
  padding: 4px;
  background-color: ${colors.neutral00};
  border-radius: 8px;
`;

const ActionsStyled = styled.div`
  flex: 0.2;
  display: flex;
  gap: 26px;
  flex-direction: row;
  justify-content: space-between;
  align-items: center;
`;

const IconEditStyled = styled(IconEdit)<IconProps>`
  color: ${colors.neutral60};
  height: 18px;
  width: 18px;
  cursor: ${({ disabled }) => (disabled ? 'default' : 'pointer')};
`;

const IconDeleteStyled = styled(IconDelete)<IconProps>`
  height: 18px;
  width: 14px;
  color: ${colors.red50};
  cursor: ${({ disabled }) => (disabled ? 'default' : 'pointer')};
`;

const IconChevronRightStyled = styled(IconChevronRight)<IconProps>`
  color: ${colors.neutral60};
  cursor: ${({ disabled }) => (disabled ? 'default' : 'pointer')};
`;

export {
  CardStyled,
  TitleStyled,
  SubtitleStyled,
  PreviewTitleContainerStyled,
  PreviewContainerStyled,
  ActionsStyled,
  IconEditStyled,
  IconDeleteStyled,
  IconChevronRightStyled,
};
