import styled from 'styled-components';
import IconDropPin from 'components/Icons/IconDropPin';
import IconCheck from 'components/Icons/IconCheck';
import IconUndo from 'components/Icons/IconUndo';
import IconHub from 'components/Icons/IconHub';
import IconHubOpen from 'components/Icons/IconHubOpen';
import { colors } from 'syngenta-digital-cropwise-react-ui-kit';

export const StyledIconDropPin = () => {
  return <IconDropPin width={18} height={18} color={'#FFFFFF'} />;
};

export const StyledIconUndo = () => {
  return <IconUndo width={18} height={18} color={'#EB4B4B'} />;
};

export const StyledIconCheck = () => {
  return <IconCheck width={18} height={18} color={'#73DC78'} />;
};

export const StyledIconHubOpen = () => {
  return <IconHubOpen data-testid="openHubIcon" color={colors.neutral00} />;
};

export const StyledIconHub = () => {
  return <IconHub data-testid="hubIcon" color={colors.neutral60} />;
};

interface StyledDivProps {
  isDropAPinFlow?: boolean;
  id: string;
  displayMenuOption?: boolean;
}

export const StyledDiv = styled('div')<StyledDivProps>`
 margin-bottom: ${({ isDropAPinFlow }) => (isDropAPinFlow ? '-20px !important' : '82px !important')};
 display: ${({ displayMenuOption }) => (!displayMenuOption ? 'none' : '')};
 .syt-antd-btn {
  margin-right: 5px !important;
 };
`;
