import { ProductSeed } from 'base/types/Products';
import { Assignment, AssignmentProduct } from 'base/types/RecommendationCropwise';

const generateSeedDensityAdviceTableData = (
  recommendationAssignments: Assignment[],
  dataSource: any,
  productCatalog: ProductSeed[]
) => {
  const MIN_PRODUCT_REQUIRED = 3;
  function getProductName(dataSourceProduct: any): string {
    return dataSourceProduct?.SeedName ?? dataSourceProduct?.Seed?.SeedName;
  }

  function getProductId(productCatalog: any[], productName: string): any {
    return productCatalog.find((product) => product.productName === productName)?.id;
  }

  function buildProductData(
    assignmentProduct: AssignmentProduct,
    dataSourceProduct: any,
    index: number
  ) {
    const product = productCatalog.find((p) => p.id === assignmentProduct.product_id);
    return {
      ...assignmentProduct,
      ...product,
      rank: index + 1,
      variety: product?.commercialName,
      plants: dataSourceProduct?.plants,
      seeds: assignmentProduct?.seed_rate,
      plantingArea: assignmentProduct?.applied_area,
      numberOfBags: assignmentProduct?.total_quantity_required,
    };
  }
  function getRecommendationAssignmentProducts() {
    return recommendationAssignments?.map((item: Assignment, mainIndex: number) => {
      if (item.field_id === dataSource[mainIndex].FieldId) {
        return item.products.map((assignmentProduct: AssignmentProduct, index: number) => {
          const dataSourceProduct = dataSource[mainIndex]?.seedDemand[index];
          const productName = getProductName(dataSourceProduct);
          const productId = getProductId(productCatalog, productName);

          if (productId === assignmentProduct.product_id) {
            return {
              ...dataSourceProduct,
              seeds: assignmentProduct?.seed_rate,
              plantingArea: assignmentProduct?.applied_area,
              numberOfBags: assignmentProduct?.total_quantity_required,
            };
          } else {
            return buildProductData(assignmentProduct, dataSourceProduct, index);
          }
        });
      }
    });
  }
  function fillMissingProducts(products: any[], index: number): any[] {
    const productAlreadyAvailable = products.map(
      (pro: any) => pro?.productName ?? pro?.SeedName ?? pro.Seed?.SeedName
    );

    const alternateProducts = dataSource[index]?.seedDemand?.filter(
      (seedD: any) => !productAlreadyAvailable.includes(getProductName(seedD))
    );

    const missingProductCount = MIN_PRODUCT_REQUIRED - products.length;

    for (let i = 0; i < missingProductCount; i++) {
      products.push({
        ...alternateProducts[i],
        rank: products.length + 1,
      });
    }

    return products;
  }

  function processRecommendationAssignments(recommendationAssignmentsProduct: any[]): any[] {
    return recommendationAssignmentsProduct?.map((product: any, index: number) => {
      if (product.length < MIN_PRODUCT_REQUIRED) {
        return fillMissingProducts(product, index);
      }
      return product;
    });
  }
  const recommendationAssignmentsProduct = getRecommendationAssignmentProducts();
  const processedProducts = processRecommendationAssignments(recommendationAssignmentsProduct);
  return dataSource.map((field: any, index: number) => ({
    ...field,
    seedDemand: processedProducts[index],
    expanded: index === 0,
  }));
};

export default generateSeedDensityAdviceTableData;
