import { SVGProps } from 'react';

import { colors } from '../../theme/colors';

const IconLastPage = ({
  height = '10px',
  width = '10px',
  color = colors.neutral70,
  ...props
}: SVGProps<SVGSVGElement>) => (
  <svg
    width={width}
    height={height}
    viewBox="0 0 10 10"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
    {...props}
  >
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M3.78918 5L0.34668 1.5575L1.40418 0.5L5.90418 5L1.40418 9.5L0.34668 8.4425L3.78918 5ZM9.65418 0.5H8.15418V9.5H9.65418V0.5Z"
      fill={color}
    />
  </svg>
);

export default IconLastPage;
