import styled from 'styled-components';
import breakpoints from 'base/constants/breakpoints';
import { Col } from 'syngenta-digital-cropwise-react-ui-kit';

export const ChartContainer = styled.div`
  margin: auto;
  overflow: hidden;
  height: 650px;
  margin-top: 20px;
  margin-bottom: 20px;
  padding-top: 12px;

  svg {
    margin-top: -80px;
  }

  @media only screen and ${breakpoints.maxWidth.sm} {
    height: 100%;

    svg {
      margin-top: 0px;
      margin-left: -16px;
    }
  }

  @media screen and ${breakpoints.minWidth.md} {
    height: 100%;
  }
`;

export const ChartContainerPdf = styled.div`
  margin: auto;
  overflow: hidden;
  height: 650px;
  margin-top: 20px;
  margin-bottom: 20px;

  svg {
    margin-top: -80px;
  }
`;

export const ProductList = styled.div`
  display: flex;
  gap: 24px;
  justify-content: center;
  align-items: center;

  & > div {
    display: flex;
    gap: 8px;
    justify-content: center;
    align-items: center;
    font-size: 16px;
  }
`;

export const StyledProduct = styled.div`
  @media only screen and ${breakpoints.maxWidth.sm} {
    font-family: 'Noto Sans';
    font-style: normal;
    font-weight: 400 !important;
    font-size: 12px !important;
    line-height: 12px !important;
  }
`;

export const StyledCaptionsContainer = styled.div`
  width: 100%;
`;

export const StyledCol = styled(Col)`
  display: flex !important;
  margin-bottom: 12px;
`;

export const StyledAcronym = styled.div`
  width: 25%;
  font-family: 'Noto Sans';
  font-style: normal;
  font-weight: 600;
  font-size: 14px;
  line-height: 22px;
`;

export const StyledCaption = styled.div`
  width: 80%;
  font-family: 'Noto Sans';
  font-style: normal;
  font-weight: 400;
  font-size: 14px;
  line-height: 22px;
`;

interface IproductDotColoreProps {
  color: string;
}

export const ProductDotColor = styled.div<IproductDotColoreProps>`
  display: inline-block;
  border-radius: 50%;
  width: 24px;
  height: 24px;
  background: ${(backgroundColor) => backgroundColor.color};

  @media only screen and ${breakpoints.maxWidth.sm} {
    width: 10px;
    height: 10px;
  }
`;
