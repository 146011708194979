/* eslint-disable indent */
/* eslint-disable no-confusing-arrow */
import { TooltipProps } from 'antd';
import styled from 'styled-components';
import { Radio, Tooltip, Typography, colors } from 'syngenta-digital-cropwise-react-ui-kit';

export interface ButtonProps {
  backgroundColor?: string;
  borderRadius?: string;
}

export const StyledButton = styled.button<ButtonProps>`
  border: none;
  width: 40px;
  height: 40px;
  margin-top: 20px;
  border-radius: ${({ borderRadius }) => borderRadius ?? '8px'};
  background-color: ${({ backgroundColor }) => backgroundColor ?? colors.neutral90};
  cursor: pointer;
  &:hover {
    background-color: ${({ backgroundColor }) => backgroundColor ?? colors.neutral80};
  }
`;

type TooltipCustomProps = TooltipProps & { fixedTooltipLabel?: boolean };

export const StyledTooltip = styled(Tooltip)<TooltipCustomProps>`
  & + div {
    left: ${(props) => (props.fixedTooltipLabel ? 'unset !important;' : '')};
  }
`;

export const TooltipContainer = styled.div`
  width: 206px;
  display: flex;
  flex-direction: column;
  margin: 0px 40px 0px 0px;
  border-radius: 8px;
  margin: 5px;
  cursor: pointer;
`;

interface TextProps {
  center?: boolean;
}

export const TooltipTitle = styled(Typography.Text)<TextProps>`
  font-family: Noto Sans;
  font-size: 14px;
  line-height: 24px;
  font-weight: 400;
  letter-spacing: -0.075em;
  color: ${colors.neutral40};
  margin: 0 3px 0 2px;
`;

export const TooltipRow = styled.div`
  display: flex;
  align-items: center;
  margin: 8px 0px;
`;

export const TooltipIcon = styled.div`
  width: 24px;
  height: 24px;
  left: 12px;
`;

export const TooltipText = styled.span`
  font-family: Noto Sans;
  font-size: 14px;
  line-height: 20px;
  font-weight: 400;
  letter-spacing: -0.075em;
  color: #ffffff;
  flex: 1;
  margin-left: 8px;
`;

export const TooltipRadio = styled(Radio)`
  margin-left: 15px;

  .syt-antd-radio-inner::after {
    top: 50%;
    left: 50%;
  }
`;
