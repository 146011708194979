import { SVGProps } from 'react';
import { colors } from 'syngenta-digital-cropwise-react-ui-kit';

const IconUpArrow = ({
  height = '12px',
  width = '12px',
  color = colors.neutral60,
  ...props
}: SVGProps<SVGSVGElement>) => (
  <svg
    width={width}
    height={height}
    viewBox="13.437 13.518 12.946 13.156"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
    {...props}
  >
    <path
      d="M14 20L15.0575 21.0575L19.25 16.8725V26H20.75V16.8725L24.935 21.065L26 20L20 14L14 20Z"
      fill={color}
    />
  </svg>
);

export default IconUpArrow;
