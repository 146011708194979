import { ReactNode } from 'react';
import {
  NotificationFooterContainer,
  NotificationFooterBar,
  NotificationFooterOverlay,
  NotificationContent,
  NotificationFooterWrapper,
} from './NotificationFooter.styles';

interface NotificationFooterProps {
  [key: string]: unknown;
  children: ReactNode;
  notificationColor?: string;
  onClickOverlay?: () => void;
  wrapperId?: string;
}

const NotificationFooter: React.FC<NotificationFooterProps> = ({
  notificationColor,
  children,
  onClickOverlay,
  wrapperId = '',
  ...others
}) => {
  return (
    <NotificationFooterWrapper id={wrapperId}>
      <NotificationFooterOverlay data-testid="overlay" onClick={onClickOverlay} />
      <NotificationFooterContainer {...others}>
        <NotificationFooterBar notificationColor={notificationColor} className="header-bar" />
        <NotificationContent className="modal-content">{children}</NotificationContent>
      </NotificationFooterContainer>
    </NotificationFooterWrapper>
  );
};

export default NotificationFooter;
