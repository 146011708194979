import { useMemo, useEffect } from 'react';
import { SliderBaseProps, SliderSingleProps } from 'antd/lib/slider';
import { useTranslation } from 'react-i18next';
import { useAppState } from '../../context/AppState';

import disableSwipeBack from '../../utils/mobileDisableBackSwipe';

import {
  SliderNoDotsStyled,
  TitleStyled,
  SliderContainerStyled,
} from './AgronomicWeightingsSlider.styles';

type AgronomicSliderProps = SliderBaseProps &
  SliderSingleProps & {
    active: boolean;
    attributeExtrema: {
      id: number;
      max: number;
      min: number;
      default: number | undefined;
    };
    attributeName: string;
    attributeOriginal?: string;
    attributeId: string;
    isMobile: boolean;
  };

const AgronomicSlider = (props: AgronomicSliderProps) => {
  const { attributeName, attributeOriginal, attributeId, isMobile, ...rest } = props;
  const { t } = useTranslation();
  const { flow: { isLongTermMobileDesign } } = useAppState();
  const marks = useMemo(
    () => ({
      0: t('Low importance'),
      1: '',
      2: '',
      3: '',
      4: '',
      5: '',
      6: '',
      7: '',
      8: '',
      9: '',
      10: t('High importance'),
    }),
    [t]
  );
  useEffect(() => {
    if (isMobile) {
      const slider = document.getElementById(`agroSlider-${attributeId}`);
      slider?.addEventListener('touchstart', (e) => disableSwipeBack(e), { passive: false });
      return () => {
        slider?.removeEventListener('touchstart', disableSwipeBack);
      };
    }
  }, []);

  return (
    <SliderContainerStyled data-testid="slider-container">
      <TitleStyled>{attributeOriginal !== '' ? attributeOriginal : attributeName}</TitleStyled>
      <SliderNoDotsStyled
        marks={marks}
        min={0}
        max={10}
        {...rest}
        isLongTermMobileDesign={isLongTermMobileDesign}
      />
    </SliderContainerStyled>
  );
};

export default AgronomicSlider;

export type { AgronomicSliderProps };
