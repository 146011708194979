export const getQueryParams = (queryString) => {
  const queryParams = new URLSearchParams(queryString);
  const params = {};
  for (const [key, value] of queryParams.entries()) {
    params[key] = value;
  }
  return params;
};

export const addClassByClassName = (className, newClass) => {
  const elements = document.getElementsByClassName(className);
  for (let element of elements) {
    element.classList.add(newClass);
  }
};

export const removeClassByClassName = (className, classToRemove) => {
  const elements = document.getElementsByClassName(className);
  for (let element of elements) {
    element.classList.remove(classToRemove);
  }
};
