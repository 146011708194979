/* eslint-disable indent */
import styled from 'styled-components';
import {
  SYT_PREFIX,
  Layout,
  Typography,
  colors,
  Select,
  Form,
  SliderRange,
  Skeleton,
  Collapse,
  Radio,
  Divider,
} from 'syngenta-digital-cropwise-react-ui-kit';
import { Checkbox } from 'antd';
import IconChevronRight from 'components/Icons/IconChevronRight';
import ButtonGroup from 'components/ButtonGroup';
import InfoBubble from 'components/InfoBubble';
import breakpoints from 'base/constants/breakpoints';
import { SliderRangeProps } from 'antd/lib/slider';

type LongTermMobileDesignProps = {
  isLongTermMobileDesign?: boolean;
};

type StyledSliderType = SliderRangeProps &
  LongTermMobileDesignProps & {
    onAfterChange?: (sliderValue: [number, number]) => void;
    enableGlowHighlight: boolean;
  };

const { Sider } = Layout;
const { Title, Text } = Typography;

const StyledSider = styled(Sider)`
  display: flex;
  background-color: ${colors.neutral00};
  flex: 1;
  height: 100%;

  .${SYT_PREFIX}-select-selector, .${SYT_PREFIX}-picker {
    background-color: ${colors.neutral10} !important;
  }

  .${SYT_PREFIX}-layout-sider-children {
    display: flex;
    flex-direction: column;
    padding: 24px;
    overflow: scroll;
    padding-bottom: 56px;
  }
`;

const StyledSliderRange = styled(SliderRange)<StyledSliderType>`
  &.${SYT_PREFIX}-slider {
    padding-bottom: 0px !important;
    margin-bottom: 0px !important;
  }

  @media screen and ${breakpoints.maxWidth.md} {
    .${SYT_PREFIX}-slider-handle {
      ${(props) => props.isLongTermMobileDesign && `transform: translateX(0) !important;`}
      &::after {
        inset: -15px; // 40px of space to be pressable in mobile
      }
    }
  }
`;

// .${SYT_PREFIX}-slider-track-1 {
//   box-shadow: ${({ enableGlowHighlight }) =>
//     (enableGlowHighlight ? `0 0 10px ${colors.blue60}}` : '')};
// }

const SpanSliderRange = styled.span`
  font-style: normal;
  font-weight: 400;
  font-size: 12px;
  line-height: 20px;
`;

const ContainerSpanSliderRange = styled.div`
  display: flex;
  flex-direction: row;
  width: 100%;
  justify-content: space-between;
`;

const StyledTitle = styled(Title)`
  color: ${colors.neutral80};
  margin-bottom: 8px;
  font-size: 20px;
  font-weight: 600;
`;

const StyledParagraph = styled.p`
  color: ${colors.neutral70};
  margin-bottom: 22px;
  font-size: 14px;
  @media screen and ${breakpoints.maxWidth.sm} {
    margin-bottom: 34px;
  }
`;

const InfoParagraph = styled.p`
  color: ${colors.blue60};
  margin-bottom: 16px;
  font-size: 12px;
`;

const StyledLink = styled.div`
  display: flex;
  cursor: pointer;
  font-size: 14px;
  font-weight: 400;
  color: ${colors.blue60};
  text-align: inherit;
  justify-content: space-between;
  align-items: center;
  svg {
    margin-left: 40px;
  }
`;

const StyledText = styled(Text)`
  color: ${colors.neutral100};
  margin-bottom: 2px;
  display: block;
  font-size: 14px;

  & button {
    margin-left: 16px;
  }

  .ant-typography-secondary {
    color: ${colors.neutral50};
  }
`;

const StyledFormItem = styled(Form.Item)`
  margin-bottom: 24px;
  .agronomic-input-label-row > span {
    width: 93%;
  }
`;

const StyledFormRadioItem = styled(Form.Item)`
  margin-bottom: 16px;
`;

const StyledSelect = styled(Select)`
  font-size: 14px;
  .${SYT_PREFIX}-select-selection-placeholder {
    color: ${colors.neutral60};
    font-size: 14px;
  }

  .${SYT_PREFIX}-select-arrow {
    color: ${colors.neutral60};
  }

  .${SYT_PREFIX}-select-selection-item {
    color: ${colors.neutral100};
  }
`;

const StyledMultiSelect = styled(Select)`
  .syt-antd-select-selection-placeholder {
    color: #696f88;
    font-size: 14px;
  }
  .syt-antd-select-selector {
    border-color: #c2c7d0 !important;
  }
  .syt-antd-select-item-option-selected {
    background-color: #e6f8ff;
    .syt-antd-select-item-option-state {
      color: #0071cd;
    }
  }
`;

const StyledButtonGroup = styled(ButtonGroup)`
  display: none;
  flex: 1;
  left: 0;
  right: 0;
  padding: 21px;
  padding-top: 16px;
  background: ${colors.neutral00};

  @media screen and ${breakpoints.maxWidth.sm} {
    display: flex;
  }

  .ant-btn-default {
    background-color: ${colors.neutral00};
  }
`;

const StyledInfoBubble = styled(InfoBubble)`
  & + div .${SYT_PREFIX}-tooltip-inner {
    font-size: 14px;
    line-height: 20px;
    letter-spacing: -0.0075em;
  }
`;

const StyledLightSkeleton = styled(Skeleton)`
  .${SYT_PREFIX}-skeleton-content {
    & h3 {
      width: 100% !important;
    }
    & ul {
      margin-top: 7px !important;
      margin-bottom: 24px !important;
    }
  }
`;

const StyledSmallSkeleton = styled(Skeleton)`
  .${SYT_PREFIX}-skeleton-content {
    display: flex;
    justify-content: end;
  }
  & ul {
    margin-bottom: 24px !important;
  }
`;

const StyledBigSkeleton = styled(Skeleton)`
  .${SYT_PREFIX}-skeleton-content {
    display: flex;
    justify-content: center;
  }
  & h3 {
    width: 100% !important;
    height: 32px !important;
  }
  & ul {
    margin-bottom: 24px !important;
  }
`;

interface IIconProps {
  disabled?: boolean;
  isOpened?: boolean;
}

const StyledIconChevronRight = styled(IconChevronRight)<IIconProps>`
  color: ${colors.neutral60};
  cursor: ${({ disabled }) => (disabled ? 'default' : 'pointer')};
  transform: ${({ isOpened }) => (isOpened ? 'rotate(-90deg)' : 'rotate(90deg)')};
`;

interface StyledPanelProps extends LongTermMobileDesignProps {
  expandIcon?: any;
}

const StyledCollapse = styled(Collapse)<StyledPanelProps>`
  border: none;
  padding-left: 0;
  padding-right: 0;

  ${(props) => props.isLongTermMobileDesign && `width: 100%;`}

  .syt-antd-collapse-item,
  .syt-antd-collapse-content,
  .syt-antd-collapse-content {
    border: none;
    padding-left: 0;
  }

  .${SYT_PREFIX}-collapse-header {
    padding-left: 0 !important;
    padding-right: 0 !important;
  }

  .${SYT_PREFIX}-collapse-header-text {
    line-height: 20px;
    letter-spacing: -0.0075em;
    border: none;
    color: ${colors.neutral80};
    margin-bottom: 8px;
    font-size: 20px;
    font-weight: 600;
  }
  .syt-antd-collapse-arrow {
    top: 47% !important;
  }
`;

const { Panel } = Collapse;

const StyledPanel = styled(Panel)`
  border: none;
  background-color: ${colors.neutral00};
  padding: 0;

  .${SYT_PREFIX}-collapse-content-box {
    padding: 0;
  }
`;

const StyledRadioGroup = styled(Radio.Group)`
  margin-top: 8px;
  .syt-antd-radio-wrapper {
    font-size: 14px;
    .syt-antd-radio-inner {
      &::after {
        left: 7px;
        top: 7px;
      }
    }
    .syt-antd-radio + * {
      padding-right: 0px;
    }
    .radioOptionLabel {
      display: block;
      line-height: 20px;
    }
  }
`;

const StyledRadio = styled(Radio)`
  font-size: 14px;
`;

const StyledRadioGroupForIrrigation = styled(StyledRadioGroup)`
  display: block;
`;

const StyledCheckboxGroup = styled(Checkbox.Group)`
  margin-top: 10px;
  .ant-checkbox-input {
    width: 16px;
    height: 16px;
  }
  .ant-checkbox-group-item {
    display: inline-flex;
    font-size: 14px;
    padding-right: 27px;
  }
  .ant-checkbox {
    margin-right: 10px;
    display: flex;
    align-items: center;
  }
`;

const StyledDivider = styled(Divider)`
  height: 1px;
  background-color: ${colors.neutral20};
  width: 100%;
  margin-top: 4px;
  margin-bottom: 5px;
`;

const ColoredText = styled(Text)`
  color: #868ca2;
`;

export {
  StyledSider,
  StyledTitle,
  StyledParagraph,
  InfoParagraph,
  StyledLink,
  StyledText,
  StyledSelect,
  StyledFormItem,
  StyledButtonGroup,
  StyledSliderRange,
  SpanSliderRange,
  ContainerSpanSliderRange,
  StyledInfoBubble,
  StyledLightSkeleton,
  StyledSmallSkeleton,
  StyledBigSkeleton,
  StyledIconChevronRight,
  StyledCollapse,
  StyledPanel,
  StyledRadioGroup,
  StyledRadio,
  StyledCheckboxGroup,
  StyledMultiSelect,
  StyledDivider,
  ColoredText,
  StyledRadioGroupForIrrigation,
  StyledFormRadioItem
};
