import styled from 'styled-components';
import { Typography, colors } from 'syngenta-digital-cropwise-react-ui-kit';

const { Text } = Typography;

const TextStyled = styled(Text)`
  font-style: normal;
  font-weight: 400;
  font-size: 14px;
  line-height: 16px;
  /* identical to box height, or 114% */

  display: flex;
  align-items: center;
  letter-spacing: -0.0075em;

  /* Neutral / neutral-100 */

  color: ${colors.neutral100};
  margin-bottom: 5px;
`;

const ContainerStyled = styled.div`
  margin-bottom: 20px;
`;

const RequiredTextStyled = styled(Text)`
  color: ${colors.red60}
`;

const TextErrorStyled = styled(Text)({
  display: 'block',
  color: 'red',
});

export {
  TextStyled,
  ContainerStyled,
  RequiredTextStyled,
  TextErrorStyled,
};
