import { Typography, SYT_PREFIX } from 'syngenta-digital-cropwise-react-ui-kit';
import styled from 'styled-components';

import breakpoints from 'base/constants/breakpoints';
import { colors } from 'theme/colors';

const { Title, Paragraph } = Typography;

export const StyledContainer = styled('div')`
  position: absolute;
  margin: 0;
  top: 56px;
  height: calc(100% - 56px);
  background-color: ${colors.neutral10};
  z-index: 10;
  width: 100vw;
  overflow: auto;

  .windows-card {
    border: transparent;
    box-shadow: 0 5px 12px 4px rgb(0 0 0 / 9%);
  }

  // Mobile
  @media only screen and ${breakpoints.maxWidth.sm} {
    padding-bottom: 60px;
    text-align: unset;
    height: 100%;
  }
`;

// Image
export const StyledImage = styled.img`
  width: 100%;

  // Mobile
  @media screen and ${breakpoints.maxWidth.sm} {
    border-radius: 0px;
    height: 26dvh;
  }
`;

// Typography

export const StyledParagraph = styled(Paragraph)`
  font-style: normal;
  font-weight: 400;
  font-size: 16px;
  line-height: 24px;

  display: flex;
  align-items: center;
  text-align: center;
  letter-spacing: -0.0075em;

  /* Neutral / neutral-100 */
  color: #14151c;

  /* Inside auto layout */
  flex: none;
  order: 1;
  flex-grow: 0;

  &.${SYT_PREFIX}-typography {
    max-width: 62.5%;
    margin: 12px auto 24px;
    @media only screen and ${breakpoints.maxWidth.sm} {
      max-width: fit-content;
    }
  }
`;

export const StyledTitle = styled(Title)`
  color: ${colors.green60};
  font-size: 20px;
  font-style: normal;
  font-weight: 600;
  line-height: 28px;

  /* Large/ Body Med - 20px | 28px */

  /* identical to box height, or 140% */

  display: flex;
  align-items: center;
  text-align: center;
  letter-spacing: -0.0075em;

  /* Inside auto layout */
  flex: none;
  order: 1;
  flex-grow: 0;
`;

export const StyledOrText = styled(Paragraph)`
  &.${SYT_PREFIX}-typography {
    color: #696f88;
    margin-bottom: 0px;
    @media screen and ${breakpoints.maxWidth.xl} {
      margin-right: 56px;
    }
  }

  width: 19px;
  margin: 0px 50px 0px 50px;

  font-style: normal;
  font-weight: 400;
  font-size: 16px;
  line-height: 24px;

  display: flex;
  align-items: center;
  text-align: center;
  letter-spacing: -0.0075em;

  @media screen and ${'(min-width: 0px)'} {
    padding-top: 8px;
    padding-bottom: 8px;
  }

  @media screen and ${breakpoints.minWidth.lg} {
    padding-top: 24px;
    padding-bottom: 24px;
  }
`;

// Containers

export const StyledMainContainer = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  margin-top: 70px;

  @media screen and ${breakpoints.maxWidth.md} {
    margin-top: 32px;
  }
`;

export const StyledTextContainer = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
`;

export const MainText = styled.div`
  display: flex;
  justify-content: center;
  @media only screen and ${breakpoints.maxWidth.sm} {
    display: block;
    padding: 0 18px;
  }
`;
export const StyledButtonContainer = styled.div`
  padding-top: 15px;
  padding-bottom: 15px;
  padding-right: 271px;
  padding-left: 273px;

  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: center;

  // Responsive
  @media screen and ${breakpoints.maxWidth.xl} {
    flex-direction: column;
    padding-right: 0px;
    padding-left: 0px;
  }
`;

export const StyledInnerButtonContainer = styled.div`
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: center;
  margin: auto;
  max-width: 1366px;

  @media screen and ${breakpoints.maxWidth.xl} {
    flex-direction: column;
  }
`;

export const Overlay = styled.div`
  position: absolute;
  top: 0;
  left: 0;
  bottom: 0;
  width: 100%;
`;
