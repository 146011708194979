export const SUPPORTED_LANGS = [
  { value: 'en', name: 'English', shortLabel: 'EN' },
  { value: 'hu', name: 'Magyar', shortLabel: 'HU' },
  { value: 'pl', name: 'Polski', shortLabel: 'PL' },
  { value: 'ro', name: 'Română', shortLabel: 'RO' },
  { value: 'de', name: 'Deutsch', shortLabel: 'DE' },
  { value: 'uk', name: 'українська', shortLabel: 'UA' },
  { value: 'bg', name: 'български', shortLabel: 'BG' },
  { value: 'it', name: 'Italiano', shortLabel: 'IT' },
];

export const NONDDG_SUPPORTED_LANGS = [
  { value: 'en', name: 'English', shortLabel: 'EN' },
  { value: 'hu', name: 'Magyar', shortLabel: 'HU' },
  { value: 'pl', name: 'Polski', shortLabel: 'PL' },
  { value: 'ro', name: 'Română', shortLabel: 'RO' },
  { value: 'de', name: 'Deutsch', shortLabel: 'DE' },
  { value: 'uk', name: 'українська', shortLabel: 'UA' },
  { value: 'bg', name: 'български', shortLabel: 'BG' },
  { value: 'it', name: 'Italiano', shortLabel: 'IT' },
  { value: 'cs', name: 'čeština', shortLabel: 'CS' },
  { value: 'fr', name: 'Français', shortLabel: 'FR' },
  { value: 'pt', name: 'Português', shortLabel: 'PT' },
  { value: 'ru', name: 'Русский', shortLabel: 'RU' },
  { value: 'sr', name: 'српски', shortLabel: 'SR' },
  { value: 'sk', name: 'slovenský', shortLabel: 'SK' },
  { value: 'es', name: 'Español', shortLabel: 'ES' },
  { value: 'tr-TR', name: 'Türkçe', shortLabel: 'Tr-TR' },
  { value: 'en-GB', name: 'English (UK)', shortLabel: 'En-GB' },
];
