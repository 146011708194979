import styled from 'styled-components';
import { colors } from 'syngenta-digital-cropwise-react-ui-kit';

export const StyledContainer = styled.div`
  display: flex;
  justify-content: space-between;
  position: absolute;
  bottom: 0;
  gap: 16px;
  width: 100%;

  .ant-btn {
    flex: 1;

    &:first-child {
      border: 1px solid ${colors.neutral30};
    }
    &:nth-child(2) {
      background-color: ${colors.green60} !important;
      color: ${colors.neutral10};
    }
    &:disabled {
      color: ${colors.neutral30};
      background-color: ${colors.neutral10} !important;
      border: 1px solid ${colors.neutral30};

      path {
        fill: ${colors.neutral30};
      }
    }
  }
`;
