/* eslint-disable indent */
import styled, { ThemedStyledProps } from 'styled-components';
import { Typography, Card, colors } from 'syngenta-digital-cropwise-react-ui-kit';
import { CardProps } from 'antd/lib/card';
import { RefAttributes } from 'react';

const { Text } = Typography;

const StyledCard = styled(Card)<
  ThemedStyledProps<
    CardProps & RefAttributes<HTMLDivElement> & { is_selected: string; disabled?: boolean },
    any
  >
>`
  border-radius: 8px;
  width: 156px;
  height: 120px;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  cursor: pointer;
  border: ${({ is_selected }) => (is_selected === 'true' ? `2px solid ${colors.blue60}` : 'none')};
  background-color: ${colors.neutral10};
  pointer-events: ${({ disabled }) => (disabled ? 'none' : 'auto')};

  &:hover {
    border: ${({ is_selected }) =>
      (is_selected === 'true' ? `2px solid ${colors.blue60}` : 'none')};
  }

  svg,
  span {
    opacity: ${({ disabled }) => (!disabled ? 1 : 0.3)};
  }
`;

const StyledText = styled(Text)`
  font-size: 16px;
  color: ${({ disabled }) => (disabled ? colors.neutral60 : colors.neutral80)};
  display: block;
  text-align: center;
  text-transform: capitalize;

  strong {
    font-size: 16px;
  }
`;

const StyledIconContainer = styled.div`
  display: flex;
  justify-content: center;
  padding-bottom: 9px;
`;

const Tag = styled.div`
  text-transform: uppercase;
  font-weight: 600;
  font-size: 12px;
  text-align: center;
  padding: 0px 4px;
  color: ${colors.neutral00};
  background-color: ${colors.neutral60};
  border-radius: 4px;
  margin-top: 10px;
`;

export { StyledCard, StyledText, StyledIconContainer, Tag };
