import styled from 'styled-components';
import { Typography, colors } from 'syngenta-digital-cropwise-react-ui-kit';
import breakpoints from 'base/constants/breakpoints';

interface IProductsContainerProps {
  center?: boolean;
}

interface IDisclaimerTextProps {
  center?: boolean;
}

const ProductsContainer = styled.div<IProductsContainerProps>`
  display: flex;
  flex-direction: column;
  align-items: stretch;

  @media screen and ${breakpoints.maxWidth.sm} {
    padding-left: 16px;
  }
`;

const DisclaimerText = styled(Typography.Text)<IDisclaimerTextProps>`
  font-family: 'Noto Sans';
  font-style: italic;
  font-weight: 600;
  font-size: 16px;
  line-height: 24px;
  letter-spacing: -0.0075em;
  margin: 0;

  color: ${colors.neutral80};
  text-align: ${({ center }) => (center ? 'center' : 'none')};
`;

const DisclaimerContentText = styled(Typography.Text)`
  font-family: 'Noto Sans';
  font-style: italic;
  font-weight: 600;
  font-size: 16px;
  line-height: 24px;
  letter-spacing: -0.0075em;
  margin: 0;

  color: ${colors.neutral80};
`;

const CarouselContainer = styled.div`
  margin-top: 48px;
  margin-bottom: 32px !important;
`;

const DisclaimerCapitalText = styled.span`
  text-transform: uppercase;
`;

export {
  ProductsContainer,
  DisclaimerText,
  DisclaimerContentText,
  CarouselContainer,
  DisclaimerCapitalText,
};
