export function roundedRectangle(
  x: number,
  y: number,
  w: number,
  h: number,
  r: number,
  tl: boolean,
  tr: boolean,
  bl: boolean,
  br: boolean
) {
  let retval;
  retval = `M${x + r},${y}`;
  retval += `h${w - 2 * r}`;
  if (tr) {
    if (h < 0) {
      retval += `a${r},${r} 1 0 0 ${r},${-r}`;
    } else {
      retval += `a${r},${r} 0 0 1 ${r},${r}`;
    }
  } else {
    retval += `h${r}`;
    retval += `v${r}`;
  }
  retval += `v${h - 2 * r}`;
  if (br) {
    if (h < 0) {
      retval += `a${r},${r} 1 0 0 ${-r},${-r}`;
    } else {
      retval += `a${r},${r} 0 0 1 ${-r},${r}`;
    }
  } else {
    retval += `v${r}`;
    retval += `h${-r}`;
  }
  retval += `h${2 * r - w}`;
  if (bl) {
    if (h < 0) {
      retval += `a${r},${r} 1 0 0 ${-r},${r}`;
    } else {
      retval += `a${r},${r} 0 0 1 ${-r},${-r}`;
    }
  } else {
    retval += `h${-r}`;
    retval += `v${-r}`;
  }
  retval += `v${ 2 * r - h}`;
  if (tl) {
    if (h < 0) {
      retval += `a${r},${r} 1 0 0 ${r},${r}`;
    } else {
      retval += `a${r},${r} 0 0 1 ${r},${-r}`;
    }
  } else {
    retval += `v${-r}`;
    retval += `h${r}`;
  }
  retval += 'z';
  return retval;
}
