export const objectToQueryParams = (obj: any): string => {
  const searchParams = new URLSearchParams();

  for (const key in obj) {
    if (obj[key]) {
      searchParams.append(key, obj[key]);
    }
  }

  return searchParams.toString();
};

export const removeQueryParameter = (name: string) : void => {
  const urlSearchParams = new URLSearchParams(window.location.search);
  if (!new URLSearchParams(window.location.search).get(name)) {
    return;
  }
  urlSearchParams.delete(name);
  const newUrlSearchParams = urlSearchParams.toString() ? `?${urlSearchParams.toString()}` : '';
  const newUrl = `${window.location.origin}${window.location.pathname}${newUrlSearchParams}`;
  window.history.replaceState({}, '', newUrl);
};

export const isQueryParameterPresent = (name: string): boolean => {
  const isPresent = new URL(window.location.href).searchParams.has(name);
  return isPresent;
};
