import styled from 'styled-components';
import CornCropBG from 'assets/images/cornBackgroundImage.png';
import SunflowerCropBG from 'assets/images/Sunflower.png';
import BarleyCropBG from 'assets/images/Barley_BG.png';
import WheatCropBG from 'assets/images/Wheat_BG.png';
import SoyabeanCropBG from 'assets/images/Soyabean_BG.png';

interface RecommendationInfoWrapperProps {
  cropName?: string;
}

const getCropImage = (cropName: string) => {
  switch (cropName) {
    case 'corn':
    case 'silage corn':
    case 'summer corn':
      return CornCropBG;
    case 'sunflower':
      return SunflowerCropBG;
    case 'barley':
      return BarleyCropBG;
    case 'wheat':
      return WheatCropBG;
    case 'soybeans':
      return SoyabeanCropBG;
    default:
      return CornCropBG;
  }
};

export const RecommendationInfoWrapper = styled.div<
  { cropName: string } & RecommendationInfoWrapperProps
>`
  background-repeat: no-repeat;
  position: relative;
  background-size: cover;
  background-image: linear-gradient(
      164deg,
      rgba(6, 67, 28, 0.85) 22.54%,
      rgba(11, 10, 44, 0.73) 114.76%
    ),
    url(${({ cropName }) => getCropImage(cropName)});
  width: 100%;
  text-align: center;
  color: #fff;

  .white-dot {
    width: 6px;
    height: 6px;
    background-color: white;
    border-radius: 50%;
    margin: 0px 8px;
  }
  .icon-size {
    width: 16px;
    height: 16px;
  }
  .field-image {
    margin-right: 6px;
  }
  .separator-icon {
    width: 1px;
    height: 16px;
    margin: 0 16px;
  }
  .user-details-mobile {
    display: flex;
    align-items: flex-start;
    margin-top: 10px;
  }
  .user-details-desktop {
    display: flex;
  }
  .user-details-text {
    display: inline-block;
    word-wrap: break-word;
    max-width: 148px;
    margin-top: -5px;
    text-align: left;
  }
  .contact-details-mobile {
    display: flex;
    align-items: flex-start;
  }
  .contact-details-desktop {
    display: flex;
    align-items: center;
  }
  .crop-icon {
    margin-right: 8px;
  }
  .leaflet-image {
    position: absolute;
    right: 64px;
    top: 40px;
  }
  .icon-padding {
    padding: 0px 6px;
  }
  .mobile-details {
    display: flex;
    align-items: flex-start;
  }
`;
interface IsMobileProps {
  isMobile: boolean;
}

export const RecommendationInfoSection = styled.div<IsMobileProps>`
  display: flex;
  flex-direction: column;
  padding: ${({ isMobile }) => (isMobile ? '32px 20px' : '40px 64px')};
`;

export const RecommendationInfoTitle = styled.div`
  width: max-content;
  padding: 2px 4px;
  font-weight: 600;
  line-height: 16px;
  border-radius: 4px;
  background: rgba(35, 38, 48, 0.5);
`;

export const RecommendationInfoTitleNonDDG = styled.div`
  width: max-content;
  padding: 2px 4px;
  font-weight: 600;
  line-height: 38px;
  font-size: 30px;
  display: table-footer-group;
  text-align: left;
`;

export const RecommendationInfoUserDetailsNonDDG = styled.div<IsMobileProps>`
  width: max-content;
  padding: 2px 4px;
  font-weight: 500;
  margin-top: 15px;
  line-height: 24px;
  font-size: 16px;
  display: ${({ isMobile }) => !isMobile && 'flex'};
`;

export const RecommendationInfoUserName = styled.h3`
  font-size: 30px;
  font-weight: 600;
  line-height: 38px;
  border-radius: 4px;
  text-align: justify;
  margin: 0;
  color: #fff;
`;

export const RecommendationInfoDate = styled.div`
  width: max-content;
  min-width: 242px;
  height: 31px;
  border-radius: 26px 0px;
  background: var(--Green-green-40, #5ebb7f);
  font-size: 14px;
  display: flex;
  justify-content: flex-start;
  align-items: center;
  padding: 0px 19px;
  margin-top: 12px;
`;

export const RecommendationInfoFieldInfo = styled.div`
  border-radius: 8px;
  border: 1px solid var(--Border-Default-Border-Default-Disabled-Primary, #C2C7D0);
  display: flex;
  font-size: 16px;
  padding: 12px 12px;
  align-items: center;
  justify-content-center;
  width: max-content;
  margin: 24px 0px;
`;

export const RecommendationInfoFieldInfoMobilePrimary = styled(RecommendationInfoFieldInfo)`
  border-radius: 8px 8px 0px 0px;
  width: auto;
  margin: 0px;
`;
export const RecommendationInfoFieldInfoMobileSecondary = styled(RecommendationInfoFieldInfo)`
  border-radius: 0 0 8px 8px;
  width: auto;
  margin: 0px;
`;

export const RecommendationInfoFieldInfoContainer = styled.div`
  padding: 24px 0px;
`;
export const RecommendationInfoReportOverview = styled.div`
  display: flex;
  flex-direction: column;
  padding: 24px 32px;
  margin-top: 15px;
  flex - direction: column;
  align - items: flex - start;
  gap: 16px;
  border-radius: 16px;
  border: 2px solid var(--Neutral-neutral-30, #C2C7D0);
  background: rgba(35, 38, 48, 0.25);
  backdrop-filter: blur(5px);
`;
export const IconBox = styled.span`
  border-radius: 6px;
  background: rgba(105, 111, 136, 0.5);
  display: flex;
  width: 24px;
  height: 24px;
  padding: 6.75px 4.836px 4.25px 4.164px;
  justify-content: center;
  align-items: center;
`;
export const StyledHeaderText = styled.span`
  font-family: 'Noto Sans';
  font-size: 30px;
  font-style: normal;
  font-weight: 600;
  line-height: 38px; /* 126.667% */
  letter-spacing: -0.375px;
  text-align: left;
`;
export const StyledHeaderSmallText = styled.span`
  display: flex;
  font-family: 'Noto Sans';
  font-size: 20px;
  font-style: normal;
  font-weight: 600;
  line-height: 28px; /* 140% */
  letter-spacing: -0.15px;
  text-align: left;
`;
export const StyledHeaderVerySmallText = styled.span`
  font-family: 'Noto Sans';
  font-size: 16px;
  font-style: normal;
  font-weight: 400;
  line-height: 24px; /* 150% */
  letter-spacing: -0.12px;
  text-align: left;
  white-space: pre-line;

  &::after {
    content: ' ';
  }
`;
