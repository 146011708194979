interface ContactDetails {
  email: string;
  phone: string;
}

const contactEmail = [
  {
    country: 'ROU',
    postal: 'ro',
    email: 'servicii.online@syngenta.com',
    phone: '+40 21 528 12 00',
  },
  {
    country: 'POL',
    postal: 'pl',
    email: 'syngenta.polska@syngenta.com',
    phone: '+48 (22) 550 27 11',
  },
  {
    country: 'HUN',
    postal: 'hu',
    email: 'e.vetomag@syngenta.com',
    phone: '+36 70 123 45 67',
  },
  {
    country: 'BGR',
    postal: 'bg',
    email: 'bulgaria.office@syngenta.com',
    phone: '+359 882 735 800',
  },
  {
    country: 'DEU',
    postal: 'de',
    email: 'e.seeds@syngenta.com',
    phone: '+49 5222 5308012',
  },
  {
    country: 'UKR',
    postal: 'ua',
    langCode: 'uk',
    email: 'syngenta.ua@syngenta.com',
    phone: '+38 (044) 494 17 71',
  },
  {
    country: 'ZAF',
    postal: 'za',
    email: 'https://sensako.co.za/Sales',
    phone: '',
  },
];

const nonDDGContactEmail = [
  {
    country: 'ROU',
    postal: 'ro',
    email: 'Servicii.Online@syngenta.com',
    phone: '0745973337',
  },
  {
    country: 'POL',
    postal: 'pl',
    email: 'marta.cywinska@syngenta.com / joanna.tarkowska@syngenta.com',
    phone: '+48 602 769 296  / +48-785 241 721',
  },
  {
    country: 'BGR',
    postal: 'bg',
    email: 'bulgaria.office@syngenta.com',
    phone: '+359882735800',
  },
];

const DUMMY_MAIL = 'contact@syngenta.com';
const DUMMY_PHONE_NUMBER = '01223 883400';
const langCode = localStorage.getItem('i18nextLng');

const findContact = (countryCode: string | null): ContactDetails => {
  const contact = contactEmail.find(
    (data) =>
      data.country.toLocaleLowerCase() === countryCode?.toLocaleLowerCase() ||
      data.postal.toLocaleLowerCase() === countryCode?.toLocaleLowerCase() ||
      data.langCode?.toLocaleLowerCase() === langCode
  );
  return contact
    ? { email: contact.email, phone: contact.phone }
    : { email: DUMMY_MAIL, phone: DUMMY_PHONE_NUMBER };
};

const findNonDDGContact = (countryCode: string | null): ContactDetails => {
  const contact = nonDDGContactEmail.find(
    (data) =>
      data.country.toLocaleLowerCase() === countryCode?.toLocaleLowerCase() ||
      data.postal.toLocaleLowerCase() === countryCode?.toLocaleLowerCase()
  );
  return contact
    ? { email: contact.email, phone: contact.phone }
    : { email: DUMMY_MAIL, phone: DUMMY_PHONE_NUMBER };
};

export const getContactDetails = (countryCode: string, isDDG?: boolean): ContactDetails => {
  if (isDDG) {
    return findContact(countryCode);
  } else {
    return findNonDDGContact(countryCode);
  }
};

export const getEmailId = (countryCode: string | null): string => {
  if (countryCode === null) {
    return DUMMY_MAIL;
  }
  return getContactDetails(countryCode).email;
};
