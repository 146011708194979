import { useTranslation } from 'react-i18next';
import ButtonCircle from 'components/Buttons/ButtonCircle/ButtonCircle';
import { StyledIconHub } from './DropAPinControl.styles';
import { TooltipProps } from 'antd';

interface IProps {
  id?: string;
  isVisible?: boolean;
  tooltipProps?: TooltipProps;
  onClick?: () => void;
  disabled?: boolean;
}

const DropAPinControl = ({ id = '', isVisible = true, disabled = false, onClick, tooltipProps }: IProps): JSX.Element => {
  const [t] = useTranslation();

  return (
    <div id={id} data-testid="DropAPinControl" style={{ opacity: disabled ? '0.8' : '1' }}>
      {isVisible && (
        <ButtonCircle
          id={`${id}Button`}
          onClick={onClick}
          iconCustom={StyledIconHub}
          buttonProps={{ disabled }}
          tooltipProps={{
            title: t('Drop a pin'),
            placement: 'left',
            open: true,
            style: {
              zIndex: '1 !important',
            },
            className: 'tooltip-test',
            ...tooltipProps,
          }}
        />
      )}
    </div>
  );
};

export default DropAPinControl;
