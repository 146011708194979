import styled from 'styled-components';
import { colors, SYT_PREFIX, Collapse, Divider } from 'syngenta-digital-cropwise-react-ui-kit';

export const FieldListContainer = styled.div`
  display: flex;
  flex-direction: column;
`;

type LongTermMobileDesignProps = {
  isLongTermMobileDesign?: boolean;
};

interface StyledPanelProps extends LongTermMobileDesignProps {
  expandIcon?: any;
  showErrorBoder?: boolean;
}

export const StyledCollapse = styled(Collapse)<StyledPanelProps>`
  border: none;
  padding: 0;
  background-color: white;

  ${(props) => (props.isLongTermMobileDesign && `width: 100%;`)}

  .syt-antd-collapse-item,
  .syt-antd-collapse-content{
    border: none;
    padding-left: 0;
    margin-bottom: 15px;
  }
  .syt-antd-collapse-item syt-antd-collapse-item-active{
    border-radius: 10px; !important;
  }
  .syt-antd-collapse-item-active {
    border: 2px solid #F3F4F6 !important;
    padding: 0;
    padding-bottom: 10px;
    border-radius: 10px !important
  }
  

  .${SYT_PREFIX}-collapse-header {
    padding: 0 !important;
  }

  .${SYT_PREFIX}-collapse-header-text {
    line-height: 20px;
    letter-spacing: -0.0075em;
    border: none;
    color: ${colors.neutral80};
    margin-bottom: 8px;
    font-size: 20px;
    font-weight: 600;
    .syt-antd-card-bordered {
      ${(props) => (props.showErrorBoder && `border: 1px solid red;`)}
    }
  }
  .syt-antd-collapse-arrow {
    top: 47% !important;
    z-index: 1000;
  }
`;

const { Panel } = Collapse;

export const StyledPanel = styled(Panel)`
  border: none;
  background-color: ${colors.neutral00};
  padding: 0;

  .${SYT_PREFIX}-collapse-content-box {
    padding: 10px 15px 0px 0px;
    margin-bottom: -20px;
  }
`;

export const StyledDivider = styled(Divider)`
  height: 1px;
  background-color: ${colors.neutral20};
  width: 100%;
  padding: 0;
  margin: 0;
`;
