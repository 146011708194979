import styled from 'styled-components';
import {
  colors,
  ProgressStepper,
  Progress,
  SYT_PREFIX,
} from 'syngenta-digital-cropwise-react-ui-kit';

export const ProgressBarOverlayStyled = styled.div`
  height: calc(var(--vh, 1vh) * 100);
  z-index: 1;
  display: block;
  position: absolute;
  height: 100%;
  top: 0;
  left: 0;
  right: 0;
  background: rgba(0, 0, 0, 0.5);
`;

export const ProgressStepperStyled = styled(ProgressStepper)`
  background-color: ${colors.neutral80};

  .${SYT_PREFIX}-progress-stepper-step-label {
    span {
      color: ${colors.neutral00};
    }
  }

  button {
    border: 0;
  }
`;

export const ProgressStyled = styled(Progress)`
  line-height: 0;

  .${SYT_PREFIX}-progress-inner {
    border-radius: 0px;
  }

  .${SYT_PREFIX}-progress-bg {
    border-radius: 0px;
    background-color: ${colors.blue50} !important;
  }
`;
