import { DDLOptions } from 'context/store/recommendationFormReducer';
import { isHungary } from './countryCode';

const traitOptionConstants = [
  {
    label: 'Clearfield® / Clearfield®Plus',
    value: 'IMI,IMI+',
    disabled: false,
  },
  {
    label: 'Express™ / Fluence™',
    value: 'Sulfo',
    disabled: false,
  },
  {
    label: 'Conventional',
    value: 'Conventional',
    disabled: false,
  },
  {
    label: 'A.I.R.™ (Listego®Pro / Express™ / Fluence™)',
    value: 'AIR',
    disabled: false,
  },
];

export const clearfieldTraitValues = ['IMI', 'IMI+'];

export const getTraitOptions = (uniqueTraitValues: string[], countryCode: string | undefined) => {
  if (uniqueTraitValues.length > 0) {
    // remove AIR for HU
    const filterUniqueTraitValues = uniqueTraitValues.filter((item)=> item !== 'AIR' || !isHungary(countryCode));
    return traitOptionConstants.filter(item => {
      const itemValues = item.value.split(',');
      return itemValues.some(v => filterUniqueTraitValues.includes(v));
    });
  }
  return [];
};

export const getTraitLabel = (value: any) => {
  const traitValue = clearfieldTraitValues.includes(value) ? traitOptionConstants[0].value : value;
  const matchedValue = traitOptionConstants.find(item => item.value === traitValue);
  return matchedValue?.label ?? '';
};

// as we have merged Clearfield / Clearfield Plus, splitting the values before sending it to API
export const formatTraits = (traits: DDLOptions[]) => {
  const traitValues = traits.map((item: DDLOptions)=> item.value);
  return traitValues.flatMap((t: string) => t.split(','));
};
